import {theme} from "../../../Signals/ThemeSignal";
import TogglerHook from "../../../Hooks/Toggler/TogglerHook";
import { TriangleDownIcon } from '../../../Icons/TrianglDownIcon';

interface DropdownInterface {
    form: any;
    name: string;
    values: {id: string, label: string}[]
    setValues: (values: {[name: string]: string}) => void;
    className?: string;
}

export function Dropdown({form, name, values, setValues, className = ''}: DropdownInterface) {
    const currentValue = values[values.map((value: {id: string, label: string}) => value.id).indexOf(form.fields[name].value)];
    const {isVisible, toggleVisibility, ref} = TogglerHook();

    const handleChange = (id: string) => {
        const newValues: any = {};
        newValues[name] = id;

        setValues(newValues);
    }


    return <div className={`${className} cursor-pointer`} onClick={toggleVisibility}>
        <div className={`p-[10px] flex justify-between rounded-xl font-bold ${currentValue.id !== '' ? `bg-blue-500 text-white` : form.fields[name].errors.length > 0 ? `border-red-500 border text-red-500` : `border-blue-500 border text-blue-500`}`}>
            {currentValue.label}
            <TriangleDownIcon className={`w-[20px]`}/>
        </div>
        {isVisible && <div className={`absolute bg-white border mt-[5px] rounded-xl border-blue-500 font-bold text-blue-500`} ref={ref}>
            {values.filter((value: {id: string, label: string}) => value.id !== form.fields[name].value).map((value: {id: string, label: string}, index: number) => <div className={`p-[10px] hover:bg-${theme.value}-primary-100 rounded-xl`} key={`dropdown-choice-${index}`} onClick={() => handleChange(value.id)}>
                {value.label}
            </div>)}
        </div>}
    </div>;
}
