import { theme } from '../../Signals/ThemeSignal';
import { CheckRoundedIcon } from '../../Icons/CheckRoundedIcon';
import Translator from '../../Utils/Translator';
import { useEffect, useState } from 'react';

interface CopyButtonInterface {
    value: string;
    timeout?: number; // seconds
    label: string;
    className?: string;
    labelClassName?: string;
    withBorder?: boolean;
}

export function CopyButton({value, timeout = 3, label, className = '', labelClassName = '', withBorder = true}: CopyButtonInterface) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), timeout*1000);
        }
    }, [copied]);

    const copyLink = () => {
        navigator.clipboard.writeText(value);

        setCopied(true);
    }

    return <div
        className={`border-${theme.value}-primary-950 rounded text-center flex justify-center align-items cursor-pointer ${withBorder ? `border-[1px] p-[5px]` : ``}`}
        onClick={copyLink}>
        {copied && <CheckRoundedIcon className={`w-[30px]`}/>}
        <div className={`${labelClassName}`}>{label}</div>
    </div>
}
