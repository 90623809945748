import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import { BACK_OFFICE_ALL_ADMINISTRATORS, BACK_OFFICE_ALL_USERS } from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { UserInterface } from '../Interfaces/UserInterface';

export function BackOfficeUsers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_ALL_USERS,
            (response: any) => setUsers(response.data)
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_users'}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {users.map((user: UserInterface) => <Link to={`/back-office/administrators/${user.id}`}>
                    <div key={`user-${user.id}`}
                         className={`hover:bg-${theme.value}-primary-200 rounded p-[10px] border-[1px] border-${theme.value}-primary-950`}>
                        {user.firstname} {user.lastname} ({user.login})
                    </div>
                </Link>)}
            </div>
        </div>
    </PageTemplate>;
}
