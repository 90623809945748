import {signal} from "@preact/signals-react";
import {generateUUID} from "../Utils/Uuid";
import {LocalStorage} from "../Utils/LocalStorage";
import { NewApiManager } from '../Utils/NewApiManager';
import { DEVICE_REGISTER } from '../Constants/ApiUrls';
import { setFirebaseConfig } from './FirebaseDatabaseSignal';

export const deviceId = signal(LocalStorage.getDeviceId());

if (deviceId.value === undefined) {
    deviceId.value = generateUUID();
    LocalStorage.setDeviceId(deviceId.value);
}

export function registerDevice() {
    NewApiManager.post(
        DEVICE_REGISTER,
        {
            deviceId: deviceId.value,
        },
        (response: any) => setFirebaseConfig(response.data)
    );
}

registerDevice();
