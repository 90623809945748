import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useRef, useState} from "react";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_NOTE_NAME, LABEL_UPDATE_NOTE_FORM_ERROR, LABEL_UPDATE_NOTE_FORM_SUCCESS,
} from "../Constants/Label";
import {Editor} from "@tinymce/tinymce-react";
import {theme} from "../Signals/ThemeSignal";
import { useNavigate, useParams } from "react-router-dom";
import {
    BACK_OFFICE_DELETE_NOTE,
    CREATE_NOTE_FORM_VALIDATOR,
    NOTE,
    TINYMCE_API_KEY,
    UPDATE_NOTE
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { DeleteConfirmationModal } from '../Components/DeleteConfirmationModal/DeleteConfirmationModal';
import Translator from '../Utils/Translator';

export function UpdateNote() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitLock, submitSuccess, submitError} = FormHook(['name', 'content']);
    const [tinyMCEApiKey, setTinyMCEApiKey] = useState<undefined|string>(undefined);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {
        resetGlobalSearch();
        if (id === undefined) {
            return;
        }
        loadConstrains(CREATE_NOTE_FORM_VALIDATOR);
        NewApiManager.get(
            TINYMCE_API_KEY,
            (response: any) => setTinyMCEApiKey(response.data)
        );
        NewApiManager.get(
            NOTE(id),
            (response: any) => setValues(response.data)
        );
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (editorRef.current) {
            // @ts-ignore
            setValues({content: editorRef.current.getContent()});
        }

        if (!shouldSubmit || id === undefined) {
            return;
        }

        submitLock();
        NewApiManager.put(
            UPDATE_NOTE(id),
            {
                name: form.fields.name.value,
                content: form.fields.content.value
            },
            () => submitSuccess(LABEL_UPDATE_NOTE_FORM_SUCCESS),
            () => submitError(LABEL_UPDATE_NOTE_FORM_ERROR)
        );
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const deleteNote = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_NOTE(id),
            () => navigate('/activities')
        );
    }

    return <PageTemplate sidebar={`normal`}>
        <DeleteConfirmationModal message={Translator.translateId('label__note_delete_confirmation_message')} deleteAction={deleteNote} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`p-[10px]`}>
            {tinyMCEApiKey !== undefined && form.fields.content.value !== null && <form onSubmit={onSubmit}>
                <div className={`flex gap-[10px]`}>
                    <Input className={`w-full`} form={form} name={'name'} handleChange={handleChange}
                           placeholder={LABEL_NOTE_NAME}/>
                    <button type={'submit'}
                            className={`border-${theme.value}-primary-950 border-[1px] p-[10px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>Save
                    </button>
                    <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`}
                            onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
                </div>

                <div className={`mt-[10px]`}>
                    {/* @ts-ignore */}
                    <Editor onInit={(_evt, editor) => editorRef.current = editor}
                            apiKey={tinyMCEApiKey}
                            initialValue={form.fields.content.value.toString()}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                    />
                </div>
            </form>}
        </div>
    </PageTemplate>;
}
