import {VideoDataInterface} from "../../Interfaces/VideoDataInterface";
import {useEffect, useState} from "react";
import {theme} from "../../Signals/ThemeSignal";
import {addToListProcess} from "../../Signals/PlayingMediaListSignal";
import {AddToList} from "../../Icons/AddToListIcon";
import {CheckIcon} from "../../Icons/CheckIcon";
import {TrashIcon} from "../../Icons/TrashIcon";
import {DeleteConfirmationModal} from "../DeleteConfirmationModal/DeleteConfirmationModal";
import Translator from "../../Utils/Translator";
import {DotIcon} from "../../Icons/DotIcon";
import {PLAYLIST_VIDEO_TOGGLE} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";

export function YoutubeVideoCard({playlistId, videoData, className = '', loadPlaylist}: {videoData: VideoDataInterface, className: string, playlistId?: string, loadPlaylist?: () => void}) {
    const [showAddedIndicator, setShowAddedIndicator] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    useEffect(() => {
        if (showAddedIndicator) {
            setTimeout(() => {
                setShowAddedIndicator(false);
            }, 2000);
        }
    }, [showAddedIndicator]);

    const onlyAddToList = () => {
        addToListProcess(videoData);
        setShowAddedIndicator(true);
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const deleteCurrentVideo = () => {
        if (playlistId === undefined || videoData.videoId === undefined) {
            return;
        }
        NewApiManager.post(
            PLAYLIST_VIDEO_TOGGLE(playlistId),
            {
                videoIds: [videoData.videoId],
                toggleVideos: true
            },
            () => {
                if (loadPlaylist !== undefined) loadPlaylist();
                toggleDeletionModal();
            }
        );
    }

    return <>
        <DeleteConfirmationModal message={Translator.translateId('label__playlist_video_delete_confirmation_message')} deleteAction={deleteCurrentVideo} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`${className}`}>
            <div>
                <div className={`flex justify-end`}>
                    <div className={`flex justify-center items-center border-[1px] border-${theme.value}-primary-950 rounded h-[50px] w-[50px] mb-[-50px] z-important bg-${theme.value}-primary-50`}>
                        {!showAddedIndicator && <AddToList className={`w-[40px] h-[40px] fill-${theme.value}-primary-950`} onClick={onlyAddToList}/>}
                        {showAddedIndicator && <CheckIcon className={`w-[30px] h-[30px] fill-${theme.value}-primary-950`}/>}
                    </div>
                </div>
                <img src={videoData.image.url} className={`rounded-2xl z-normal cursor-pointer`} onClick={() => addToListProcess(videoData, true)}/>
            </div>
            <div
                className={`flex justify-center items-center mt-[-35px] mb-[5px] z-important`}>
                <div className={`rounded-[50px] h-[30px] border-[1px] border-${theme.value}-primary-950 bg-${theme.value}-primary-50 flex items-center px-[5px]`}>
                    {(videoData.duration.hours === '00' && videoData.duration.minutes === '0' && videoData.duration.seconds === '0') ? <div className={`font-bold flex items-center pr-[5px] gap-[5px]`}><DotIcon className={`w-[20px] fill-red-500 animate-ping absolute`}/><DotIcon className={`w-[20px] fill-red-500`}/> {Translator.translateId(`label__live`)}</div> : `${videoData.duration.hours.padStart(2, '0')}:${videoData.duration.minutes.padStart(2, '0')}:${videoData.duration.seconds.padStart(2, '0')}`}
                </div>
            </div>
            <div className={`line-clamp-2 font-bold cursor-pointer`}
                      dangerouslySetInnerHTML={{__html: videoData.title}} onClick={() => addToListProcess(videoData, true)}></div>
            <div className={`flex justify-between`}>
                <div dangerouslySetInnerHTML={{__html: videoData.channelTitle}}></div>
                {playlistId && <div onClick={toggleDeletionModal}>
                    <TrashIcon className={`w-[20px]`}/>
                </div>}
            </div>
        </div>
    </>;
}