import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {NewsInterface} from "../Interfaces/NewsInterface";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {ALL_NEWS} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { NewsPremium } from '../Components/News/NewsPremium';
import { NewsSecondary } from '../Components/News/NewsSecondary';

export function News() {
    const [news, setNews] = useState<undefined|NewsInterface[]>();

    useEffect(() => {
        resetGlobalSearch();
        NewApiManager.get(
            ALL_NEWS,
            (response: any) => setNews(response.data)
        );
    }, []);

    return <PageTemplate sidebar={'normal'}>
        <div className={'md:flex md:gap-[10px]'}>
            <div className={`w-full md:w-[70%]`}>
                {news !== undefined && news.filter((article: NewsInterface) => article.position === 'position_1').map((article: NewsInterface) =>
                    <NewsPremium news={article} key={`news-${article.id}`}/>
                )}
            </div>
            <div className={`flex gap-[10px] flex-wrap md:w-[30%]`}>
                {news !== undefined && news.filter((article: NewsInterface) => article.position === 'position_2').map((article: NewsInterface) => <NewsSecondary news={article} key={`news-${article.id}`} className={`w-[calc(50%-5px)] md:w-full`}/>)}
            </div>
        </div>
    </PageTemplate>;
}
