import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_NEWS_CONFIG_ARTICLE_URL_SELECTOR, LABEL_NEWS_CONFIG_BASE_SELECTOR,
    LABEL_NEWS_CONFIG_DESCRIPTION,
    LABEL_NEWS_CONFIG_DESCRIPTION_SELECTOR, LABEL_NEWS_CONFIG_IMAGE_CREDIT_SELECTOR,
    LABEL_NEWS_CONFIG_IMAGE_SELECTOR,
    LABEL_NEWS_CONFIG_IN_ARTICLE_DESCRIPTION_SELECTOR, LABEL_NEWS_CONFIG_IN_ARTICLE_SOURCE_DATETIME_SELECTOR,
    LABEL_NEWS_CONFIG_LANG,
    LABEL_NEWS_CONFIG_NAME,
    LABEL_NEWS_CONFIG_SOURCE_DATETIME_SELECTOR,
    LABEL_NEWS_CONFIG_TITLE_SELECTOR, LABEL_NEWS_CONFIG_UPDATE_FORM_ERROR, LABEL_NEWS_CONFIG_UPDATE_FORM_SUCCESS,
    LABEL_NEWS_CONFIG_URL,
} from "../Constants/Label";
import {useNavigate, useParams} from "react-router-dom";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {NewsInterface} from "../Interfaces/NewsInterface";
import {NewsCard} from "../Components/News/NewsCard";
import {ExclamationTriangledIcon} from "../Icons/ExclamationTriangledIcon";
import { NewsConfigInterface, positions } from "../Interfaces/NewsConfigInterface";
import {
    BACK_OFFICE_DELETE_NEWS_CONFIG, BACK_OFFICE_ENABLE_NEWS_CONFIG, BACK_OFFICE_FETCH_NEWS_DATA,
    BACK_OFFICE_NEWS_CONFIG,
    BACK_OFFICE_UPDATE_NEWS_CONFIG,
    BACK_OFFICE_UPDATE_NEWS_CONFIG_FORM_VALIDATOR
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { Dropdown } from '../Components/Common/Input/Dropdown';
import { SpinnerIcon } from '../Icons/SpinnerIcon';

export function BackOfficeNewsConfigUpdate() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);

    const [config, setConfig] = useState<NewsConfigInterface|undefined>(undefined);

    const [showSavedFomCreate, setShowSavedFromCreate] = useState(queryParameters.get('saved') === '1')

    const {id} = useParams();

    const [saved, setSaved] = useState(true);

    const [news, setNews] = useState<{
        loading: boolean,
        data: NewsInterface[]|undefined,
        success: boolean,
        errorMessage?: string,
    }>({
        loading: false,
        data: undefined,
        success: false,
        errorMessage: undefined,
    });
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {shouldSubmit, form, handleChange, setForm, setConstrains, setValues, loadConstrains, submitLock, submitError, submitSuccess} = FormHook(['url', 'name', 'description', 'lang', 'baseSelector', 'articleUrlSelector', 'imageSelector', 'imageCreditSelector', 'titleSelector', 'descriptionSelector', 'sourceDateTimeSelector', 'inArticleDescriptionSelector', 'inArticleSourceDateTimeSelector', 'position']);

    const onSubmit = (e: any) => {
        if (id === undefined) {
            return;
        }

        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            BACK_OFFICE_UPDATE_NEWS_CONFIG(id),
            {
                name: form.fields.name.value,
                lang: form.fields.lang.value,
                url: form.fields.url.value,
                position: form.fields.position.value,
                description: form.fields.description.value,
                baseSelector: form.fields.baseSelector.value,
                articleUrlSelector: form.fields.articleUrlSelector.value,
                imageSelector: form.fields.imageSelector.value,
                imageCreditSelector: form.fields.imageCreditSelector.value,
                titleSelector: form.fields.titleSelector.value,
                descriptionSelector: form.fields.descriptionSelector.value,
                sourceDateTimeSelector: form.fields.sourceDateTimeSelector.value,
                inArticleDescriptionSelector: form.fields.inArticleDescriptionSelector.value,
                inArticleSourceDateTimeSelector: form.fields.inArticleSourceDateTimeSelector.value
            },
            () => {
                submitSuccess(LABEL_NEWS_CONFIG_UPDATE_FORM_SUCCESS);
                setSaved(() => true);
            },
            () => submitError(LABEL_NEWS_CONFIG_UPDATE_FORM_ERROR)
        );
    };

    const onChange = (e: any) => {
        setSaved(() => false);
        setShowSavedFromCreate(() => false);
        handleChange(e);
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_UPDATE_NEWS_CONFIG_FORM_VALIDATOR);
    }, []);


    useEffect(() => {
        if (id !== undefined) {
            loadNewsConfig(id);
        }
    }, []);

    const loadNewsConfig = (id: string) => {
        NewApiManager.get(
            BACK_OFFICE_NEWS_CONFIG(id),
            (response: any) => {
                setValues({
                    url: response.data.url,
                    lang: response.data.lang,
                    name: response.data.name,
                    position: response.data.position,
                    baseSelector: response.data.baseSelector !== null ? response.data.baseSelector : '',
                    description: response.data.description !== null ? response.data.description : '',
                    articleUrlSelector: response.data.articleUrlSelector !== null ? response.data.articleUrlSelector : '',
                    imageSelector: response.data.imageSelector !== null ? response.data.imageSelector : '',
                    imageCreditSelector: response.data.imageCreditSelector !== null ? response.data.imageCreditSelector : '',
                    titleSelector: response.data.titleSelector !== null ? response.data.titleSelector : '',
                    descriptionSelector: response.data.descriptionSelector !== null ? response.data.descriptionSelector : '',
                    sourceDateTimeSelector: response.data.sourceDateTimeSelector !== null ? response.data.sourceDateTimeSelector : '',
                    inArticleDescriptionSelector: response.data.inArticleDescriptionSelector !== null ? response.data.inArticleDescriptionSelector : '',
                    inArticleSourceDateTimeSelector: response.data.inArticleSourceDateTimeSelector !== null ? response.data.inArticleSourceDateTimeSelector : '',
                });
                setConfig(response.data);
            }
        );
    }

    const deleteNewsConfig = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_NEWS_CONFIG(id),
            () => navigate('/back-office/news-configs')
        );
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const checkConfiguration = () => {
        setNews({
           ...news,
            loading: true,
            data: [],
        });

        NewApiManager.post(
            BACK_OFFICE_FETCH_NEWS_DATA,
            {
                name: form.fields.name.value,
                lang: form.fields.lang.value,
                url: form.fields.url.value,
                position: form.fields.position.value,
                description: form.fields.description.value,
                baseSelector: form.fields.baseSelector.value,
                articleUrlSelector: form.fields.articleUrlSelector.value,
                imageSelector: form.fields.imageSelector.value,
                imageCreditSelector: form.fields.imageCreditSelector.value,
                titleSelector: form.fields.titleSelector.value,
                descriptionSelector: form.fields.descriptionSelector.value,
                sourceDateTimeSelector: form.fields.sourceDateTimeSelector.value,
                inArticleDescriptionSelector: form.fields.inArticleDescriptionSelector.value,
                inArticleSourceDateTimeSelector: form.fields.inArticleSourceDateTimeSelector.value
            },
            (response: any) => {
                setNews({
                    ...news,
                    loading: false,
                    data: response.data,
                    success: true,
                    errorMessage: undefined,
                });
            },
            (response: any) => {setNews({
                ...news,
                loading: false,
                data: [],
                success: false,
                errorMessage: response.data
            });
            }
        );
    };

    const enableNewsConfig = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.get(
            BACK_OFFICE_ENABLE_NEWS_CONFIG(id),
            () => loadNewsConfig(id)
        );
    }

    return <PageTemplate sidebar={"back-office"}>
        <DeleteConfirmationModal message={Translator.translateId('label__news_config_delete_confirmation_message')} deleteAction={deleteNewsConfig} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/back-office/news-configs'} label={'label__back_office_news_config'}/>
                <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`} onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
            </div>
            <div className={`mt-[10px]`}>
                <form onSubmit={onSubmit}>
                    <Input form={form} name={'url'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_URL}/>
                    <Input className={`mt-[10px]`} form={form} name={'lang'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_LANG}/>
                    <Input className={`mt-[10px]`} form={form} name={'name'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_NAME}/>
                    <Input className={`mt-[10px]`} form={form} name={'description'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_DESCRIPTION}/>
                    <Input className={`mt-[10px]`} form={form} name={'baseSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_BASE_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'articleUrlSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_ARTICLE_URL_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'imageSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_IMAGE_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'imageCreditSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_IMAGE_CREDIT_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'titleSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_TITLE_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'descriptionSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_DESCRIPTION_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'sourceDateTimeSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_SOURCE_DATETIME_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'inArticleDescriptionSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_IN_ARTICLE_DESCRIPTION_SELECTOR}/>
                    <Input className={`mt-[10px]`} form={form} name={'inArticleSourceDateTimeSelector'} handleChange={onChange}
                           placeholder={LABEL_NEWS_CONFIG_IN_ARTICLE_SOURCE_DATETIME_SELECTOR}/>
                    <Dropdown className={`mt-[10px]`} form={form} name={'position'} values={[
                        {id: '', label: Translator.translateId('label__news_position')},
                        ...positions
                    ]} setValues={setValues}/>
                    {form.error !== undefined && <div className={`pt-[10px] text-red-600`}>
                        {Translator.translateId(form.error.id)}
                    </div>}
                    {(form.success !== undefined || showSavedFomCreate) && <div className={`pt-[10px] text-green-600`}>
                        {Translator.translate(LABEL_NEWS_CONFIG_UPDATE_FORM_SUCCESS)}
                    </div>}
                    {!saved && <div className={`italic flex gap-[10px] mt-[10px]`}>
                        <ExclamationTriangledIcon className={`w-[20px`}/> {Translator.translateId('label__news_config_not_saved')}
                    </div>}
                    <div className={`flex justify-end py-[10px]`}>
                        <button type={'submit'}
                                className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                    </div>
                </form>
                {config !== undefined && config.lastExecutionDateTime !== null && config.isBroken && <div className={`flex gap-[10px] italic text-red-600`}>
                    <ExclamationTriangledIcon className={`w-[20px]`}/> {Translator.translateId('label__back_office_news_config_disabled')} <span onClick={enableNewsConfig} className={`underline cursor-pointer`}>{Translator.translateId('cta__reactivate')}</span>
                </div>}
            </div>
        </div>
        <div className={`border-0 border-t-[1px] border-${theme.value}-primary-950 p-[10px]`}>
            <button onClick={checkConfiguration}
                    className={`mb-[10px] border-[1px] border-${theme.value}-primary-950 p-[10px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>{Translator.translateId('cta__check_news_config')}</button>
            {news.loading && <SpinnerIcon/>}
            <div>
                {news.errorMessage !== undefined && <div className={`text-red-600`}>{news.errorMessage}</div>}
                {news.errorMessage === undefined && news.data !== undefined && news.data.length > 0 && news.data.map((currentNews: NewsInterface, index: number) =>
                    <NewsCard key={`news-${index}`} news={currentNews}/>)}
            </div>
        </div>
    </PageTemplate>;
}
