import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import AbstractApi from "../Api/AbstractApi";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_COMPANY_INTERNAL_NAME,
    LABEL_COMPANY_NAME, LABEL_COMPANY_URL,
    LABEL_COUPON_UPDATE_FORM_ERROR, LABEL_COUPON_UPDATE_FORM_SUCCESS,
} from "../Constants/Label";
import {useNavigate, useParams} from "react-router-dom";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {NewApiManager} from "../Utils/NewApiManager";
import {
    BACK_OFFICE_COMPANY, BACK_OFFICE_DELETE_COMPANY,
    BACK_OFFICE_UPDATE_COMPANY,
    BACK_OFFICE_UPDATE_COMPANY_FORM_VALIDATOR
} from "../Constants/ApiUrls";

export function BackOfficeCompanyUpdate() {
    const {id} = useParams();
    const navigate = useNavigate();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {shouldSubmit, form, handleChange, setForm, setConstrains, setValues, submitSuccess, submitLock, submitError, handleChangeCheckbox, loadConstrains} = FormHook(['name', 'internalName', 'url']);

    const onSubmit = (e: any) => {
        if (id === undefined) {
            return;
        }

        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            BACK_OFFICE_UPDATE_COMPANY(id),
            {
                name: form.fields.name.value,
                internalName: form.fields.internalName.value,
                url: form.fields.url.value !== '' ? form.fields.url.value : null
            },
            () => submitSuccess(LABEL_COUPON_UPDATE_FORM_SUCCESS),
            () => submitError(LABEL_COUPON_UPDATE_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_UPDATE_COMPANY_FORM_VALIDATOR);
    }, []);


    useEffect(() => {
        if (id === undefined) {
            return;
        }
        NewApiManager.get(
            BACK_OFFICE_COMPANY(id),
            (response: any) => {
                setValues({
                    'name': response.data.name,
                    'internalName': response.data.internalName,
                    'url': response.data.url === null ? '' : response.data.url,
                })
            }
        )
    }, []);

    const deleteCompany = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_COMPANY(id),
            () =>  navigate('/back-office/companies')
        );
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    return <PageTemplate sidebar={"back-office"}>
        <DeleteConfirmationModal message={Translator.translateId('label__company_delete_confirmation_message')} deleteAction={deleteCompany} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/back-office/companies'} label={'label__back_office_companies'}/>
                <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`} onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
            </div>
            <div className={`mt-[10px]`}>
                <form onSubmit={onSubmit}>
                    <Input form={form} name={'name'} handleChange={handleChange}
                           placeholder={LABEL_COMPANY_NAME}/>
                    <Input className={`mt-[10px]`} form={form} name={'internalName'} handleChange={handleChange}
                           placeholder={LABEL_COMPANY_INTERNAL_NAME}/>
                    <Input className={`mt-[10px]`} form={form} name={'url'} handleChange={handleChange}
                           placeholder={LABEL_COMPANY_URL}/>
                    {form.error !== undefined && <div className={`pt-[10px] text-red-600`}>
                        {Translator.translateId(form.error.id)}
                    </div>}
                    {form.success !== undefined && <div className={`pt-[10px] text-green-600`}>
                        {Translator.translateId(form.success.id)}
                    </div>}
                    <div className={`flex justify-end py-[10px]`}>
                        <button type={'submit'}
                                className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                    </div>
                </form>
            </div>

        </div>
    </PageTemplate>;
}
