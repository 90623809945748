import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {theme} from "../Signals/ThemeSignal";
import {FormHook} from "../Hooks/FormHook";
import { useEffect, useState } from "react";
import Input from "../Components/Common/Input/Input";
import {
    CTA_SUBMIT,
    LABEL_LOG_IN_FORM_ERROR, LABEL_LOGIN_OR_EMAIL,
    LABEL_PASSWORD
} from "../Constants/Label";
import Translator from "../Utils/Translator";
import {setUserAccount} from "../Signals/UserAccountSignal";
import {Link, useNavigate} from "react-router-dom";
import {USER_LOG_IN, USER_LOG_IN_FORM_VALIDATOR} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import QRCode from 'react-qr-code';
import { deviceId } from '../Signals/DeviceIdSignal';
import { closeQrCodeAutoLogInRef, qrCodeAutoLogInRef } from '../FirebaseRefs/Autologin';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { closeRef } from '../Signals/FirebaseDatabaseSignal';

export function LogIn() {
    const navigate = useNavigate();
    const {form, handleChange, shouldSubmit, loadConstrains, submitError, submitLock} = FormHook(['loginOrEmail', 'password']);

    const urlParams = new URLSearchParams(window.location.search).get('auto-log-in');
    const [isAutoLogIn, setIsAutoLogIn] = useState(urlParams !== null && urlParams === '1');

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            USER_LOG_IN,
            {
                loginOrEmail: form.fields.loginOrEmail.value,
                password: form.fields.password.value
            },
            (response: any) => {
                setUserAccount(response.data);
                closeQrCodeAutoLogInRef();
                navigate('/extensions/website-log-in');
            },
            () => submitError(LABEL_LOG_IN_FORM_ERROR)
        );
    };

    useEffect(() => {
        if (isAutoLogIn) {
            setTimeout(qrCodeAutoLogInRef, 1000);
        } else {
            closeQrCodeAutoLogInRef();
        }
    }, [isAutoLogIn]);

    useEffect(() => {
        loadConstrains(USER_LOG_IN_FORM_VALIDATOR);
    }, []);

    const externalLogInUrl = `${process.env.REACT_APP_FRONT_URL}/qr-code-auto-log-in/${deviceId}`;

    return <PageTemplate childrenClassName={`min-h-[700px]`} sidebar={"normal"}>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__log_in')}</div>
                </div>
                <div className={`pt-[60px] p-[10px] mb-[10px]`}>
                    <div className={`flex gap-[10px]`}>
                        <div onClick={() => setIsAutoLogIn(false)} className={`w-full text-center border p-[3px] rounded cursor-pointer ${!isAutoLogIn ? 'bg-gray-200' : ''}`}>
                            {Translator.translateId('cta__log_in_via_login')}
                        </div>
                        <div onClick={() => setIsAutoLogIn(true)} className={`w-full text-center border p-[3px] rounded cursor-pointer ${isAutoLogIn ? 'bg-gray-200' : ''}`}>
                            {Translator.translateId('cta__log_in_via_qr_code')}
                        </div>
                    </div>
                    {!isAutoLogIn ? <form onSubmit={onSubmit}>
                        <Input className={`mt-[10px]`} form={form} name={'loginOrEmail'} handleChange={handleChange}
                               placeholder={LABEL_LOGIN_OR_EMAIL}/>
                        <Input className={`mt-[10px]`} type={"password"} form={form} name={'password'}
                               handleChange={handleChange} placeholder={LABEL_PASSWORD}/>
                        {form.error !== undefined && <div
                            className={`mt-[10px] bg-red-200 border-[1px] border-red-300 text-red-700 rounded p-[10px]`}>{Translator.translate(form.error)}</div>}
                        {form.success !== undefined && <div
                            className={`mt-[10px] bg-green-200 border-[1px] border-green-300 text-green-700 rounded p-[10px]`}>{Translator.translate(form.success)}</div>}
                        <div className={`text-right mt-[10px]`}>
                            <PrimaryLink to={'/register'} className={'underline'}
                                         label={Translator.translateId('cta__log_in_to_register')}/>
                        </div>
                        <div className={`text-right`}>
                            <PrimaryLink to={'/forgot-password'} className={'underline'}
                                         label={Translator.translateId('label__forgot_password')}/>
                        </div>
                        <div className={"flex justify-center mt-[20px]"}>
                            <PrimaryButton onClick={onSubmit}>{Translator.translate(CTA_SUBMIT)}</PrimaryButton>
                        </div>
                    </form> : <>
                        <div className={`p-[10px]`}>
                            <div className={`text-center`}>
                                {Translator.translateId('label__log_in_with_qr_code')}
                            </div>
                        </div>
                        <div className={`flex justify-center`}>
                            <QRCode value={externalLogInUrl} className={`w-[200px] h-[200px]`}/>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    </PageTemplate>;
}
