import { connectionAlertStatus } from '../Signals/ConnectionAlertStatusSignal';
import { theme } from '../Signals/ThemeSignal';
import Translator from '../Utils/Translator';
import { WifiLostIcon } from '../Icons/WifiLostIcon';
import { WifiIcon } from '../Icons/WifiIcon';

export default function ConnectionAlert() {
    if (connectionAlertStatus.value === undefined) {
        return <></>;
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-important-modal`}/>
        <div className={`fixed z-important-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px] max-w-[300px] text-center`}>
                {connectionAlertStatus.value === 'interrupted' &&
                    <div className={`flex items-center justify-center gap-[10px]`}>
                        <div><WifiLostIcon className={`h-[40px] w-[40px] fill-red-600`}/></div>
                        <div className={`font-bold`}>{Translator.translateId('label__connection_interrupted')}</div>
                    </div>
                }
                {connectionAlertStatus.value === 'restored' &&
                    <div className={`flex items-center justify-center gap-[10px]`}>
                        <div><WifiIcon className={`h-[40px] w-[40px] fill-green-600`}/></div>
                        <div className={`font-bold`}>{Translator.translateId('label__connection_restored')}</div>
                    </div>}
            </div>
        </div>
    </>;
}