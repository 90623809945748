import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_WEATHER_API_KEY_CREATION_ALREADY_EXISTS_FORM_ERROR, LABEL_WEATHER_API_KEY_CREATION_FORM_ERROR,
    LABEL_WEATHER_API_KEY_CREATION_FORM_SUCCESS, LABEL_WEATHER_API_KEY_CREATION_INVALID_FORM_ERROR,
    LABEL_YOUTUBE_API_KEY,
} from "../Constants/Label";
import {YoutubeApiKeyInterface} from "../Interfaces/YoutubeApiKeyInterface";
import {CheckRoundedIcon} from "../Icons/CheckRoundedIcon";
import {CrossRoundedIcon} from "../Icons/CrossRoundedIcon";
import {TrashIcon} from "../Icons/TrashIcon";
import {
    BACK_OFFICE_CREATE_WEATHER_API_KEY, BACK_OFFICE_CREATE_WEATHER_API_KEY_FORM_VALIDATOR,
    BACK_OFFICE_DELETE_WEATHER_API_KEY,
    BACK_OFFICE_WEATHER_API_KEYS,
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { BackButton } from '../Components/Common/BackButton/BackButton';

export function BackOfficeWeatherApiKeysUpdate() {
    const [apiKeys, setApiKeys] = useState([]);
    const {shouldSubmit, form, handleChange, setValues, submitLock, submitError, submitSuccess, loadConstrains} = FormHook(['apiKey']);
    const [apiKeyToDelete, setApiKeyToDelete] = useState<string|undefined>(undefined);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            BACK_OFFICE_CREATE_WEATHER_API_KEY,
            {
                apiKey: form.fields.apiKey.value
            },
            () => {
                submitSuccess(LABEL_WEATHER_API_KEY_CREATION_FORM_SUCCESS);
                setValues({
                    apiKey: '',
                })
                loadApiKeys();
            },
            (response: any) => {
                const errorMap: any = {
                    api_key_already_exists: LABEL_WEATHER_API_KEY_CREATION_ALREADY_EXISTS_FORM_ERROR,
                    invalid_or_broken_api_key: LABEL_WEATHER_API_KEY_CREATION_INVALID_FORM_ERROR,
                };
                submitError(errorMap[response.data] !== undefined ? errorMap[response.data] : LABEL_WEATHER_API_KEY_CREATION_FORM_ERROR);
            }
        );
    };


    const loadApiKeys = () => {
        NewApiManager.get(
            BACK_OFFICE_WEATHER_API_KEYS,
            (response: any) => setApiKeys(response.data)
        );
    };

    const deleteApiKey = () => {
        if (apiKeyToDelete === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_WEATHER_API_KEY(apiKeyToDelete),
            () => {
                loadApiKeys();
                setApiKeyToDelete(undefined);
            }
        );
    }

    useEffect(() => {
        loadConstrains(BACK_OFFICE_CREATE_WEATHER_API_KEY_FORM_VALIDATOR);
        loadApiKeys();
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={Translator.translateId('label__api_keys')}/>
            <div className={`my-[10px]`}>
                {apiKeys.map((apiKey: YoutubeApiKeyInterface) => <div
                    className={`border-[1px] border-${theme.value}-primary-950 mb-[10px] p-[10px] rounded flex gap-[10px]`}>
                    <div>
                        {!apiKey.isBroken && <CheckRoundedIcon className={`w-[20px]`}/>}
                        {apiKey.isBroken && <CrossRoundedIcon className={`w-[20px]`}/>}
                    </div>
                    <div>{apiKey.requestsCounter}</div>
                    <div>{apiKey.apiKey}</div>
                    <div>
                        {apiKeyToDelete !== apiKey.id && <TrashIcon className={`w-[30px]`} onClick={() => setApiKeyToDelete(apiKey.id)}/>}
                        {apiKeyToDelete === apiKey.id && <div onClick={deleteApiKey}>{Translator.translateId('cta__confirm')}</div>}
                    </div>
                </div>)}
                <div className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded`}>
                    <form className={`flex gap-[10px]`} onSubmit={onSubmit}>
                        <Input className={`w-full`} form={form} name={'apiKey'} handleChange={handleChange} placeholder={LABEL_YOUTUBE_API_KEY}/>
                        <button type={'submit'} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] bg-${theme.value}-primary-950 rounded text-${theme.value}-primary-50`}>{Translator.translateId('cta__submit')}</button>
                    </form>
                    {form.error !== undefined && <div className={`text-red-600`}>{Translator.translate(form.error)}</div>}
                </div>
            </div>
        </div>
    </PageTemplate>;
}
