import Translator from '../../Utils/Translator';
import Input from '../Common/Input/Input';
import {
    LABEL__REFERRAL_LINK_NAME, LABEL_LINK_REFERRAL_CODE,
    LABEL_LINK_URL, LABEL_REFERRAL_LINK_CREATE_FORM_ERROR,
} from '../../Constants/Label';
import { theme } from '../../Signals/ThemeSignal';
import { CheckIcon } from '../../Icons/CheckIcon';
import { Modal } from '../Modal/Modal';
import { FormHook } from '../../Hooks/FormHook';
import { NewApiManager } from '../../Utils/NewApiManager';
import {
    CREATE_REFERRAL_LINK,
    CREATE_REFERRAL_LINK_FORM_VALIDATOR,
} from '../../Constants/ApiUrls';
import { useEffect } from 'react';

interface CreateReferralLinkModalInterface {
    loadLinks: () => void;
    toggle: () => void;
}

export function CreateReferralLinkModal({loadLinks, toggle}: CreateReferralLinkModalInterface) {
    const {form, handleChange, setForm, submitLock, shouldSubmit, submitError, loadConstrains} = FormHook(['name', 'url', 'isVisible', 'referralCode'], {isVisible: false});

    useEffect(() => {
        loadConstrains(CREATE_REFERRAL_LINK_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        submitLock();
        NewApiManager.post(
            CREATE_REFERRAL_LINK,
            {
                name: form.fields.name.value,
                url: form.fields.url.value,
                referralCode: form.fields.referralCode.value,
                isVisible: form.fields.isVisible.value ? 1 : 0
            },
            () => {
                toggle();
                loadLinks();
            },
            () => submitError(LABEL_REFERRAL_LINK_CREATE_FORM_ERROR)
        );
    };

    const toggleIsVisible = () => {
        setForm({
            ...form,
            fields: {
                ...form.fields,
                isVisible: {
                    ...form.fields.isVisible,
                    // @ts-ignore
                    value: !form.fields.isVisible.value,
                }
            }
        });
    };

    return <Modal label={Translator.translateId('label__new_referral_link')} toggle={toggle}>
        <form onSubmit={onSubmit}>
            <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL__REFERRAL_LINK_NAME}
                   className={`min-w-[150px]`}/>
            <Input form={form} name={'url'} handleChange={handleChange} placeholder={LABEL_LINK_URL}
                   className={`min-w-[150px] mt-[10px]`}/>
            <Input form={form} name={'referralCode'} handleChange={handleChange} placeholder={LABEL_LINK_REFERRAL_CODE}
                   className={`min-w-[150px] mt-[10px]`}/>
            <div className={`mt-[10px]`}>
                <div className={`flex gap-[10px]`}>
                    <div
                        className={`p-[5px] w-[40px] h-[40px] border-[1px] border-${theme.value}-primary-950 rounded flex items-center justify-center`}
                        onClick={toggleIsVisible}>
                        {!form.fields.isVisible.value ? '' : <CheckIcon className={`w-[20px] h-[20px]`}/>}
                    </div>
                    <div className={`flex items-center cursor-pointer`} onClick={toggleIsVisible}>
                        <div>
                            <div>{Translator.translateId('label__referral_link_is_visible')}</div>
                            <div className={`text-xs`}>{Translator.translateId('label__referral_link_is_visible_description')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                    className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={toggle}
                    className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
        </form>
    </Modal>;
}
