import { Link } from 'react-router-dom';

interface PrimaryLinkInterface {
    to: string;
    label: any;
    target?: ''|'_blank';
    className?: string;
    onClick?: () => void;
}

export function PrimaryLink({to, label, onClick, target = '', className = ''}: PrimaryLinkInterface) {
    return <Link to={to} target={target}><div onClick={onClick} className={`text-blue-500 hover:underline font-bold ${className}`}>
        {label}
    </div></Link>;
}
