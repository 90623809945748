import PageTemplate from "../Components/PageTemplate/PageTemplate";
import { CardSelf as UserCard } from '../Components/User/CardSelf';
import {Cards as CouponCards} from '../Components/Coupon/Cards';
import { Cards as PostCards } from '../Components/Posts/Cards';
import { Cards as DeviceCards } from '../Components/Devices/Cards';
import { NewApiManager } from '../Utils/NewApiManager';
import { USER_DEVICES, USER_POSTS, USER_SELF_COUPONS, USER_SELF_POSTS } from '../Constants/ApiUrls';
import { useEffect, useState } from 'react';
import { PostBasicInterface } from '../Interfaces/PostBasicInterface';
import { CouponInterface } from '../Interfaces/CouponInterface';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { userAccount } from '../Signals/UserAccountSignal';
import { postsRefs } from '../FirebaseRefs/Posts';

export function UserSelfProfile() {
    const [posts, setPosts] = useState<PostBasicInterface[]|undefined>(undefined);
    const [coupons, setCoupons] = useState<CouponInterface[]|undefined>(undefined);
    const [devices, setDevices] = useState<CouponInterface[]|undefined>(undefined);

    useEffect(() => {
        loadPosts();
        loadCoupons();
        loadDevices();
    }, []);

    useEffect(() => {
        postsRefs(posts ?? [], setPosts);
    }, [posts]);

    const loadPosts = () => {
        if (userAccount.value === undefined) {
            return;
        }

        NewApiManager.get(
            USER_SELF_POSTS,
            (response: any) => {
                setPosts(response.data);
            }
        );
    }

    const loadCoupons = () => {
        if (userAccount.value === undefined) {
            return;
        }
        NewApiManager.get(
            USER_SELF_COUPONS(),
            (response: any) => setCoupons(response.data)
        );
    }

    const loadDevices = () => NewApiManager.get(
        USER_DEVICES,
        (response: any) => setDevices(response.data)
    );

    if (userAccount.value === undefined) {
        return <></>;
    }

    return <PageTemplate sidebar={"profile"}>
        <div className={`md:flex gap-[10px]`}>
            <div>
                <UserCard userId={userAccount.value.id} className={'mt-[10px]'}/>
            </div>
            <div className={`w-full`}>
                <div>
                    {coupons !== undefined ?
                        <CouponCards coupons={coupons} className={`mt-[10px]`} limit={4} showMore={true}/>
                        : <SpinnerIcon/>
                    }
                </div>
                <div>
                    {posts !== undefined ?
                        <PostCards posts={posts} className={`mt-[10px]`} limit={4} showMore={true} setPosts={setPosts} loadPosts={loadPosts}/>
                        : <div className={`mt-[10px]`}><SpinnerIcon/></div>
                    }
                </div>
                <div>
                    {devices !== undefined ?
                        <DeviceCards devices={devices} className={`mt-[10px]`} limit={4} showMore={true}/>
                        : <div className={`mt-[10px]`}><SpinnerIcon/></div>
                    }
                </div>
            </div>
        </div>
    </PageTemplate>;
}
