import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_COUPON_DESCRIPTION, LABEL_COUPON_UPDATE_FORM_ERROR, LABEL_COUPON_UPDATE_FORM_SUCCESS,
    LABEL_COUPON_VALUE
} from "../Constants/Label";
import {useNavigate, useParams} from "react-router-dom";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {COUPON, DELETE_COUPON, UPDATE_COUPON, UPDATE_COUPON_FORM_VALIDATOR} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { InputDateTime } from '../Components/Common/Input/InputDateTime';
import { localDateTimeToUtc, utcDateTimeToLocal } from '../Utils/Date';

export function CouponUpdate() {
    const {id} = useParams();
    const navigate = useNavigate();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {shouldSubmit, form, handleChange, setValues, loadConstrains, submitLock, submitError, submitSuccess} = FormHook(['description', 'couponValue', 'startDate', 'endDate']);

    const onSubmit = (e: any) => {
        if (id === undefined) {
            return;
        }

        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.put(
            UPDATE_COUPON(id),
            {
                description: form.fields.description.value,
                couponValue: form.fields.couponValue.value,
                // @ts-ignore
                startDate: form.fields.startDate.value === '' ? '' : localDateTimeToUtc(form.fields.startDate.value),
                // @ts-ignore
                endDate: form.fields.endDate.value === '' ? '' : localDateTimeToUtc(form.fields.endDate.value)
            },
            () => submitSuccess(LABEL_COUPON_UPDATE_FORM_SUCCESS),
            () => submitError(LABEL_COUPON_UPDATE_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(UPDATE_COUPON_FORM_VALIDATOR);
        if (id === undefined) {
            return;
        }
        NewApiManager.get(
            COUPON(id),
            (response: any) => {
                const startDate = response.data.startDate?.date.split('.')[0];
                const endDate = response.data.endDate?.date.split('.')[0];

                setValues({
                    description: response.data.description,
                    couponValue: response.data.couponValue,
                    // @ts-ignore
                    startDate: startDate === undefined ? '' : utcDateTimeToLocal(startDate),
                    endDate: endDate === undefined ? '' : utcDateTimeToLocal(endDate),
                });
            }
        );
    }, []);

    const deleteCurrentCoupon = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            DELETE_COUPON(id),
            () => navigate('/coupons')
        );
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    return <PageTemplate sidebar={"normal"}>
        <DeleteConfirmationModal message={Translator.translateId('label__coupon_delete_confirmation_message')} deleteAction={deleteCurrentCoupon} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/coupons'} label={'label__coupons'}/>
                <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`} onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
            </div>
            <div className={`mt-[10px]`}>
                <form onSubmit={onSubmit}>
                    <Input form={form} name={'description'} handleChange={handleChange}
                           placeholder={LABEL_COUPON_DESCRIPTION}/>
                    <Input className={`mt-[10px]`} form={form} name={'couponValue'} handleChange={handleChange}
                           placeholder={LABEL_COUPON_VALUE}/>
                    <div className={`flex`}>
                        <InputDateTime className={`mt-[10px]`} form={form}
                                       label={Translator.translateId('label__coupon_update_start_date')}
                                       name={'startDate'} setValues={setValues}/>
                    </div>
                    <div className={`flex`}>
                        <InputDateTime className={`mt-[10px]`} form={form}
                                       label={Translator.translateId('label__coupon_update_end_date')}
                                       name={'endDate'} setValues={setValues}/>
                    </div>
                    {form.error !== undefined && <div className={`pt-[10px] text-red-600`}>
                        {Translator.translateId(form.error.id)}
                    </div>}
                    {form.success !== undefined && <div className={`pt-[10px] text-green-600`}>
                        {Translator.translateId(form.success.id)}
                    </div>}
                    <div className={`flex justify-end py-[10px]`}>
                        <button type={'submit'}
                                className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                    </div>
                </form>
            </div>

        </div>
    </PageTemplate>;
}
