interface PrimaryButtonInterface {
    withBackground?: boolean;
    onClick?: (value?: any) => void;
    className?: string;
    children: any;
}

export function PrimaryButton({withBackground = true, onClick = () => {}, children, className = ''}: PrimaryButtonInterface) {
    return <div className={`rounded-xl text-center p-[10px] ${className}
        ${withBackground ? 
            'bg-blue-500 text-white hover:bg-blue-700' 
            : 'border-blue-500 border text-blue-500 hover:border-blue-700 hover:text-blue-700'
        }
        font-bold cursor-pointer`}
                onClick={onClick}>
        {children}
    </div>;
}
