import PageTemplate from '../Components/PageTemplate/PageTemplate';
import { theme } from '../Signals/ThemeSignal';
import Translator from '../Utils/Translator';
import Input from '../Components/Common/Input/Input';
import {
    CTA_SUBMIT,
    LABEL_EMAIL,
} from '../Constants/Label';
import { NewApiManager } from '../Utils/NewApiManager';
import { USER_FORGOT_PASSWORD, USER_FORGOT_PASSWORD_FORM_VALIDATOR } from '../Constants/ApiUrls';
import { FormHook } from '../Hooks/FormHook';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { useEffect } from 'react';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';

export function ForgotPassword() {
    const {form, handleChange, setForm, shouldSubmit, loadConstrains} = FormHook(['email']);

    useEffect(() => {
        loadConstrains(USER_FORGOT_PASSWORD_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.post(
            USER_FORGOT_PASSWORD,
            {
                email: form.fields.email.value,
            },
            () => {
                setForm({
                    ...form,
                    error: undefined,
                    success: '',
                    isLoading: false
                })
            }
        );
    };

    return <PageTemplate childrenClassName={`min-h-[700px]`} sidebar={"normal"}>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div
                className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div
                        className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__forgot_password')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    {form.success === undefined ? <form onSubmit={onSubmit}>
                        <Input className={`mt-[10px]`} form={form} name={'email'} handleChange={handleChange}
                               placeholder={LABEL_EMAIL}/>
                        {form.error !== undefined && <div className={`mt-[10px] bg-red-200 border-[1px] border-red-300 text-red-700 rounded p-[10px]`}>{Translator.translate(form.error)}</div>}
                        <div className={"flex justify-center mt-[20px]"}>
                            <PrimaryButton onClick={onSubmit}>
                                {Translator.translate(CTA_SUBMIT)}
                            </PrimaryButton>
                        </div>
                    </form> : <div className={`mt-[10px]`}>
                        <div>
                            {Translator.translateId('label__forgot_password_email_sent')}
                        </div>
                        <PrimaryLink to={'/log-in'} label={Translator.translateId('label__back_to_login')}/>
                    </div>}
                </div>
            </div>
        </div>
    </PageTemplate>;
}
