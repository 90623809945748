import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";
import { VideoDataForPlayingMediaListInterface, VideoDataInterface } from "../Interfaces/VideoDataInterface";
import {PreviousIcon} from "../Icons/PreviousIcon";
import {PlayIcon} from "../Icons/PlayIcon";
import {PauseIcon} from "../Icons/PauseIcon";
import {NextIcon} from "../Icons/NextIcon";
import {globalSearch, GlobalSearchHook} from "../Signals/GlobalSearchSignal";
import {MagnifyingGlassIcon} from "../Icons/MagnifyingGlassIcon";
import {TrashIcon} from "../Icons/TrashIcon";
import { SAVE_YOUTUBE_REMOTE_ACTION, YOUTUBE_PLAYER_INFO, YOUTUBE_SEARCH_BY_KEYWORD } from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { PlayingMediaListInterface } from "../Signals/PlayingMediaListSignal";
import {SpinnerIcon} from "../Icons/SpinnerIcon";
import { addRef, firebaseRealTimeDatabaseSignal } from '../Signals/FirebaseDatabaseSignal';
import { ExclamationTriangledIcon } from '../Icons/ExclamationTriangledIcon';
import { localDateTimeToUtc } from '../Utils/Date';
import moment from 'moment';
import { Drag, DragDrop, Drop } from '../Components/Common/DragAndDrop/SimpleDragAndDrop';

export default function YoutubeRemote() {
    const {id} = useParams();
    const [page, setPage] = useState<'playlist'|'search'>('playlist');
    const [playerData, setPlayerData] = useState<PlayingMediaListInterface|undefined>(undefined);
    const [actionContentToConfirm, setActionContentToConfirm] = useState<string|undefined>(undefined);
    const {getResults, globalSearchEffect, isLoading} = GlobalSearchHook();
    const [videosAddedCounter, setVideosAddedCounter] = useState(0);

    const getItems = (results: any) => {
        return results.items;
    };

    const searchOnYoutube = (keyword: string, pageToken: string, callback: (response: any) => void = () => {}) => {
        NewApiManager.get(
            YOUTUBE_SEARCH_BY_KEYWORD(keyword, pageToken),
            (response: any) => {
                callback(response)
            }
        );
    }
    globalSearchEffect(searchOnYoutube, getItems);

    useEffect(() => {
        loadPlayerInfo();

        listenPlayerUpdate();
    }, []);

    const listenPlayerUpdate = () => {
        if (firebaseRealTimeDatabaseSignal.value.config === undefined) {
            setTimeout(listenPlayerUpdate, 1000);
            return;
        }
        if (id !== undefined) {
            const playerRefPath = `player-${id}`;
            addRef(playerRefPath, (action: any) => {
                switch(action.performedAction) {
                    case 'updated':
                        loadPlayerInfo(localDateTimeToUtc(moment().format()));
                        break;
                }
            }, 0);
        }
    }

    const loadPlayerInfo = (afterDate: any = undefined) => {
        if (undefined === id) {
            return;
        }

        NewApiManager.get(
            YOUTUBE_PLAYER_INFO(id),
            (response: any) => setPlayerData(JSON.parse(response.data.lastState)),
        );
    };

    if (id === undefined || playerData === undefined || playerData?.isRemoteActivated === false) {
        return <div className={`h-[100dvh] flex justify-center items-center`}>
            <div>
                <div className={`flex justify-center`}><ExclamationTriangledIcon className={`w-[50px] fill-red-500`}/></div>
                <div className={`w-full max-w-[400px] mt-[10px] text-center`}>
                    {Translator.translateId('label__player_remote_disabled')}
                </div>
            </div>
        </div>;
    }

    const openPlaylistPage = () => {
        setPage('playlist');
        setVideosAddedCounter(0);
    }

    const sendAction = (actionContent: string|undefined) => {
        if (id === undefined || actionContent === undefined) {
            return;
        }

        NewApiManager.post(
            SAVE_YOUTUBE_REMOTE_ACTION(id),
            {
                actionContent,
            }
        )
        setActionContentToConfirm(undefined);
    };

    const getPlayingVideoIndex = () => {
        const playingVideoIndex = playerData.items.map((videoData: VideoDataForPlayingMediaListInterface) => videoData.isPlaying).indexOf(true);

        return playingVideoIndex === -1 ? 0 : playingVideoIndex;
    }

    const playingVideoIndex = getPlayingVideoIndex();

    const onDrop = (result: any) => {
        sendAction(`reorder:${result.source.index}--${result.destination.index}`);

        const newOrder = [
            ...playerData.items
        ];

        const [draggedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, draggedItem);

        setPlayerData({
            ...playerData,
            items: newOrder
        });
    }

    return <>
        <div className={`fixed top-0 w-full bg-${theme.value}-primary-50 z-important`}>
            <div className={`m-auto w-full`}>
                <div className={`flex border-b-[1px] border-${theme.value}-primary-950`}>
                    <div onClick={openPlaylistPage}
                         className={`w-[50%] text-center border-r-[1px] border-${theme.value}-primary-950 cursor-pointer h-[50px] flex items-center justify-center ${page === 'playlist' && `bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}`}>
                        {Translator.translateId(`cta__remote_playlist`)}{videosAddedCounter > 0 && <> [+<span className={`animate-ping`}>{videosAddedCounter}</span>]</>}
                    </div>
                    <div onClick={() => {setPage('search'); window.scrollTo(0, 0)}}
                         className={`w-[50%] text-center cursor-pointer h-[50px] flex items-center justify-center ${page === 'search' && `bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}`}>
                        {Translator.translateId(`cta__remote_search`)}
                    </div>
                </div>
            </div>
        </div>
        <div
            className={`fixed top-[calc(100%-72px)] w-full border-t-[1px] border-${theme.value}-primary-950 h-[72px] z-important bg-${theme.value}-primary-50`}>
            <div className={`m-auto flex gap-[10px] justify-center items-center w-full max-w-[400px] h-full`}>
                <div className={`w-[30px]`}>
                    <PreviousIcon className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}
                                  onClick={() => sendAction('previous')}/>
                </div>
                {playerData.status === 'pause' && <div>
                    <PlayIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}
                              onClick={() => sendAction('play')}/>
                </div>}
                {playerData.status === 'play' && <div>
                    <PauseIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}
                               onClick={() => sendAction('pause')}/>
                </div>}
                <div className={`w-[30px]`}>
                    <NextIcon className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}
                              onClick={() => sendAction('next')}/>
                </div>
            </div>
        </div>
        {actionContentToConfirm !== undefined && <>
            <div onClick={() => {
                setActionContentToConfirm(undefined)
            }} className={`fixed top-0 left-0 w-full h-[100dvh] opacity-50 bg-${theme.value}-primary-600 z-modal`}/>
            <div
                className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px] max-h-[calc(100dvh-50px)] overflow-y-auto`}>
                <div className={`bg-${theme.value}-primary-50 rounded p-[10px] `}>
                    <div>{Translator.translateId('label__confirm_performing_action')}</div>

                    <div
                        className={`mt-[10px] flex justify-center`}
                        onClick={() => sendAction(actionContentToConfirm)}>
                        <button
                            className={`p-[10px] border-[1px] rounded border-${theme.value}-primary-950 text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__confirm')}</button>
                    </div>
                </div>
            </div>
        </>}
        <div className={`h-[50px]`}>

        </div>
        <div>
            <div className={`h-[calc(100dvh-122px)] overflow-y-auto`}>
                {page === 'playlist' &&
                    <DragDrop onDragEnd={onDrop}>
                        <Drop id={'playlist'}>
                            {playerData.items?.map((video: VideoDataInterface, index: number) => <Drag key={`video-${index}`} id={`video${index}`} index={index}>
                                <div>
                                    <div
                                        className={`rounded flex gap-[10px] p-[5px] overflow-hidden cursor-pointer ${playingVideoIndex === index && `bg-${theme.value}-primary-200`}`}>
                                        <div onClick={() => setActionContentToConfirm(`playIndex:${index}`)}
                                            className={`flex justify-center items-center max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px]`}>
                                            <img src={video.image.url}
                                                 className={`${playingVideoIndex === index ? `rounded-full w-[80px] h-[80px] animate-[spin_5s_linear_infinite]` : 'rounded'}`}/>
                                        </div>
                                        <div className={`w-full`} onClick={() => setActionContentToConfirm(`playIndex:${index}`)}>
                                            <div className={`line-clamp-2 font-bold`}>
                                                {video.title}
                                            </div>
                                            <div className={`line-clamp-2`}>
                                                {video.channelTitle}
                                            </div>
                                        </div>
                                        <div>
                                            <TrashIcon className={`w-[20px]`} onClick={() => setActionContentToConfirm(`remove:${index}`)}/>
                                        </div>
                                    </div>
                                </div>
                            </Drag>)}
                        </Drop>
                    </DragDrop>
                }
                <div className={`p-[10px] ${page !== 'search' && 'hidden'}`}>
                    <div
                        className={`border-${theme.value}-primary-950 border-[1px] h-[calc(100%-10px)] w-full max-w-[500px] flex rounded`}>
                        <input type={'text'} className={`w-full m-[5px] bg-${theme.value}-primary-50`}
                               placeholder={Translator.translateId('label__type_keyword')}
                               onChange={(e: any) => globalSearch(e.target.value)}/>
                        <button
                            className={`border-l-[1px] border-${theme.value}-primary-950 bg-${theme.value}-primary-50 px-[10px] rounded fill-${theme.value}-primary-950`}>
                            <MagnifyingGlassIcon className={`w-[20px] h-[20px]`}/>
                        </button>
                    </div>
                    {isLoading() && <div>
                        <div className={`w-full flex justify-center p-[10px] mt-[10px]`}>
                            <SpinnerIcon/>
                        </div>
                    </div>}
                    <div className={`mt-[10px]`}>
                        {getResults().filter((result: VideoDataInterface) => result?.videoId !== undefined).map((video: VideoDataInterface, index: number) =>
                            <div key={`result-${index}`} onClick={() => {
                                setVideosAddedCounter(() => videosAddedCounter + 1);
                                sendAction(`addVideo:${JSON.stringify(video)}`)
                            }}>
                                <div
                                    className={`rounded flex gap-[10px] p-[5px] cursor-pointer`}>
                                    <div
                                        className={`flex justify-center items-center max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px]`}>
                                        <img src={video.image.url} className={'rounded'}/>
                                    </div>
                                    <div className={`w-full`}>
                                        <div className={`line-clamp-2 font-bold`}>
                                            {video.title}
                                        </div>
                                        <div className={`line-clamp-2`}>
                                            {video.channelTitle}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
