import { PostInterface } from '../Interfaces/PostInterface';
import { addRef } from '../Signals/FirebaseDatabaseSignal';
import { NewApiManager } from '../Utils/NewApiManager';
import { POST } from '../Constants/ApiUrls';

export function postsRefs(posts: PostInterface[], setPosts: any) {
    posts.forEach((post: PostInterface) => {
        addRef(`post-${post.id}`, (action: any) => {
            switch(action.performedAction) {
                case 'commented':
                case 'disliked':
                case 'liked':
                    NewApiManager.get(
                        POST(post.id),
                        (response: any) => {
                            let newPosts = [...posts];
                            newPosts = newPosts.map((currentPost: PostInterface) => {
                                if (currentPost.id !== post.id) {
                                    return currentPost;
                                }

                                return response.data;
                            });

                            setPosts(newPosts);
                        }
                    )
                    break;
            }
        });
    });
}
