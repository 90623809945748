import PageTemplate from '../Components/PageTemplate/PageTemplate';
import Translator from '../Utils/Translator';
import { FormHook } from '../Hooks/FormHook';
import { NewApiManager } from '../Utils/NewApiManager';
import { useEffect } from 'react';
import {
    QRCODE_AUTO_LOG_IN,
    QRCODE_AUTO_LOG_IN_FORM_VALIDATOR
} from '../Constants/ApiUrls';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../Components/Common/Input/Input';
import { LABEL_KARAOKE_MODE, LABEL_LOG_IN_FORM_ERROR, LABEL_PASSWORD } from '../Constants/Label';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { theme } from '../Signals/ThemeSignal';
import { CheckIcon } from '../Icons/CheckIcon';

export function QRCodeAutoLogIn() {
    const navigate = useNavigate();

    const {deviceId} = useParams();

    const {form, setForm, loadConstrains, shouldSubmit, handleChange} = FormHook(['password', 'karaokeMode'], {karaokeMode: false});

    useEffect(() => {
        loadConstrains(QRCODE_AUTO_LOG_IN_FORM_VALIDATOR);
    }, []);

    if (deviceId === undefined) {
        navigate('/');

        return <></>;
    }

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.post(
            QRCODE_AUTO_LOG_IN(deviceId),
            {
                password: form.fields.password.value,
                karaokeMode: form.fields.karaokeMode.value ? 1 : 0,
            },
            () => {
                setForm({
                    ...form,
                    success: '',
                    error: undefined,
                });
                setTimeout(() => {
                    navigate('/');
                }, 5000);
            },
            () => setForm({
                ...form,
                loading: false,
                error: LABEL_LOG_IN_FORM_ERROR,
                success: undefined,
            })
        );
    }

    const toggleKaraokeMode = () => {
        setForm({
            ...form,
            fields: {
                ...form.fields,
                karaokeMode: {
                    ...form.fields.karaokeMode,
                    // @ts-ignore
                    value: !form.fields.karaokeMode.value,
                }
            }
        });
    }

    return <PageTemplate childrenClassName={`justify-center flex`}>
        <div className={`p-[10px] max-w-[300px] w-full`}>
            <div className={`border border-black bg-white p-[10px] rounded-xl`}>
                {form.success === undefined ? <>
                    <div className={`text-center font-bold`}>
                        {Translator.translateId('label__auto_log_in_approval')}
                    </div>
                    <form onSubmit={onSubmit} className={`mt-[10px]`}>
                        <Input type={'password'} form={form} name={'password'} handleChange={handleChange}
                               placeholder={LABEL_PASSWORD}/>
                        <div className={`mt-[10px] flex gap-[10px]`}>
                            <div
                                className={`p-[5px] w-[40px] h-[40px] border-[1px] border-${theme.value}-primary-950 rounded flex items-center justify-center`}
                                onClick={toggleKaraokeMode}>
                                {!form.fields.karaokeMode.value ? '' :
                                    <CheckIcon className={`w-[20px] h-[20px]`}/>}
                            </div>
                            <div className={`flex items-center cursor-pointer`} onClick={toggleKaraokeMode}>
                                {Translator.translate(LABEL_KARAOKE_MODE)}
                            </div>
                        </div>
                        <div className={`flex gap-[10px] justify-center pt-[10px] items-center`}>
                            <PrimaryLink to={'/'} label={Translator.translateId('label__deny')}/>
                            <PrimaryButton onClick={onSubmit}>{Translator.translateId('label__approve')}</PrimaryButton>
                        </div>
                        {form.error !== undefined &&
                            <div className={`text-red-500`}>{Translator.translate(form.error)}</div>}
                    </form>
                </> : <div className={`text-green-500 font-bold text-center`}>
                    {Translator.translateId('label__qr_code_log_in_success')}
                </div>}
            </div>
        </div>
    </PageTemplate>;
}
