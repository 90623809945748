export class LocalStorage {
    static values: {[index:string]: string} = {};

    static storageType: 'localStorage'|'chromeStorage' = 'localStorage';
    static storageContext: 'web'|'background'|'popup'|'contentScript' = 'web';
    // @ts-ignore
    static localStorageObject: any;
    // @ts-ignore
    static isExtBackground: boolean = false;
    static isExtPopup: boolean = false;
    static isLoaded: boolean = false;

    static userAccountStorageIndex: 'userAccount'|'extensionUserAccount' = 'userAccount';
    static deviceIdStorageIndex: 'deviceId'|'extensionDeviceId' = 'deviceId';

    static init(fn:any = () => {}) {
        try {
            LocalStorage.localStorageObject = localStorage;
            LocalStorage.storageType = 'localStorage';
        } catch (e: any) {
            // @ts-ignore
            LocalStorage.localStorageObject = chrome.storage;
            LocalStorage.storageType = 'chromeStorage';
        }

        if (LocalStorage.storageType === 'localStorage') {
            LocalStorage.values = {...localStorage};
            fn();
            return;
        }

        if (LocalStorage.storageType === 'chromeStorage') {
            LocalStorage.localStorageObject.sync.get(null).then((values: any) => {
                LocalStorage.values = values;
                fn();
            });
            return;
        }
    }

    static set(index: string, value: any, fn: any = () => {}) {
        if (!LocalStorage.isLoaded) {
            LocalStorage.init();
        }

        const newValue = JSON.stringify(value);

        if (LocalStorage.storageType === 'localStorage') {
            LocalStorage.localStorageObject.setItem(index, newValue);
            LocalStorage.init(fn);
            return;
        }

        if (LocalStorage.storageType === 'chromeStorage') {
            let newStorageValue: {[indexName:string]: any} = {};
            newStorageValue[index] = newValue;

            // Add into LocalStorage.values in order to use it immediately
            LocalStorage.values[index] = JSON.stringify(value);

            LocalStorage.localStorageObject.sync.set(newStorageValue).then((values: any) => {
                LocalStorage.init(fn);
            });
        }
    }

    static get(index: string) {
        if (!LocalStorage.isLoaded) {
            LocalStorage.init();
        }

        if (LocalStorage.values[index] === undefined) {
            return undefined;
        }

        try {
            return JSON.parse(LocalStorage.values[index]);
        } catch (e: any) {
            return LocalStorage.values[index];
        }
     }

    static remove(index: string) {
        if (LocalStorage.storageType === 'localStorage') {
            LocalStorage.localStorageObject.removeItem(index);
            LocalStorage.init();
        }

        if (LocalStorage.storageType === 'chromeStorage') {
            LocalStorage.localStorageObject.sync.remove(index).then((values: any) => {
                LocalStorage.init();
            });
        }
    }

    static chooseContext(context: 'web'|'background'|'popup'|'contentScript') {
        if (context === 'web') {
            LocalStorage.userAccountStorageIndex = 'userAccount';
            LocalStorage.deviceIdStorageIndex = 'deviceId';
        } else {
            LocalStorage.userAccountStorageIndex = 'extensionUserAccount';
            LocalStorage.deviceIdStorageIndex = 'extensionDeviceId';
        }
    }

    static setContext(context: 'web'|'background'|'popup') {
        LocalStorage.storageContext = context;
    }

    static getUserAccount() {
        return LocalStorage.get(LocalStorage.userAccountStorageIndex);
    }

    static setUserAccount(value: any, fn: any = () => {}) {
        LocalStorage.set(LocalStorage.userAccountStorageIndex, value, fn);
    }

    static removeUserAccount() {
        LocalStorage.remove(LocalStorage.userAccountStorageIndex);
    }

    static getDeviceId() {
        return LocalStorage.get(LocalStorage.deviceIdStorageIndex);
    }

    static setDeviceId(value: any, fn: any = () => {}) {
        LocalStorage.set(LocalStorage.deviceIdStorageIndex, value, fn);
    }

    static removeDeviceId() {
        LocalStorage.remove(LocalStorage.deviceIdStorageIndex);
    }
}