import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_COUPON_CREATION_FORM_SUCCESS,
    LABEL_COUPON_DESCRIPTION,
    LABEL_COUPON_VALUE
} from "../Constants/Label";
import {Link} from "react-router-dom";
import {CouponInterface} from "../Interfaces/CouponInterface";
import {
    BACK_OFFICE_COUPONS,
    BACK_OFFICE_CREATE_COUPON,
    BACK_OFFICE_CREATE_COUPON_FORM_VALIDATOR
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeCoupons() {
    const [coupons, setCoupons] = useState([]);

    const [isCreatingCoupon, setIsCreatingCoupon] = useState(false);

    const {shouldSubmit, form, handleChange, setValues, loadConstrains, submitLock, submitSuccess, submitError} = FormHook(['description', 'couponValue']);

    const toggleCreatingCoupon = () => setIsCreatingCoupon(() => !isCreatingCoupon);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            BACK_OFFICE_CREATE_COUPON,
            {
                description: form.fields.description.value,
                couponValue: form.fields.couponValue.value
            },
            () => {
                submitSuccess();
                setValues({
                    description: '',
                    couponValue: '',
                })
                loadCoupons();
            },
            () => submitError(LABEL_COUPON_CREATION_FORM_SUCCESS)
        );
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_CREATE_COUPON_FORM_VALIDATOR);
    }, []);


    const loadCoupons = () => {
        NewApiManager.get(
            BACK_OFFICE_COUPONS,
            (response: any) => setCoupons(response.data)
        );
    };

    useEffect(() => {
        loadCoupons();
    }, []);

    const formFlexRowStyle = `xl:w-[calc(100%/2-8px)]  w-full`;
    const flexRowStyle = `xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] w-full`;

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_coupons'}/>
            <div className={`mt-[10px] flex gap-[10px] flex-wrap`}>
                {isCreatingCoupon && <form onSubmit={onSubmit} className={`border-[1px] border-${theme.value}-primary-950 ${formFlexRowStyle} h-[150px] rounded border-dashed flex`}>
                    <div className={`w-full`}>
                        <div className={`p-[10px]`}>
                            <Input form={form} handleChange={handleChange} name={'description'}
                                   placeholder={LABEL_COUPON_DESCRIPTION}/>
                        </div>
                        <div className={`flex justify-end px-[10px]`}>
                            <div
                                className={`p-[5px] border-dashed rounded border-${theme.value}-primary-950 border-[1px] w-1/2`}>
                                <Input form={form} handleChange={handleChange} name={'couponValue'} placeholder={LABEL_COUPON_VALUE}/>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`border-l-[1px] border-dashed border-${theme.value}-primary-950 p-[10px] flex items-center justify-center`}>
                        <div>
                            <button type={'submit'}
                                    className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                            <button onClick={toggleCreatingCoupon}
                                    className={`block w-full border-[1px] border-red-700 p-[5px] mt-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-red-600`}>{Translator.translateId('cta__cancel')}</button>
                        </div>
                    </div>
                </form>}
                {!isCreatingCoupon && <div onClick={toggleCreatingCoupon}
                                           className={`cursor-pointer border-[1px] border-dashed border-${theme.value}-primary-950 ${flexRowStyle} flex justify-center items-center h-[150px] rounded`}>
                {Translator.translateId('cta__add_new_coupon')}
                </div>}
                {coupons.map((coupon: CouponInterface, index: number) => <Link to={`/back-office/coupons/${coupon.id}/update`} key={`coupon-${index}`}
                                                                   className={`border-[1px] border-${theme.value}-primary-950 ${flexRowStyle} h-[150px] rounded`}>
                    <div className={`p-[5px]`} title={coupon.description}>
                        <div className={`font-bold line-clamp-2 min-h-[50px]`}>
                            {coupon.description}
                        </div>
                    </div>
                    <div className={`flex justify-end px-[10px] py-[5px]`}>
                        <div className={`p-[5px] border-dashed rounded border-${theme.value}-primary-950 border-[1px]`}>
                            {coupon.couponValue}
                        </div>
                    </div>
                    <div className={`flex justify-end p-[10px] italic`}>
                        {coupon.company === null ? Translator.translateId('label__coupon_personal_use') : coupon.company.name}
                    </div>
                </Link>)}
            </div>

        </div>
    </PageTemplate>;
}
