import Translator from "../../../Utils/Translator";

interface DateTimeLabelInterface {
    datetime: string;
    showTime?: boolean;
}

export function DateTimeLabel({datetime, showTime = true}: DateTimeLabelInterface) {
    return <>
        {showTime && Translator.translateId(`label__date_time`, undefined, {
            day: datetime.split(' ')[0].split('-')[2],
            month: Translator.translateId(`label__month_${datetime.split(' ')[0].split('-')[1]}_short`),
            year: datetime.split(' ')[0].split('-')[0],
            hours: datetime.split(' ')[1].split(':')[0],
            minutes: datetime.split(' ')[1].split(':')[1],
        })}
        {!showTime && Translator.translateId(`label__date_without_time`, undefined, {
            day: datetime.split(' ')[0].split('-')[2],
            month: Translator.translateId(`label__month_${datetime.split(' ')[0].split('-')[1]}_short`),
            year: datetime.split(' ')[0].split('-')[0],
        })}
    </>
}
