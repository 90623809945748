import Translator, { TranslationDataInterface } from '../../../Utils/Translator';
import { CheckIcon } from '../../../Icons/CheckIcon';

interface CheckboxInteface {
    form: any;
    name: string;
    checkedValue: string|boolean;
    uncheckedValue: string|boolean;
    setValues: (values: any) => void;
    label: TranslationDataInterface;
    className?: string;
    description?: string;
}

export function Checkbox({description, form, name, checkedValue, uncheckedValue, setValues, label, className = ''}: CheckboxInteface) {
    const toggleValue = () => {
        setValues({
            [name]: form.fields[name].value === checkedValue ? uncheckedValue : checkedValue,
        })
    }

    return <div>
        <div className={`flex items-center gap-[10px] ${className}`}>
            <div className={`border border-black rounded w-[30px] h-[30px] flex justify-center items-center cursor-pointer`} onClick={toggleValue}>
                {form.fields[name].value === checkedValue && <CheckIcon className={`w-[20px] h-[20px] fill-black`}/>}
            </div>
            <div className={`flex`}>
                <div className={`cursor-pointer font-bold`} onClick={toggleValue}>{Translator.translate(label)}</div>
            </div>
        </div>
        <div className={`pl-[40px] text-gray-500 italic`}>
            {description !== undefined && description}
        </div>
    </div>;
}
