import {NewsInterface} from "../../Interfaces/NewsInterface";
import {theme} from "../../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import Translator from "../../Utils/Translator";

interface NewsCardInterface {
    news: NewsInterface;
    className?: string;
}
export function NewsCard({news, className = ''}: NewsCardInterface) {
    const classes = `${className} border-[1px] p-[10px] border-${theme.value}-primary-950 rounded mb-[10px] block md:flex gap-[10px] max-w-full`;

    const block = (content: any) => {
        if (news.url) {
            return <Link to={news.url} className={classes}>
                {content}
            </Link>;
        }

        return <div className={classes}>
            {content}
        </div>;
    };

    const missingClass = 'border-[1px] border-red-600 text-red-600 min-h-[20px]';

    return block(<>
        <div className={`${news.image === undefined ? `${missingClass} min-h-[50px] max-w-[100px]` : ''}`}>
            <img className={`w-full md:max-w-[100px] md:w-[100px]`} src={news.image}/>
            {news.image === undefined && Translator.translateId('label__missing_image')}
        </div>
        <div className={`max-w-full overflow-hidden`}>
            <div className={`${news.title === undefined ? `${missingClass}` : `font-bold line-clamp-2`}`}>{news.title}{news.title === undefined && Translator.translateId('label__missing_title')}</div>
            <div className={`${news.description === undefined ? `${missingClass}` : `line-clamp-2`}`}>{news.description}{news.description === undefined && Translator.translateId('label__missing_description')}</div>
            <div className={`md:flex justify-between gap-[10px] text-${theme.value}-primary-500`}>
                <div className={`${news.url === undefined ? `${missingClass}` : `line-clamp-1 text-sm`}`}>{news.url}{news.url === undefined && Translator.translateId('label__missing_url')}</div>
                <div className={`${news.sourceDateTime === undefined ? `${missingClass}` : `italic whitespace-nowrap text-sm text-center md:text-right`}`}>{news.sourceDateTime}{news.sourceDateTime === undefined && Translator.translateId('label__missing_datetime')}</div>
            </div>
        </div>
    </>);
}