import { PrimaryButton } from './PrimaryButton';

interface PrimaryIconButtonInterface {
    withBackground?: boolean;
    onClick?: (value?: any) => void;
    className?: string;
    children: any;
}

export function PrimaryIconButton({withBackground = true, onClick = () => {}, children, className = ''}: PrimaryIconButtonInterface) {
    return <PrimaryButton withBackground={withBackground} onClick={onClick} className={`${className} w-[35px] h-[35px] flex items-center justify-center text-2xl`}>
        {children}
    </PrimaryButton>;
}
