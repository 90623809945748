import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";
import {Link, useSearchParams} from "react-router-dom";

export function LoggedOut() {
    const [params] = useSearchParams();

    return <PageTemplate childrenClassName={`min-h-[700px]`} sidebar={"normal"}>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__logged_out')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    {(params.get('auto') === null || params.get('auto') !== '1') ? Translator.translateId('label__account_logged_out') : Translator.translateId('label__account_logged_out_session_timeout')}
                    <Link to={'/log-in'}><div className={'text-right text-sm underline'}>
                        {Translator.translateId('cta__log_in_again')}
                    </div></Link>
                </div>
            </div>
        </div>
    </PageTemplate>;
}