import { CrossRoundedIcon } from '../../Icons/CrossRoundedIcon';

interface ModalInterface {
    toggle: () => void;
    children: any;
    label: string;
}

export function Modal({toggle, children, label}: ModalInterface) {
    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-gray-600 z-modal`}
             onClick={() => toggle()}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-[700px] md:w-[80%] h-full max-h-full md:h-auto md:max-h-[80dvh] bg-white rounded-xl`}>
            <div className={`border-b p-[10px] flex justify-between items-center md:hidden`}>
                <div className={`text-[20px] font-bold`}>
                    {label}
                </div>
                <CrossRoundedIcon className={`w-[30px] cursor-pointer`} onClick={toggle}/>
            </div>
            <div className={`p-[10px] md:max-h-[80dvh] overflow-y-auto`}>
                {children}
            </div>
        </div>
    </>;
}
