import { TOKEN_REFRESH_URL } from "../Constants/ApiUrls";
import {logOut, setUserAccount} from "../Signals/UserAccountSignal";
import {LocalStorage} from "./LocalStorage";
import {
    connectionAlertStatus,
    connectionInterrupted,
    connectionRestored
} from '../Signals/ConnectionAlertStatusSignal';

export const REQUEST_METHOD_GET = 'GET';
export const REQUEST_METHOD_POST = 'POST';
export const REQUEST_METHOD_PUT = 'PUT';

export const REQUEST_METHOD_DELETE = 'DELETE';

export const REQUEST_TYPE_JSON = 'json';

export const REQUEST_TYPE_IMAGE = 'image';
type REQUEST_METHODS = 'GET' | 'POST' | 'DELETE' | 'PUT';
type REQUEST_TYPES = 'json'|'image';

interface RequestOptions {
    method: REQUEST_METHODS,
    headers: {
        authorization?: string;
        'Content-Type'?: string;
    },
    body?: string;
}

interface Request {
    method: REQUEST_METHODS;
    url: string;
    payload: any;
    successCallback: (response: any) => void;
    errorCallback: (response: any) => void;
    type: REQUEST_TYPES;
}

let requests: Request[] = [];
let isExecutingRequests: boolean = false;

export class NewApiManager {
    static get(url: string, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}) {
        return NewApiManager.addRequest(REQUEST_METHOD_GET, url, undefined, successCallback, errorCallback);
    }

    static post(url: string, payload: any, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}) {
        return NewApiManager.addRequest(REQUEST_METHOD_POST, url, payload, successCallback, errorCallback);
    }

    static postImage(url: string, payload: any, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}) {
        return NewApiManager.addRequest(REQUEST_METHOD_POST, url, payload, successCallback, errorCallback, false, REQUEST_TYPE_IMAGE);
    }

    static put(url: string, payload: any, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}) {
        return NewApiManager.addRequest(REQUEST_METHOD_PUT, url, payload, successCallback, errorCallback);
    }

    static delete(url: string, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}) {
        return NewApiManager.addRequest(REQUEST_METHOD_DELETE, url, undefined, successCallback, errorCallback);
    }

    static addRequest(method: REQUEST_METHODS, url: string, payload: any = undefined, successCallback: (response: any) => void = () => {}, errorCallback: (response: any) => void = () => {}, prepend: boolean = false, type: REQUEST_TYPES = REQUEST_TYPE_JSON) {
        const data = {
            method,
            url,
            payload,
            successCallback,
            errorCallback,
            type
        };

        if (prepend) {
            requests.unshift(data);
        } else {
            requests.push(data);
        }

        if (!isExecutingRequests) {
            isExecutingRequests = true;
            NewApiManager.fireRequests();
        }
    }

    static fireRequests() {
        if (requests.length === 0) {
            isExecutingRequests = false;
            return;
        }

        const request = requests.shift();
        if (undefined === request) {
            return;
        }
        const {method, url, payload, successCallback, errorCallback, type} = request;

        (async function() {
            try {

                const requestOptions: RequestOptions = {
                    method,
                    headers: {
                        // @ts-ignore
                        'X-Device-ID': LocalStorage.getDeviceId(),
                    }
                };

                if (LocalStorage.getUserAccount()?.jwt) {
                    requestOptions.headers.authorization = LocalStorage.getUserAccount().jwt;
                }

                if (payload !== undefined && type === REQUEST_TYPE_JSON) {
                    requestOptions.headers['Content-Type'] = 'application/json';
                    requestOptions.body = JSON.stringify(payload);
                }

                if (payload !== undefined && type === REQUEST_TYPE_IMAGE) {
                    requestOptions.body = payload;
                }

                let response: any = await fetch(url, requestOptions);
                if (!response.ok && response.status === 401 && requestOptions.headers.authorization !== undefined) {
                    if (url === TOKEN_REFRESH_URL) {
                        logOut(undefined, true);
                        return;
                    }

                    NewApiManager.addRequest(method, url, payload, successCallback, errorCallback, true, type);
                    NewApiManager.addRequest('POST', TOKEN_REFRESH_URL, payload, successCallback, errorCallback, true, type);

                    NewApiManager.fireRequests();
                    return;
                }
                const formattedResponse = {data: await response.json(), status: response.status, ok: response.ok}
                if (connectionAlertStatus.value !== undefined) {
                    connectionRestored();
                }

                switch (url) {
                    case TOKEN_REFRESH_URL:
                        setUserAccount(formattedResponse.data);
                        NewApiManager.fireRequests();
                        return;
                }

                if (!formattedResponse.ok) {
                    errorCallback(formattedResponse);
                } else {
                    successCallback(formattedResponse);
                }

                NewApiManager.fireRequests();
            } catch (error: any) {
                connectionInterrupted();
                NewApiManager.addRequest(method, url, payload, successCallback, errorCallback, true, type);
                setTimeout(() => {
                    NewApiManager.fireRequests();
                }, 3000);
            }
        })();
    }
}
