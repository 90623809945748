import { PostBasicInterface } from '../../Interfaces/PostBasicInterface';
import Translator from '../../Utils/Translator';
import { Card } from './Card';
import { DeviceInterface } from '../../Interfaces/DeviceInterface';

interface CardsInterface {
    devices: DeviceInterface[];
    className?: string;
    limit?: number;
    showMore?: boolean;
    loadPosts?: () => void;
}

export function Cards({devices, className = '', limit = 0, showMore = false}: CardsInterface) {
    return <>
        <div className={`${className} bg-white border rounded-xl w-full`}>
            <div>
                <div className={`p-[10px]`}>
                    <div className={`text-[25px] font-bold`}>
                        {Translator.translateId('label__my_devices')}
                    </div>
                    <div className={`italic`}>
                        {Translator.translateId('label__my_devices_auto_log_out')}
                    </div>
                    <div className={`italic`}>
                        {Translator.translateId('label__my_devices_manual_log_out')}
                    </div>
                    <div className={`xl:flex xl:flex-wrap xl:gap-[10px] mt-[10px]`}>
                        {devices.slice(0, limit === 0 ? devices.length : limit).map((device: DeviceInterface, index: number) =>
                            <Card key={`device-${index}`} device={device} className={`xl:w-[calc((100%/2)-5px)] my-[10px]`}/>
                        )}
                    </div>
            </div>
            {showMore && limit > 0 && devices?.length > limit &&
                <div className={`font-bold underline p-[20px] border-t text-center`}>
                    {Translator.translateId('label__show_more')}
                </div>
            }
            </div>
        </div>
    </>;
}