import {BoardCardInterface} from "../../Interfaces/BoardCardInterface";
import {StoryPointTile} from "./StoryPointTile";
import {DueDots} from "./DueDots";

interface CardInterface {
    card: BoardCardInterface;
    toggleUpdateModal: (item: BoardCardInterface) => void;
    className?: string;
}

export function Card({card, toggleUpdateModal, className = ''}: CardInterface) {

    return <div onClick={() => toggleUpdateModal(card)} className={`${card.completeDate !== null && `bg-green-100`} ${className}`}>
        <div className={`font-bold line-clamp-3`} title={card.name}>
            {card.name}
        </div>
        {(card.boardStoryPoint !== null || card.dueDate !== null) && <div className={`flex mt-[10px] justify-between gap-[5px]`}>
            <div>
                {card.boardStoryPoint !== null && <StoryPointTile backgroundColor={card.boardStoryPoint.backgroundColor} textColor={card.boardStoryPoint.textColor} name={card.boardStoryPoint.name} points={card.boardStoryPoint.points}/>}
            </div>
            <div>
                {card.dueDate !== null && <DueDots card={card}/>}
            </div>
        </div>}
    </div>;
}