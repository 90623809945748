import {theme} from "../../../Signals/ThemeSignal";
import {BackIcon} from "../../../Icons/BackIcon";
import Translator from "../../../Utils/Translator";
import {Link} from "react-router-dom";

interface BackButtonInterface {
    link: string;
    label: string;
}

export function BackButton({link, label}: BackButtonInterface) {
    return <div className={'flex'}>
        <div
            className={`flex rounded bg-${theme.value}-primary-200 items-center border-[1px] border-${theme.value}-primary-950`}>
            <Link to={link}><div
                className={`p-[10px] hover:bg-${theme.value}-primary-300 cursor-pointer border-r-[1px] border-${theme.value}-primary-950 rounded-l`}>
                <BackIcon className={`h-[30px] w-[30px] fill-${theme.value}-primary-950`}/>
            </div></Link>
            <div className={`p-[10px] items-center text-xl`}>
                {Translator.translateId(label)}
            </div>
        </div>
    </div>;
}
