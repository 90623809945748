import Translator from "../Utils/Translator";

export const CTA_LOG_IN = Translator.generateTranslationData('cta', 'log_in');
export const CTA_REGISTER = Translator.generateTranslationData('cta', 'register');
export const LABEL_WELCOME = Translator.generateTranslationData('label', 'welcome');
export const LABEL_APP_NAME = Translator.generateTranslationData('label', 'app_name');
export const LABEL_REGISTER = Translator.generateTranslationData('label', 'register');
export const LABEL_SETTINGS = Translator.generateTranslationData('label', 'settings');
export const LABEL_LOG_IN = Translator.generateTranslationData('label', 'log_in');
export const LABEL_CONFIGURE_PASSWORD = Translator.generateTranslationData('label', 'configure_password');
export const LABEL_FIRSTNAME = Translator.generateTranslationData('label', 'firstname');
export const LABEL_LOGIN_OR_EMAIL = Translator.generateTranslationData('label', 'login_or_email');
export const LABEL_LASTNAME = Translator.generateTranslationData('label', 'lastname');
export const LABEL_EMAIL = Translator.generateTranslationData('label', 'email');
export const LABEL_LOGIN = Translator.generateTranslationData('label', 'login');
export const LABEL_DAY = Translator.generateTranslationData('label', 'day');
export const LABEL_MONTH = Translator.generateTranslationData('label', 'month');
export const LABEL_YEAR = Translator.generateTranslationData('label', 'year');
export const LABEL_PASSWORD = Translator.generateTranslationData('label', 'password');
export const LABEL_CONFIRM_PASSWORD = Translator.generateTranslationData('label', 'confirm_password');
export const LABEL_REFERRER_LOGIN = Translator.generateTranslationData('label', 'referrer_login');
export const LABEL_KARAOKE_MODE = Translator.generateTranslationData('label', 'karaoke_mode');
export const LABEL_FORM_RULE = (ruleName: string, value?: string|number|boolean|null) => Translator.generateTranslationData('label', `form_rule_${ruleName}`, {value});
export const LABEL_LOCALE_FR = Translator.generateTranslationData('label', `locale_fr`);
export const LABEL_LOCALE_EN = Translator.generateTranslationData('label', `locale_en`);
export const LABEL_FORM_ERROR = Translator.generateTranslationData('label', `form_error`);
export const LABEL_FORM_CUSTOM_ERROR = (data: any) => Translator.generateTranslationData('label', `form_error_${data}`);
export const LABEL_REGISTER_FORM_SUCCESS = Translator.generateTranslationData('label', `register_form_success`);
export const LABEL_UPDATE_NOTE_FORM_SUCCESS = Translator.generateTranslationData('label', `update_note_form_success`);
export const LABEL_UPDATE_NOTE_FORM_ERROR = Translator.generateTranslationData('label', `update_note_form_error`);
export const LABEL_LOG_IN_FORM_SUCCESS = Translator.generateTranslationData('label', `log_in_form_success`);
export const LABEL_REGISTER_FORM_ERROR = Translator.generateTranslationData('label', `register_form_error`);
export const LABEL_FORGOT_PASSWORD_FORM_ERROR = Translator.generateTranslationData('label', `forgot_password_form_error`);
export const LABEL_REGISTER_UNKNOWN_REFERRER_FORM_ERROR = Translator.generateTranslationData('label', `register_unknown_referrer_form_error`);
export const LABEL_CREATE_NOTE_FORM_ERROR = Translator.generateTranslationData('label', `create_note_form_error`);
export const LABEL_LOG_IN_FORM_ERROR = Translator.generateTranslationData('label', `log_in_form_error`);
export const LABEL_CONFIGURE_PASSWORD_FORM_ERROR = Translator.generateTranslationData('label', `configure_password_form_error`);
export const LABEL_ACCOUNT_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `account_update_form_success`);
export const LABEL_CONFIGURE_PASSWORD_FORM_SUCCESS = Translator.generateTranslationData('label', `configure_password_form_success`);
export const LABEL_NOTE_NAME = Translator.generateTranslationData('label', `note_name`);
export const LABEL_TYPE_YOUR_NAME = Translator.generateTranslationData('label', `type_your_name`);
export const LABEL_TITLE = Translator.generateTranslationData('label', `title`);
export const LABEL_CONTACT_SUBJECT = Translator.generateTranslationData('label', `contact_subject`);
export const LABEL_LEAVE_COMMENT = Translator.generateTranslationData('label', `leave_comment`);
export const LABEL_PROBLEM_DESCRIPTION = Translator.generateTranslationData('label', `problem_description`);
export const LABEL_CONTACT_ADMIN_DESCRIPTION = Translator.generateTranslationData('label', `contact_admin_description`);
export const CTA_SUBMIT = Translator.generateTranslationData('cta', `submit`);
export const CTA_LOG_OUT = Translator.generateTranslationData('cta', `log_out`);
export const CTA_SETTINGS = Translator.generateTranslationData('cta', `settings`);
export const LABEL_WELCOME_1 = Translator.generateTranslationData('label', `welcome_1`);
export const LABEL_WELCOME_2 = Translator.generateTranslationData('label', `welcome_2`);
export const LABEL_COUPON_DESCRIPTION = Translator.generateTranslationData('label', `coupon_description`);
export const LABEL_COMPANY_NAME = Translator.generateTranslationData('label', `company_name`);
export const LABEL_COMPANY_INTERNAL_NAME = Translator.generateTranslationData('label', `company_internal_name`);
export const LABEL_COMPANY_URL = Translator.generateTranslationData('label', `company_internal_url`);
export const LABEL_COUPON_VALUE = Translator.generateTranslationData('label', `coupon_value`);
export const LABEL_COUPON_URL = Translator.generateTranslationData('label', `coupon_url`);
export const LABEL_COUPON_CREATION_FORM_SUCCESS = Translator.generateTranslationData('label', `coupon_creation_form_success`);
export const LABEL_COUPON_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `coupon_update_form_success`);
export const LABEL_BOARD_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_update_form_success`);
export const LABEL_BOARD_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `board_update_form_error`);
export const LABEL_BOARD_COLUMN_CREATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_column_create_form_success`);
export const LABEL_BOARD_COLUMN_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_column_update_form_success`);
export const LABEL_BOARD_ROW_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_row_update_form_success`);
export const LABEL_BOARD_STORY_POINT_CREATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_story_point_create_form_success`);
export const LABEL_BOARD_STORY_POINT_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `board_story_point_create_form_error`);
export const LABEL_BOARD_ROW_CREATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_row_create_form_success`);
export const LABEL_BOARD_CARD_CREATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_card_create_form_success`);
export const LABEL_BOARD_CARD_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `board_card_update_form_success`);
export const LABEL_BOARD_CARD_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `board_card_update_form_error`);
export const LABEL_BOARD_CARD_COMMENT_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `board_card_comment_create_form_error`);
export const LABEL_BOARD_CARD_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `board_card_create_form_error`);
export const LABEL_SHORTCUT_LINK_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `shortcut_link_create_form_error`);
export const LABEL_REFERRAL_LINK_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `shortcut_link_create_form_error`);
export const LABEL_SHORTCUT_LINK_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `shortcut_link_update_form_error`);
export const LABEL_REFERRAL_LINK_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `referral_link_update_form_error`);
export const LABEL_BOARD_COLUMN_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `board_column_create_form_error`);
export const LABEL_BOARD_COLUMN_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `board_column_update_form_error`);
export const LABEL_BOARD_ROW_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `board_row_update_form_error`);
export const LABEL_BOARD_ROW_CREATE_FORM_ERROR = Translator.generateTranslationData('label', `board_row_create_form_error`);
export const LABEL_COUPON_CREATION_FORM_ERROR = Translator.generateTranslationData('label', `coupon_creation_form_error`);
export const LABEL_COUPON_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `coupon_update_form_error`);
export const LABEL_COMPANY_CREATION_FORM_ERROR = Translator.generateTranslationData('label', `company_creation_form_error`);
export const LABEL_COMPANY_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `company_update_form_error`);
export const LABEL_COMPANY_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `company_update_form_success`);
export const LABEL_COMPANY_CREATION_FORM_SUCCESS = Translator.generateTranslationData('label', `company_creation_form_success`);
export const LABEL_CAA_CREATION_FORM_SUCCESS = Translator.generateTranslationData('label', `caa_creation_form_success`);
export const LABEL_CAA_CREATION_FORM_ERROR = Translator.generateTranslationData('label', `caa_creation_form_error`);
export const LABEL_CAA_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `caa_update_form_success`);
export const LABEL_NEWS_CONFIG_UPDATE_FORM_SUCCESS = Translator.generateTranslationData('label', `caa_update_form_success`);
export const LABEL_CAA_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `caa_update_form_error`);
export const LABEL_NEWS_CONFIG_UPDATE_FORM_ERROR = Translator.generateTranslationData('label', `caa_update_form_error`);
export const LABEL_CAA_URL_REGEXP = Translator.generateTranslationData('label', `caa_url_regexp`);
export const LABEL_NEWS_CONFIG_URL = Translator.generateTranslationData('label', `news_config_url`);
export const LABEL_NEWS_CONFIG_LANG = Translator.generateTranslationData('label', `news_config_lang`);
export const LABEL_NEWS_CONFIG_NAME = Translator.generateTranslationData('label', `news_config_name`);
export const LABEL_NEWS_CONFIG_DESCRIPTION = Translator.generateTranslationData('label', `news_config_description`);
export const LABEL_NEWS_CONFIG_BASE_SELECTOR = Translator.generateTranslationData('label', `news_config_base_selector`);
export const LABEL_NEWS_CONFIG_IMAGE_SELECTOR = Translator.generateTranslationData('label', `news_config_image_selector`);
export const LABEL_NEWS_CONFIG_IMAGE_CREDIT_SELECTOR = Translator.generateTranslationData('label', `news_config_image_credit_selector`);
export const LABEL_NEWS_CONFIG_ARTICLE_URL_SELECTOR = Translator.generateTranslationData('label', `news_config_article_url_selector`);
export const LABEL_NEWS_CONFIG_TITLE_SELECTOR = Translator.generateTranslationData('label', `news_config_title_selector`);
export const LABEL_NEWS_CONFIG_DESCRIPTION_SELECTOR = Translator.generateTranslationData('label', `news_config_description_selector`);
export const LABEL_NEWS_CONFIG_IN_ARTICLE_DESCRIPTION_SELECTOR = Translator.generateTranslationData('label', `news_config_in_article_description_selector`);
export const LABEL_NEWS_CONFIG_SOURCE_DATETIME_SELECTOR = Translator.generateTranslationData('label', `news_config_source_datetime_selector`);
export const LABEL_NEWS_CONFIG_IN_ARTICLE_SOURCE_DATETIME_SELECTOR = Translator.generateTranslationData('label', `news_config_in_article_source_datetime_selector`);
export const LABEL_BOARD_NAME = Translator.generateTranslationData('label', `board_name`);
export const LABEL_COMMENT = Translator.generateTranslationData('label', `write_comment`);
export const LABEL_BOARD_COLUMN_NAME = Translator.generateTranslationData('label', `board_column_name`);
export const LABEL__SHORTCUT_LINK_NAME = Translator.generateTranslationData('label', `shortcut_link_name`);
export const LABEL__REFERRAL_LINK_NAME = Translator.generateTranslationData('label', `referral_link_name`);
export const LABEL_LINK_URL = Translator.generateTranslationData('label', `link_url`);
export const LABEL_LINK_REFERRAL_CODE = Translator.generateTranslationData('label', `link_referral_code`);
export const LABEL_BOARD_ROW_NAME = Translator.generateTranslationData('label', `board_row_name`);
export const LABEL_BOARD_STORY_POINT_NAME = Translator.generateTranslationData('label', `board_story_point_name`);
export const LABEL_BOARD_STORY_POINT_POINTS = Translator.generateTranslationData('label', `board_story_point_points`);
export const LABEL_BOARD_STORY_POINT_BACKGROUND_COLOR = Translator.generateTranslationData('label', `board_story_point_background_color`);
export const LABEL_BOARD_STORY_POINT_TEXT_COLOR = Translator.generateTranslationData('label', `board_story_point_text_color`);
export const LABEL_BOARD_CARD_NAME = Translator.generateTranslationData('label', `board_card_name`);
export const LABEL_PLAYLIST_CARD_NAME = Translator.generateTranslationData('label', `playlist_name`);
export const LABEL_BOARD_CARD_STORY_POINTS = Translator.generateTranslationData('label', `board_card_story_points`);
export const LABEL_BOARD_CARD_DESCRIPTION = Translator.generateTranslationData('label', `board_card_description`);
export const LABEL_BOARD_CARD_COMMENT = Translator.generateTranslationData('label', `board_card_comment`);
export const LABEL_CAA_COUPON = Translator.generateTranslationData('label', `caa_coupon`);
export const LABEL_CAA_REMOVE_TIMEOUT = Translator.generateTranslationData('label', `caa_remove_timeout`);
export const LABEL_CAA_SUBMIT_TIMEOUT = Translator.generateTranslationData('label', `caa_submit_timeout`);
export const LABEL_CAA_DOM_CHANGE = Translator.generateTranslationData('label', `caa_dom_change`);
export const LABEL_CAA_REMOVE = Translator.generateTranslationData('label', `caa_remove`);
export const LABEL_CAA_SUBMIT = Translator.generateTranslationData('label', `caa_submit`);
export const LABEL_CAA_TOTAL = Translator.generateTranslationData('label', `caa_total`);
export const LABEL_YOUTUBE_API_KEY = Translator.generateTranslationData('label', `youtube_api_key`);
export const LABEL_YOUTUBE_API_KEY_CREATION_FORM_SUCCESS = Translator.generateTranslationData('label', `youtube_api_key_creation_form_success`);
export const LABEL_WEATHER_API_KEY_CREATION_FORM_SUCCESS = Translator.generateTranslationData('label', `weather_api_key_creation_form_success`);
export const LABEL_YOUTUBE_API_KEY_CREATION_FORM_ERROR = Translator.generateTranslationData('label', `youtube_api_key_creation_form_error`);
export const LABEL_WEATHER_API_KEY_CREATION_FORM_ERROR = Translator.generateTranslationData('label', `weather_api_key_creation_form_error`);
export const LABEL_YOUTUBE_API_KEY_CREATION_INVALID_FORM_ERROR = Translator.generateTranslationData('label', `youtube_api_key_creation_invalid_form_error`);
export const LABEL_WEATHER_API_KEY_CREATION_INVALID_FORM_ERROR = Translator.generateTranslationData('label', `weather_api_key_creation_invalid_form_error`);
export const LABEL_YOUTUBE_API_KEY_CREATION_ALREADY_EXISTS_FORM_ERROR = Translator.generateTranslationData('label', `youtube_api_key_creation_already_exists_form_error`);
export const LABEL_WEATHER_API_KEY_CREATION_ALREADY_EXISTS_FORM_ERROR = Translator.generateTranslationData('label', `weather_api_key_creation_already_exists_form_error`);
export const LABEL_CREATE_POST_BASIC_PLACEHOLDER = Translator.generateTranslationData('label', `create_post_basic_content_placeholder`);
export const LABEL_CREATE_POST_COUPON_PLACEHOLDER = Translator.generateTranslationData('label', `create_post_coupon_content_placeholder`);
export const LABEL_MOOD = Translator.generateTranslationData('label', `mood`);
export const LABEL_USER_MOOD = Translator.generateTranslationData('label', `user_mood`);
export const LABEL_DESCRIPTION = Translator.generateTranslationData('label', `description`);
export const LABEL_USER_DESCRIPTION = Translator.generateTranslationData('label', `user_description`);
export const LABEL_USER_IS_PRIVATE = Translator.generateTranslationData('label', `user_is_private`);
export const LABEL_USER_IS_PRIVATE_DESCRIPTION = Translator.generateTranslationData('label', `user_is_private_description`);
