import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {
    BACK_OFFICE_ALL_ADMINISTRATORS,
    BACK_OFFICE_ALL_USERS,
    BACK_OFFICE_SPACESHARE_RATES
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { UserInterface } from '../Interfaces/UserInterface';
import { RateInterface } from '../Interfaces/RateInterface';

export function BackOfficeSpaceshareRates() {
    const [rates, setRates] = useState([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_SPACESHARE_RATES,
            (response: any) => setRates(response.data)
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {rates.map((rate: RateInterface, index) => <div key={`rate-${index}`}>
                    {rate.rate} {rate.userAccount.login} {rate.title} {rate.message}
                </div>)}
            </div>
        </div>
    </PageTemplate>;
}
