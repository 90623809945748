import { DealIcon } from '../../Icons/DealIcon';
import { CouponInterface } from '../../Interfaces/CouponInterface';
import moment from 'moment';
import { utcDateTimeToLocal } from '../../Utils/Date';
import Translator from '../../Utils/Translator';
import { ShareIcon } from '../../Icons/ShareIcon';
import { useEffect, useState } from 'react';
import { CreatePostCouponModal } from '../Posts/CreatePostCouponModal';
import { EditIcon } from '../../Icons/EditIcon';
import { EditModal } from './EditModal';
import { DeleteModal } from './DeleteModal';
import { CrossRoundedIcon } from '../../Icons/CrossRoundedIcon';
import { LinkIcon } from '../../Icons/LinkIcon';
import { isValidUrl } from '../../Utils/String';
import { CopyIcon } from '../../Icons/CopyIcon';
import { CheckRoundedIcon } from '../../Icons/CheckRoundedIcon';
import { CopyButton } from '../Common/CopyButton';

interface CardInterface {
    coupon: CouponInterface;
    className?: string;
    showShareButton?: boolean;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    loadCoupons?: () => void;
}

export function Card({coupon, className = '', showShareButton = false, showEditButton = false, showDeleteButton = false, loadCoupons = () => {}}: CardInterface) {
    let started = false;
    let ended = false;
    let startDate = coupon.startDate !== null ? utcDateTimeToLocal(coupon.startDate.date) : null;
    let endDate = coupon.endDate !== null ? utcDateTimeToLocal(coupon.endDate.date) : null;
    const [postCouponModalVisible, setPostCouponModalVisible] = useState(false);
    const [editCouponModalVisible, setEditCouponModalVisible] = useState(false);
    const [deleteCouponModalVisible, setDeleteCouponModalVisible] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 3000);
        }
    }, [copied]);

    if (moment().diff(moment(startDate)) > 0) {
        started = true;
    }

    if (moment().diff(moment(endDate)) > 0) {
        ended = true
    }

    const dateTimeLabel = (dateTime: string) => Translator.translateId(`label__date_time`, undefined, {
        day: dateTime.split(' ')[0].split('-')[2],
        month: Translator.translateId(`label__month_${dateTime.split(' ')[0].split('-')[1]}_short`),
        year: dateTime.split(' ')[0].split('-')[0],
        hours: dateTime.split(' ')[1].split(':')[0],
        minutes: dateTime.split(' ')[1].split(':')[1],
    })

    const isEmpty = (value: string|null) => {
        return value === '' || value === null;
    }

    let couponUrl: string|undefined = undefined
    if (isValidUrl(coupon.couponUrl) && coupon.couponUrl !== null) {
        const url = new URL(coupon.couponUrl);
        couponUrl = `${url.protocol}//${url.hostname}`;
    }

    const copyCouponValue = () => {
        if (coupon.couponValue === null) {
            return;
        }
        navigator.clipboard.writeText(coupon.couponValue);

        setCopied(true);
    }

    const isOutdated = started && ended;

    return <>
        {postCouponModalVisible && <CreatePostCouponModal coupon={coupon} toggleModal={() => setPostCouponModalVisible(false)} submitSuccessCallback={() => setPostCouponModalVisible(false)}/>}
        {editCouponModalVisible && <EditModal coupon={coupon} toggleModal={() => setEditCouponModalVisible(false)} submitSuccessCallback={() => {setEditCouponModalVisible(false); loadCoupons()}}/>}
        {deleteCouponModalVisible && <DeleteModal coupon={coupon} toggleModal={() => setEditCouponModalVisible(false)} deleteSuccessCallback={() => {setDeleteCouponModalVisible(false); loadCoupons()}}/>}
        <div className={`${className} p-[10px] bg-gray-100 rounded-xl ${isOutdated ? 'opacity-35 hover:opacity-100' : ''}`}>
            <div className={`flex gap-[10px] items-start`}>
                <div>
                    <DealIcon className={`w-[40px]`}/>
                </div>
                <div className={`w-full`}>
                    <div className={`flex w-full justify-between items-start`}>
                        <div className={`font-bold text-[25px]`}>
                            {!isEmpty(coupon.couponValue) && couponUrl === undefined && <div className={`flex gap-[5px] items-center`}>
                                {coupon.couponValue !== null && <CopyButton withBorder={false} labelClassName={`max-w-[200px] text-ellipsis overflow-hidden`} value={coupon.couponValue} label={coupon.couponValue}/>}
                            </div>}
                            {isEmpty(coupon.couponValue) && couponUrl !== undefined && <a className={`text-blue-500 flex gap-[5px] items-center`} href={coupon.couponUrl !== null ? coupon.couponUrl : ''} target={'_blank'}>
                                <div><LinkIcon className={`w-[20px] fill-blue-500`}/></div>
                                {/*@ts-ignore*/}
                                <div className={`underline`}>{isValidUrl(coupon.couponUrl) ? new URL(coupon.couponUrl).hostname : Translator.translateId('label__unknown_website')}</div>
                            </a>}
                            {!isEmpty(coupon.couponValue) && couponUrl !== undefined && <div className={`flex items-center gap-[5px]`}>{coupon.couponValue}{!copied ? <CopyIcon onClick={copyCouponValue} className={`w-[20px] cursor-pointer`}/> : <CheckRoundedIcon className={`w-[20px]`}/>}</div>}
                        </div>
                        {(showShareButton || showEditButton) && <div className={`flex gap-[10px] items-center`}>
                            {showEditButton &&
                                <div onClick={() => setEditCouponModalVisible(true)} className={`cursor-pointer`}>
                                    <EditIcon className={`w-[20px]`}/></div>}
                            {showShareButton &&
                                <div onClick={() => setPostCouponModalVisible(true)} className={`cursor-pointer`}>
                                    <ShareIcon
                                        className={`w-[20px]`}/></div>}
                            {showDeleteButton &&
                                <div onClick={() => setDeleteCouponModalVisible(true)} className={`cursor-pointer`}>
                                    <CrossRoundedIcon
                                        className={`w-[20px]`}/></div>}
                        </div>}
                    </div>
                    {!isEmpty(coupon.couponValue) && couponUrl !== undefined && coupon.couponUrl !== null && <a className={`text-blue-500 flex gap-[5px] items-center`} href={couponUrl} target={'_blank'}>
                        <div><LinkIcon className={`w-[20px] fill-blue-500`}/></div>
                        <div>{couponUrl}</div>
                    </a>}
                    <div>{coupon.description}</div>
                    <div className={`italic font-bold`}>
                        {!started && !ended && startDate !== null && <div className={`text-blue-500`}>
                            {Translator.translateId('label__starts_on_date', undefined, {date: dateTimeLabel(startDate)})}
                        </div>}
                        {started && !ended && endDate !== null && <div className={`text-red-500`}>
                            {Translator.translateId('label__ends_on_date', undefined, {date: dateTimeLabel(endDate)})}
                        </div>}
                        {!ended && endDate !== null && startDate === null && <div className={`text-red-500`}>
                            {Translator.translateId('label__ends_on_date', undefined, {date: dateTimeLabel(endDate)})}
                        </div>}
                        {started && endDate === null && <div className={`text-green-500`}>
                            {Translator.translateId('label__date_in_progress')}
                        </div>}
                        {isOutdated && <div className={`text-gray-400`}>
                            {Translator.translateId('label__oudated')}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
