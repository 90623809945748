import PageTemplate from "../Components/PageTemplate/PageTemplate";
import { useEffect, useState } from "react";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import { NewApiManager } from '../Utils/NewApiManager';
import {
    CREATE_POST_BASIC,
    CREATE_POST_BASIC_FORM_VALIDATOR, INFO,
    MOST_POPULAR_YOUTUBE_VIDEOS,
    POSTS
} from '../Constants/ApiUrls';
import { PostBasicInterface } from '../Interfaces/PostBasicInterface';
import { Card } from '../Components/Posts/Card';
import { YoutubeVideoCard } from '../Components/YoutubeVideo/YoutubeVideoCard';
import Translator from '../Utils/Translator';
import { VideoDataInterface } from '../Interfaces/VideoDataInterface';
import { ProfileIcon } from '../Icons/ProfileIcon';
import { isLoggedIn, userAccount } from '../Signals/UserAccountSignal';
import { Dropdown } from '../Components/Common/Input/Dropdown';
import TextArea from '../Components/Common/Input/Textarea';
import { LABEL_CREATE_POST_BASIC_PLACEHOLDER } from '../Constants/Label';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { FormHook } from '../Hooks/FormHook';
import { PostCouponInterface } from '../Interfaces/PostCouponInterface';
import { InfoInterface } from '../Interfaces/InfoInterface';
import { postsRefs } from '../FirebaseRefs/Posts';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';

export default function Homepage() {
    const {loadConstrains, shouldSubmit, form, handleChange, setValues} = FormHook(['content', 'entityVisibility']);

    const [info, setInfo] = useState<InfoInterface|undefined>();
    const [posts, setPosts] = useState<PostBasicInterface[]|PostCouponInterface[]|undefined>(undefined);
    const [mostPopularVideos, setMostPopularVideos] = useState([]);
    const [hasPostsBefore, setHasPostBefore] = useState(false);

    useEffect(() => {
        resetGlobalSearch();

        loadConstrains(CREATE_POST_BASIC_FORM_VALIDATOR);
        loadPosts();

        NewApiManager.get(
            MOST_POPULAR_YOUTUBE_VIDEOS(2),
            (response: any) => setMostPopularVideos(response.data),
        )

        NewApiManager.get(
            INFO,
            (response: any) => {
                setInfo(response.data);
            },
        );
    }, []);

    useEffect(() => {
        postsRefs(posts ?? [], setPosts);
    }, [posts]);

    const loadPosts = (type: 'beforeDate'|'afterDate'|undefined = undefined, value: string|undefined = undefined) => {
        const postCount = 10;
        setHasPostBefore(false);
        NewApiManager.get(
            POSTS(type, value, postCount),
            (response: any) => {
                let newState = [];
                if (type === 'beforeDate') {
                    newState = [
                        ...(posts??[]),
                        ...response.data,
                    ];
                }
                if (type === 'afterDate') {
                    newState = [
                        ...response.data,
                        ...(posts??[]),
                    ];
                }
                if (type === undefined) {
                    newState = response.data;
                }
                if (response.data.length === postCount) {
                    setHasPostBefore(true);
                }

                setPosts(newState);
            },
        )
    }

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.post(
            CREATE_POST_BASIC,
            {
                entityVisibility: form.fields.entityVisibility.value,
                content: form.fields.content.value
            },
            (response: any) => {
                setValues({
                    content: '',
                    entityVisibility: '',
                });
                if ((posts??[]).length > 0) {
                    // @ts-ignore
                    loadPosts('afterDate', posts[0].creationDate.date);
                } else {
                    loadPosts();
                }
            }
        );
    }

    let userCountPercentage = 0;
    if (info !== undefined) {
        userCountPercentage = (info.userCounter/info.userCounterGoal)*100;
        if (userCountPercentage > 100) userCountPercentage = 100;
    }

    return <PageTemplate sidebar={"normal"}>
        <div className={`xl:flex gap-[10px] mt-[10px]`}>
            <div className={`w-full px-[10px]`}>
                <div className={`border bg-yellow-100 mb-[10px] rounded-xl p-[20px]`}>
                    <div>
                        {Translator.translateId('label__discover_spaceshare')}
                    </div>
                    <div className={`flex gap-[10px] mt-[10px]`}>
                        <PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__about_us')}</PrimaryButton>} to={'/about-us'}/>
                        <PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__roadmap')}</PrimaryButton>} to={'/roadmap'}/>
                        <PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__team')}</PrimaryButton>} to={'/team'}/>
                        {/*<PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__ask_me_anything')}</PrimaryButton>} to={'/ama'}/>*/}
                        {/*<PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__features')}</PrimaryButton>} to={'/features'}/>*/}
                        {/*<PrimaryLink label={<PrimaryButton>{Translator.translateId('cta__conditions')}</PrimaryButton>} to={'/conditions'}/>*/}
                    </div>
                </div>
                {isLoggedIn() && <div className={`bg-white rounded-xl border`}>
                    <form>
                        <div className={`p-[20px]`}>
                            <div
                                className={`flex ${form.fields.entityVisibility.value !== '' ? 'mb-[20px]' : ''} gap-[10px]`}>
                                <div
                                    className={`w-[80px] h-[80px] flex justify-center items-center rounded-full bg-gray-300`}>
                                    {userAccount.value !== undefined && userAccount.value.profileImage !== null ?
                                        <img src={userAccount.value.profileImage.url}
                                             alt={Translator.translateId('label__profile_image')}
                                             className={`w-full h-full bg-white rounded-full`}/> :
                                        <ProfileIcon className={`w-[40px] h-[40px] fill-black`}/>}
                                </div>
                                <div>
                                    <div className={`font-bold text-[20px]`}>{userAccount.value?.login}</div>
                                    <Dropdown form={form} name={'entityVisibility'} values={[
                                        {
                                            id: '',
                                            label: Translator.translateId('label__create_a_post')
                                        },
                                        {
                                            id: 'public',
                                            label: Translator.translateId('label__visibility_public')
                                        },
                                        {
                                            id: 'followers',
                                            label: Translator.translateId('label__visibility_followers')
                                        },
                                    ]} setValues={setValues}/>
                                </div>
                            </div>
                            {form.fields.entityVisibility.value !== '' &&
                                <TextArea form={form} name={'content'} handleChange={handleChange}
                                          className={`mt-[10px]`} textareaClassName={`min-h-[50px]`}
                                          placeholder={LABEL_CREATE_POST_BASIC_PLACEHOLDER}/>}
                        </div>
                        {form.fields.entityVisibility.value !== '' &&
                            <div className={`border-t p-[20px] flex justify-end`}>
                                <PrimaryButton onClick={onSubmit}>{Translator.translateId('cta__share')}</PrimaryButton>
                            </div>}
                    </form>
                </div>}
                {posts === undefined && <div className={`flex justify-center mt-[10px]`}><SpinnerIcon/></div>}
                {posts?.map((post: PostBasicInterface, index: number) =>
                    <Card showFakeCommentBlock={true} key={`post-${index}`} post={post}
                          className={`bg-white rounded-xl p-[10px] border`} posts={posts} setPosts={setPosts}/>)}
                {posts !== undefined && hasPostsBefore && <div className={`p-[10px] mt-[10px] flex justify-center`}>
                    {/*@ts-ignore*/}
                    <PrimaryButton
                        onClick={() => loadPosts('beforeDate', posts[posts.length - 1].creationDate.date)}>{Translator.translateId('cta__load_more')}</PrimaryButton>
                </div>}
            </div>
            <div className={`w-[500px] hidden xl:block`}>
                {info !== undefined && <div className={`bg-white rounded-xl p-[10px] border`}>
                    <div className={`text-[25px] font-bold`}>
                        {Translator.translateId('label__goals_title')}
                    </div>
                    <div className={`border-b my-[10px]`}/>
                    <div className={`rounded-xl border h-[40px]`}>
                        <div className={`bg-blue-500 rounded-xl h-[40px] mb-[-35px]`} style={{width: `${userCountPercentage}%`}}/>
                        <div className={`flex gap-[5px] items-end justify-center font-bold`}>
                            <div className={`text-[20px]`}>{info?.userCounter}</div>
                            <div>/</div>
                            <div>{Translator.translateId('label__users', undefined, {counter: info.userCounterGoal})}</div>
                        </div>
                    </div>
                </div>}
                <div className={`bg-white rounded-xl p-[10px] mt-[10px] border`}>
                    <div className={`text-[25px] font-bold`}>
                        {Translator.translateId('label__most_popular_video')}
                    </div>
                    <div className={`border-b my-[10px]`}/>
                    {mostPopularVideos.length > 0 && <div>
                        {mostPopularVideos.map((videoData: VideoDataInterface, index: number) => <YoutubeVideoCard key={`video-${index}`}
                            videoData={videoData} className={`${index > 0 ? 'border-t' : ''} p-[20px]`}/>)}
                    </div>}
                </div>
            </div>
        </div>
    </PageTemplate>;
}
