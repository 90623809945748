import { PostInterface } from '../Interfaces/PostInterface';
import { addRef, closeRef } from '../Signals/FirebaseDatabaseSignal';
import { NewApiManager } from '../Utils/NewApiManager';
import { POST, QRCODE_AUTO_LOG_IN_APPLY } from '../Constants/ApiUrls';
import { deviceId } from '../Signals/DeviceIdSignal';
import { setUserAccount } from '../Signals/UserAccountSignal';

export function qrCodeAutoLogInRef() {
    const path = `device-${deviceId}`;
    addRef(path, (action: any) => {
        switch(action.performedAction) {
            case 'qrcode-auto-log-in':
                NewApiManager.get(
                    QRCODE_AUTO_LOG_IN_APPLY,
                    (response: any) => {
                        setUserAccount(response.data);

                        if (action.parameters.karaokeMode !== undefined && action.parameters.karaokeMode) {
                            document.location.href = '/karaoke';
                        } else {
                            document.location.href = '/';
                        }
                    },
                );
                closeQrCodeAutoLogInRef();
                break;
        }
    }, 600);
}

export function closeQrCodeAutoLogInRef() {
    const path = `device-${deviceId}`;
    closeRef(path);
}
