import PageTemplate from "../Components/PageTemplate/PageTemplate";
import Translator from "../Utils/Translator";
import {theme} from "../Signals/ThemeSignal";
import {useEffect, useState} from "react";
import {SpinnerIcon} from "../Icons/SpinnerIcon";
import {isLoggedIn} from "../Signals/UserAccountSignal";
import {Link, useNavigate} from "react-router-dom";
import {BoardInterface} from "../Interfaces/BoardInterface";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {NoteInterface} from "../Interfaces/NoteInterface";
import {NewApiManager} from "../Utils/NewApiManager";
import {ALL_NOTES, BOARDS, CREATE_BOARD} from "../Constants/ApiUrls";

export function Activities() {
    const navigate = useNavigate();
    const [isCreating, setIsCreating] = useState(false);
    const [boards, setBoards] = useState<BoardInterface[]|undefined>(undefined);
    const [notes, setNotes] = useState<NoteInterface[]|undefined>(undefined);
    const createNewBoard = () => {
        if (isCreating) {
            return;
        }
        setIsCreating(true);
        NewApiManager.post(CREATE_BOARD, {}, (response: any) => {
            setIsCreating(false);
            return navigate(`/boards/${response.data.id}`);
        });
    };

    useEffect(() => {
        if (!isLoggedIn()) {
            return;
        }
        NewApiManager.get(BOARDS, (response: any) => setBoards(response.data));
        NewApiManager.get(ALL_NOTES, (response: any) => setNotes(response.data));
        resetGlobalSearch();
    }, []);

    return <PageTemplate sidebar={`normal`}>
        <div className={`p-[10px]`}>
            <div className={`text-2xl`}>
                {Translator.translateId('label__my_notes')}
            </div>
            <div className={`flex gap-[10px] mt-[10px] flex-wrap`}>
                {isLoggedIn() && <Link to={'/notes/create'} className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full`}>
                    <div className={`font-bold border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] bg-${theme.value}-primary-200 border-dashed h-[100px] flex justify-center items-center`}>
                        {Translator.translateId('cta__new')}
                    </div>
                </Link>}
                {!isLoggedIn() && <div>
                    {Translator.translateId(`label__should_log_in_for_notes`)}
                </div>}
                {isLoggedIn() && notes?.map((note: NoteInterface, index: number) => <Link to={`/notes/${note.id}`} key={`note-${index}`} className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] h-[100px] flex p-[10px]`}>
                    <div className={`font-bold line-clamp-2`}>{note.name}</div>
                </Link>)}
                {isLoggedIn() && notes === undefined && <div className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] h-[100px] flex items-center justify-center`}>
                    <SpinnerIcon/>
                </div>}
            </div>
            <div className={`text-2xl mt-[10px]`}>
                {Translator.translateId('label__my_boards')}
            </div>
            <div className={`flex gap-[10px] mt-[10px] flex-wrap`}>
                {isLoggedIn() && <div onClick={createNewBoard}
                    className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full font-bold border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] bg-${theme.value}-primary-200 border-dashed h-[100px] flex justify-center items-center`}>
                    {isCreating ? <SpinnerIcon/> : Translator.translateId('cta__new')}
                </div>}
                {!isLoggedIn() && <div>
                    {Translator.translateId(`label__should_log_in_for_boards`)}
                </div>}
                {isLoggedIn() && boards?.map((board: BoardInterface, index: number) => <Link to={`/boards/${board.id}`} key={`board-${index}`} className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] h-[100px] flex`}>
                    <div className={`font-bold line-clamp-2 ${board.name === null && `italic`}`}>{board.name !== null ? board.name : Translator.translateId(`label__untitled_board`)}</div>
                </Link>)}
                {isLoggedIn() && boards === undefined && <div className={`xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full border-[1px] cursor-pointer rounded border-${theme.value}-primary-950 p-[10px] h-[100px] flex items-center justify-center`}>
                    <SpinnerIcon/>
                </div>}
            </div>
        </div>
    </PageTemplate>;
}
