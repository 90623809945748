import {ReactElement} from "react";
import {locale} from "../Signals/LocaleSignal";
import {translations} from "../Signals/TranslationsSignal";

export interface TranslationDataInterface {
  id: string;
  variables?: any;
}

export default class Translator {
  static generateTranslationData = (type: string, description: string, variables?: any) => {
    return {
      id: `${type}__${description}`,
      variables,
    };
  }

  static translate({id, variables}: TranslationDataInterface, forcedLocale: string|undefined = undefined) {
    variables = variables ?? {};

    const localeToUse = forcedLocale === undefined ? locale.value : forcedLocale;
    let messages: any = translations.value;
    messages = messages === null ? {} : messages;

    if (undefined === messages[id] || undefined === messages[id][localeToUse]) {
      return id;
    }

    let currentMessage = messages[id][localeToUse].message;
    Object.keys(variables).forEach((variableName) => {
      // @ts-ignore
      currentMessage = Translator.replaceJSX(currentMessage, `{${variableName}}`, variables[variableName]);
    });

    return currentMessage;
  }

  static translateId(id: string, forcedLocale: string|undefined = undefined, variables: any = {}) {

    return Translator.translate({id, variables}, forcedLocale);
  }

  // Used to display component as part of translation variables
  static replaceJSX(message: string, variable: string, value: ReactElement){
    const str = message.split(variable).flatMap((item) => [item, value]);
    str.pop();

    return str.join('');
  }
}
