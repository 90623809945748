import './KaraokePlayer.css';
import YouTube, { YouTubePlayer } from 'react-youtube';
import {
    activateRemote,
    changeReadTypeProcess,
    endVideoProcess, executeRemoteActions, getPlayingVideoIndex,
    initPlayerProcess, isNextPreviousEnabled, nextVideoProcess,
    pauseProcess, playByVideoIndexProcess, playingMediaList,
    playProcess, previousVideoProcess, reorderItems
} from '../../Signals/PlayingMediaListSignal';
import { theme } from '../../Signals/ThemeSignal';
import { RecIcon } from '../../Icons/RecIcon';
import { RepeatAllIcon } from '../../Icons/RepeatAllIcon';
import { RepeatOneIcon } from '../../Icons/RepeatOneIcon';
import { PreviousIcon } from '../../Icons/PreviousIcon';
import { PlayIcon } from '../../Icons/PlayIcon';
import { PauseIcon } from '../../Icons/PauseIcon';
import { NextIcon } from '../../Icons/NextIcon';
import { InternetMobileIcon } from '../../Icons/InternetMobileIcon';
import { useEffect, useState } from 'react';
import { addRef } from '../../Signals/FirebaseDatabaseSignal';
import { NewApiManager } from '../../Utils/NewApiManager';
import { YOUTUBE_REMOTE_ACTIONS } from '../../Constants/ApiUrls';
import { deviceId } from '../../Signals/DeviceIdSignal';
import Translator from '../../Utils/Translator';
import QRCode from 'react-qr-code';
import { ExclamationTriangledIcon } from '../../Icons/ExclamationTriangledIcon';
import { CopyButton } from '../Common/CopyButton';
import { DragDrop, Drop } from '../Common/DragAndDrop/SimpleDragAndDrop';
import { VideoDataInterface } from '../../Interfaces/VideoDataInterface';

export function KaraokePlayer() {
    const [remoteModalIsVisible, setRemoteModalIsVisible] = useState<boolean>(true);

    const onReady = (e: YouTubePlayer) => {
        initPlayerProcess(e);
    }

    const opts = {
        height: "100%",
        width: "100%",
    };

    const playingVideoIndex = getPlayingVideoIndex();

    const onDrop = (result: any) => {
        reorderItems(result.source.index, result.destination.index);
    }

    return <>
        {remoteModalIsVisible && <RemoteModal closeModals={() =>setRemoteModalIsVisible(false)}/>}
        <div className={`h-[calc(100vh-70px)]`}>
            <div id={`youtube-player2`} className={`bg-black h-[80%] w-full`}>
                <YouTube
                    onReady={onReady}
                    onPlay={playProcess}
                    onPause={pauseProcess}
                    onEnd={endVideoProcess}
                    opts={opts}
                />
            </div>
            <div
                className={`flex h-[50px] justify-between items-center p-[10px] bg-${theme.value}-primary-200`}>
                <div className={`flex gap-[10px] justify-center items-center`}>
                    <div onClick={changeReadTypeProcess}
                         className={`cursor-pointer flex height-[30px] items-center justify-center`}>
                        {playingMediaList.value.readType === 'no-repeat' &&
                            <RecIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                        {playingMediaList.value.readType === 'repeat-all' &&
                            <RepeatAllIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                        {playingMediaList.value.readType === 'repeat-one' &&
                            <RepeatOneIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                    </div>
                </div>
                <div className={`flex justify-center items-center`}>
                    <div className={`w-[30px]`}>
                        {isNextPreviousEnabled('previous') && <PreviousIcon onClick={previousVideoProcess}
                                                                            className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}/>}
                    </div>
                    {playingMediaList.value.status === 'pause' && <div>
                        <PlayIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}
                                  onClick={() => playProcess()}/>
                    </div>}
                    {playingMediaList.value.status === 'play' && <div onClick={pauseProcess}>
                        <PauseIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}/>
                    </div>}
                    <div className={`w-[30px]`}>
                        {isNextPreviousEnabled('next') &&
                            <NextIcon onClick={nextVideoProcess}
                                      className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}/>}
                    </div>
                </div>
                <InternetMobileIcon
                    className={`w-[30px] cursor-pointer ${!playingMediaList.value.isRemoteActivated ? 'fill-red-600' : 'fill-green-600'}`}
                    onClick={() => setRemoteModalIsVisible(true)}/>
            </div>
            <div>
                <DragDrop onDragEnd={onDrop}>
                    <Drop id={'playlist-modal'} className={`flex flex-wrap p-[10px] gap-[10px]`}>
                        {playingMediaList.value.items.map((videoData: VideoDataInterface, index: number) => <div
                            className={`rounded flex gap-[10px] p-[5px] cursor-pointer border w-full sm:w-[calc(calc(100%/2)-5px)] lg:w-[calc(calc(100%/3)-7px)] 2xl:w-[calc(calc(100%/4)-9px)] ${playingVideoIndex === index ? 'bg-gray-200' : ''}`}>
                            <div
                                className={`flex justify-center items-center max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] overflow-hidden`}>
                                <img onClick={() => playByVideoIndexProcess(index)} src={videoData.image.url}
                                     className={`${playingVideoIndex === index ? `rounded-full w-[80px] h-[80px] animate-[spin_5s_linear_infinite]` : 'rounded'}`}/>
                            </div>
                            <div onClick={() => playByVideoIndexProcess(index)} className={`w-full`}>
                                <div className={`line-clamp-2 font-bold`}>
                                    {videoData.title}
                                </div>
                                <div className={`line-clamp-2`}>
                                    {videoData.channelTitle}
                                </div>
                            </div>
                        </div>)}
                    </Drop>
                </DragDrop>
            </div>
        </div>
    </>;
}

interface RemoteModalInterface {
    closeModals: () => void;
}

function RemoteModal({closeModals}: RemoteModalInterface) {
    const remoteRefPath = `remote-${deviceId.value}`;

    useEffect(() => {
        setTimeout(() => {
            activateRemote(true);
            addRef(remoteRefPath, (action: any) => {
                switch (action.performedAction) {
                    case 'added':
                        NewApiManager.get(
                            YOUTUBE_REMOTE_ACTIONS(deviceId.value),
                            (response: any) => {
                                response.data.forEach((data: any) => executeRemoteActions(data))
                            }
                        );
                        break;
                }
            }, 0);
        }, 1000);
    }, []);

    const remoteUrl = `${process.env.REACT_APP_FRONT_URL}/youtube-remote/${deviceId.value}`;

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={closeModals}/>
        <div
            className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px] max-h-[calc(100vh-50px)] overflow-y-auto`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px]`}>
                <div className={`w-full sm:border-b-0 border-${theme.value}-primary-950 border-b-[1px] p-[10px]`}>
                    <div className={`justify-center flex cursor-pointer`}>
                        <QRCode value={remoteUrl} className={`w-[290px] h-[290px] ${!playingMediaList.value.isRemoteActivated && 'opacity-10'}`}/>
                        {!playingMediaList.value.isRemoteActivated && <div className={`w-[300px] h-[300px] flex gap-[10px] font-bold justify-center items-center absolute p-[10px]`}>
                            <ExclamationTriangledIcon className={`w-[50px] fill-red-500`}/> {Translator.translateId(`cta__disabled_youtube_player_message`)}
                        </div>}
                    </div>
                </div>
                <CopyButton label={Translator.translateId(`cta__copy_remote_page_link`)} value={remoteUrl}/>
            </div>
        </div>
    </>;
}
