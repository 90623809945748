import { connectionAlertStatus } from '../Signals/ConnectionAlertStatusSignal';
import { theme } from '../Signals/ThemeSignal';
import Translator from '../Utils/Translator';
import { WifiLostIcon } from '../Icons/WifiLostIcon';
import { WifiIcon } from '../Icons/WifiIcon';
import {
    canCloseVersionAlertShow,
    checkFrontVersion,
    maxReminderCounter,
    reminderCounter,
    versionAlertShow
} from '../Signals/VersionAlertStatusSignal';
import { RocketIcon } from '../Icons/RocketIcon';
import { PrimaryButton } from './Common/Button/PrimaryButton';

export default function VersionAlert() {
    if (!versionAlertShow.value) {
        return <></>;
    }

    const toggleVersionAlertShow = () => {
        checkFrontVersion();
    }

    return <>
        <div onClick={toggleVersionAlertShow} className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-important-modal`}/>
        <div className={`fixed z-important-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px] max-w-[350px]`}>
                <div className={`text-2xl font-bold flex items-center justify-center gap-[10px]`}>
                    <div>
                        <RocketIcon className={`w-[30px]`}/>
                    </div>
                    <div>
                        {Translator.translateId('label__spaceshare_updated')}
                    </div>
                </div>
                <div className={`mt-[10px]`}>
                    {Translator.translateId('label__spaceshare_updated_description')}
                </div>
                {canCloseVersionAlertShow() && <>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_updated_description2')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_updated_description3')}
                    </div>
                </>}
                {reminderCounter === maxReminderCounter && <div className={`mt-[10px] text-red-500 font-bold`}>
                    {Translator.translateId('label__spaceshare_updated_last_reminder')}
                </div>}
                {canCloseVersionAlertShow() && <div className={`mt-[10px]`}>
                    {Translator.translateId('label__spaceshare_updated_reminder', undefined, {
                        counter: reminderCounter,
                        max: maxReminderCounter,
                    })}
                </div>}
                <div className={`mt-[10px] flex gap-[10px] items-center justify-center`}>
                    <div className={`flex justify-center`}>
                        <a href={''}><PrimaryButton className={`text-center`}>{Translator.translateId('cta__refresh_now')}</PrimaryButton></a>
                    </div>
                    {canCloseVersionAlertShow() && <div className={`text-center`}>
                        <span className={`text-blue-500 hover:text-blue-700 font-bold cursor-pointer hover:underline`}
                              onClick={checkFrontVersion}>{Translator.translateId('label__remind_later')}</span>
                    </div>}
                </div>
            </div>
        </div>
    </>;
}
