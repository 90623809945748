import {theme} from "../../Signals/ThemeSignal";
import Translator from "../../Utils/Translator";
import {BoardRowInterface} from "../../Interfaces/BoardRowInterface";

interface CardCreateButtonInterface {
    toggle: (row?: BoardRowInterface) => void;
    row?: BoardRowInterface;
}

export function CardCreateButton({toggle, row = undefined}: CardCreateButtonInterface) {
    return <div
        className={`font-bold p-[10px] flex items-center justify-center cursor-pointer border-[1px] border-dashed border-${theme.value}-primary-950 rounded`}
        onClick={() => toggle(row)}>
        {Translator.translateId(`cta__create_board_card`)}
    </div>;
}