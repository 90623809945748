import { UserInterface } from '../../Interfaces/UserInterface';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { EditIcon } from '../../Icons/EditIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { NewApiManager } from '../../Utils/NewApiManager';
import { UPLOAD_USER_PROFILE_IMAGE, USER, USER_SELF } from '../../Constants/ApiUrls';
import { updateUserProfileImage, userAccount } from '../../Signals/UserAccountSignal';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';
import Translator from '../../Utils/Translator';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { PrimaryTitle } from '../Common/Title/PrimaryTitle';
import { PrimaryLink } from '../Common/Link/PrimaryLink';
import { UpdateUserModal } from './UpdateUserModal';
import { TextareaContent } from '../Common/Content/TextareaContent';
import { LockedIcon } from '../../Icons/LockedIcon';
import { UnlockedIcon } from '../../Icons/UnlockedIcon';
import { QrCodeIcon } from '../../Icons/QrCodeIcon';
import { UserQrCodeModal } from './UserQrCodeModal';
import { LinkIcon } from '../../Icons/LinkIcon';
import { useDropzone } from 'react-dropzone';

interface CardInterface {
    userId: string;
    className?: string;
}

export function CardSelf({userId, className = ''}: CardInterface) {
    const [user, setUser] = useState<undefined|UserInterface>();
    const navigate = useNavigate();
    const [userModalVisible, setUserModalVisible] = useState(false);
    const [userQrCodeModalVisible, setUserQrCodeModalVisible] = useState(false);

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = () => {
        NewApiManager.get(
            USER_SELF,
            (response: any) => {
                setUser(response.data);
                updateUserProfileImage(response.data.profileImage);
            },
            () => navigate('/')
        );
    };

    const onDrop = useCallback(async (acceptedFiles: any) => {
        const formData = new FormData();
        const [file] = acceptedFiles;
        formData.append("file", file);

        NewApiManager.postImage(
            UPLOAD_USER_PROFILE_IMAGE,
            formData,
            loadUser
        );
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    if (user === undefined) {
        return <div className={`${className} md:w-[300px] border rounded-xl bg-white p-[20px] flex justify-center`}>
            <SpinnerIcon/>
        </div>;
    }

    return <>
        {userModalVisible && <UpdateUserModal user={user} submitSuccessCallback={() => {setUserModalVisible(false); loadUser();}} toggleModal={() => setUserModalVisible(false)}/>}
        {userQrCodeModalVisible && <UserQrCodeModal user={user} toggleModal={() => setUserQrCodeModalVisible(false)}/>}
        <div className={`${className} md:w-[300px] border rounded-xl bg-white`}>
            <div className={`h-[100px] bg-blue-200 rounded-t-xl`}></div>
            <div className={`my-[-50px]`}>
                <div className={`rounded-full p-[5px] w-[130px] h-[130px] flex justify-center items-center bg-white ml-[20px] cursor-pointer`}>
                    <div {...getRootProps()}
                         className={`w-full h-full flex justify-center items-center bg-gray-300 rounded-full`}>
                        <input {...getInputProps()} />
                        {user.profileImage !== null ?
                            <img src={user.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                            <ProfileIcon className={`w-[50px] h-[50px] fill-black`}/>}
                    </div>
                </div>
            </div>
            <div className={`p-[20px]`}>
                <div className={`flex gap-[10px] justify-end`}>
                    <div>
                        <EditIcon onClick={() => setUserModalVisible(true)}
                                  className={`w-[30px] fill-gray-500 cursor-pointer`}/>
                    </div>
                    <div className={`border rounded`}>
                        <QrCodeIcon onClick={() => setUserQrCodeModalVisible(true)}
                                  className={`w-[30px] stroke-black fill-black cursor-pointer`}/>
                    </div>
                </div>
                <div>
                    <div className={`flex gap-[5px] items-center`}>
                        <div>
                            {user.isPrivate === 1 ? <LockedIcon className={`w-[20px]`}/> :
                                <UnlockedIcon className={`w-[20px]`}/>}
                        </div>
                        <PrimaryTitle label={user.login}/>
                    </div>
                    {user.referrerUserAccount !== null && <div className={`ml-[25px]`}>
                        <Link to={`/users/${user.referrerUserAccount.id}`} className={`flex gap-[5px]`}>
                            <LinkIcon className={`w-[20px]`}/> {user.referrerUserAccount.login}
                        </Link>
                    </div>}
                    {user.mood !== null && user.mood.length > 0 && <div className={`pt-[10px]`}>{user.mood}</div>}
                    {user.description !== null && user.description.length > 0 && <div className={`pt-[10px]`}><TextareaContent content={user.description}/></div>}
                </div>
                <div className={`pt-[10px]`}>
                    <PrimaryLink to={`/users/${userId}/connections`} label={Translator.translateId('label__followers_counter', undefined, {counter: 67})}/>
                </div>
                {userId !== userAccount.value?.id && <div className={`flex mt-[10px] gap-[10px]`}>
                    <PrimaryButton className={`flex items-center`}>{Translator.translateId('label__follow')}</PrimaryButton>
                    <PrimaryButton className={`flex items-center text-center`} withBackground={false}>{Translator.translateId('label__send_message')}</PrimaryButton>
                </div>}
                <div className={`mt-[10px] underline`}>
                    <Link to={`/users/${userId}/links`}>
                        {Translator.translateId('cta__user_links')}
                    </Link>
                </div>
            </div>
        </div>
    </>;
}
