import {useEffect, useState} from "react";
import AbstractApi from "../Api/AbstractApi";

interface ResultsInterface {
    [keyword: string]: {
        status: 'in_progress'|'done',
        results: any[]
    };
}

export function SearchHook(
    apiSearchFunction: (keyword: string, successCallback: (response: any) => void) => any = (keyword: string, successCallback: (response: any) => void = () => {}) => { return {isSuccess: false} }) {
    const [keyword, setKeyword] = useState('');
    const [keywordDebounce, setKeywordDebounce] = useState('');

    const [results, setResults] = useState<ResultsInterface>({})

    useEffect(() => {
        if (keywordDebounce !== keyword) {
            const timer = setTimeout(() => {
                setKeyword(keywordDebounce);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [keywordDebounce])

    useEffect(() => {
        if (keyword !== '') {
            search();
        }
    }, [keyword]);

    function search() {
        setResults({
            ...results,
            [keyword]: {
                status: 'in_progress',
                results: results[keyword]?.results,
            }
        });

        apiSearchFunction(
            keyword,
            (response: any) => {
                setResults({
                    ...results,
                    [keyword]: {
                        status: 'done',
                        results: response.data
                    }
                })
            }
        );
    }

    return {
        setKeywordDebounce,
        keywordDebounce,
        results: results[keyword],
    }
}