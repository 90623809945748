import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";
import {useEffect, useState} from "react";
import {
    globalSearch,
    GlobalSearchHook,
    globalSearchKeywordDebounce,
    nextGlobalSearchPage,
    resetGlobalSearch
} from "../Signals/GlobalSearchSignal";
import {SpinnerIcon} from "../Icons/SpinnerIcon";
import {VideoDataInterface} from "../Interfaces/VideoDataInterface";
import {YoutubeVideoCard} from "../Components/YoutubeVideo/YoutubeVideoCard";
import {isLoggedIn} from "../Signals/UserAccountSignal";
import {PlaylistInterface} from "../Interfaces/PlaylistInterface";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_CAA_CREATION_FORM_ERROR,
    LABEL_PLAYLIST_CARD_NAME
} from "../Constants/Label";
import {FormHook} from "../Hooks/FormHook";
import {Link} from "react-router-dom";
import {
    CREATE_PLAYLIST,
    CREATE_PLAYLIST_FORM_VALIDATOR,
    MOST_POPULAR_YOUTUBE_VIDEOS,
    SELF_PLAYLISTS, YOUTUBE_SEARCH_BY_KEYWORD
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function Youtube() {
    const {getResults, globalSearchEffect, hasNextPage, getNextPageToken, isLoading} = GlobalSearchHook();
    const [selfPlaylists, setSelfPlaylists] = useState<PlaylistInterface[]|undefined>(undefined);
    const [newPlaylistModalIsOpened, setNewPlaylistModalIsOpened] = useState(false);
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitError, submitLock, submitSuccess} = FormHook(['name']);
    const [mostPopularStats, setMostPopularStats] = useState<undefined|VideoDataInterface[]>(undefined);

    const getItems = (results: any) => {
        return results.items;
    };

    const searchOnYoutube = (keyword: string, pageToken: string, callback: (response: any) => void = () => {}) => {
        NewApiManager.get(
            YOUTUBE_SEARCH_BY_KEYWORD(keyword, pageToken),
            (response: any) => {
                callback(response)
            }
        );
    }

    globalSearchEffect(searchOnYoutube, getItems);

    useEffect(() => {
        resetGlobalSearch(true, Translator.translateId('label__search_on_youtube'));
        globalSearch('');
        NewApiManager.get(
            MOST_POPULAR_YOUTUBE_VIDEOS(20),
            (response: any) => setMostPopularStats(response.data)
        );
        loadConstrains(CREATE_PLAYLIST_FORM_VALIDATOR);
        if (isLoggedIn()) {
            loadSelfPlaylists();
        }
    }, []);

    const loadSelfPlaylists = () => {
        NewApiManager.get(
            SELF_PLAYLISTS,
            (response: any) => setSelfPlaylists(response.data)
        );
    }

    const toggleNewPlaylistModal = () => {
        setNewPlaylistModalIsOpened(() => !newPlaylistModalIsOpened);
    };

    const submitPlaylist = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            CREATE_PLAYLIST,
            {
                name: form.fields.name.value
            },
            () => {
                submitSuccess();
                setValues({
                    name: '',
                });
                loadSelfPlaylists();
                toggleNewPlaylistModal();
            },
            () => submitError(LABEL_CAA_CREATION_FORM_ERROR)
        );
    }

    return <PageTemplate sidebar={"normal"}>
        {newPlaylistModalIsOpened && <>
            <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
                 onClick={toggleNewPlaylistModal}/>
            <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
                <div className={`bg-${theme.value}-primary-50 rounded p-[10px]`}>
                    <form onSubmit={submitPlaylist}>
                        <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_PLAYLIST_CARD_NAME}/>
                        <button type={'submit'}
                                className={`mt-[10px] w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                    </form>
                </div>
            </div>
        </>}
        <div className={'p-[10px]'}>
            {/*<div className={`flex`}>*/}
            {/*    <Link to={'/youtube/settings'} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded bg-${theme.value}-primary-500`}>*/}
            {/*        {Translator.translateId('cta__youtube_settings')}*/}
            {/*    </Link>*/}
            {/*</div>*/}
            <div className={`text-2xl`}>
                {Translator.translateId(`label__playlists`)}
            </div>
            <div className={`flex gap-[10px] mt-[10px] flex-wrap`}>
                {!isLoggedIn() && Translator.translateId('label__should_log_in_for_playlists')}
                {isLoggedIn() && <div
                    className={`p-[10px] border-[1px] border-dashed border-${theme.value}-primary-950 rounded hover:bg-${theme.value}-primary-200 cursor-pointer`} onClick={toggleNewPlaylistModal}>{Translator.translateId('cta__new')}</div>}
                {isLoggedIn() && selfPlaylists !== undefined && selfPlaylists.map((playlist: PlaylistInterface, index: number) =>
                    <Link key={`playlist-${index}`} to={`/playlists/${playlist.id}`}>
                        <div className={`p-[10px] border-[1px] border-${theme.value}-primary-950 rounded hover:bg-${theme.value}-primary-200 cursor-pointer`}>
                            {playlist.name}
                        </div>
                    </Link>
                )}
            </div>
            <div className={`text-2xl mt-[10px]`}>
                {Translator.translateId(`label__most_popular_stats_youtube_videos`)}
            </div>
            <div className={`flex gap-[10px] mt-[10px] max-w-full overflow-x-auto`}>
                {mostPopularStats !== undefined && mostPopularStats.map((video: VideoDataInterface, index: number) =>
                    <YoutubeVideoCard key={`video-${index}`} videoData={video}
                                      className={`xl:min-w-[calc(100%/7-18px)] lg:min-w-[calc(100%/5-18px)] sm:min-w-[calc(100%/4-18px)] min-w-[40%] cursor-pointer`}/>)}
            </div>
            <div className={`text-2xl mt-[10px]`}>
                {globalSearchKeywordDebounce.value !== undefined && globalSearchKeywordDebounce.value !== '' && Translator.translateId('label__youtube_results_title', undefined, {keyword: globalSearchKeywordDebounce})}
                {(globalSearchKeywordDebounce.value === undefined || globalSearchKeywordDebounce.value === '') && Translator.translateId('label__youtube_popular_title')}
            </div>
            <div className={`mt-[10px] flex gap-[10px] flex-wrap`}>
                {getResults().filter((result: VideoDataInterface) => result?.videoId !== undefined).map((result: VideoDataInterface, index: number) =>
                    <YoutubeVideoCard key={`video-${index}`} videoData={result}
                                      className={`xl:w-[calc(100%/4-8px)] lg:w-[calc(100%/3-8px)] md:w-[calc(100%/3-8px)] w-[calc(100%/2-8px)] cursor-pointer`}/>)}
            </div>
            {isLoading() && <div className={`w-full flex justify-center p-[10px] mt-[10px]`}>
                <SpinnerIcon/>
            </div>}
            {!isLoading() && hasNextPage() && <div className={`flex justify-center mt-[10px]`}>
                <div
                    className={`border-${theme.value}-primary-950 border-[1px] rounded p-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}
                    onClick={() => nextGlobalSearchPage(getNextPageToken())}>
                    {Translator.translateId('cta__load_more')}
                </div>
            </div>}
        </div>
    </PageTemplate>;
}
