import PageTemplate from '../Components/PageTemplate/PageTemplate';
import { geolocation, initGeolocation } from '../Signals/Geolocation';
import { useEffect, useState } from 'react';
import { GeolocationInterface } from '../Interfaces/GeolocationInterface';
import Translator from '../Utils/Translator';
import { NewApiManager } from '../Utils/NewApiManager';
import { WEATHER_CURRENT } from '../Constants/ApiUrls';
import { DetailsInterface, ForecastDayInterface, WeatherInterface } from './WeatherInterface';
import { LocationIcon } from '../Icons/LocationIcon';
import { getCurrentWeather } from '../Utils/Weather';
import { LocationInterface } from '../Interfaces/LocationInterface';
import TogglerHook from '../Hooks/Toggler/TogglerHook';
import { theme } from '../Signals/ThemeSignal';
import { TriangleDownIcon } from '../Icons/TrianglDownIcon';
import { TriangleUpIcon } from '../Icons/TriangleUpIcon';
import cities from 'cities.json';
import { MagnifyingGlassIcon } from '../Icons/MagnifyingGlassIcon';
import { isSameDate, isSameDateHour, serverDateTimeToCustom } from '../Utils/Date';
import { DateTimeLabel } from '../Components/Common/Date/DateTimeLabel';
import { WaterIcon } from '../Icons/WaterIcon';
import { WindIcon } from '../Icons/WindIcon';
import { useSignals } from '@preact/signals-react/runtime';
import moment from 'moment';
import { SpinnerIcon } from '../Icons/SpinnerIcon';

let weatherRefreshTimeout:any = undefined;
let lastSyncedDate: any = undefined;

export function Weather() {
    useSignals();

    initGeolocation();

    const [selectedGeolocation, setSelectedGeolocation] = useState<GeolocationInterface|undefined>(undefined)
    const [oldSelectedGeolocation, setOldSelectedGeolocation] = useState<GeolocationInterface|undefined>(undefined)
    const [isGeolocation, setIsGeolocation] = useState(false);
    const [data, setData] = useState<undefined|WeatherInterface>();
    const [degreeType, setDegreeType] = useState<'c'|'f'>('c');
    const [windType, setWindType] = useState<'kph'|'mph'>('kph');
    const [locations, setLocations] = useState<LocationInterface[]>([
        {
            name: Translator.translateId('label__my_position'),
            positions: geolocation.value,
            isGeolocation: true,
        }
    ]);

    // set to default "geolocation.value" here because of geolocation prompt validation
    if (oldSelectedGeolocation !== geolocation.value) {
        setSelectedGeolocation(geolocation.value);
        setIsGeolocation(true);
        setOldSelectedGeolocation(geolocation.value);
    }

    const updateLocation = (positions: GeolocationInterface|undefined, isGeoloc: boolean = false) => {
        setSelectedGeolocation(positions ?? geolocation.value);
        setIsGeolocation(isGeoloc);
    }

    const loadWeather = () => {
        if (selectedGeolocation === undefined) {
            return;
        }

        NewApiManager.get(
            WEATHER_CURRENT(`${selectedGeolocation.latitude},${selectedGeolocation.longitude}`),
            (response: any) => {
                setData(response.data);

                if (!isGeolocation) {
                    const existingLocation = locations.filter((location: LocationInterface) => location.positions?.latitude === selectedGeolocation.latitude && location.positions.longitude === selectedGeolocation.longitude);
                    if (existingLocation.length === 0) {
                        setLocations([
                            ...locations,
                            {
                                positions: {
                                    latitude: selectedGeolocation.latitude,
                                    longitude: selectedGeolocation.longitude,
                                },
                                name: response.data.location.name,
                                isGeolocation: false
                            },
                        ])
                    }
                }

                lastSyncedDate = moment();
                syncWeatherIfNotFresh();
            }
        );
    }

    const syncWeatherIfNotFresh = () => {
        clearTimeout(weatherRefreshTimeout);
        weatherRefreshTimeout = setTimeout(() => {
            if (moment().diff(moment(lastSyncedDate), 'minutes') > 15) {
                loadWeather();
            }
            syncWeatherIfNotFresh();
        }, 1000);
    }

    useEffect(() => {
        if (selectedGeolocation === undefined) {
            return;
        }

        loadWeather();
    }, [selectedGeolocation]);

    const currentWeather = data !== undefined ? getCurrentWeather(data) : undefined;

    const degreeLabel = (conditions: DetailsInterface|undefined) => {
        if (degreeType === 'f') {
            return `${(conditions?.temp_f ?? '_ _')}°F`
        }

        return `${(conditions?.temp_c ?? '_ _')}°C`;
    }

    const degreeMinLabel = (conditions: DetailsInterface|undefined) => {
        if (degreeType === 'f') {
            return `${(conditions?.mintemp_f ?? '_ _')}°F`
        }

        return `${(conditions?.mintemp_c ?? '_ _')}°C`;
    }

    const degreeMaxLabel = (conditions: DetailsInterface|undefined) => {
        if (degreeType === 'f') {
            return `${(conditions?.maxtemp_f ?? '_ _')}°F`
        }

        return `${(conditions?.maxtemp_c ?? '_ _')}°C`;
    }

    const windMaxLabel = (conditions: DetailsInterface|undefined) => {
        if (windType === 'mph') {
            return `${(conditions?.maxwind_mph ?? '_ _')}m/h`
        }

        return `${(conditions?.maxwind_kph ?? '_ _')}km/h`;
    }

    const windLabel = (conditions: DetailsInterface|undefined) => {
        if (windType === 'mph') {
            return `${(conditions?.wind_mph ?? '_ _')}m/h`
        }

        return `${(conditions?.wind_kph ?? '_ _')}km/h`;
    }

    return <PageTemplate sidebar={'normal'}>
        <div className={`p-[10px]`}>
            <div className={`flex gap-[10px] justify-center lg:justify-start p-[20px]`}>
                <div className={`flex items-center gap-[10px]`}>
                    <div className={`w-[80px] h-[80px]`}>
                        {currentWeather?.condition.icon &&
                            <img src={currentWeather?.condition.icon} className={`min-w-[80px] min-h-[80px]`}/>}
                    </div>
                    <div>
                        {isGeolocation ? <div>
                            <div className={`text-[25px] font-bold`}>
                                {Translator.translateId('label__my_position')}
                            </div>
                            <LocationSelector locations={locations} updateLocation={updateLocation} data={data} className={`text-[20px]`}/>
                        </div> : <div>
                            <LocationSelector locations={locations} updateLocation={updateLocation} data={data} className={`text-[30px] font-bold`}/>
                        </div>}
                    </div>
                </div>
                <div className={`flex gap-[10px] border-l pl-[10px] border-${theme.value}-primary-950`}>
                    <div>
                        <div className={`md:flex items-center gap-[10px]`}>
                            <div className={`text-center text-[30px]`}>
                                {degreeLabel(currentWeather)}
                            </div>
                            <div>
                                <div title={currentWeather?.condition.text}
                                     className={`text-[20px] text-center md:text-left`}>{currentWeather?.condition.code ? Translator.translateId(`label__weather_text_${currentWeather?.condition.code}`) : '_______'}</div>
                            </div>
                        </div>
                        <div className={`md:flex justify-center gap-[10px]`}>
                            <div className={`text-[20px] flex items-center justify-center`}>
                                <TriangleDownIcon className={`w-[20px]`}/> {degreeMinLabel(data?.forecast.forecastday[0].day)}
                            </div>
                            <div className={`text-[20px] flex items-center justify-center`}>
                                <TriangleUpIcon className={`w-[20px]`}/> {degreeMaxLabel(data?.forecast.forecastday[0].day)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data === undefined && <div className={`mt-[10px] flex justify-center`}><SpinnerIcon/></div>}
            <div className={`mt-[10px] md:flex gap-[10px] flex-wrap`}>
                {data?.forecast.forecastday.map((day: ForecastDayInterface, index: number) => <div key={`day-${index}`}
                                                                                                   className={`rounded md:w-[calc(100%/2-8px)] md:min-w-[calc(100%/2-8px)] w-full border border-${theme.value}-primary-950 mb-[10px] md:m-0`}>
                    <div className={`h-[200px] flex items-center justify-center bg-blue-100`}>
                        <div>
                            <div className={`p-[10px] text-center text-[20px]`}>
                                <div>{isSameDate({date: day.date}, {date: serverDateTimeToCustom(data?.location.tz_id)}) && Translator.translateId('label__today')}</div>
                                <div className={`font-bold`}><DateTimeLabel datetime={day.date} showTime={false}/></div>
                            </div>
                            <div className={`flex gap-[10px] justify-center items-center`}>
                                <div>
                                    <img src={day.day.condition.icon}/>
                                </div>
                                <div>
                                    <div className={`flex justify-center gap-[10px] text-[20px]`}>
                                        {degreeMinLabel(day.day)} - {degreeMaxLabel(day.day)}
                                    </div>
                                    <div className={`flex gap-[10px]`}>
                                        <div className={`flex gap-[5px]`}>
                                            <WaterIcon className={`w-[20px]`}/> {day.day.avghumidity}
                                        </div>
                                        <div className={`flex gap-[5px]`}>
                                            <WindIcon className={`w-[20px]`}/> {windMaxLabel(day.day)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div title={day.day.condition.text}
                                 className={`p-[10px] text-[20px] text-center gap-[10px]`}>{day.day.condition.code ? Translator.translateId(`label__weather_text_${day.day.condition.code}`) : '_______'}</div>
                        </div>
                    </div>
                    {day.hour.map((hour: DetailsInterface, index: number) => <div key={`hour-${index}`}
                                                                                  className={`h-[110px] flex gap-[20px] items-center justify-between p-[10px] border-t border-${theme.value}-primary-950 ${isSameDateHour({date: serverDateTimeToCustom(data?.location.tz_id)}, {date: hour.time ?? ''}) ? `bg-${theme.value}-primary-300` : 'bg-white'}`}>
                        <div className={`text-[25px]`}>
                            {('0' + index).slice(-2)}:00
                        </div>
                        <div className={`w-[70px] text-center font-bold`}>
                            <img src={hour.condition.icon} className={`min-w-[70px]`}/>
                            {hour.chance_of_rain !== undefined && hour.chance_of_rain > 0 && `${hour.chance_of_rain}%`}
                            {hour.chance_of_snow !== undefined && hour.chance_of_snow > 0 && `${hour.chance_of_rain}%`}
                        </div>
                        <div title={hour.condition.text}
                             className={`text-[18px] w-full text-center`}>{hour.condition.code ? Translator.translateId(`label__weather_text_${hour.condition.code}`) : '_______'}</div>
                        <div className={`w-full text-[20px] flex justify-center`}>
                            <div>
                                <div>
                                    {degreeLabel(hour)}
                                </div>
                                <div className={`flex gap-[5px]`}>
                                    <div className={`flex justify-center`}>
                                        <WaterIcon className={`w-[20px]`}/>
                                    </div>
                                    {hour.humidity}
                                </div>
                                <div className={`flex gap-[5px]`}>
                                    <div className={`flex justify-center`}>
                                        <WindIcon className={`w-[20px]`}/>
                                    </div>
                                    {windLabel(hour)}
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>)}
            </div>
        </div>
    </PageTemplate>
}

interface LocationSelectorInterface {
    locations: LocationInterface[];
    updateLocation: (value: GeolocationInterface | undefined, isGeoloc: boolean) => void;
    className?: string;
    data?: WeatherInterface;
}

const preformattedCityNames: any = {
    'Paris (FR)': 'Paris (Île-de-France)'
}


function LocationSelector({locations, className = '', data, updateLocation}: LocationSelectorInterface) {
    const {isVisible, toggleVisibility, ref} = TogglerHook();

    const [keyword, setKeyword] = useState<string>('');

    const formatCityName = (city: any) => {
        const cityName = `${city.name} (${city.country})`;

        return preformattedCityNames[cityName] ?? cityName;
    };

    // @ts-ignore
    const suggestedLocations = keyword !== undefined && keyword.length >= 3 ? cities.filter((city: any) => formatCityName(city).toUpperCase().includes(keyword.toUpperCase())) : [];

    return <div className={``}>
        <div ref={ref}>
            <div onClick={toggleVisibility}
                className={`flex justify-between items-center gap-[10px] border rounded border-${theme.value}-primary-950`}>
                <div className={`p-[5px] ${className}`}>
                    {data?.location.name.toUpperCase() ?? '_ _ _ _'}
                </div>
                <div className={`border-l border-${theme.value}-primary-950 p-[5px]`}>
                    {!isVisible && <TriangleDownIcon className={`w-[15px]`}/>}
                    {isVisible && <TriangleUpIcon className={`w-[15px]`}/>}
                </div>
            </div>
            {isVisible && <div className={`bg-${theme.value}-primary-50 absolute border border-${theme.value}-primary-950 mt-[5px] rounded`}>
                <div className={`p-[5px] font-bold`}>
                    {Translator.translateId(`label__select_location`)}
                </div>
                <div className={`p-[5px] border-y border-${theme.value}-primary-950`}>
                    <div className={`text-left`}>
                        <div className={`flex gap-[10px]`}>
                            <MagnifyingGlassIcon className={`w-[20px] fill-${theme.value}-primary-500`}/>
                            <input className={`w-full`}
                                   value={keyword}
                                   placeholder={Translator.translateId('label__weather_location_keyword')}
                                   onChange={(e) => setKeyword(e.target.value)}/>
                        </div>
                    </div>
                </div>
                {suggestedLocations.length > 0 && keyword.length >= 3 && <div className={`p-[5px] max-h-[300px] overflow-y-auto overflow-x-hidden`}>
                    {suggestedLocations.map((location: any, index: number) => <div key={`location-${index}`}>
                        <div className={`p-[5px] ${index > 0 && 'mt-[5px]'} cursor-pointer rounded bg-${theme.value}-primary-200`} onClick={() => { updateLocation({latitude: location.lat, longitude: location.lng}, false); toggleVisibility()}}>
                            {formatCityName(location)}
                        </div>
                    </div>)}
                </div>}
                {suggestedLocations.length === 0 && keyword.length >= 3 && <div className={`p-[5px]`}>
                    {Translator.translateId('label__no_results')}
                </div>}
                {keyword.length < 3 && <div className={`p-[5px]`}>
                    {locations.map((location: LocationInterface, index: number) => <div key={`location-${index}`} onClick={() => { updateLocation(location.positions, location.isGeolocation); toggleVisibility() }} className={`cursor-pointer p-[5px] ${index > 0 && 'mt-[5px]'} cursor-pointer rounded bg-${theme.value}-primary-200 flex gap-[5px] items-center ${location.isGeolocation && 'font-bold'}`}>
                        {location.isGeolocation && <LocationIcon className={`w-[20px]`}/>} {location.name}
                    </div>)}
                </div>}
            </div>}
        </div>
    </div>
}
