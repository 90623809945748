import {Link} from "react-router-dom";
import {theme} from "../../Signals/ThemeSignal";
import ToggleHook from "../../Hooks/Toggler/TogglerHook";
import Translator from "../../Utils/Translator";
import {locale, setLocale} from "../../Signals/LocaleSignal";
import { isLoggedIn, isUserGroup, logOut, userAccount } from "../../Signals/UserAccountSignal";
import {MagnifyingGlassIcon} from "../../Icons/MagnifyingGlassIcon";
import {
    globalSearch,
    globalSearchPlaceholder,
    showGlobalSearch,
    showSmallDeviceGlobalSearch
} from "../../Signals/GlobalSearchSignal";
import {CrossRoundedIcon} from "../../Icons/CrossRoundedIcon";
import {useState} from "react";
import {HeaderLinks} from "../Sidebar/Sidebar";
import {ProfileHeaderLinks} from "../Sidebar/ProfileSidebar";
import {BackOfficeHeaderLinks} from "../Sidebar/BackOfficeSidebar";
import {playingMediaList} from "../../Signals/PlayingMediaListSignal";
import logo from '../../Images/logo.png';
import { DotsIcon } from '../../Icons/DotsIcon';
import { HomeIcon } from '../../Icons/HomeIcon';
import { MonitorYoutubeIcon } from '../../Icons/MonitorYoutubeIcon';
import { DealIcon } from '../../Icons/DealIcon';
import { ActivityIcon } from '../../Icons/ActivityIcon';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { CloudIcon } from '../../Icons/CloudIcon';
import { NewsIcon } from '../../Icons/NewsIcon';
import { QrCodeIcon } from '../../Icons/QrCodeIcon';
import { ContactUsModal } from '../ContactUs/ContactUsModal';

interface HeaderInterface {
    sidebar?: 'normal'|'profile'|'back-office'|undefined;
}

export default function Header({sidebar}: HeaderInterface) {
    const {toggleVisibility: toggleSettings, isVisible: isSettingsVisible, ref: settingsRef} = ToggleHook();
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const [isContactUsModalOpened, setIsContactUsModalOpened] = useState(false);

    const toggleMobileMenu = () => setIsMobileMenuOpened(() => !isMobileMenuOpened);

    return <div className={`z-header border-b fixed w-full bg-${theme.value}-primary-50`}>
        {isMobileMenuOpened && <>
            <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`} onClick={toggleMobileMenu}/>
            <div className={`fixed z-modal-content top-0 left-0 bg-${theme.value}-primary-50 h-[100vh] max-h-[100vh] w-[80%]`}>
                {sidebar === 'normal' && <HeaderLinks toggleMobileMenu={toggleMobileMenu}/>}
                {sidebar === 'profile' && <ProfileHeaderLinks toggleMobileMenu={toggleMobileMenu}/>}
                {sidebar === 'back-office' && <BackOfficeHeaderLinks toggleMobileMenu={toggleMobileMenu}/>}
            </div>
        </>}
        {isContactUsModalOpened && <ContactUsModal toggleModal={() => setIsContactUsModalOpened(false)}/>}
        <div>
            <div className={`max-w-[1400px] m-auto flex items-center justify-between h-[70px]
                min-[1720px]:m-0 min-[1720px]:max-w-[calc(100%-300px)]
                min-[2000px]:m-auto min-[2000px]:max-w-[1400px]
                ${playingMediaList.value.items.length === 0 && 'min-[1720px]:m-auto'}
            `}>
                <div className={"text-[30px] p-[10px] flex gap-[10px]"}>
                    <Link to={'/'} className={`p-[10px] bg-blue-500 rounded-xl w-[50px]`}>
                        <img src={logo} className={`w-[50px]`}/>
                    </Link>
                </div>
                <div className={"w-full p-[10px] h-full items-center justify-center hidden sm:flex"}>
                    {showGlobalSearch.value && <div
                        className={`border-${theme.value}-primary-950 border-[1px] h-[calc(100%-10px)] w-full max-w-[500px] flex rounded`}>
                        <input type={'text'} className={`w-full m-[5px] bg-${theme.value}-primary-50`}
                               placeholder={globalSearchPlaceholder.value}
                               onChange={(e: any) => globalSearch(e.target.value)}/>
                        <button
                            className={`border-l-[1px] border-${theme.value}-primary-950 bg-${theme.value}-primary-50 px-[10px] rounded fill-${theme.value}-primary-950`}>
                            <MagnifyingGlassIcon className={`w-[20px] h-[20px]`}/>
                        </button>
                    </div>}
                </div>
                <div className={"flex items-center gap-[10px] p-[10px] h-full"}>
                    {showGlobalSearch.value && !showSmallDeviceGlobalSearch.value && <div onClick={() => showSmallDeviceGlobalSearch.value = true}
                        className={`p-[10px] cursor-pointer sm:hidden  fill-${theme.value}-primary-950`}>
                        <MagnifyingGlassIcon className={`fill-gray-700 hover:fill-gray-900 w-[30px] h-[30px]`}/>
                    </div>}
                    <div ref={settingsRef} className={'h-full flex items-center gap-[30px]'}>
                        <div className={`items-center flex gap-[20px] w-full`}>
                            <Link to={'/'}>
                                <HomeIcon className={`w-[30px] fill-gray-700 hover:fill-gray-900`}/>
                            </Link>
                            <Link to={'/youtube'}>
                                <MonitorYoutubeIcon className={`w-[30px] fill-gray-700 hover:fill-gray-900`}/>
                            </Link>
                            <Link to={'/activities'}>
                                <ActivityIcon className={`w-[30px] fill-gray-700 hover:fill-gray-900`}/>
                            </Link>
                        </div>
                        {isLoggedIn() && userAccount.value !== undefined && <Link to={`/users/self`}>
                            <div className={`border bg-gray-300 rounded-full w-[50px] h-[50px] flex justify-center items-center`}>
                                {userAccount.value.profileImage !== null ? <img src={userAccount.value.profileImage.url} className={`w-full h-full rounded-full`}/> : <ProfileIcon className={`w-[30px]`}/>}
                            </div>
                        </Link>}
                        <div className={`cursor-pointer`} onClick={toggleSettings}>
                            <DotsIcon className={`w-[30px] fill-gray-700 hover:fill-gray-900`}/>
                        </div>
                        {isSettingsVisible && <div
                            className={`fixed top-[75px] md:right-auto md:float-right w-[200px] ${isLoggedIn() ? 'ml-[80px]' : ''} border rounded bg-white`}>
                            <Link to={'/coupons'}>
                                <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex items-center gap-[10px]`}>
                                    <DealIcon className={`w-[20px]`}/>
                                    {Translator.translateId('cta__deals')}
                                </div>
                            </Link>
                            <Link to={'/news'}>
                                <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex items-center gap-[10px]`}>
                                    <NewsIcon className={`w-[20px]`}/>
                                    {Translator.translateId('cta__news')}
                                </div>
                            </Link>
                            <Link to={'/weather'}>
                                <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex items-center gap-[10px]`}>
                                    <CloudIcon className={`w-[20px]`}/>
                                    {Translator.translateId('cta__weathers')}
                                </div>
                            </Link>
                            <div className={`border-t`}></div>
                            {!isLoggedIn() && <Link to={"/register"}>
                                <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                                    {Translator.translateId('cta__register')}
                                </div>
                            </Link>}
                            {!isLoggedIn() && <div className={`rounded flex justify-between items-center`}>
                                <Link to={"/log-in"} className={`hover:bg-${theme.value}-primary-200 p-[10px] w-full`}>
                                    {Translator.translateId('cta__log_in')}
                                </Link>
                                <Link to={"/log-in?auto-log-in=1"} className={`hover:bg-${theme.value}-primary-200 p-[10px]`}>
                                    <div className={`border rounded`}>
                                        <QrCodeIcon className={`w-[21px] stroke-black`}/>
                                    </div>
                                </Link>
                            </div>}
                            {isLoggedIn() && userAccount.value !== undefined && <>
                                <div className={`p-[10px] hover:bg-gray-200 rounded cursor-pointer`}
                                     onClick={logOut}>
                                    {Translator.translateId('cta__log_out')}
                                </div>
                            </>}
                            <div className={`border-t`}></div>
                            <div>
                                {locale.value === 'fr' && <div
                                    className={`p-[10px] cursor-pointer w-full justify-center hover:bg-gray-200 rounded`}
                                    onClick={() => setLocale('en')}>
                                    {Translator.translateId('cta_switch_to_locale_en')}
                                </div>}
                                {locale.value === 'en' && <div
                                    className={`p-[10px] cursor-pointer w-full justify-center hover:bg-gray-200 rounded`}
                                    onClick={() => setLocale('fr')}>
                                    {Translator.translateId('cta_switch_to_locale_fr')}
                                </div>}
                                <Link to={`/about-us`}>
                                    <div
                                        className={`p-[10px] hover:bg-gray-200`}>{Translator.translateId('cta__about_us')}</div>
                                </Link>
                                <div className={`cursor-pointer`} onClick={() => setIsContactUsModalOpened(true)}>
                                    <div
                                        className={`p-[10px] hover:bg-gray-200`}>{Translator.translateId('cta__contact_us')}</div>
                                </div>
                            </div>
                            {isLoggedIn() && isUserGroup('admin') && <>
                                <div className={`border-t`}></div>
                                <Link to={`/back-office`}>
                                    <div
                                        className={`p-[10px] hover:bg-gray-200`}>{Translator.translateId('cta__back_office')}</div>
                                </Link>
                            </>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {showGlobalSearch.value && showSmallDeviceGlobalSearch.value && <div className={`p-[10px]`}>
            <div
                className={`border-${theme.value}-primary-950 border-[1px] h-[calc(100%-10px)] w-full flex rounded`}>
                <input type={'text'} className={`w-full m-[5px] bg-${theme.value}-primary-50`}
                       placeholder={globalSearchPlaceholder.value}
                       onChange={(e: any) => globalSearch(e.target.value)}/>
                <button onClick={() => showSmallDeviceGlobalSearch.value = false}
                    className={`border-l-[1px] border-${theme.value}-primary-950 bg-${theme.value}-primary-50 px-[10px] rounded fill-${theme.value}-primary-950`}>
                    <CrossRoundedIcon className={`w-[20px] h-[20px]`}/>
                </button>
            </div>
        </div>}
    </div>;
}
