import {signal} from "@preact/signals-react";
import {LocalStorage} from "../Utils/LocalStorage";

const LSItem = 'locale';
export type LOCALE_TYPES = 'fr'|'en'
export const locale = signal(LocalStorage.get(LSItem) ?? 'fr');
export function setLocale(newLocale: LOCALE_TYPES) {
    locale.value = newLocale;
    LocalStorage.set(LSItem, newLocale);
}
