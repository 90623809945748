import { Link, useNavigate, useParams, } from "react-router-dom";
import { useEffect, useState } from "react";
import { resetGlobalSearch } from '../Signals/GlobalSearchSignal';
import PageTemplate from '../Components/PageTemplate/PageTemplate';
import Translator from '../Utils/Translator';
import { userAccount } from '../Signals/UserAccountSignal';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { CreateShortcutLinkModal } from '../Components/Links/CreateShortcutLinkModal';
import { NewApiManager } from '../Utils/NewApiManager';
import { BACK_OFFICE_DELETE_LINK, REFERRAL_LINKS, SHORTCUT_LINKS } from '../Constants/ApiUrls';
import { LinkInterface } from '../Interfaces/LinkInterface';
import { LinkIcon } from '../Icons/LinkIcon';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import { UpdateShortcutLinkModal } from '../Components/Links/UpdateShortcutLinkModal';
import { EditIcon } from '../Icons/EditIcon';
import { VisibleIcon } from '../Icons/Visible';
import { InvisibleIcon } from '../Icons/InvisibleIcon';
import { CreateReferralLinkModal } from '../Components/Links/CreateReferralLinkModal';
import { UpdateReferralLinkModal } from '../Components/Links/UpdateReferralLinkModal';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { CopyButton } from '../Components/Common/CopyButton';
import { TrashIcon } from '../Icons/TrashIcon';
import { DeleteConfirmationModal } from '../Components/DeleteConfirmationModal/DeleteConfirmationModal';

export function Links() {
    const navigate = useNavigate();
    const {userId} = useParams();
    const [createShortcutLinkModalOpened, setCreateShortcutLinkModalOpened] = useState(false);
    const [updateShortcutLinkModal, setUpdateShortcutLinkModal] = useState<LinkInterface|undefined>(undefined);
    const [createReferralLinkModalOpened, setCreateReferralLinkModalOpened] = useState(false);
    const [updateReferralLinkModal, setUpdateReferralLinkModal] = useState<LinkInterface|undefined>(undefined);
    const [shortcutLinks, setShortcutLinks] = useState<LinkInterface[]|undefined>(undefined);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [referralLinks, setReferralLinks] = useState<LinkInterface[]|undefined>(undefined);
    const [linkToDelete, setLinkToDelete] = useState<LinkInterface|undefined>(undefined);

    useEffect(() => {
        resetGlobalSearch();
        loadShortcutLinks();
        loadReferralLinks();
    }, []);

    const loadShortcutLinks = () => {
        NewApiManager.get(
            SHORTCUT_LINKS,
            (response: any) => setShortcutLinks(response.data)
        );
    };

    const loadReferralLinks = () => {
        NewApiManager.get(
            REFERRAL_LINKS,
            (response: any) => setReferralLinks(response.data)
        );
    };

    const toggleCreateShortcutLinkModal = () => {
        setCreateShortcutLinkModalOpened(() => !createShortcutLinkModalOpened);
    }

    const toggleCreateReferralLinkModal = () => {
        setCreateReferralLinkModalOpened(() => !createReferralLinkModalOpened);
    }

    const toggleUpdateShortcutLinkModal = () => {
        setUpdateShortcutLinkModal(undefined);
    }

    const toggleUpdateReferralLinkModal = () => {
        setUpdateReferralLinkModal(undefined);
    }

    const toggleDeletionModal = (link: LinkInterface|undefined = undefined) => {
        setLinkToDelete(link);
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const isSelfUser = userId === userAccount.value?.id;

    const deleteLink = () => {
        if (linkToDelete === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_LINK(linkToDelete.id),
            () => {
                switch (linkToDelete.linkType) {
                    case 'referral':
                        loadReferralLinks();
                        break;
                    case 'shortcut':
                        loadShortcutLinks();
                        break;
                }
                toggleDeletionModal();
            }
        );
    }

    return <PageTemplate sidebar={"normal"}>
        <DeleteConfirmationModal message={Translator.translateId('label__note_delete_confirmation_message')} deleteAction={deleteLink} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        {createShortcutLinkModalOpened && <CreateShortcutLinkModal loadLinks={loadShortcutLinks} toggle={toggleCreateShortcutLinkModal}/>}
        {createReferralLinkModalOpened && <CreateReferralLinkModal loadLinks={loadReferralLinks} toggle={toggleCreateReferralLinkModal}/>}
        {updateShortcutLinkModal !== undefined && <UpdateShortcutLinkModal link={updateShortcutLinkModal} loadLinks={loadShortcutLinks} toggle={toggleUpdateShortcutLinkModal}/>}
        {updateReferralLinkModal !== undefined && <UpdateReferralLinkModal link={updateReferralLinkModal} loadLinks={loadReferralLinks} toggle={toggleUpdateReferralLinkModal}/>}
        <div className={`p-[10px]`}>
            <div className={`bg-white p-[10px] rounded-xl`}>
                <div className={`text-2xl flex items-center gap-[10px]`}>
                    {Translator.translateId('label__shortcut_links')} {isSelfUser &&
                    <PrimaryButton
                        onClick={toggleCreateShortcutLinkModal}>{Translator.translateId('cta__new')}</PrimaryButton>}
                </div>
                <div className={`italic`}>
                    {Translator.translateId('label__description_shortcut_links')}
                </div>
                {shortcutLinks === undefined && <SpinnerIcon/>}
                {shortcutLinks?.map((link: LinkInterface) => <div
                    className={`p-[10px] flex gap-[10px] items-center ${!link.isVisible ? 'opacity-35 hover:opacity-100' : ''}`}
                    key={`link-${link.id}`}>
                    <div>{link.isVisible ? <VisibleIcon className={`w-[20px]`}/> :
                        <InvisibleIcon className={`w-[20px]`}/>}</div>
                    <div><Link to={link.url} target={`_blank`}><LinkIcon className={`w-[20px]`}/></Link></div>
                    <div className={`cursor-pointer`} onClick={() => setUpdateShortcutLinkModal(link)}><EditIcon
                        className={`w-[20px]`}/></div>
                    <div className={`cursor-pointer`}
                            onClick={() => toggleDeletionModal(link)}><TrashIcon className={`w-[20px]`}/></div>
                    <div>{link.name}</div>
                    <PrimaryLink to={link.url} label={link.url} target={'_blank'}/>
                </div>)}
                {shortcutLinks?.length === 0 && <div className={`p-[10px] flex justify-center`}>
                    {Translator.translateId('label__no_referral_links')}
                </div>}
            </div>
            <div className={`bg-white p-[10px] rounded-xl mt-[10px]`}>
                <div className={`text-2xl mt-[10px] flex items-center gap-[10px]`}>
                    {Translator.translateId('label__referral_links')} {isSelfUser &&
                    <PrimaryButton
                        onClick={toggleCreateReferralLinkModal}>{Translator.translateId('cta__new')}</PrimaryButton>}
                </div>
                <div className={`italic`}>
                    {Translator.translateId('label__description_referral_links')}
                </div>
                {referralLinks === undefined && <SpinnerIcon/>}
                {referralLinks?.map((link: LinkInterface) => <div
                    className={`p-[10px] flex gap-[10px] items-center ${!link.isVisible ? 'opacity-35 hover:opacity-100' : ''}`}
                    key={`link-${link.id}`}>
                    <div>{link.isVisible ? <VisibleIcon className={`w-[20px]`}/> :
                        <InvisibleIcon className={`w-[20px]`}/>}</div>
                    <div><Link to={link.url} target={`_blank`}><LinkIcon className={`w-[20px]`}/></Link></div>
                    <div className={`cursor-pointer`} onClick={() => setUpdateReferralLinkModal(link)}><EditIcon
                        className={`w-[20px]`}/></div>
                    <div className={`cursor-pointer`}
                         onClick={() => toggleDeletionModal(link)}><TrashIcon className={`w-[20px]`}/></div>
                    {link.referralCode !== null && <CopyButton value={link.referralCode} label={link.referralCode}/>}
                    <div>{link.name}</div>
                    <PrimaryLink to={link.url} label={link.url} target={'_blank'}/>
                </div>)}
                {referralLinks?.length === 0 && <div className={`p-[10px] flex justify-center`}>
                    {Translator.translateId('label__no_referral_links')}
                </div>}
            </div>
        </div>
    </PageTemplate>;
}
