import PageTemplate from '../Components/PageTemplate/PageTemplate';
import Translator from '../Utils/Translator';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import { PictureIcon } from '../Icons/PictureIcon';
import { FriendIcon } from '../Icons/FriendIcon';
import { NewsIcon } from '../Icons/NewsIcon';
import { DollarIcon } from '../Icons/DollarIcon';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { HomeIcon } from '../Icons/HomeIcon';
import logo from '../Images/logo.png';
import { GearIcon } from '../Icons/GearIcon';
import { theme } from '../Signals/ThemeSignal';
import { ExclamationTriangledIcon } from '../Icons/ExclamationTriangledIcon';
import { QuestionIcon } from '../Icons/QuestionIcon';
import { QrCodeIcon } from '../Icons/QrCodeIcon';
import { ProfileIcon } from '../Icons/ProfileIcon';
import { LinkIcon } from '../Icons/LinkIcon';
import { CloudIcon } from '../Icons/CloudIcon';
import { ActivityIcon } from '../Icons/ActivityIcon';
import { PlayIcon } from '../Icons/PlayIcon';
import { CouponAutoApplyIcon } from '../Icons/CouponAutoApplyIcon';

export function Roadmap() {
    return <PageTemplate>
        <div className={`p-[10px] lg:flex gap-[10px]`}>
            <div className={`w-full bg-white rounded-xl border p-[10px]`}>
                <RoadmapObject label={Translator.translateId('label__incoming_features')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isStart={true}/>
                <RoadmapObject label={Translator.translateId('label__add_project_related_pages')} icon={<QuestionIcon className={`w-[20px]`} />} inProgress={true}/>
                <RoadmapObject label={Translator.translateId('label__connection_lost_management')} icon={<ExclamationTriangledIcon className={`w-[20px]`} />} isDone={true}/>
                <RoadmapObject label={Translator.translateId('label__version_management')} icon={<ExclamationTriangledIcon className={`w-[20px]`} />} isDone={true}/>
                <RoadmapObject label={Translator.translateId('label__one_scan_log_in')} icon={<QrCodeIcon className={`w-[20px] stroke-black fill-transparent border-0`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/log-in?auto-log-in=1'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_news')} label={Translator.translateId('label__implement_auto_fetching_news')} icon={<NewsIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/news'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_social')} label={Translator.translateId('label__follower_system')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/users/018ea587-da81-7235-b2f5-5ce7d5da3a66'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_social')} label={Translator.translateId('label__share_coupons_and_deals')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/coupons'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_social')} label={Translator.translateId('label__write_post')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_social')} label={Translator.translateId('label__manage_user_profile')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/users/self'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_mail')} label={Translator.translateId('label__implement_mailing')} icon={<LinkIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_sync')} label={Translator.translateId('label__implement_firebase_real_time_database')} icon={<LinkIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_weather')} label={Translator.translateId('label__implement_weather_api')} icon={<CloudIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/weather'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_notes')} label={Translator.translateId('label__manage_notes_and_tiny_mce')} icon={<NewsIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/activities'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_boards')} label={Translator.translateId('label__manage_boards_effort_comfort')} icon={<ActivityIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/activities'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_boards')} label={Translator.translateId('label__manage_boards')} icon={<ActivityIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/activities'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_player')} label={Translator.translateId('label__karaoke_mode')} icon={<PlayIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/youtube'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_player')} label={Translator.translateId('label__virtual_playing_list')} icon={<PlayIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/youtube'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_player')} label={Translator.translateId('label__manage_playlists')} icon={<PlayIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/youtube'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_player')} label={Translator.translateId('label__implement_youtube_api')} icon={<PlayIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/youtube'}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_deals')} label={Translator.translateId('label__manage_coupons_and_deals')} icon={<CouponAutoApplyIcon className={`w-[30px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/coupons'}/>}/>
                <RoadmapObject label={Translator.translateId('label__manage_https')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__front')} label={Translator.translateId('label__deploy_front_to_s3')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={'/'}/>}/>
                <RoadmapObject title={Translator.translateId('label__api')} label={Translator.translateId('label__deploy_back_to_raspberry')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_backoffice')} label={Translator.translateId('label__manage_back_office')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject label={Translator.translateId('label__admin_and_user_distinct')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject label={Translator.translateId('label__create_log_in')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={`/log-in`}/>}/>
                <RoadmapObject label={Translator.translateId('label__create_register')} icon={<ProfileIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true} link={<PrimaryLink label={Translator.translateId('cta__discover')} to={`/register`}/>}/>
                <RoadmapObject title={Translator.translateId('label__spaceshare_translate')} label={Translator.translateId('label__create_translation_system')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__api')} label={Translator.translateId('label__add_docker_container')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__front')} label={Translator.translateId('label__create_react_project')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject title={Translator.translateId('label__api')} label={Translator.translateId('label__create_symfony_project')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isDone={true}/>
                <RoadmapObject label={Translator.translateId('label__roadmap_implement_project')} icon={<GearIcon className={`w-[20px] stroke-${theme.value}-primary-950`} />} isEnd={true} isDone={true}/>
            </div>
            <div className={`min-w-[300px] mt-[10px] lg:mt-0`}>
                <div className={`border bg-white rounded-xl p-[10px]`}>
                    <div className={`font-bold`}>
                        {Translator.translateId('label__also_discover')}
                    </div>
                    <div className={`ml-[10px]`}>
                        <PrimaryLink to={'/about-us'} label={Translator.translateId('cta__about_us')}/>
                        <PrimaryLink to={'/roadmap'} label={Translator.translateId('cta__roadmap')}/>
                        <PrimaryLink to={'/team'} label={Translator.translateId('cta__team')}/>
                        {/*<PrimaryLink to={'/ama'} label={Translator.translateId('cta__ask_me_anything')}/>*/}
                        {/*<PrimaryLink to={'/features'} label={Translator.translateId('cta__features')}/>*/}
                        {/*<PrimaryLink to={'/conditions'} label={Translator.translateId('cta__conditions')}/>*/}
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>;
}

function RoadmapObject({title, link, label, icon, isEnd = false, isStart = false, isDone = false, inProgress = false}: { label: string, icon: any, isEnd?: boolean, isStart?: boolean, isDone?: boolean, inProgress?: boolean, link?: any, title?: string}) {
    let backgroundColor = 'bg-gray-50';
    if (isDone) {
        backgroundColor = 'bg-green-100';
    }
    if (inProgress) {
        backgroundColor = 'bg-yellow-100';
    }

    return <div className={`flex gap-[10px] items-center`}>
        <div className={`flex justify-center`}>
            <div>
                <div className={`w-[30px] border-b-0 border h-[65px] md:h-[55px] m-auto mb-[-5px] ${isStart ? 'border-t-[1px]' : 'border-t-0'} ${backgroundColor}`}></div>
                <div className={`rounded-full w-[50px] border h-[50px] flex items-center justify-center ${backgroundColor}`}>
                    {icon}
                </div>
                <div className={`w-[30px] mt-[-5px] border h-[65px] md:h-[55px] m-auto border-t-0 ${isEnd ? 'border-b-[1px]' : 'border-b-0'} ${backgroundColor}`}></div>
            </div>
        </div>
        <div className={`border font-bold p-[10px] rounded-xl w-full ${backgroundColor}`}>
            {title !== undefined && <div className={`text-xl mb-[10px]`}>{title}</div>}
            <div>{label}</div>
            {link !== undefined && <div className={`mt-[10px] flex gap-[10px]`}>
                {link}
            </div>}
        </div>
    </div>;
}
