import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_COMPANY_CREATION_FORM_ERROR,
    LABEL_COMPANY_INTERNAL_NAME, LABEL_COMPANY_NAME
} from "../Constants/Label";
import {Link} from "react-router-dom";
import {CompanyInterface} from "../Interfaces/CompanyInterface";
import {NewApiManager} from "../Utils/NewApiManager";
import {
    BACK_OFFICE_ALL_COMPANIES,
    BACK_OFFICE_CREATE_COMPANY,
    BACK_OFFICE_CREATE_COMPANY_FORM_VALIDATOR
} from "../Constants/ApiUrls";

export function BackOfficeCompanies() {
    const [companies, setCompanies] = useState([]);

    const [isCreatingCompany, setIsCreatingCompany] = useState(false);

    const {shouldSubmit, form, handleChange, setValues, loadConstrains, submitError, submitLock} = FormHook(['name', 'internalName']);

    const toggleCreatingCompany = () => setIsCreatingCompany(() => !isCreatingCompany);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.post(
            BACK_OFFICE_CREATE_COMPANY,
            {
                name: form.fields.name.value,
                internalName: form.fields.internalName.value
            },
            () => {
                setValues({
                    name: '',
                    internalName: '',
                })
                loadCompanies();
            },
            () => submitError(LABEL_COMPANY_CREATION_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_CREATE_COMPANY_FORM_VALIDATOR);
    }, []);


    const loadCompanies = () => {
        NewApiManager.get(
            BACK_OFFICE_ALL_COMPANIES,
            (response: any) => setCompanies(response.data)
        );
    };

    useEffect(() => {
        loadCompanies();
    }, []);

    const formFlexRowStyle = `xl:w-[calc(calc(100%/4)*2-8px)] md:w-[calc(calc(100%/3)*2-8px)] w-full`;
    const flexRowStyle = `xl:w-[calc(100%/4-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full`;

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_companies'}/>
            <div className={`mt-[10px] flex gap-[10px] flex-wrap`}>
                {isCreatingCompany && <form onSubmit={onSubmit} className={`border-[1px] border-${theme.value}-primary-950 ${formFlexRowStyle} h-[150px] rounded border-dashed flex`}>
                    <div className={`w-full`}>
                        <div className={`p-[10px]`}>
                            <Input form={form} handleChange={handleChange} name={'name'}
                                   placeholder={LABEL_COMPANY_NAME}/>
                        </div>
                        <div className={`flex justify-end px-[10px]`}>
                            <div
                                className={`p-[5px] border-dashed rounded border-${theme.value}-primary-950 border-[1px] w-1/2`}>
                                <Input form={form} handleChange={handleChange} name={'internalName'} placeholder={LABEL_COMPANY_INTERNAL_NAME}/>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`border-l-[1px] border-dashed border-${theme.value}-primary-950 p-[10px] flex items-center justify-center`}>
                        <div>
                            <button type={'submit'}
                                    className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                            <button onClick={toggleCreatingCompany}
                                    className={`block w-full border-[1px] border-red-700 p-[5px] mt-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-red-600`}>{Translator.translateId('cta__cancel')}</button>
                        </div>
                    </div>
                </form>}
                {!isCreatingCompany && <div onClick={toggleCreatingCompany}
                                           className={`cursor-pointer border-[1px] border-dashed border-${theme.value}-primary-950 ${flexRowStyle} flex justify-center items-center h-[150px] rounded`}>
                {Translator.translateId('cta__add_new_company')}
                </div>}
                {companies.map((company: CompanyInterface, index: number) => <Link
                    to={`/back-office/companies/${company.id}/update`} key={`coupon-${index}`}
                    className={`border-[1px] border-${theme.value}-primary-950 ${flexRowStyle} h-[150px] rounded`}>
                    <div className={`p-[5px]`} title={company.name}>
                        <div className={`font-bold line-clamp-1 min-h-[30px]`}>
                            {company.name}
                        </div>
                    </div>
                    <div className={`flex justify-center p-[10px] italic`}>
                        {company.url === null ? Translator.translateId('label__company_no_url') : company.url}
                    </div>
                    <div className={`flex justify-end px-[10px] py-[5px]`}>
                        <div className={`p-[5px] border-dashed rounded border-${theme.value}-primary-950 border-[1px]`}>
                            {company.internalName}
                        </div>
                    </div>
                </Link>)}
            </div>

        </div>
    </PageTemplate>;
}
