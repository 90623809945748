import { DeviceInterface } from '../../Interfaces/DeviceInterface';
import Translator from '../../Utils/Translator';
import { ComputerIcon } from '../../Icons/ComputerIcon';
import { DateTimeLabel } from '../Common/Date/DateTimeLabel';
import { deviceId } from '../../Signals/DeviceIdSignal';

interface CardInterface {
    device: DeviceInterface;
    className?: string;
}

export function Card({device, className = ''}: CardInterface) {
    const current = device.deviceId === deviceId.value ? <span className={`text-[12px]`}>{Translator.translateId('label__current_device')}</span> : '';

    return <div className={`p-[10px] rounded-xl bg-gray-100 flex gap-[10px] items-center ${className}`}>
        <div>
            <div className={`p-[20px] bg-white rounded-full`}>
                <ComputerIcon className={`w-[40px]`}/>
            </div>
        </div>
        <div>
            <div className={`font-bold text-[20px]`}>
                {device.deviceName === null ?
                    <div className={`italic`}>
                        {Translator.translateId('label__device_without_name')} {current}
                    </div>
                    : `device.deviceName ${current}`
                }
            </div>
            {device.updateDate !== undefined && device.updateDate !== null && <div>
                {Translator.translateId('label__device_update_date')} : <DateTimeLabel datetime={device.updateDate.date}/>
            </div>}
        </div>
    </div>;
}
