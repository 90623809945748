import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useNavigate, useParams,} from "react-router-dom";
import {useEffect, useState} from "react";
import {BoardInterface} from "../Interfaces/BoardInterface";
import {FormHook} from "../Hooks/FormHook";
import {
    LABEL_BOARD_NAME,
    LABEL_BOARD_UPDATE_FORM_ERROR,
    LABEL_BOARD_UPDATE_FORM_SUCCESS,
} from "../Constants/Label";
import Input from "../Components/Common/Input/Input";
import Translator from "../Utils/Translator";
import {theme} from "../Signals/ThemeSignal";
import {SpinnerIcon} from "../Icons/SpinnerIcon";
import {GearIcon} from "../Icons/GearIcon";
import TogglerHook from "../Hooks/Toggler/TogglerHook";
import {BoardColumnInterface} from "../Interfaces/BoardColumnInterface";
import {BoardRowInterface} from "../Interfaces/BoardRowInterface";
import {BoardCardInterface} from "../Interfaces/BoardCardInterface";
import {PlusIcon} from "../Icons/PlusIcon";
import {WorkIcon} from "../Icons/WorkIcon";
import {ColumnCreationModal} from "../Components/Board/ColumnCreationModal";
import {ColumnUpdateModal} from "../Components/Board/ColumnUpdateModal";
import {CardUpdateModal} from "../Components/Board/CardUpdateModal";
import {RowCreationModal} from "../Components/Board/RowCreationModal";
import {CardCreationModal} from "../Components/Board/CardCreationModal";
import {CardCreateButton} from "../Components/Board/CardCreateButton";
import {RowUpdateModal} from "../Components/Board/RowUpdateModal";
import {StoryPointsManagementModal} from "../Components/Board/StoryPointsManagementModal";
import {Card} from "../Components/Board/Card";
import {Drag, DragDrop, Drop, ValueInterface} from "../Components/Common/DragAndDrop/SimpleDragAndDrop";
import {DollarIcon} from "../Icons/DollarIcon";
import {ControllerIcon} from "../Icons/ControllerIcon";
import {InvisibleIcon} from "../Icons/InvisibleIcon";
import {VisibleIcon} from "../Icons/Visible";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {TrashIcon} from "../Icons/TrashIcon";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {
    BOARD,
    DELETE_BOARD,
    UPDATE_BOARD, UPDATE_BOARD_CARDS_ORDER, UPDATE_BOARD_COLUMN_VISIBILITY,
    UPDATE_BOARD_COLUMNS_ORDER,
    UPDATE_BOARD_FORM_VALIDATOR, UPDATE_BOARD_ROW_VISIBILITY, UPDATE_BOARD_ROWS_ORDER
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function Board() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [board, setBoard] = useState<BoardInterface|undefined>(undefined);
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, setConstrains, setForm, submitLock, submitSuccess, submitError} = FormHook(['name']);
    const [columnCreationModalIsOpened, setColumnCreationModalIsOpened] = useState(false);
    const [columnUpdateModalIsOpened, setColumnUpdateModalIsOpened] = useState(false);
    const [rowUpdateModalIsOpened, setRowUpdateModalIsOpened] = useState(false);
    const [rowCreationModalIsOpened, setRowCreationModalIsOpened] = useState(false);
    const {isVisible: isBoardOptionsVisible, ref: boardOptionsRef, toggleVisibility: toggleBoardOptionsVisibility} = TogglerHook();
    const [deleteBoardModalVisible, setDeleteBoardModalVisible] = useState(false);
    const [cardUpdateModalIsOpened, setCardUpdateModalIsOpened] = useState(false);
    const [selectedCard, setSelectedCard] = useState<BoardCardInterface|undefined>(undefined);
    const [selectedColumn, setSelectedColumn] = useState<BoardColumnInterface|undefined>(undefined);
    const [selectedRow, setSelectedRow] = useState<BoardRowInterface|undefined>(undefined);
    const [cardCreationModalIsOpened, setCardCreationModalIsOpened] = useState(false);
    const [storyPointsManagementModalIsOpened, setStoryPointsManagementModalIsOpened] = useState(false);
    const [draggingType, setDraggingType] = useState<undefined|'card'|'column'|'row'>(undefined);

    useEffect(() => {
        if (id === undefined) {
            navigate(`/activities`);
            return;
        }
        loadConstrains(UPDATE_BOARD_FORM_VALIDATOR);
        loadBoard();
        resetGlobalSearch();
    }, []);

    function computeStoryRates(cards: BoardCardInterface[], column?: BoardColumnInterface|null, row?: BoardRowInterface|null) {
        if (column !== undefined) {
            cards = cards.filter((card: BoardCardInterface) => card.boardColumn === column || card.boardColumn?.id === column?.id);
        }
        if (row !== undefined) {
            cards = cards.filter((card: BoardCardInterface) => card.boardRow === row || card.boardRow?.id === row?.id);
        }

        let effortPoints = 0;
        let comfortPoints = 0;

        cards.forEach((card: BoardCardInterface) => {
            if (card.boardStoryPoint !== null && card.boardStoryPoint.storyType === 'effort') effortPoints += card.boardStoryPoint.points;
            if (card.boardStoryPoint !== null && card.boardStoryPoint.storyType === 'comfort') comfortPoints += card.boardStoryPoint.points;
        });
        const totalPoints = effortPoints+comfortPoints;

        return {
            effort: {
                points: effortPoints,
                rate: parseFloat((effortPoints === 0 || totalPoints === 0) ? '0' : ((effortPoints/totalPoints)*100).toString()).toFixed(2),
            },
            comfort: {
                points: comfortPoints,
                rate: parseFloat((comfortPoints === 0 || totalPoints === 0) ? '0' : ((comfortPoints/totalPoints)*100).toString()).toFixed(2),
            },
        };
    }

    const submitBoardName = (e: any) => {
        if (id === undefined) {
            return;
        }

        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            UPDATE_BOARD(id),
            {name: form.fields.name.value},
            () => submitSuccess(LABEL_BOARD_UPDATE_FORM_SUCCESS),
            () => submitError(LABEL_BOARD_UPDATE_FORM_ERROR)
        );
    }

    const loadBoard = () => {
        if (id === undefined) {
            return;
        }
        NewApiManager.get(
            BOARD(id),
            (response: any) => {
                setValues({
                    name: response.data.name !== null ? response.data.name : '',
                });

                response.data = computeRates(response.data);

                setBoard(response.data);
            }
        );
    };

    const toggleCardUpdateModal = (boardCard: BoardCardInterface|undefined = undefined) => {
        setSelectedRow(undefined);
        setCardUpdateModalIsOpened(() => !cardUpdateModalIsOpened);
        if (boardCard !== undefined) {
            setSelectedCard(boardCard);
        }
    }
    const toggleCardCreationModal = (row: BoardRowInterface|undefined = undefined) => {
        setSelectedRow(row);
        setCardCreationModalIsOpened(() => !cardCreationModalIsOpened);
    }
    const toggleColumnUpdateModal = (boardColumn: BoardColumnInterface|undefined = undefined) => {
        setColumnUpdateModalIsOpened(() => !columnUpdateModalIsOpened);
        if (boardColumn !== undefined) {
            setSelectedColumn(boardColumn);
        }
    }
    const toggleRowUpdateModal = (boardRow: BoardRowInterface|undefined = undefined) => {
        setRowUpdateModalIsOpened(() => !rowUpdateModalIsOpened);
        if (boardRow !== undefined) {
            setSelectedRow(boardRow);
        }
    }

    const toggleColumnCreationModal = () => {
        setColumnCreationModalIsOpened(() => !columnCreationModalIsOpened);
    }

    const toggleRowCreationModal = () => {
        setRowCreationModalIsOpened(() => !rowCreationModalIsOpened);
    }

    const toggleHiddenRow = (id: string) => {
        if (board === undefined) {
            return;
        }
        let newBoard = {...board};
        let isHidden = false;
        newBoard.rows.forEach((row: BoardRowInterface, index) => {
            if (row.id === id) {
                isHidden = !newBoard.rows[index].isHidden
                newBoard.rows[index].isHidden = isHidden;
            }
        });

        NewApiManager.put(
            UPDATE_BOARD_ROW_VISIBILITY(board.id, id),
            {
                isHidden
            }
        );

        setBoard(newBoard);
    };

    const toggleHiddenColumn = (id: string) => {
        if (board === undefined) {
            return;
        }
        let newBoard = {...board};
        let isHidden = false;
        newBoard.columns.forEach((column: BoardColumnInterface, index) => {
            if (column.id === id) {
                isHidden = !newBoard.columns[index].isHidden
                newBoard.columns[index].isHidden = isHidden;
            }
        });

        NewApiManager.put(
            UPDATE_BOARD_COLUMN_VISIBILITY(board.id, id),
            {
                isHidden
            }
        );

        setBoard(newBoard);
    };

    const toggleManageStoryPointsModal = () => {
        setStoryPointsManagementModalIsOpened(!storyPointsManagementModalIsOpened);
    };

    const onDropCard = (newOrder: any, draggedItem: BoardCardInterface, dropResult: any) => {
        if (board === undefined) {
            return;
        }

        const [rowIndex, columnIndex] = dropResult.destination.droppableId.split('-');
        draggedItem.boardRow = board.rows[rowIndex] ?? null;
        draggedItem.boardColumn = board.columns[columnIndex] ?? null;

        newOrder.map((card: BoardCardInterface) => {
            if (card.id === draggedItem.id) {
                card.boardRow = draggedItem.boardRow;
                card.boardColumn = draggedItem.boardColumn;
            }

            return card;
        });
        NewApiManager.put(
            UPDATE_BOARD_CARDS_ORDER(board.id),
            {
                orderedCardIds: newOrder.map((card: BoardCardInterface) => card.id),
                cardToUpdate: {id: draggedItem.id, boardRow: draggedItem.boardRow?.id ?? null, boardColumn: draggedItem.boardColumn?.id ?? null}
            }
        );

        if (board.columns[columnIndex]?.autoCompleteCard === 1) {
            setTimeout(() => {
                loadBoard();
            }, 500);
        }

        return {
            ...board,
            cards: newOrder,
        };
    }

    const onDropRow = (newOrder: any) => {
        if (board === undefined) {
            return;
        }

        NewApiManager.put(
            UPDATE_BOARD_ROWS_ORDER(board.id),
            {
                orderedRowIds: newOrder.map((row: any) => row.id)
            }
        );

        return {
            ...board,
            rows: newOrder,
        };
    };
    const onDropColumn = (newOrder: any) => {
        if (board === undefined) {
            return;
        }
        NewApiManager.put(
            UPDATE_BOARD_COLUMNS_ORDER(board.id),
            {
                orderedColumnIds: newOrder.map((column: any) => column.id)
            }
        );

        return {
            ...board,
            columns: newOrder,
        };
    };

    const onDropEnd = (result: any) => {
        if (board === undefined) {
            return;
        }

        let values: any[] = [];
        let dropCallback: any = (newOrder: ValueInterface[], draggedItem: any, dropResult: any) => {};
        switch (draggingType) {
            case 'card':
                values = board.cards;
                dropCallback = onDropCard;
                break;
            case 'column':
                values = board.columns;
                dropCallback = onDropColumn;
                break;
            case 'row':
                values = board.rows;
                dropCallback = onDropRow;
                break;
        }
        const newOrder = [...values];
        const [draggedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, draggedItem);

        let newBoard = dropCallback(newOrder, draggedItem, result);

        newBoard = computeRates(newBoard);
        setBoard(newBoard);
    }

    const onDragUpdate = (result: any) => {
        const currentDraggingType = result.draggableId.split('-')[0];
        if (['card', 'row', 'column'].includes(currentDraggingType)) {
            setDraggingType(currentDraggingType);
        }
    }

    const computeRates = (responseData: any) => {
        responseData.columns = responseData.columns.map((column: BoardColumnInterface) => {
            return {
                ...column,
                rates: computeStoryRates(responseData.cards, column),
            }
        });

        responseData.rows = responseData.rows.map((row: BoardRowInterface) => {
            let cols: any = {};
            responseData.columns.forEach((column: BoardColumnInterface) => {
                cols[column.id] = {
                    rates: computeStoryRates(responseData.cards, column, row),
                };
            });
            return {
                ...row,
                rates: computeStoryRates(responseData.cards, undefined, row),
                columns: cols,
            }
        });

        let defaultRowCols: any = {};
        responseData.columns.forEach((column: BoardColumnInterface) => {
            defaultRowCols[column.id] = {
                rates: computeStoryRates(responseData.cards, column, null),
            };
        });
        responseData.defaultRow = {
            rates: computeStoryRates(responseData.cards, undefined, null),
            columns: defaultRowCols
        }

        return responseData;
    }

    const toggleDeletionBoardModal = () => {
        setDeleteBoardModalVisible(() => !deleteBoardModalVisible);
    }

    const onDeleteBoard = () => {
        if (id === undefined) {
            return;
        }
        NewApiManager.delete(
            DELETE_BOARD(id),
            () => navigate('/activities')
        );
    }

    return <PageTemplate sidebar={"normal"}>
        {board !== undefined && columnCreationModalIsOpened && <ColumnCreationModal
            toggle={toggleColumnCreationModal}
            board={board}
            loadBoard={loadBoard}
        />}
        {board !== undefined && selectedColumn !== undefined && columnUpdateModalIsOpened && <ColumnUpdateModal
            board={board}
            loadBoard={loadBoard}
            toggle={toggleColumnUpdateModal}
            column={selectedColumn}
        />}
        {board !== undefined && rowCreationModalIsOpened && <RowCreationModal
            toggle={toggleRowCreationModal}
            boardId={board.id}
            loadBoard={loadBoard}
        />}
        {board !== undefined && selectedRow !== undefined && rowUpdateModalIsOpened && <RowUpdateModal
            board={board}
            row={selectedRow}
            toggle={toggleRowUpdateModal}
            loadBoard={loadBoard}
        />}
        {board !== undefined && cardCreationModalIsOpened && <CardCreationModal
            toggle={toggleCardCreationModal}
            board={board}
            loadBoard={loadBoard}
            row={selectedRow}
        />}
        {board !== undefined && cardUpdateModalIsOpened && selectedCard && <CardUpdateModal
            toggle={toggleCardUpdateModal}
            card={selectedCard}
            board={board}
            loadBoard={loadBoard}
        />}
        {board !== undefined && storyPointsManagementModalIsOpened && <StoryPointsManagementModal
            toggle={toggleManageStoryPointsModal}
            board={board}
            loadBoard={loadBoard}
        />}
        <DeleteConfirmationModal message={Translator.translateId('label__board_delete_confirmation_message')} deleteAction={onDeleteBoard} toggleModal={toggleDeletionBoardModal} isVisible={deleteBoardModalVisible}/>
        <div className={`p-[10px]`}>
            <div className={`flex justify-between gap-[10px]`}>
                <form onSubmit={submitBoardName}>
                    <div className={`flex gap-[10px] items-center`}>
                        <Input form={form} name={`name`} handleChange={handleChange} placeholder={LABEL_BOARD_NAME}/>
                        {!form.isLoading && board !== undefined && form.fields?.name.value !== board.name && form.fields.name.value !== '' &&
                            <button type={`submit`}
                                    className={`border-${theme.value}-primary-950 border-[1px] text-${theme.value}-primary-50 bg-${theme.value}-primary-950 p-[10px] rounded`}>{Translator.translateId(`cta__submit`)}</button>}
                        {form.isLoading && <SpinnerIcon/>}
                    </div>
                </form>
                <div className={`flex gap-[5px]`}>
                    <div
                        className={`border-${theme.value}-primary-950 border-[1px] flex px-[10px] items-center rounded cursor-pointer`}
                        ref={boardOptionsRef} onClick={toggleBoardOptionsVisibility}>
                        <GearIcon className={`stroke-${theme.value}-primary-950 w-[30px]`}/>
                        {isBoardOptionsVisible && <div
                            className={`border-${theme.value}-primary-950 border-[1px] rounded bg-${theme.value}-primary-50 absolute mt-[110px] ml-[-158px] w-[200px] z-important`}>
                            <div
                                className={`p-[10px] hover:bg-${theme.value}-primary-100 cursor-pointer rounded flex gap-[10px] items-center`}
                                onClick={toggleManageStoryPointsModal}>
                                <WorkIcon
                                    className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__manage_story_points')}
                            </div>
                            <div
                                className={`p-[10px] hover:bg-${theme.value}-primary-100 cursor-pointer rounded flex gap-[10px] items-center`}
                                onClick={toggleDeletionBoardModal}>
                                <TrashIcon
                                    className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__delete')}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {board === undefined && <div className={`mt-[10px]`}><SpinnerIcon/></div>}
            {board !== undefined &&
                <div>
                <DragDrop onDragUpdate={onDragUpdate} onDragEnd={onDropEnd}>
                    <div className={`mt-[10px] bg-${theme.value}-primary-100 rounded p-[5px]`}>
                        <div className={`overflow-x-auto`}>
                            <Columns board={board} toggleColumnCreationModal={toggleColumnCreationModal} toggleHiddenColumn={toggleHiddenColumn}
                                     toggleColumnUpdateModal={toggleColumnUpdateModal} draggingType={draggingType}/>
                            <div className={`h-[calc(100vh-263px)]`}>
                                <DefaultRow board={board} toggleCardCreationModal={toggleCardCreationModal}
                                            toggleCardUpdateModal={toggleCardUpdateModal} draggingType={draggingType}/>
                                <Rows board={board} toggleRowUpdateModal={toggleRowUpdateModal}
                                      toggleHiddenRow={toggleHiddenRow} toggleCardCreationModal={toggleCardCreationModal}
                                      toggleCardUpdateModal={toggleCardUpdateModal} draggingType={draggingType}/>
                                <div className={`mt-[10px] flex`} onClick={toggleRowCreationModal}>
                                    <div className={`text-xs font-bold items-center cursor-pointer flex gap-[5px]`}
                                         onClick={toggleRowCreationModal}><PlusIcon
                                        className={`w-[15px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__create_board_row')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DragDrop>
                </div>
            }
        </div>
    </PageTemplate>;
}

interface ColumnsInterface {
    board: BoardInterface;
    toggleColumnCreationModal: () => void;
    toggleColumnUpdateModal: (column: BoardColumnInterface) => void;
    draggingType: 'card' | 'row' | 'column' | undefined;
    toggleHiddenColumn: (columnId: string) => void;
}

function Columns({board, toggleColumnCreationModal, toggleColumnUpdateModal, draggingType, toggleHiddenColumn}: ColumnsInterface) {
    return <div className={`flex sticky top-0 w-max bg-light-primary-100`}>
        <div
            className={`w-[200px] min-w-[200px] font-bold flex items-center justify-center bg-${theme.value}-primary-50 m-[5px] p-[5px] rounded border-[1px] border-${theme.value}-primary-950`}>
            {Translator.translateId(`label__board_backlog_title`)}
        </div>
        <Drop id={'columns'} direction={'horizontal'} className={`flex`} isDropDisabled={draggingType !== 'column'}>
            {board.columns.map((column: BoardColumnInterface, index: number) => {
                if (column.isHidden) {
                    return <div title={column.name} className={`w-[40px] h-[35px] flex justify-center items-center rounded bg-${theme.value}-primary-50 m-[5px] cursor-pointer border-[1px] border-${theme.value}-primary-950`} onClick={() => toggleHiddenColumn(column.id)}>
                        <InvisibleIcon className={`w-[15px] fill-${theme.value}-primary-950`}/>
                    </div>;
                }

                return <Drag id={`column-${index}`} index={index} key={`column-${index}`}>
                    <div className={`w-[200px] min-w-[200px] font-bold bg-${theme.value}-primary-50 rounded m-[5px] h-[35px] flex items-center justify-center gap-[5px] border-[1px] border-${theme.value}-primary-950`}>
                        {!column.isHidden && <span onClick={() => toggleColumnUpdateModal(column)}>{column.name}</span>}
                        <div onClick={() => toggleHiddenColumn(column.id)} className={`cursor-pointer`}>
                            {column.isHidden ?
                                <InvisibleIcon className={`w-[15px] fill-${theme.value}-primary-950`}/> :
                                <VisibleIcon className={`w-[15px] fill-${theme.value}-primary-950`}/>}
                        </div>
                    </div>
                </Drag>;
            })}
        </Drop>

        <div onClick={toggleColumnCreationModal}
             className={`w-[200px] min-w-[200px] font-bold flex items-center cursor-pointer justify-center bg-${theme.value}-primary-50 m-[5px] p-[5px] rounded border-dashed border-[1px] border-${theme.value}-primary-950`}>
            {Translator.translateId('cta__create_board_column')}
        </div>
    </div>;
}

interface DefaultRowInterface {
    board: BoardInterface;
    toggleCardCreationModal: () => void;
    toggleCardUpdateModal: () => void;
    draggingType: 'card'|'row'|'column'|undefined;
}
function DefaultRow({board, toggleCardCreationModal, toggleCardUpdateModal, draggingType}: DefaultRowInterface) {
    const rates = board.defaultRow.rates;

    return <div className={`flex gap-[10px] p-[5px]`}>
        <div className={`w-[200px] min-w-[200px] bg-${theme.value}-primary-50 p-[5px] rounded items-stretch`}>
            <CardCreateButton toggle={toggleCardCreationModal}/>
            <Drop id={'default-backlog'} className={`min-h-[55px]`}>
                {board.cards.map((card: BoardCardInterface, index) => card.boardRow === null && card.boardColumn === null &&
                    <Drag id={`card-${index}`} index={index} key={`card-${index}`} className={`mb-[10px] last:mb-0`}>
                        <Card card={card} toggleUpdateModal={toggleCardUpdateModal}
                              className={`mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded p-[10px] cursor-pointer bg-${theme.value}-primary-50`}/>
                    </Drag>)}
            </Drop>
        </div>
        {board.columns.map((column: BoardColumnInterface, index) => {
            if (column.isHidden) {
                return <div className={`min-w-[40px] bg-${theme.value}-primary-200 rounded`}></div>;
            }
            const colRates = board.defaultRow.columns[column.id].rates;

            return <Drop isDropDisabled={draggingType !== "card"} key={`default-${index}`}
                         id={`default-${index}`}
                         className={`w-[200px] min-w-[200px] min-h-[55px] bg-${theme.value}-primary-50 p-[5px] rounded items-stretch`}>
                {(colRates.effort.points !== 0 || colRates.comfort.points !== 0) && <div className={`flex justify-center gap-[5px] items-center`}>
                    <div className={`flex gap-[5px]`}>
                        <DollarIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                        {colRates.effort.rate}%
                    </div>
                    <div>
                        |
                    </div>
                    <div className={`flex gap-[5px]`}>
                        <ControllerIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                        {colRates.comfort.rate}%
                    </div>
                </div>}
                {board.cards.map((card: BoardCardInterface, index) => card.boardRow === null && card.boardColumn?.id === column.id &&
                    <Drag id={`card-${index}`} index={index} key={`card-${index}`} className={`mb-[10px] last:mb-0`}>
                        <Card card={card} toggleUpdateModal={toggleCardUpdateModal}
                              className={`border-[1px] border-${theme.value}-primary-950 rounded p-[10px] cursor-pointer bg-${theme.value}-primary-50`}/>
                    </Drag>)}
            </Drop>
        })}
        {(rates.effort.points !== 0 || rates.comfort.points !== 0) && <div className={`font-bold flex justify-center gap-[5px] items-center`}>
            <div>
                <div className={`flex gap-[5px] justify-center`}>
                    <DollarIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                    {rates.effort.rate}%
                </div>
                <div className={`flex gap-[5px] justify-center`}>
                    <ControllerIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                    {rates.comfort.rate}%
                </div>
            </div>
        </div>}
    </div>;
}

interface RowsInterface {
    board: BoardInterface;
    toggleRowUpdateModal: (row: BoardRowInterface) => void;
    toggleHiddenRow: (rowId: string) => void;
    toggleCardCreationModal: () => void;
    toggleCardUpdateModal: () => void;
    draggingType: 'card'|'row'|'column'|undefined;
}

function Rows({board, toggleRowUpdateModal, toggleCardUpdateModal, toggleCardCreationModal, toggleHiddenRow, draggingType}: RowsInterface) {
    return <Drop id={'drop-row'} isDropDisabled={draggingType !== "row"}>
        {board.rows.map((row: BoardRowInterface, rowIndex: number) => <Drag index={rowIndex} id={`row-${rowIndex}`} key={`row-${rowIndex}`}>
            <div key={`row-${rowIndex}`} className={`mt-[10px]`}>
                <div className={`flex`}>
                    <div className={`font-bold text-sm cursor-pointer flex gap-[5px] items-center`}>
                        <GearIcon onClick={() => toggleRowUpdateModal(row)} className={`w-[15px] stroke-${theme.value}-primary-950`}/>
                        {row.name}
                        <div onClick={() => toggleHiddenRow(row.id)}>
                            {row.isHidden ? <InvisibleIcon className={`w-[15px] fill-${theme.value}-primary-950`}/> : <VisibleIcon className={`w-[15px] fill-${theme.value}-primary-950`}/>}
                        </div>
                    </div>
                </div>
                {!row.isHidden && <div className={`flex gap-[10px] p-[5px]`}>
                    <RowColumns draggingType={draggingType} board={board} row={row} toggleCardCreationModal={toggleCardCreationModal} toggleCardUpdateModal={toggleCardUpdateModal} rowIndex={rowIndex}/>
                </div>}
            </div>
        </Drag>)}
    </Drop>;
}

interface RowColumnsInterface {
    board: BoardInterface;
    row: BoardRowInterface;
    toggleCardCreationModal: () => void;
    toggleCardUpdateModal: () => void;
    rowIndex: number;
    draggingType: 'card'|'row'|'column'|undefined;
}

function RowColumns({board, toggleCardCreationModal, toggleCardUpdateModal, row, rowIndex, draggingType}: RowColumnsInterface) {
    const rates: any = row.rates;

    return <>
        <div className={`w-[200px] min-w-[200px] bg-${theme.value}-primary-50 p-[5px] rounded items-stretch`}>
            <CardCreateButton toggle={toggleCardCreationModal} row={row}/>
            <Drop id={`${rowIndex}-backlog`} className={`min-h-[55px]`} isDropDisabled={draggingType !== "card"}>
                {board.cards.map((card: BoardCardInterface, index) => card.boardRow?.id === row.id && card.boardColumn === null && <Drag id={`card-${index}`} index={index} key={`card-${index}`} className={`mb-[10px] last:mb-0`}>
                    <Card card={card} toggleUpdateModal={toggleCardUpdateModal} className={`mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded p-[10px] cursor-pointer bg-${theme.value}-primary-50`}/>
                </Drag>)}
            </Drop>
        </div>
        {board.columns.map((column: BoardColumnInterface, index) => {
            if (column.isHidden) {
                return <div className={`min-w-[40px] bg-${theme.value}-primary-200 rounded`}></div>;
            }
            const colRates = row.columns[column.id].rates;

            return <Drop key={`${rowIndex}-${index}`} id={`${rowIndex}-${index}`} className={`w-[200px] min-w-[200px] min-h-[55px] bg-${theme.value}-primary-50 p-[5px] rounded items-stretch`} isDropDisabled={draggingType !== "card"}>
                {(colRates.effort.points !== 0 || colRates.comfort.points !== 0) && <div className={`flex justify-center gap-[5px] items-center`}>
                    <div className={`flex gap-[5px]`}>
                        <DollarIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                        {colRates.effort.rate}%
                    </div>
                    <div>
                        |
                    </div>
                    <div className={`flex gap-[5px]`}>
                        <ControllerIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                        {colRates.comfort.rate}%
                    </div>
                </div>}
                {board.cards.map((card: BoardCardInterface, index) => card.boardRow?.id === row.id && card.boardColumn?.id === column.id && <Drag id={`card-${index}`} index={index} key={`card-${index}`} className={`mb-[10px] last:mb-0`}>
                    <Card card={card} toggleUpdateModal={toggleCardUpdateModal} className={`border-[1px] border-${theme.value}-primary-950 rounded p-[10px] cursor-pointer bg-${theme.value}-primary-50`}/>
                </Drag>)}
            </Drop>;
        })}
        {(rates.effort.points !== 0 || rates.comfort.points !== 0) && <div className={`font-bold flex justify-center gap-[5px] items-center`}>
            <div>
                <div className={`flex gap-[5px] justify-center`}>
                    <DollarIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                    {rates.effort.rate}%
                </div>
                <div className={`flex gap-[5px] justify-center`}>
                    <ControllerIcon className={`fill-${theme.value}-primary-950 w-[20px]`}/>
                    {rates.comfort.rate}%
                </div>
            </div>
        </div>}
    </>;
}
