import {signal} from "@preact/signals-react";
import {useState} from "react";
import AbstractApi from "../Api/AbstractApi";
import {VideoDataInterface} from "../Interfaces/VideoDataInterface";

export const showGlobalSearch = signal<boolean>(false);
export const showSmallDeviceGlobalSearch = signal<boolean>(false);

export const globalSearchKeywordDebounce = signal<string|undefined>(undefined);
export const globalSearchData = signal<any>({
    keyword: undefined,
    pageToken: undefined,
});
export const globalSearchPlaceholder = signal<string>('');
let debounceTimer: any = undefined;
let debounceTimerAmount = 500;
export function globalSearch(keyword: string) {
    clearTimeout(debounceTimer);
    globalSearchKeywordDebounce.value = keyword;
    debounceTimer = setTimeout(() => {
        applyGlobalSearch();
    }, debounceTimerAmount);
}

function applyGlobalSearch() {
    globalSearchData.value = {
        keyword: globalSearchKeywordDebounce.value,
        pageToken: undefined,
    };
}

export function nextGlobalSearchPage(nextPageToken: string) {
    globalSearchData.value = {
        ...globalSearchData.value,
        pageToken: nextPageToken,
    };
}

export function resetGlobalSearch(show: boolean = false, label: string = '') {
    clearTimeout(debounceTimer);
    showGlobalSearch.value = show;
    globalSearchKeywordDebounce.value = undefined;
    globalSearchData.value = {
        keyword: undefined,
        page: undefined,
    };
    globalSearchPlaceholder.value = label;
}

export function GlobalSearchHook() {
    const [searchResults, setSearchResults] = useState<any>({});
    const globalSearchEffect = (searchCallback: any, resultsArrayTransformer: any) => {
        if (globalSearchData.value.keyword !== undefined && (
            searchResults[globalSearchData.value.keyword] === undefined
            || searchResults[globalSearchData.value.keyword].pageToken !== globalSearchData.value.pageToken
        )) {
            let results = {
                ...searchResults,
            };
            results[globalSearchData.value.keyword] = {
                keyword: globalSearchData.value.keyword,
                pageToken: globalSearchData.value.pageToken,
                results: results[globalSearchData.value.keyword]?.results ?? [],
                status: 'IN_PROGRESS',
                nextPageToken: undefined,
            };

            setSearchResults(results);

            searchCallback(
                globalSearchData.value.keyword,
                globalSearchData.value.pageToken,
                (response: any) => {
                    let results = {
                        ...searchResults,
                    };
                    results[globalSearchData.value.keyword] = {
                        ...results[globalSearchData.value.keyword],
                        keyword: globalSearchData.value.keyword,
                        pageToken: globalSearchData.value.pageToken,
                        results: [
                            ...results[globalSearchData.value.keyword]?.results ?? [],
                            ...resultsArrayTransformer(response.data)
                        ],
                        status: 'COMPLETE',
                        nextPageToken: response.data.nextPageToken,
                    };
                    setSearchResults(results);
                },
                (response: any) => {
                    let results = {
                        ...searchResults,
                    };
                    results[globalSearchData.value.keyword] = {
                        ...results[globalSearchData.value.keyword],
                        status: 'COMPLETE',
                    };
                    setSearchResults(results);
                }
            )
        }
    };

    const getResults = (): VideoDataInterface[] => {
        return searchResults[globalSearchData.value.keyword]?.results ?? [];
    }

    const getNextPageToken = () => {
        return searchResults[globalSearchData.value.keyword]?.nextPageToken;
    }

    const hasNextPage = () => {
        return searchResults[globalSearchData.value.keyword]?.nextPageToken !== null && searchResults[globalSearchData.value.keyword]?.nextPageToken !== undefined;
    }

    const isLoading = () => {
        return searchResults[globalSearchData.value.keyword]?.status === 'IN_PROGRESS';
    }

    return {
        globalSearchEffect,
        getResults,
        hasNextPage,
        getNextPageToken,
        searchResults,
        isLoading
    }
}