import { CouponInterface } from '../../Interfaces/CouponInterface';
import Translator from '../../Utils/Translator';
import { Card } from './Card';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';

interface CardsInterface {
    className?: string;
    limit?: number;
    showMore?: boolean;
    coupons: CouponInterface[];
    showEditButton?: boolean;
    loadCoupons?: () => void;
}

export function Cards({className = '', limit = 0, showMore = false, coupons, showEditButton = false, loadCoupons = () => {}}: CardsInterface) {
    return <div className={`${className} bg-white border rounded-xl w-full`}>
        <div className={`p-[20px]`}>
            <div className={`text-[25px] font-bold`}>
                {Translator.translateId('label__coupons')}
            </div>
            {coupons === undefined ? <div className={`mt-[10px]`}>
                <SpinnerIcon/>
            </div> : <div className={`xl:flex xl:flex-wrap xl:gap-[10px] mt-[10px]`}>
                {coupons.length === 0 ? <div className={`italic`}>
                    {Translator.translateId('label__no_coupons')}
                </div> : coupons.slice(0, limit === 0 ? coupons.length : limit).map((coupon: CouponInterface, index: number) =>
                    <Card showShareButton={true} key={`coupon-${index}`} coupon={coupon} className={`xl:w-[calc((100%/2)-5px)] mt-[10px]`} showEditButton={showEditButton} loadCoupons={loadCoupons}/>)}
            </div>}
        </div>
        {coupons !== undefined && showMore && limit > 0 && coupons?.length > limit &&
            <div className={`font-bold underline p-[20px] border-t text-center`}>
                {Translator.translateId('label__show_more')}
            </div>
        }
    </div>;
}
