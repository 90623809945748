import Header from "../Header/Header";
import {Sidebar} from "../Sidebar/Sidebar";
import {BackOfficeSidebar} from "../Sidebar/BackOfficeSidebar";
import {ProfileSidebar} from "../Sidebar/ProfileSidebar";
import {showSmallDeviceGlobalSearch} from "../../Signals/GlobalSearchSignal";
import NewYoutubePlayer from "../YoutubePlayer/NewYoutubePlayer";
import React from "react";
import {playingMediaList} from "../../Signals/PlayingMediaListSignal";

interface PageTemplateInterface {
    children: any;
    childrenClassName?: string;
    sidebar?: 'back-office'|'normal'|'profile'|undefined;
}

export default function PageTemplate({children, childrenClassName = '', sidebar = undefined}: PageTemplateInterface) {
    return <div className={`min-h-[100dvh] bg-gray-100`}>
        <Header sidebar={sidebar}/>
        <div className={`max-w-[1400px] flex justify-between pb-[50px] m-auto
                min-[1720px]:m-0 min-[1720px]:max-w-[calc(100%-300px)]
                min-[2000px]:m-auto min-[2000px]:max-w-[1400px]
                ${playingMediaList.value.items.length === 0 && 'min-[1720px]:m-auto'}
        `}>
            <div className={`${childrenClassName} ${showSmallDeviceGlobalSearch.value ? 'pt-[120px]' : `pt-[70px]`} w-full`}>
                {children}
            </div>
        </div>
    </div>;
}