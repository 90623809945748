import { FormHook } from '../../Hooks/FormHook';
import Translator from '../../Utils/Translator';
import Input from '../Common/Input/Input';
import TextArea from '../Common/Input/Textarea';
import { LABEL_LEAVE_COMMENT, LABEL_TITLE } from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { StarIcon } from '../../Icons/StarIcon';
import { useEffect, useState } from 'react';
import { POST_RATE_SPACESHARE, RATING_FORM_VALIDATOR } from '../../Constants/ApiUrls';
import { NewApiManager } from '../../Utils/NewApiManager';

export function ContactRateForm() {
    const {form, setForm, handleChange, setValues, loadConstrains, submitLock, shouldSubmit} = FormHook(['rate', 'title', 'message'], {rate: 0});

    useEffect(() => {
        loadConstrains(RATING_FORM_VALIDATOR);
    }, []);

    const selectStars = (counter: number) => {
        setValues({
            rate: counter+'',
        });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (shouldSubmit()) {
            submitLock();

            NewApiManager.post(
                POST_RATE_SPACESHARE,
                {
                    rate: form.fields.rate.value,
                    title: form.fields.title.value,
                    message: form.fields.message.value,
                },
                (response: any) => {
                    setForm({
                        ...form,
                        success: '',
                        error: undefined,
                        isLoading: false,
                    });
                },
                () => {
                    setForm({
                        ...form,
                        success: undefined,
                        error: '',
                        isLoading: false,
                    });
                }
            )
        }
    };

    if (form.success !== undefined) {
        return <div>
            {Translator.translateId('label__thank_for_rating_spaceshare')}
        </div>;
    }

    return <form onSubmit={onSubmit}>
        <div className={`flex gap-[10px]`}>
            <div className={`flex gap-[10px] flex-row-reverse rating-stars`}>
                <StarIcon onClick={() => selectStars(5)} className={`w-[30px] ${Number(form.fields.rate.value ?? 0) >= 5 ? `fill-[#fbbe24]` : ''}`}/>
                <StarIcon onClick={() => selectStars(4)} className={`w-[30px] ${Number(form.fields.rate.value ?? 0) >= 4 ? `fill-[#fbbe24]` : ''}`}/>
                <StarIcon onClick={() => selectStars(3)} className={`w-[30px] ${Number(form.fields.rate.value ?? 0) >= 3 ? `fill-[#fbbe24]` : ''}`}/>
                <StarIcon onClick={() => selectStars(2)} className={`w-[30px] ${Number(form.fields.rate.value ?? 0) >= 2 ? `fill-[#fbbe24]` : ''}`}/>
                <StarIcon onClick={() => selectStars(1)} className={`w-[30px] ${Number(form.fields.rate.value ?? 0) >= 1 ? `fill-[#fbbe24]` : ''}`}/>
            </div>
        </div>
        <Input className={`mt-[10px]`} form={form} name={'title'} handleChange={handleChange} placeholder={LABEL_TITLE}/>
        <TextArea form={form} name={'message'} handleChange={handleChange} className={`mt-[10px] border border-black`} placeholder={LABEL_LEAVE_COMMENT}/>
        <PrimaryButton className={`mt-[10px]`} onClick={onSubmit}>
            {Translator.translateId('cta__submit')}
        </PrimaryButton>
    </form>;
}
