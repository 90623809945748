import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {CouponInterface} from "../Interfaces/CouponInterface";
import Translator from "../Utils/Translator";
import { isLoggedIn, userAccount } from "../Signals/UserAccountSignal";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {NewApiManager} from "../Utils/NewApiManager";
import { PUBLIC_COUPONS, USER_COUPONS } from "../Constants/ApiUrls";
import { Card } from '../Components/Coupon/Card';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { CreateModal } from '../Components/Coupon/CreateModal';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { PrimaryIconButton } from '../Components/Common/Button/PrimaryIconButton';

export function Coupons() {
    const [publicCoupons, setPublicCoupons] = useState<{incoming: CouponInterface[], permanent: CouponInterface[], inProgress: CouponInterface[]}>({
        incoming: [],
        permanent: [],
        inProgress: [],
    });
    const [userCoupons, setUserCoupons] = useState<CouponInterface[]|undefined>(undefined);
    const [createCouponModalVisible, setCreateCouponModalVisible] = useState(false);
    const [hasNextCoupons, setHasNextCoupons] = useState(false);

    const loadUserCoupons = (reset: boolean = false) => {
        const couponsCount = 10;
        setHasNextCoupons(false);

        let couponsOffset = 0;
        if (!reset) {
            couponsOffset = ((userCoupons) ?? []).length;
        }
        if (isLoggedIn() && userAccount.value?.id !== undefined) {
            NewApiManager.get(
                USER_COUPONS(userAccount.value.id, couponsCount+1, couponsOffset),
                (response: any) => {
                    let coupons = response.data;
                    if (coupons.length > couponsCount) {
                        setHasNextCoupons(true);
                        coupons = response.data.slice(0, couponsCount);
                    }
                    if (reset) {
                        setUserCoupons(coupons);
                    } else {
                        setUserCoupons([...(userCoupons ?? []), ...coupons]);
                    }
                }
            );
        }
    };

    const loadPublicCoupons = () => {
        NewApiManager.get(
            PUBLIC_COUPONS(3),
            (response: any) => setPublicCoupons(response.data)
        );
    };

    useEffect(() => {
        loadUserCoupons(true);
        loadPublicCoupons();
        resetGlobalSearch();
    }, []);

    return <PageTemplate sidebar={"normal"}>
        {createCouponModalVisible && <CreateModal submitSuccessCallback={() => {setCreateCouponModalVisible(false); loadUserCoupons(true);}} toggleModal={() => setCreateCouponModalVisible(false)}/>}
        <div className={`mt-[10px]`}>
            <div className={`xl:flex gap-[10px]`}>
                <div className={`w-full`}>
                    {isLoggedIn() && <div className={`rounded-xl bg-white mb-[10px] border`}>
                        <div className={`p-[20px] flex justify-between items-center`}>
                            <div className={`flex gap-[10px] items-center`}>
                                <div
                                    className={`rounded-full bg-blue-500 w-[30px] h-[30px] flex justify-center items-center font-bold text-white`}>{(userCoupons??[]).length}</div>
                                <div
                                    className={`text-[25px] font-bold`}>{Translator.translateId('label__my_coupons_personal')}</div>
                            </div>
                            <div>
                                <PrimaryIconButton onClick={() => setCreateCouponModalVisible(true)}>+</PrimaryIconButton>
                            </div>
                        </div>
                        <div className={`px-[10px] pb-[10px] flex items-start flex-wrap gap-[10px]`}>
                            {userCoupons === undefined && <SpinnerIcon/>}
                            {userCoupons?.map((coupon: CouponInterface, index: number) =>
                                <Card key={`user-coupon-${index}`} coupon={coupon}
                                      className={`md:w-[calc((100%/2)-5px)] w-full`}
                                      showEditButton={true} showShareButton={true} showDeleteButton={true}
                                      loadCoupons={() => {
                                          loadUserCoupons(true);
                                          loadPublicCoupons();
                                      }}/>)}
                            {hasNextCoupons && <div className={`w-full mt-[10px] flex justify-center`}>
                                <PrimaryButton onClick={() => loadUserCoupons()}>{Translator.translateId('cta__load_more')}</PrimaryButton>
                            </div>}
                        </div>
                    </div>}
                    {!isLoggedIn() && <div className={`border rounded-xl bg-white p-[20px] text-center font-bold italic`}>
                        {Translator.translateId('label_should_log_in_for_coupon_feature')}
                    </div>}
                </div>
                {(publicCoupons.incoming.length > 0 || publicCoupons.permanent.length > 0 || publicCoupons.inProgress.length > 0) &&
                    <div className={`xl:w-[500px] w-full xl:block mt-[10px] xl:mt-0`}>
                        {publicCoupons.inProgress.length > 0 &&
                            <div className={`rounded-xl bg-white mb-[10px] border`}>
                                <div className={`p-[20px]`}>
                                    <div className={`flex gap-[10px] items-center`}>
                                        <div
                                            className={`rounded-full bg-blue-500 w-[30px] h-[30px] flex justify-center items-center font-bold text-white`}>{publicCoupons.inProgress.length}</div>
                                        <div
                                            className={`text-[25px] font-bold`}>{Translator.translateId('label__incoming_public_deals_in_progress')}</div>
                                    </div>
                                </div>
                                <div className={`px-[10px] pb-[10px] flex items-start flex-wrap gap-[10px]`}>
                                    {publicCoupons.inProgress.map((coupon: CouponInterface, index: number) =>
                                        <Card key={`public-inProgress-coupon-${index}`} coupon={coupon}
                                              className={`xl:w-full md:w-[calc((100%/2)-5px)] w-full`}/>)}
                                </div>
                            </div>}
                        {publicCoupons.incoming.length > 0 &&
                            <div className={`rounded-xl bg-white mb-[10px] border`}>
                                <div className={`p-[20px]`}>
                                    <div className={`flex gap-[10px] items-center`}>
                                        <div
                                            className={`rounded-full bg-blue-500 w-[30px] h-[30px] flex justify-center items-center font-bold text-white`}>{publicCoupons.incoming.length}</div>
                                        <div
                                            className={`text-[25px] font-bold`}>{Translator.translateId('label__incoming_public_deals_incoming')}</div>
                                    </div>
                                </div>
                                <div className={`px-[10px] pb-[10px] flex items-start flex-wrap gap-[10px]`}>
                                    {publicCoupons.incoming.map((coupon: CouponInterface, index: number) =>
                                        <Card key={`public-incoming-coupon-${index}`} coupon={coupon}
                                              className={`xl:w-full md:w-[calc((100%/2)-5px)] w-full`}/>)}
                                </div>
                            </div>
                        }
                        {publicCoupons.permanent.length > 0 &&
                            <div className={`rounded-xl bg-white mb-[10px] border`}>
                                <div className={`p-[20px]`}>
                                    <div className={`flex gap-[10px] items-center`}>
                                        <div
                                            className={`rounded-full bg-blue-500 w-[30px] h-[30px] flex justify-center items-center font-bold text-white`}>{publicCoupons.permanent.length}</div>
                                        <div
                                            className={`text-[25px] font-bold`}>{Translator.translateId('label__incoming_public_deals_permanent')}</div>
                                    </div>
                                </div>
                                <div className={`px-[10px] pb-[10px] flex items-start flex-wrap gap-[10px]`}>
                                    {publicCoupons.permanent.map((coupon: CouponInterface, index: number) =>
                                        <Card key={`public-incoming-coupon-${index}`} coupon={coupon}
                                              className={`xl:w-full md:w-[calc((100%/2)-5px)] w-full`}/>)}
                                </div>
                            </div>}
                    </div>
                }
            </div>
        </div>
    </PageTemplate>;
}
