import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_CARD_COMMENT, LABEL_BOARD_CARD_COMMENT_CREATE_FORM_ERROR,
    LABEL_BOARD_CARD_DESCRIPTION,
    LABEL_BOARD_CARD_NAME,
    LABEL_BOARD_CARD_UPDATE_FORM_ERROR,
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {TrashIcon} from "../../Icons/TrashIcon";
import {useEffect, useState} from "react";
import Textarea from "../Common/Input/Textarea";
import {Dropdown} from "../Common/Input/Dropdown";
import {BoardCardInterface} from "../../Interfaces/BoardCardInterface";
import {FormHook} from "../../Hooks/FormHook";
import {BoardRowInterface} from "../../Interfaces/BoardRowInterface";
import {BoardColumnInterface} from "../../Interfaces/BoardColumnInterface";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {BoardStoryPointInterface} from "../../Interfaces/BoardStoryPointInterface";
import {StoryPointTile} from "./StoryPointTile";
import {InputDateTime} from "../Common/Input/InputDateTime";
import {CheckRoundedIcon} from "../../Icons/CheckRoundedIcon";
import {DateTimeLabel} from "../Common/Date/DateTimeLabel";
import {BoardCardCommentInterface} from "../../Interfaces/BoardCardCommentInterface";
import {convertDateTime} from "../../Utils/Formatter";
import {NewApiManager} from "../../Utils/NewApiManager";
import {
    BOARD_CARD_COMMENTS,
    CREATE_BOARD_CARD_COMMENT,
    CREATE_BOARD_CARD_COMMENT_FORM_VALIDATOR, DELETE_BOARD_CARD, DELETE_BOARD_CARD_COMMENT,
    UPDATE_BOARD_CARD,
    UPDATE_BOARD_CARD_FORM_VALIDATOR
} from "../../Constants/ApiUrls";
import { localDateTimeToUtc, utcDateTimeToLocal } from '../../Utils/Date';
import { Modal } from '../Modal/Modal';

interface CardUpdateModalInterface {
    toggle: (card?: BoardCardInterface) => void;
    card: BoardCardInterface;
    board: BoardInterface;
    loadBoard: () => void;
}

export function CardUpdateModal({card, toggle, board, loadBoard}: CardUpdateModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitSuccess, submitError, submitLock} = FormHook(['name', 'description', 'boardRow', 'boardStoryPoint', 'boardColumn', 'dueDate']);
    const {form: formComment, shouldSubmit: shouldSubmitComment, handleChange: handleChangeComment, setValues: setCommentValues, loadConstrains: loadCommentConstrains, submitSuccess: commentSubmitSuccess, submitError: commentSubmitError, submitLock: commentSubmitLock} = FormHook(['comment']);

    const [isDeleting, setIsDeleting] = useState(false);
    const [comments, setComments] = useState<undefined|BoardCardCommentInterface[]>(undefined);
    const [deletingCommentId, setDeletingCommentId] = useState<string|undefined>(undefined);

    
    useEffect(() => {
        // @ts-ignore
        const dueDate = card.dueDate?.date.split('.')[0];

        setValues({
            name: card.name ?? '',
            description: card.description ?? '',
            boardStoryPoint: card.boardStoryPoint?.id ?? '',
            boardColumn: card.boardColumn?.id ?? '',
            boardRow: card.boardRow?.id ?? '',
            // @ts-ignore
            dueDate: dueDate === undefined ? '' : utcDateTimeToLocal(dueDate),
        });
        loadConstrains(UPDATE_BOARD_CARD_FORM_VALIDATOR);
        loadCommentConstrains(CREATE_BOARD_CARD_COMMENT_FORM_VALIDATOR);
        loadComments();
    }, []);

    const submitUpdateCard = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            UPDATE_BOARD_CARD(board.id, card.id),
            {
                name: form.fields.name.value,
                description: form.fields.description.value,
                boardStoryPoint: form.fields.boardStoryPoint.value,
                boardRow: form.fields.boardRow.value,
                boardColumn: form.fields.boardColumn.value,
                // @ts-ignore
                dueDate: form.fields.dueDate.value === '' ? '' : localDateTimeToUtc(form.fields.dueDate.value)
            },
            () => {
                submitSuccess();
                loadBoard();
            },
            () => submitError(LABEL_BOARD_CARD_UPDATE_FORM_ERROR)
        );
    }

    const onDelete = () => {
        NewApiManager.delete(
            DELETE_BOARD_CARD(board.id, card.id),
            () => {
                toggle();
                loadBoard();
            }
        )
    }

    const rows = board?.rows?.map((boardRow: BoardRowInterface) => { return {id: boardRow.id, label: boardRow.name} }) ?? [];
    const columns = board?.columns?.map((boardColumn: BoardColumnInterface) => { return {id: boardColumn.id, label: boardColumn.name} }) ?? [];
    const storyPoints = board.storyPoints?.map((boardStoryPoint: BoardStoryPointInterface) => { return {id: boardStoryPoint.id, label: <StoryPointTile className={`w-full`} points={boardStoryPoint.points} name={boardStoryPoint.name} textColor={boardStoryPoint.textColor} backgroundColor={boardStoryPoint.backgroundColor}/>} });

    const loadComments = () => {
        NewApiManager.get(
            BOARD_CARD_COMMENTS(board.id, card.id),
            (response: any) => setComments(response.data)
        );
    }

    const submitComment = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmitComment()) {
            return;
        }
        commentSubmitLock();
        NewApiManager.post(
            CREATE_BOARD_CARD_COMMENT(board.id, card.id),
            {
                comment: formComment.fields.comment.value
            },
            () => {
                commentSubmitSuccess();
                setCommentValues({comment: ''});
                loadComments();
            },
            () => commentSubmitError(LABEL_BOARD_CARD_COMMENT_CREATE_FORM_ERROR)
        );
    }

    const handleDeleteComment = () => {
        if (deletingCommentId === undefined) {
            return;
        }
        NewApiManager.delete(
            DELETE_BOARD_CARD_COMMENT(board.id, card.id, deletingCommentId),
            () => {
                setDeletingCommentId(undefined);
                loadComments();
            }
        );
    }

    return <Modal label={Translator.translateId('label__update_card')} toggle={toggle}>
        {card.completeDate !== null && <div className={`flex mb-[10px]`}>
            <div
                className={`border-[1px] rounded-full p-[5px] border-${theme.value}-primary-950 flex gap-[5px] items-center text-xs bg-green-200`}>
                <CheckRoundedIcon className={`w-[20px] h-[20px]`}/>
                <DateTimeLabel datetime={card.completeDate.date}/>
            </div>
        </div>}
        <form onSubmit={submitUpdateCard}>
            <div className={`md:flex md:gap-[10px]`}>
                <div className={`w-full`}>
                    <Input form={form} name={'name'} handleChange={handleChange}
                           placeholder={LABEL_BOARD_CARD_NAME}
                           className={`min-w-[250px]`}/>
                    <Textarea form={form} name={'description'}
                              handleChange={handleChange}
                              placeholder={LABEL_BOARD_CARD_DESCRIPTION}
                              className={`min-w-[250px] mt-[10px]`}
                              textareaClassName={`min-h-[200px]`}
                    />
                </div>
                <div className={`min-w-[200px] mt-[10px] md:mt-0`}>
                    <InputDateTime
                        form={form}
                        setValues={setValues}
                        name={'dueDate'}
                        label={Translator.translateId('label__board_card_due_date')}
                    />
                    {rows.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardRow'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_row_default')},
                            ...rows
                        ]}
                        setValues={setValues}
                    />}
                    {columns.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardColumn'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_column_backlog')},
                            ...columns
                        ]}
                        setValues={setValues}
                    />}
                    {storyPoints.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardStoryPoint'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_story_point_none')},
                            ...storyPoints
                        ]}
                        setValues={setValues}
                    />}
                    {!isDeleting && <div
                        className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}
                        onClick={() => setIsDeleting(true)}>
                        <TrashIcon
                            className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__delete')}
                    </div>}
                    {isDeleting && <div
                        className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}
                        onClick={onDelete}>
                        <TrashIcon
                            className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__confirm')}
                    </div>}
                </div>
            </div>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={() => toggle()}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
        </form>
        <div className={`border-t-[1px] border-${theme.value}-primary-950 my-[10px]`}></div>
        <form onSubmit={submitComment}>
            <Textarea form={formComment} name={'comment'} handleChange={handleChangeComment}
                      placeholder={LABEL_BOARD_CARD_COMMENT}/>
            <button type={'submit'}
                    className={`w-full border-[1px] mt-[10px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta_submit_comment')}</button>
        </form>
        {comments?.map((comment: BoardCardCommentInterface, index: number) => <div className={`mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded p-[5px]`} key={`comment-${index}`}>
            <div className={`bg-${theme.value}-primary-200 rounded p-[5px]`}>{comment.comment.split(/\n/).map(line => <div key={line} className={`min-h-[25px]`}>{line}</div>)}</div>

            <div className={`flex justify-between text-xs mt-[5px]`}>
                <div>
                    <span className={`flex gap-[5px] items-center`}>
                        <TrashIcon className={`w-[15px]`}/>
                        {deletingCommentId === comment.id ?
                            <span className={`cursor-pointer`} onClick={handleDeleteComment}>{Translator.translateId('cta__confirm')}</span>
                            : <span className={`cursor-pointer`} onClick={() => setDeletingCommentId(comment.id)}>{Translator.translateId('cta__delete')}</span>
                        }
                    </span>
                </div>
                <div>
                    {convertDateTime({date: utcDateTimeToLocal(comment.creationDate.date)})}
                </div>
            </div>
        </div>)}
    </Modal>;
}
