import {
    DragDropContext,
    Droppable as DroppableContext,
    Draggable as DraggableContext,
    DraggableProvided, DroppableProvided
} from 'react-beautiful-dnd';

export interface ValueInterface {
    id: string;
    [name: string]: any;
}

interface DragDropInterface {
    children: any;
    onDragEnd?: (dropResult: any) => void;
    onDragEndCallback?: (newOrder: ValueInterface[], draggedItem: any, dropResult: any) => void;
    values?: any[];
    onDragUpdate?: (result: any) => void;
}
export function DragDrop({children, values = [], onDragEnd, onDragEndCallback, onDragUpdate = (result: any) => {}}: DragDropInterface) {
    if (onDragEnd === undefined) {
        onDragEnd = (dropResult: any) => {
            const newOrder = [...values];
            const [draggedItem] = newOrder.splice(dropResult.source.index, 1);
            newOrder.splice(dropResult.destination.index, 0, draggedItem);

            if (onDragEndCallback !== undefined) {
                onDragEndCallback(newOrder, draggedItem, dropResult);
            }
        }
    }

    return <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
        {children}
    </DragDropContext>;
}

interface DropInterface {
    children: any;
    id: string;
    direction?: 'vertical'|'horizontal';
    className?: string;
    isDropDisabled?: boolean;
}
export function Drop({id, direction = 'vertical', className = '', children, isDropDisabled = false}: DropInterface) {
    let horizontalProperties = {};
    if (direction === 'horizontal') {
        horizontalProperties = {
            type: 'COLUMN',
            direction: 'horizontal',
        };
    }

    return <DroppableContext droppableId={id} {...horizontalProperties} isDropDisabled={isDropDisabled}>
        {(provided: DroppableProvided) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={className}
            >
                {children}
            </div>
        )}
    </DroppableContext>
}

interface DragInterface {
    children: any;
    id: string;
    index: number;
    className?: string;
}
export function Drag({children, id, index, className = ''}: DragInterface){
    return <DraggableContext draggableId={id} index={index}>
        {(provided: DraggableProvided) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={className}
            >
                {children}
            </div>
        )}
    </DraggableContext>;
}