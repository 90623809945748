import React from 'react';
import Router from "./Router";
import {useSignals} from "@preact/signals-react/runtime";
import {theme} from "./Signals/ThemeSignal";
import {LocalStorage} from "./Utils/LocalStorage";
import {locale} from "./Signals/LocaleSignal";
import NewYoutubePlayer from "./Components/YoutubePlayer/NewYoutubePlayer";
import {firebaseRealTimeDatabaseSignal} from "./Signals/FirebaseDatabaseSignal";
import ConnectionAlert from './Components/ConnectionAlert';
import VersionAlert from './Components/VersionAlert';

function App() {document.title = `Queube - Back-office`;
    document.title = `Spaceshare`;

    LocalStorage.init();
    useSignals();

    // /!\ Do not remove the following line, it is needed for component re-rendering after locale changed
    const currentLocale = locale.value;
    const currentFirebaseDatabase = firebaseRealTimeDatabaseSignal.value;

    if (window.location.pathname === '/karaoke') {
        return <Router/>;
    }

    return (
        <div className={`text-${theme.value}-primary-950 bg-${theme.value}-primary-50 min-h-[100dvh]`}>
            <div className={`
            bg-light-primary-50 bg-light-primary-100 bg-light-primary-200 bg-light-primary-300 bg-light-primary-400 bg-light-primary-500 bg-light-primary-600 bg-light-primary-700 bg-light-primary-800 bg-light-primary-900 bg-light-primary-950
            hover:bg-light-primary-50 hover:bg-light-primary-100 hover:bg-light-primary-200 hover:bg-light-primary-300 hover:bg-light-primary-400 hover:bg-light-primary-500 hover:bg-light-primary-600 hover:bg-light-primary-700 hover:bg-light-primary-800 hover:bg-light-primary-900 hover:bg-light-primary-950
            text-light-primary-50 text-light-primary-100 text-light-primary-200 text-light-primary-300 text-light-primary-400 text-light-primary-500 text-light-primary-600 text-light-primary-700 text-light-primary-800 text-light-primary-900 text-light-primary-950
            border-light-primary-50 border-light-primary-100 border-light-primary-200 border-light-primary-300 border-light-primary-400 border-light-primary-500 border-light-primary-600 border-light-primary-700 border-light-primary-800 border-light-primary-900 border-light-primary-950
            fill-light-primary-50 fill-light-primary-100 fill-light-primary-200 fill-light-primary-300 fill-light-primary-400 fill-light-primary-500 fill-light-primary-600 fill-light-primary-700 fill-light-primary-800 fill-light-primary-900 fill-light-primary-950
            stroke-light-primary-50 stroke-light-primary-100 stroke-light-primary-200 stroke-light-primary-300 stroke-light-primary-400 stroke-light-primary-500 stroke-light-primary-600 stroke-light-primary-700 stroke-light-primary-800 stroke-light-primary-900 stroke-light-primary-950
            bg-dark-primary-50 bg-dark-primary-100 bg-dark-primary-200 bg-dark-primary-300 bg-dark-primary-400 bg-dark-primary-500 bg-dark-primary-600 bg-dark-primary-700 bg-dark-primary-800 bg-dark-primary-900 bg-dark-primary-950
            hover:bg-dark-primary-50 hover:bg-dark-primary-100 hover:bg-dark-primary-200 hover:bg-dark-primary-300 hover:bg-dark-primary-400 hover:bg-dark-primary-500 hover:bg-dark-primary-600 hover:bg-dark-primary-700 hover:bg-dark-primary-800 hover:bg-dark-primary-900 hover:bg-dark-primary-950
            text-dark-primary-50 text-dark-primary-100 text-dark-primary-200 text-dark-primary-300 text-dark-primary-400 text-dark-primary-500 text-dark-primary-600 text-dark-primary-700 text-dark-primary-800 text-dark-primary-900 text-dark-primary-950
            border-dark-primary-50 border-dark-primary-100 border-dark-primary-200 border-dark-primary-300 border-dark-primary-400 border-dark-primary-500 border-dark-primary-600 border-dark-primary-700 border-dark-primary-800 border-dark-primary-900 border-dark-primary-950
            fill-dark-primary-50 fill-dark-primary-100 fill-dark-primary-200 fill-dark-primary-300 fill-dark-primary-400 fill-dark-primary-500 fill-dark-primary-600 fill-dark-primary-700 fill-dark-primary-800 fill-dark-primary-900 fill-dark-primary-950
            stroke-dark-primary-50 stroke-dark-primary-100 stroke-dark-primary-200 stroke-dark-primary-300 stroke-dark-primary-400 stroke-dark-primary-500 stroke-dark-primary-600 stroke-dark-primary-700 stroke-dark-primary-800 stroke-dark-primary-900 stroke-dark-primary-950
            min-[1720px]:border-light-primary-950 min-[1720px]:border-dark-primary-950
            `}/>
            <Router/>
            <NewYoutubePlayer/>
            <ConnectionAlert/>
            <VersionAlert/>
        </div>
    );
}

export default App;
