import {signal} from "@preact/signals-react";

export const connectionAlertStatus = signal<string|undefined>(undefined);
let hideAlertTimeOut: any = undefined;

export function connectionInterrupted() {
    connectionAlertStatus.value = 'interrupted';
    clearTimeout(hideAlertTimeOut);
}

export function connectionRestored() {
    connectionAlertStatus.value = 'restored';

    clearTimeout(hideAlertTimeOut);
    hideAlertTimeOut = setTimeout(() => {
        connectionAlertStatus.value = undefined;
    }, 1000);
}