import { PostBasicInterface } from '../../Interfaces/PostBasicInterface';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { DateTimeLabel } from '../Common/Date/DateTimeLabel';
import { LikeIcon } from '../../Icons/LikeIcon';
import Translator from '../../Utils/Translator';
import { ShareIcon } from '../../Icons/ShareIcon';
import {Card as CouponCard} from '../Coupon/Card';
import { PostCouponInterface } from '../../Interfaces/PostCouponInterface';
import { TextareaContent } from '../Common/Content/TextareaContent';
import { ExclamationTriangledIcon } from '../../Icons/ExclamationTriangledIcon';
import { NewApiManager } from '../../Utils/NewApiManager';
import { DELETE_POST_LIKE, POST_LIKE } from '../../Constants/ApiUrls';
import { isLoggedIn, userAccount } from '../../Signals/UserAccountSignal';
import { useState } from 'react';
import { CommentModal } from './CommentModal';
import { utcDateTimeToLocal } from '../../Utils/Date';
import { postsRefs } from '../../FirebaseRefs/Posts';

interface CardInterface {
    post: PostBasicInterface|PostCouponInterface;
    className?: string;
    showFakeCommentBlock?: boolean;
    posts: PostBasicInterface[]|PostCouponInterface[];
    setPosts: (value: PostBasicInterface[]|PostCouponInterface[]) => void;
}

export function Card({post, className = '', showFakeCommentBlock = false, posts, setPosts}: CardInterface) {
    const [commentModalIsOpened, setCommentModalIsOpened] = useState(false);

    const toggleCommentModal = () => {
        setCommentModalIsOpened(() => !commentModalIsOpened);
    }

    const sharedLabel = () => {
        switch (post.postType) {
            case 'post_coupon':
                return Translator.translateId(`label__shared_${post.postType}`);
            break;
        }

        return '';
    }

    const likeDislikeThisPost = () => {
        if (!isLoggedIn()) {
            return;
        }

        postsRefs(posts, setPosts);

        if (!post.userLiked) {
            NewApiManager.post(POST_LIKE(post.id), {likeType: 'thumbsup'});
            return;
        }
        NewApiManager.delete(DELETE_POST_LIKE(post.id));
    };

    return <>
        {commentModalIsOpened && <CommentModal post={post} toggle={toggleCommentModal} setPosts={setPosts} posts={posts}/>}
        <div className={`mt-[10px] ${className}`}>
            <div className={`flex gap-[10px] items-center`}>
                <div className={`rounded-full w-[50px] h-[50px] bg-gray-200 flex justify-center items-center`}>
                    {userAccount.value !== undefined && userAccount.value.profileImage !== null ?
                        <img src={userAccount.value.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                        <ProfileIcon className={`w-[20px] h-[20px] fill-black`}/>}
                </div>
                <div>
                    <div className={`flex gap-[5px] items-end`}>
                        <div className={`font-bold text-[20px]`}>{post.userAccount.login}</div>
                        <div className={`text-gray-500 italic`}>{sharedLabel()}</div>
                    </div>
                    <div className={`text-gray-400`}><DateTimeLabel datetime={utcDateTimeToLocal(post.creationDate.date)}/></div>
                </div>
            </div>
            <div className={`my-[20px]`}>
                {post.postType === 'post_basic' && <PostBasic post={post}/>}
                {/* @ts-ignore */}
                {post.postType === 'post_coupon' && <PostCoupon post={post}/>}
            </div>
            <div className={`mt-[10px] flex items-center justify-between gap-[10px]`}>
                <div className={`flex gap-[5px] items-center`}>
                    <div className={`rounded p-[10px] flex justify-center items-center ${!post.userLiked ? `bg-gray-200` : 'bg-blue-500'} cursor-pointer`} onClick={likeDislikeThisPost}>
                        <LikeIcon className={`w-[15px] ${!post.userLiked ? 'fill-blue-500' : `fill-white`}`}/>
                    </div>
                    {post.likesCounter > 1 && Translator.translateId('label__post_like_counter_plural', undefined, {counter: post.likesCounter})}
                    {post.likesCounter === 1 && Translator.translateId('label__post_like_counter', undefined, {counter: post.likesCounter})}
                </div>
                <div className={`flex gap-[10px] items-center`}>
                    <div className={`text-[15px] text-gray-500 underline cursor-pointer`} onClick={() => setCommentModalIsOpened(true)}>
                        {post.commentsCounter === 0 && Translator.translateId('label__no_comment')}
                        {post.commentsCounter === 1 && Translator.translateId('label__comments_counter')}
                        {post.commentsCounter > 1 && Translator.translateId('label__comments_counter_plural', undefined, {counter: post.commentsCounter})}
                    </div>
                    <div>
                        <ShareIcon className={`w-[20px]`}/>
                    </div>
                </div>
            </div>
            {isLoggedIn() && showFakeCommentBlock && <div className={`mt-[10px] border-t pt-[10px]`}>
                <div className={`flex gap-[10px] items-start`}>
                    <div className={`rounded-full w-[50px] h-[50px] bg-gray-200 flex justify-center items-center`}>
                        {userAccount.value !== undefined && userAccount.value.profileImage !== null ?
                            <img src={userAccount.value.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                            <ProfileIcon className={`w-[20px] h-[20px] fill-black`}/>}
                    </div>
                    <div className={`w-full`}>
                        <div className={`font-bold`}>{post.userAccount.login}</div>
                        <textarea onClick={toggleCommentModal} className={`w-full border rounded p-[10px]`} placeholder={Translator.translateId('label__write_comment')}/>
                    </div>
                </div>
            </div>}
        </div>
    </>;
}

function PostBasic({post}: { post: PostBasicInterface }) {
    return <>
        <TextareaContent content={post.content}/>
    </>;
}

function PostCoupon({post}: { post: PostCouponInterface }) {
    return <div>
        {post.coupon !== null ? <div className={`flex`}>
            <CouponCard coupon={post.coupon} className={`border p-[10px] bg-gray-100 xl:w-[50%] max-w-[500px] w-full`}/>
        </div> : <div className={`flex`}>
            <div
                className={`border-red-500 border p-[10px] bg-red-100 rounded-xl font-bold flex gap-[10px] items-center`}>
                <ExclamationTriangledIcon className={`w-[20px]`}/>
                <div>{Translator.translateId('label__coupon_deleted')}</div>
            </div>
        </div>}
        {post.content.length > 0 && <div className={`mt-[10px]`}>
            <TextareaContent content={post.content}/>
        </div>}
    </div>;
}
