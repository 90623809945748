import PageTemplate from "../Components/PageTemplate/PageTemplate";
import Translator from "../Utils/Translator";
import {theme} from "../Signals/ThemeSignal";
import {useEffect} from "react";
import {isLoggedIn} from "../Signals/UserAccountSignal";

export function WebsiteExtensionAutoLogIn() {
    useEffect(() => {
        setTimeout(() => {
            document.location.href = '/';
        }, 2000);
    }, []);

    return <PageTemplate>
        <div className={`w-full flex items-center p-[10px] h-[calc(100vh-130px)]`}>
            <div
                className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div
                        className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__after_log_in')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    {Translator.translateId('label__after_log_in_description')}
                </div>
            </div>
        </div>
    </PageTemplate>;
}