import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormHook} from "../Hooks/FormHook";
import {
    CTA_SUBMIT,
    LABEL_CONFIGURE_PASSWORD_FORM_ERROR, LABEL_CONFIGURE_PASSWORD_FORM_SUCCESS, LABEL_EMAIL,
    LABEL_PASSWORD
} from "../Constants/Label";
import {useEffect} from "react";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";
import Input from "../Components/Common/Input/Input";
import {USER_CONFIGURE_PASSWORD, USER_CONFIGURE_PASSWORD_FORM_VALIDATOR} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function ConfigurePassword() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const {form, handleChange, shouldSubmit, loadConstrains, submitSuccess, submitError, submitLock} = FormHook(['email', 'password', 'resetPassword'], {email: params.get('email')});
    //
    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            USER_CONFIGURE_PASSWORD,
            {
                email: form.fields.email.value,
                password: form.fields.password.value,
                temporaryPassword: params.get('resetPassword')
            },
            () => {
                submitSuccess(LABEL_CONFIGURE_PASSWORD_FORM_SUCCESS);
                setTimeout(() => {
                    navigate('/log-in');
                }, 2000);
            },
            () => submitError(LABEL_CONFIGURE_PASSWORD_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(USER_CONFIGURE_PASSWORD_FORM_VALIDATOR);
    }, []);

    return <PageTemplate childrenClassName={`min-h-[700px]`} sidebar={"normal"}>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__configure_password')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    <form onSubmit={onSubmit}>
                        <Input className={`mt-[10px]`} form={form} name={'email'} handleChange={handleChange} placeholder={LABEL_EMAIL}/>
                        <Input className={`mt-[10px]`} type={"password"} form={form} name={'password'} handleChange={handleChange} placeholder={LABEL_PASSWORD}/>
                        {form.error !== undefined && <div className={`mt-[10px] bg-red-200 border-[1px] border-red-300 text-red-700 rounded p-[10px]`}>{Translator.translate(form.error)}</div>}
                        {form.success !== undefined && <div className={`mt-[10px] bg-green-200 border-[1px] border-green-300 text-green-700 rounded p-[10px]`}>{Translator.translate(form.success)}</div>}
                        <div className={"flex justify-center mt-[20px]"}>
                            <button type={"submit"} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded`}>
                                {Translator.translate(CTA_SUBMIT)}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </PageTemplate>;
}
