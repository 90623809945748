import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_CARD_CREATE_FORM_ERROR,
    LABEL_BOARD_CARD_DESCRIPTION,
    LABEL_BOARD_CARD_NAME,
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import Textarea from "../Common/Input/Textarea";
import {Dropdown} from "../Common/Input/Dropdown";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {FormHook} from "../../Hooks/FormHook";
import {useEffect} from "react";
import {BoardRowInterface} from "../../Interfaces/BoardRowInterface";
import {BoardColumnInterface} from "../../Interfaces/BoardColumnInterface";
import {BoardStoryPointInterface} from "../../Interfaces/BoardStoryPointInterface";
import {StoryPointTile} from "./StoryPointTile";
import {InputDateTime} from "../Common/Input/InputDateTime";
import {CREATE_BOARD_CARD, CREATE_BOARD_CARD_FORM_VALIDATOR} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { localDateTimeToUtc } from '../../Utils/Date';
import { Modal } from '../Modal/Modal';

interface CardCreationModalInterface {
    toggle: () => void;
    board: BoardInterface;
    loadBoard: () => void;
    row?: BoardRowInterface;
}

export function CardCreationModal({toggle, board, loadBoard, row = undefined}: CardCreationModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitError, submitLock} = FormHook(['name', 'description', 'boardRow', 'boardStoryPoint', 'boardColumn', 'dueDate']);

    useEffect(() => {
        setValues({
            boardRow: row !== undefined ? row.id : '',
        });
        loadConstrains(CREATE_BOARD_CARD_FORM_VALIDATOR);
    }, []);

    const submitCreateCard = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.post(
            CREATE_BOARD_CARD(board.id),
            {
                name: form.fields.name.value,
                description: form.fields.description.value,
                boardStoryPoint: form.fields.boardStoryPoint.value,
                boardRow: form.fields.boardRow.value,
                boardColumn: form.fields.boardColumn.value,
                // @ts-ignore
                dueDate: form.fields.dueDate.value === '' ? '' : localDateTimeToUtc(form.fields.dueDate.value)
            },
            () => {
                toggle();
                loadBoard();
            },
            () => submitError(LABEL_BOARD_CARD_CREATE_FORM_ERROR)
        );
    }

    const rows = board.rows?.map((boardRow: BoardRowInterface) => { return {id: boardRow.id, label: boardRow.name} }) ?? [];
    const columns = board.columns?.map((boardColumn: BoardColumnInterface) => { return {id: boardColumn.id, label: boardColumn.name} }) ?? [];
    const storyPoints = board.storyPoints?.map((boardStoryPoint: BoardStoryPointInterface) => { return {id: boardStoryPoint.id, label: <StoryPointTile className={`w-full`} points={boardStoryPoint.points} name={boardStoryPoint.name} textColor={boardStoryPoint.textColor} backgroundColor={boardStoryPoint.backgroundColor}/>} });

    return <Modal toggle={toggle} label={Translator.translateId('label__new_card')}>
        <form onSubmit={submitCreateCard}>
            <div className={`md:flex md:gap-[10px]`}>
                <div className={`w-full`}>
                    <Input form={form} name={'name'} handleChange={handleChange}
                           placeholder={LABEL_BOARD_CARD_NAME}
                           className={`min-w-[250px]`}/>
                    <Textarea form={form} name={'description'}
                              handleChange={handleChange}
                              placeholder={LABEL_BOARD_CARD_DESCRIPTION}
                              className={`min-w-[250px] mt-[10px] border-black border`}
                              textareaClassName={`min-h-[200px]`}
                    />
                </div>
                <div className={`min-w-[200px] mt-[10px] md:mt-0`}>
                    <InputDateTime
                        form={form}
                        setValues={setValues}
                        name={'dueDate'}
                        label={Translator.translateId('label__board_card_due_date')}
                    />
                    {rows.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardRow'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_row_default')},
                            ...rows
                        ]}
                        setValues={setValues}
                    />}
                    {columns.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardColumn'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_column_backlog')},
                            ...columns
                        ]}
                        setValues={setValues}
                    />}
                    {storyPoints.length > 0 && <Dropdown
                        className={`mt-[10px]`}
                        form={form}
                        name={'boardStoryPoint'}
                        values={[
                            {id: '', label: Translator.translateId('label__board_story_point_none')},
                            ...storyPoints
                        ]}
                        setValues={setValues}
                    />}
                </div>
            </div>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={() => toggle()}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
        </form>
    </Modal>;
}
