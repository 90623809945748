import {DateTimeInterface} from "../Interfaces/DateTimeInterface";
import moment from "moment-timezone";
import { serverDateTime } from '../Signals/ServerDateTime';

export function isSameDate(datetime1: DateTimeInterface|null, datetime2: DateTimeInterface|null) {
    if (datetime1 === null || datetime2 === null) {
        return false;
    }

    const date1 = moment(datetime1.date).format('DD-MM-YY');
    const date2 = moment(datetime2.date).format('DD-MM-YY');

    return date1 === date2;
}

export function isSameDateHour(datetime1: DateTimeInterface|null, datetime2: DateTimeInterface|null) {
    if (datetime1 === null || datetime2 === null) {
        return false;
    }

    const date1 = moment(datetime1.date).format('DD-MM-YY HH');
    const date2 = moment(datetime2.date).format('DD-MM-YY HH');

    return date1 === date2;
}

export function dayMonth(date: string) {
    return moment(date).format('DD/MM');
}

export function utcDateTimeToLocal(utcDateTime: string) {
    return moment.utc(utcDateTime).tz(moment.tz.guess()).format('Y-MM-DD HH:mm:ss');
}

export function localDateTimeToUtc(localDateTime: string) {
    return moment(localDateTime).tz('UTC').format('Y-MM-DD HH:mm:ss');
}

export function serverDateTimeToCustom(customTimezone: string) {
    return moment.utc(`${serverDateTime.value}.000000`).tz(customTimezone).format('Y-MM-DD HH:mm:ss');
}

export function serverDateTimeToLocal(customTimezone: string) {
    return moment.utc(`${serverDateTime.value}.000000`).tz(moment.tz.guess()).format('Y-MM-DD HH:mm:ss');
}