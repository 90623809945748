import {Link} from "react-router-dom";
import Translator from "../../Utils/Translator";
import {NewsIcon} from "../../Icons/NewsIcon";
import {DealIcon} from "../../Icons/DealIcon";
import {theme} from "../../Signals/ThemeSignal";
import {MonitorYoutubeIcon} from "../../Icons/MonitorYoutubeIcon";
import {isLoggedIn} from "../../Signals/UserAccountSignal";
import {ActivityIcon} from "../../Icons/ActivityIcon";
import {HomeIcon} from "../../Icons/HomeIcon";
import { CloudIcon } from '../../Icons/CloudIcon';
import { ProfileIcon } from '../../Icons/ProfileIcon';

export function Sidebar() {
    return <div className={`p-[10px] text-xl`}>
        <Link to={`/`} className={`flex gap-[10px] mt-[10px] mb-[10px] items-center`}>
            <HomeIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__home')}
        </Link>
        <Link to={`/weather`} className={`flex gap-[10px] py-[10px] items-center`}>
            <CloudIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__weather')}
        </Link>
        <Link to={`/youtube`} className={`flex gap-[10px] py-[10px] items-center`}>
            <MonitorYoutubeIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__youtube')}
        </Link>
        {/*<Link to={`/news`} className={`flex gap-[10px] py-[10px] items-center`}>*/}
        {/*    <NewsIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>*/}
        {/*    {Translator.translateId('cta__news')}*/}
        {/*</Link>*/}
        <Link to={`/coupons`} className={`flex gap-[10px] py-[10px] items-center`}>
            <DealIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__coupons')}
        </Link>
        <Link to={`/activities`} className={`flex gap-[10px] py-[10px] items-center`}>
            <ActivityIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__activities')}
        </Link>
    </div>;
}
interface HeaderLinksInterface {
    toggleMobileMenu: () => void;
}
export function HeaderLinks({toggleMobileMenu}:HeaderLinksInterface) {
    return <>
        <Link to={"/"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <HomeIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/> {Translator.translateId('cta__home')}
            </div>
        </Link>
        <Link to={"/weather"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <CloudIcon className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__weather')}
            </div>
        </Link>
        {isLoggedIn() && <Link to={"/users/me"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <ProfileIcon className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__profile')}
            </div>
        </Link>}
        <Link to={"/youtube"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <MonitorYoutubeIcon className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__youtube')}
            </div>
        </Link>
        {/*<Link to={"/news"} className={`block lg:hidden`}>*/}
        {/*    <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>*/}
        {/*        {Translator.translateId('cta__news')}*/}
        {/*    </div>*/}
        {/*</Link>*/}
        <Link to={"/coupons"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <DealIcon className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__coupons')}
            </div>
        </Link>
        <Link to={"/activities"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded flex gap-[10px] items-center`}>
                <ActivityIcon className={`w-[30px] fill-${theme.value}-primary-950`}/> {Translator.translateId('cta__activities')}
            </div>
        </Link>
    </>;
}