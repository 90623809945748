import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_COUPON_CREATION_FORM_ERROR,
    LABEL_COUPON_CREATION_FORM_SUCCESS,
    LABEL_COUPON_DESCRIPTION,
    LABEL_COUPON_VALUE
} from "../Constants/Label";
import {useNavigate, useParams} from "react-router-dom";
import {
    USER_CREATE_COUPON,
    USER_CREATE_COUPON_FORM_VALIDATOR
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { InputDateTime } from '../Components/Common/Input/InputDateTime';
import { localDateTimeToUtc } from '../Utils/Date';

export function CouponCreate() {
    const navigate = useNavigate();

    const {shouldSubmit, form, setValues, handleChange, loadConstrains, submitLock, submitError} = FormHook(['description', 'couponValue', 'startDate', 'endDate']);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            USER_CREATE_COUPON,
            {
                description: form.fields.description.value,
                couponValue: form.fields.couponValue.value,
                // @ts-ignore
                startDate: form.fields.startDate.value === '' ? '' : localDateTimeToUtc(form.fields.startDate.value),
                // @ts-ignore
                endDate: form.fields.endDate.value === '' ? '' : localDateTimeToUtc(form.fields.endDate.value)
            },
            (response: any) => {
                navigate(`/coupons/${response.data.id}/update`)
            },
            () => submitError(LABEL_COUPON_CREATION_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(USER_CREATE_COUPON_FORM_VALIDATOR);
    }, []);

    return <PageTemplate sidebar={"normal"}>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/coupons'} label={'label__coupons'}/>
            </div>
            <div className={`mt-[10px]`}>
                <form onSubmit={onSubmit}>
                    <Input form={form} name={'description'} handleChange={handleChange}
                           placeholder={LABEL_COUPON_DESCRIPTION}/>
                    <Input className={`mt-[10px]`} form={form} name={'couponValue'} handleChange={handleChange}
                           placeholder={LABEL_COUPON_VALUE}/>
                    <div className={`flex`}>
                        <InputDateTime className={`mt-[10px]`} form={form} label={Translator.translateId('label__coupon_creation_start_date')} name={'startDate'} setValues={setValues}/>
                    </div>
                    <div className={`flex`}>
                        <InputDateTime className={`mt-[10px]`} form={form} label={Translator.translateId('label__coupon_creation_end_date')} name={'endDate'} setValues={setValues}/>
                    </div>
                    {form.error !== undefined && <div className={`pt-[10px] text-red-600`}>
                        {Translator.translateId(form.error.id)}
                    </div>}
                    {form.success !== undefined && <div className={`pt-[10px] text-green-600`}>
                        {Translator.translateId(form.success.id)}
                    </div>}
                    <div className={`flex justify-end py-[10px]`}>
                        <button type={'submit'}
                                className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                    </div>
                </form>
            </div>

        </div>
    </PageTemplate>;
}
