import { FormHook } from '../../Hooks/FormHook';
import Translator from '../../Utils/Translator';
import Input from '../Common/Input/Input';
import TextArea from '../Common/Input/Textarea';
import { LABEL_LEAVE_COMMENT, LABEL_LINK_URL, LABEL_PROBLEM_DESCRIPTION, LABEL_TITLE } from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { StarIcon } from '../../Icons/StarIcon';
import { useEffect, useState } from 'react';
import {
    BUG_REPORT_FORM_VALIDATOR,
    POST_BUG_REPORT,
    POST_RATE_SPACESHARE,
    RATING_FORM_VALIDATOR
} from '../../Constants/ApiUrls';
import { NewApiManager } from '../../Utils/NewApiManager';

export function ContactBugReportForm() {
    const {form, setForm, handleChange, setValues, loadConstrains, submitLock, shouldSubmit} = FormHook(['url', 'title', 'message'], {url: document.location.href});

    useEffect(() => {
        loadConstrains(BUG_REPORT_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (shouldSubmit()) {
            submitLock();

            NewApiManager.post(
                POST_BUG_REPORT,
                {
                    url: form.fields.url.value,
                    title: form.fields.title.value,
                    message: form.fields.message.value,
                },
                (response: any) => {
                    setForm({
                        ...form,
                        success: '',
                        error: undefined,
                        isLoading: false,
                    });
                },
                () => {
                    setForm({
                        ...form,
                        success: undefined,
                        error: '',
                        isLoading: false,
                    });
                }
            )
        }
    };

    if (form.success !== undefined) {
        return <div>
            {Translator.translateId('label__thank_for_reporting_a_bug')}
        </div>;
    }

    return <form onSubmit={onSubmit}>
        <Input className={`mt-[10px]`} form={form} name={'url'} handleChange={handleChange} placeholder={LABEL_LINK_URL}/>
        <Input className={`mt-[10px]`} form={form} name={'title'} handleChange={handleChange} placeholder={LABEL_TITLE}/>
        <TextArea form={form} name={'message'} handleChange={handleChange} className={`mt-[10px] border border-black`} placeholder={LABEL_PROBLEM_DESCRIPTION}/>
        <PrimaryButton className={`mt-[10px]`} onClick={onSubmit}>
            {Translator.translateId('cta__submit')}
        </PrimaryButton>
    </form>;
}
