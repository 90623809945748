import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_STORY_POINT_BACKGROUND_COLOR,
    LABEL_BOARD_STORY_POINT_CREATE_FORM_ERROR,
    LABEL_BOARD_STORY_POINT_NAME,
    LABEL_BOARD_STORY_POINT_POINTS,
    LABEL_BOARD_STORY_POINT_TEXT_COLOR
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {TrashIcon} from "../../Icons/TrashIcon";
import {useEffect, useState} from "react";
import {BoardStoryPointInterface} from "../../Interfaces/BoardStoryPointInterface";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {FormHook} from "../../Hooks/FormHook";
import {Dropdown} from "../Common/Input/Dropdown";
import {StoryPointTile} from "./StoryPointTile";
import {EditIcon} from "../../Icons/EditIcon";
import {NewApiManager} from "../../Utils/NewApiManager";
import {
    CREATE_BOARD_STORY_POINT,
    CREATE_BOARD_STORY_POINT_FORM_VALIDATOR, DELETE_BOARD_STORY_POINT, UPDATE_BOARD_STORY_POINT,
    UPDATE_BOARD_STORY_POINT_FORM_VALIDATOR
} from "../../Constants/ApiUrls";
import { Modal } from '../Modal/Modal';

interface StoryPointsManageModalInterface {
    toggle: () => void;
    board: BoardInterface;
    loadBoard: () => void;
}

export function StoryPointsManagementModal({toggle, board, loadBoard}: StoryPointsManageModalInterface) {
    const {form: createForm, shouldSubmit: createShouldSubmit, handleChange: createHandleChange, setValues: createSetValues, loadConstrains: loadCreateConstrains, submitLock: createSubmitLock, submitError: createSubmitError, submitSuccess: createSubmitSuccess} = FormHook(['name', 'storyType', 'points', 'backgroundColor', 'textColor']);
    const {form: updateForm, shouldSubmit: updateShouldSubmit, handleChange: updateHandleChange, setValues: updateSetValues, loadConstrains: loadUpdateConstrains, submitLock: updateSubmitLock, submitError: updateSubmitError, submitSuccess: updateSubmitSuccess} = FormHook(['name', 'storyType', 'points', 'backgroundColor', 'textColor']);

    const [isCreating, setIsCreating] = useState(false);
    const [updatingStoryPoint, setUpdatingStoryPoint] = useState<BoardStoryPointInterface|undefined>(undefined);
    const [deletingStoryPoint, setDeletingStoryPoint] = useState<BoardStoryPointInterface|undefined>(undefined);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string|undefined>(undefined);

    useEffect(() => {
        loadCreateConstrains(CREATE_BOARD_STORY_POINT_FORM_VALIDATOR);
        loadUpdateConstrains(UPDATE_BOARD_STORY_POINT_FORM_VALIDATOR);
    }, []);

    const onSubmitCreate = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!createShouldSubmit()) {
            return;
        }
        createSubmitLock();
        NewApiManager.post(
            CREATE_BOARD_STORY_POINT(board.id),
            {
                name: createForm.fields.name.value,
                storyType: createForm.fields.storyType.value,
                points: createForm.fields.points.value,
                backgroundColor: createForm.fields.backgroundColor.value,
                textColor: createForm.fields.textColor.value
            },
            () => {
                createSubmitSuccess();
                setIsCreating(false);
                loadBoard();
            },
            () => createSubmitError(LABEL_BOARD_STORY_POINT_CREATE_FORM_ERROR)
        );
    }

    const onSubmitUpdate = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (updatingStoryPoint === undefined || !updateShouldSubmit()) {
            return;
        }
        updateSubmitLock();
        NewApiManager.put(
            UPDATE_BOARD_STORY_POINT(board.id, updatingStoryPoint.id),
            {
                name: updateForm.fields.name.value,
                storyType: updateForm.fields.storyType.value,
                points: updateForm.fields.points.value,
                backgroundColor: updateForm.fields.backgroundColor.value,
                textColor: updateForm.fields.textColor.value
            },
            () => {
                updateSubmitSuccess();
                setUpdatingStoryPoint(undefined);
                loadBoard();
            },
            () => updateSubmitError(LABEL_BOARD_STORY_POINT_CREATE_FORM_ERROR)
        );
    }

    const setUpdate = (storyPoint: BoardStoryPointInterface) => {
        setUpdatingStoryPoint(storyPoint);
        updateSetValues({
            name: storyPoint.name,
            points: storyPoint.points.toString(),
            storyType: storyPoint.storyType,
            textColor: storyPoint.textColor,
            backgroundColor: storyPoint.backgroundColor
        });
    }

    const setCreate = () => {
        createSetValues({
            name: '',
            points: '',
            storyType: '',
            textColor: '',
            backgroundColor: ''
        });
        setIsCreating(true);
    }

    const onDelete = () => {
        if (deletingStoryPoint === undefined) {
            return;
        }

        NewApiManager.delete(
            DELETE_BOARD_STORY_POINT(board.id, deletingStoryPoint.id),
            () => {
                setDeletingStoryPoint(undefined);
                loadBoard();
            },
            (response: any) => {
                if (['story_point_not_empty'].includes(response.data)) {
                    setDeleteErrorMessage(`label__${response.data}`);
                }
            }
        );
    }

    const setDeleting = (storyPoint: BoardStoryPointInterface) => {
        setDeletingStoryPoint(storyPoint);
        setDeleteErrorMessage(undefined);
    }

    const storyTypes = [
        {id: '', label: Translator.translateId('label__choose_story_type')},
        {id: 'effort', label: Translator.translateId('label__story_type_effort')},
        {id: 'comfort', label: Translator.translateId('label__story_type_comfort')},
    ];

    return <Modal toggle={toggle} label={Translator.translateId('label__storypoints')}>
        {!isCreating && updatingStoryPoint === undefined && <div>
            <div>
                {Translator.translateId('label__storypoints_description')}
            </div>
            {board.storyPoints.map((storyPoint: BoardStoryPointInterface) => <div
                key={`storypoint-${storyPoint.id}`} className={`flex justify-center`}>
                <div className={`flex items-center mt-[10px] gap-[10px]`}>
                    <div className={`min-w-[150px] w-[150px] max-w-[150px]`}>
                        <StoryPointTile {...storyPoint} className={`w-full`}/>
                    </div>
                    <div className={`w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded`}>
                        {storyPoint.points}
                    </div>
                    <div className={`flex gap-[5px]`}>
                        <div
                            className={`border-[1px] px-[5px] rounded border-${theme.value}-primary-950 cursor-pointer flex justify-center items-center`}
                            onClick={() => setUpdate(storyPoint)}>
                            <EditIcon className={`w-[20px]`}/>
                        </div>
                        {deletingStoryPoint !== storyPoint && <div
                            className={`border-[1px] px-[5px] rounded border-${theme.value}-primary-950 cursor-pointer flex justify-center items-center`}
                            onClick={() => setDeleting(storyPoint)}>
                            <TrashIcon className={`w-[20px]`}/>
                        </div>}
                        {deletingStoryPoint !== undefined && deletingStoryPoint === storyPoint && <div
                            className={`border-[1px] px-[5px] rounded border-${theme.value}-primary-950 cursor-pointer flex justify-center items-center gap-[5px]`}
                            onClick={onDelete}>
                            <TrashIcon className={`w-[20px]`}/> {Translator.translateId(`cta__confirm`)}
                        </div>}
                    </div>
                </div>
                <div>
                    {deletingStoryPoint !== undefined && deletingStoryPoint === storyPoint && deleteErrorMessage !== undefined &&
                        <div className={`text-red-600`} onClick={onDelete}>
                            {Translator.translateId(deleteErrorMessage)}
                        </div>}
                </div>
            </div>)}
        </div>}
        {!isCreating && <div className={`flex justify-center mt-[10px]`}>
            <div onClick={setCreate}
                 className={`border-[1px] border-${theme.value}-primary-950 p-[5px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}>
                {Translator.translateId('cta__create_story_point')}
            </div>
        </div>}
        {isCreating && <div>
            <form onSubmit={onSubmitCreate}>
                <Input form={createForm} name={'name'} handleChange={createHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_NAME} className={`min-w-[150px]`}/>
                <Dropdown form={createForm} name={'storyType'} values={storyTypes} setValues={createSetValues}
                          className={`mt-[10px]`}/>
                <Input form={createForm} name={'points'} handleChange={createHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_POINTS} className={`min-w-[150px] mt-[10px]`}/>
                <Input form={createForm} name={'backgroundColor'} handleChange={createHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_BACKGROUND_COLOR}
                       className={`min-w-[150px] mt-[10px]`}/>
                <Input form={createForm} name={'textColor'} handleChange={createHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_TEXT_COLOR} className={`min-w-[150px] mt-[10px]`}/>

                {createForm.fields.backgroundColor.value !== '' && createForm.fields.textColor.value !== '' && createForm.fields.name.value !== '' && createForm.fields.points.value !== '' &&
                    <div className={`mt-[10px]`}>
                        <div className={`underline`}>{Translator.translateId('label__preview')}</div>
                        <div className={`p-[5px]`}>
                            {/*@ts-ignore*/}
                            <StoryPointTile backgroundColor={createForm.fields.backgroundColor.value} textColor={createForm.fields.textColor.value} name={createForm.fields.name.value} points={createForm.fields.points.value}/>
                        </div>
                    </div>}

                <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                    <button type={'submit'}
                            className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                    <button onClick={() => setIsCreating(false)}
                            className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
                </div>
            </form>
        </div>}
        {updatingStoryPoint !== undefined && <div>
            <form onSubmit={onSubmitUpdate}>
                <Input form={updateForm} name={'name'} handleChange={updateHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_NAME} className={`min-w-[150px]`}/>
                <Dropdown form={updateForm} name={'storyType'} values={storyTypes} setValues={updateSetValues}
                          className={`mt-[10px]`}/>
                <Input form={updateForm} name={'points'} handleChange={updateHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_POINTS} className={`min-w-[150px] mt-[10px]`}/>
                <Input form={updateForm} name={'backgroundColor'} handleChange={updateHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_BACKGROUND_COLOR}
                       className={`min-w-[150px] mt-[10px]`}/>
                <Input form={updateForm} name={'textColor'} handleChange={updateHandleChange}
                       placeholder={LABEL_BOARD_STORY_POINT_TEXT_COLOR} className={`min-w-[150px] mt-[10px]`}/>

                {updateForm.fields.backgroundColor.value !== '' && updateForm.fields.textColor.value !== '' && updateForm.fields.name.value !== '' && updateForm.fields.points.value !== '' &&
                    <div className={`mt-[10px]`}>
                        <div className={`underline`}>{Translator.translateId('label__preview')}</div>
                        <div className={`p-[5px]`}>
                            {/*@ts-ignore*/}
                            <StoryPointTile backgroundColor={updateForm.fields.backgroundColor.value} textColor={updateForm.fields.textColor.value} name={updateForm.fields.name.value} points={updateForm.fields.points.value}/>
                        </div>
                    </div>}

                <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                    <button type={'submit'}
                            className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                    <button onClick={() => setUpdatingStoryPoint(undefined)}
                            className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
                </div>
            </form>
        </div>}
    </Modal>;
}
