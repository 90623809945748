import { UserInterface } from '../../Interfaces/UserInterface';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { EditIcon } from '../../Icons/EditIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { NewApiManager } from '../../Utils/NewApiManager';
import { FOLLOW_USER, UNFOLLOW_USER, UPLOAD_USER_PROFILE_IMAGE, USER, USER_SELF } from '../../Constants/ApiUrls';
import { isLoggedIn, updateUserProfileImage, userAccount } from '../../Signals/UserAccountSignal';
import { SpinnerIcon } from '../../Icons/SpinnerIcon';
import Translator from '../../Utils/Translator';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { PrimaryTitle } from '../Common/Title/PrimaryTitle';
import { PrimaryLink } from '../Common/Link/PrimaryLink';
import { UpdateUserModal } from './UpdateUserModal';
import { TextareaContent } from '../Common/Content/TextareaContent';
import { LockedIcon } from '../../Icons/LockedIcon';
import { UnlockedIcon } from '../../Icons/UnlockedIcon';
import { QrCodeIcon } from '../../Icons/QrCodeIcon';
import { UserQrCodeModal } from './UserQrCodeModal';
import { LinkIcon } from '../../Icons/LinkIcon';
import { useDropzone } from 'react-dropzone';

interface CardInterface {
    userId: string;
    className?: string;
}

export function Card({userId, className = ''}: CardInterface) {
    const [user, setUser] = useState<undefined|UserInterface>();
    const navigate = useNavigate();
    const [userModalVisible, setUserModalVisible] = useState(false);
    const [userQrCodeModalVisible, setUserQrCodeModalVisible] = useState(false);

    useEffect(() => {
        if (userId === userAccount.value?.id) {
            navigate('/users/self');
            return;
        }
        loadUser();
    }, []);

    const loadUser = () => {
        NewApiManager.get(
            USER(userId),
            (response: any) => {
                setUser(response.data);
            },
        );
    };

    const followUser = () => {
        NewApiManager.post(
            FOLLOW_USER(userId),
            {},
            loadUser,
        );
    }

    const unfollowUser = () => {
        NewApiManager.delete(
            UNFOLLOW_USER(userId),
            loadUser,
        );
    }

    if (user === undefined) {
        return <div className={`${className} md:w-[300px] border rounded-xl bg-white p-[20px] flex justify-center`}>
            <SpinnerIcon/>
        </div>;
    }

    return <>
        {userModalVisible && <UpdateUserModal user={user} submitSuccessCallback={() => {setUserModalVisible(false); loadUser();}} toggleModal={() => setUserModalVisible(false)}/>}
        {userQrCodeModalVisible && <UserQrCodeModal user={user} toggleModal={() => setUserQrCodeModalVisible(false)}/>}
        <div className={`${className} md:w-[300px] border rounded-xl bg-white`}>
            <div className={`h-[100px] bg-blue-200 rounded-t-xl`}></div>
            <div className={`my-[-50px]`}>
                <div className={`rounded-full p-[5px] w-[130px] h-[130px] flex justify-center items-center bg-white ml-[20px] cursor-pointer`}>
                <div
                     className={`w-full h-full flex justify-center items-center bg-gray-300 rounded-full`}>
                    {user.profileImage !== null ?
                        <img src={user.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                        <ProfileIcon className={`w-[50px] h-[50px] fill-black`}/>}
                    </div>
                </div>
            </div>
            <div className={`mt-[30px]`}></div>
            <div className={`p-[20px]`}>
                <div>
                    <div className={`flex gap-[5px] items-center`}>
                        <div>
                            {user.isPrivate === 1 ? <LockedIcon className={`w-[20px]`}/> :
                                <UnlockedIcon className={`w-[20px]`}/>}
                        </div>
                        <PrimaryTitle label={user.login}/>
                    </div>
                {/*        {user.mood !== null && user.mood.length > 0 && <div className={`pt-[10px]`}>{user.mood}</div>}*/}
                {/*        {user.description !== null && user.description.length > 0 && <div className={`pt-[10px]`}><TextareaContent content={user.description}/></div>}*/}
                </div>
                {user.followersCounter !== undefined && <div>
                    <Link to={`/users/${user.id}/followers`}>{Translator.translateId('cta__followers_counter', undefined, {value: user.followersCounter})}</Link>
                </div>}

                {isLoggedIn() && user.followStatus !== undefined && user.followStatus === 'pending' && <div className={`flex mt-[10px] border p-[10px] rounded-xl bg-gray-100`}>{Translator.translateId('label__pending_follow_request_description')}</div>}
                {isLoggedIn() && user.followStatus !== undefined && user.followStatus === 'not_following' && <PrimaryButton className={`flex items-center justify-center mt-[10px]`} onClick={followUser}>{Translator.translateId('label__follow')}</PrimaryButton>}
                {isLoggedIn() && user.followStatus !== undefined && user.followStatus === 'pending' && <PrimaryButton className={`flex items-center justify-center mt-[10px]`} onClick={unfollowUser} withBackground={false}>{Translator.translateId('label__cancel_follow_request')}</PrimaryButton>}
                {isLoggedIn() && user.followStatus !== undefined && user.followStatus === 'following' && <div className={`flex gap-[10px] justify-between`}>
                    <PrimaryButton className={`flex items-center text-center mt-[10px]`} withBackground={false}>{Translator.translateId('label__send_message')}</PrimaryButton>
                    <PrimaryButton onClick={unfollowUser} className={`flex items-center text-center mt-[10px]`}>{Translator.translateId('label__unfollow')}</PrimaryButton>
                </div>}
            </div>
        </div>
    </>;
}
