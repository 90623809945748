import {TranslationDataInterface} from '../Utils/Translator';
import {LABEL_FORM_RULE} from "../Constants/Label";

export interface FormValidationResponseInterface {
  errors: TranslationDataInterface[];
  success: TranslationDataInterface[];
}

const validateInput = (value: string|boolean|number|null, criterion: any): FormValidationResponseInterface => {
  let success: TranslationDataInterface[] = [];
  let errors: TranslationDataInterface[] = [];

  for (const criterionName of Object.keys(criterion)) {
    let isSuccess = false;
    switch (criterionName) {
      case 'type':
        switch(criterion[criterionName]) {
          case 'datetime':
            isSuccess = true;
            break;
          case 'alphanumeric':
            isSuccess = true;
            break;
          case 'email':
            // @ts-ignore
            isSuccess = /\S+@\S+\.\S+/.test(value);
            break;
          case 'flag':
            // @ts-ignore
            isSuccess = [true, false].includes(value);
            break;
          case 'numeric':
            isSuccess = isNumeric(value);
            break;
        }
        break;
      case 'minLength':
        // @ts-ignore
        isSuccess = (criterion['blank'] !== undefined && criterion['blank'] === true && value.length === 0) || value.length >= criterion[criterionName];
        break;
      case 'maxLength':
        // @ts-ignore
        isSuccess = value.length <= criterion[criterionName];
        break;
      case 'required':
        isSuccess = criterion[criterionName] === false || (criterion[criterionName] === true && value !== undefined);
        break;
      case 'blank':
        // @ts-ignore
        isSuccess = criterion[criterionName] === true || (criterion[criterionName] === false && value !== undefined && (value.length > 0 || value === true || value === false));
        break;
      case 'atLeastOneSpecialChar':
        isSuccess = criterion[criterionName] === true && atLeastOneSpecialChar(value);
        break;
      case 'atLeastOneLowerCase':
        isSuccess = criterion[criterionName] === true && atLeastOneLowerCase(value);
        break;
      case 'atLeastOneUpperCase':
        isSuccess = criterion[criterionName] === true && atLeastOneUpperCase(value);
        break;
      case 'atLeastOneNumber':
        isSuccess = criterion[criterionName] === true && atLeastOneNumber(value);
        break;
    }

    if (isSuccess) {
      if (['type'].includes(criterionName)) {
        success.push(LABEL_FORM_RULE(`${criterionName}_${criterion[criterionName]}`));
      } else {
        success.push(LABEL_FORM_RULE(criterionName, criterion[criterionName]));
      }
    } else {
      if (['type'].includes(criterionName)) {
        errors.push(LABEL_FORM_RULE(`${criterionName}_${criterion[criterionName]}`));
      } else {
        errors.push(LABEL_FORM_RULE(criterionName, criterion[criterionName]));
      }
    }
  }

  return {success, errors};
};

export const translateCriterion = (name: string, value: string|number|boolean) => {
  if (`same_password`.includes(name)) {
    return LABEL_FORM_RULE(name);
  }

  let label = LABEL_FORM_RULE(name);
  switch(name) {
    case 'type':
      label = LABEL_FORM_RULE(name, value);
      break;
    case 'minLength':
    case 'maxLength':
      label = LABEL_FORM_RULE(name, value);
      break;
  }

  return label;
};

const atLeastOneSpecialChar = (value: string|boolean|number|null): boolean => {
  const specialChars = ',;:!?./§/&~"#\'{([-|`^@à)]=}*_';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneLowerCase = (value: string|boolean|number|null): boolean => {
  const specialChars = 'abcdefghijklmnopqrstuvwxyz';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i])) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneUpperCase = (value: string|boolean|number|null): boolean => {
  const specialChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i])) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneNumber = (value: string|boolean|number|null): boolean => {
  const specialChars = '0123456789';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const isNumeric = (value: string|boolean|number|null) => {
  const specialChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  let containsCharsCounter = 0;

  if (typeof value !== "string") {
    return false;
  }

  for (let char of value) {
    if (specialChars.includes(char)) {
      containsCharsCounter++;
    }
  }

  return value.length === containsCharsCounter;
}

export {validateInput};