import PageTemplate from '../Components/PageTemplate/PageTemplate';
import Translator from '../Utils/Translator';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import NicolasCHUNG from '../Images/NicolasCHUNG.jpeg';
import { TriangleDownIcon } from '../Icons/TrianglDownIcon';
import { useState } from 'react';
import { TriangleRightIcon } from '../Icons/TriangleRightIcon';

export function Team() {
    const [openedProfile, setOpenedProfile] = useState('');

    const isProfileOpened = (targetProfile: string) => {
        return targetProfile === openedProfile;
    };

    const toggleProfile = (profile: string) => {
        if (openedProfile === profile) {
            setOpenedProfile('');
            return;
        }

        setOpenedProfile(profile);
    }

    return <PageTemplate>
        <div className={`p-[10px] lg:flex gap-[10px]`}>
            <div className={`w-full`}>
                <div className={`bg-white border rounded-2xl p-[10px]`}>
                    <div className={`md:float-left mr-[10px] mb-[10px] flex justify-center md:block`}>
                        <div className={`rounded-full`}>
                            <img src={NicolasCHUNG} className={`w-[150px] height-[150px] rounded-full`}/>
                        </div>
                    </div>
                    <div className={`min-h-[150px]`}>
                        <div className={`text-2xl font-bold`}>
                            Nicolas CHUNG
                        </div>
                        <div className={`text-xl`}>
                            {Translator.translateId('label__nicolas_chung_exp')}
                        </div>
                        <div className={`mt-[10px] font-bold`}>
                            {Translator.translateId('label__nicolas_chung_main_stack1')}
                        </div>
                        <div className={`font-bold`}>
                            {Translator.translateId('label__nicolas_chung_main_stack2')}
                        </div>
                        <div className={`mt-[10px] flex items-center cursor-pointer`} onClick={() => toggleProfile('NicolasCHUNG')}>
                            {isProfileOpened('NicolasCHUNG') ? <TriangleDownIcon className={`w-[20px]`}/> : <TriangleRightIcon className={`w-[20px]`}/>}
                            <div className={`underline`}>
                                {Translator.translateId('cta__discover_more_about_profile')}
                            </div>
                        </div>
                        {isProfileOpened('NicolasCHUNG') && <div className={`mt-[10px]`}>
                            <div className={`text-xl font-bold underline`}>
                                {Translator.translateId('label__all_stacks')}
                            </div>
                            <div className={`flex flex-wrap gap-[10px] mt-[10px]`}>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>HTML</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>CSS</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>PHP</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Symfony</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Doctrine</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>PHPUnit</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Javascript</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>ReactJs</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Storybook</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>PReact Signals</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>PReact</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>TailwindCSS</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Docker</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Docker-Compose</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Raspberry Pi</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Git</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Trello</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Jira</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Space Boards</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>AWS S3</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>AWS Route 53</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>AWS Certificate Manager</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>AWS Cloudfront</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Let's Encrypt</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Cron</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Mailtrap</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Firebase Realtime Database</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Browser Extensions</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>RxJs</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Zeplin</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold`}>Figma</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Redux</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>UseBetween</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Puppeteer</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Asana</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Asana</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Asana</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Monday</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Behat</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Selenium</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>jQuery</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Jest</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>SASS</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>API Platform</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Jenkins</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Target Process</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Miro</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Python</div>
                                <div className={`p-[5px] rounded border bg-gray-100 font-bold line-through`}>Django 2</div>
                            </div>
                            <div className={`text-xl font-bold underline mt-[20px]`}>
                                {Translator.translateId('label__career')}
                            </div>
                            <div className={`border rounded-xl mt-[10px]`}>
                                <div className={`bg-gray-100 p-[10px] rounded-t-xl`}>
                                    <div>
                                        <span
                                            className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_duration5')}</span>: <span
                                        className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_description5')}</span> - {Translator.translateId('label__nicolas_chung_career_company5')}
                                    </div>
                                    <div className={`italic`}>
                                        {Translator.translateId('label__nicolas_chung_career_stacks5')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details5_1')}
                                    </div>
                                </div>
                                <div className={`p-[10px]`}>
                                    <div>
                                        {Translator.translateId('label__nicolas_chung_career_details5_2')} <PrimaryLink to={'/about-us'} target={'_blank'} label={Translator.translateId('cta__discover')} className={`inline-block`}/>
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl mt-[10px]`}>
                                <div className={`bg-gray-100 p-[10px] rounded-t-xl`}>
                                    <div>
                                        <span
                                            className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_duration4')}</span>: <span
                                        className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_description4')}</span> - {Translator.translateId('label__nicolas_chung_career_company4')}
                                    </div>
                                    <div className={`italic`}>
                                        {Translator.translateId('label__nicolas_chung_career_stacks4')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details4_1')} <PrimaryLink target={'_blank'} to={'https://queube.fr/'} label={Translator.translateId('cta__discover')} className={`inline-block`}/>
                                    </div>
                                </div>
                                <div className={`p-[10px]`}>
                                    <div>
                                        {Translator.translateId('label__nicolas_chung_career_details4_2')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details4_3')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details4_4')}
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl mt-[10px]`}>
                                <div className={`bg-gray-100 p-[10px] rounded-t-xl`}>
                                    <div>
                                        <span
                                            className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_duration1')}</span>: <span
                                        className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_description1')}</span> - {Translator.translateId('label__nicolas_chung_career_company1')}
                                    </div>
                                    <div className={`italic`}>
                                        {Translator.translateId('label__nicolas_chung_career_stacks1')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details1_1')}
                                    </div>
                                </div>
                                <div className={`p-[10px]`}>
                                    <div>
                                        {Translator.translateId('label__nicolas_chung_career_details1_2')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details1_3')}
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl mt-[10px]`}>
                                <div className={`bg-gray-100 p-[10px] rounded-t-xl`}>
                                    <div>
                                        <span
                                            className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_duration2')}</span>: <span
                                        className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_description2')}</span> - {Translator.translateId('label__nicolas_chung_career_company2')}
                                    </div>
                                    <div className={`italic`}>
                                        {Translator.translateId('label__nicolas_chung_career_stacks2')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details2_1')}
                                    </div>
                                </div>
                                <div className={`p-[10px]`}>
                                    <div>
                                        {Translator.translateId('label__nicolas_chung_career_details2_2')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details2_3')}
                                    </div>
                                    <div className={`pl-[10px]`}>
                                        - {Translator.translateId('label__nicolas_chung_career_details2_3sub1')}
                                    </div>
                                    <div className={`pl-[10px]`}>
                                        - {Translator.translateId('label__nicolas_chung_career_details2_3sub2')}
                                    </div>
                                    <div className={`pl-[10px]`}>
                                        - {Translator.translateId('label__nicolas_chung_career_details2_3sub3')} <PrimaryLink to={'https://instagram-engineering.com/storing-hundreds-of-millions-of-simple-key-value-pairs-in-redis-1091ae80f74c'} label={Translator.translateId('cta__discover')} target={'_blank'} className={`inline-block underline`}/>
                                    </div>
                                    <div className={`pl-[10px]`}>
                                        - {Translator.translateId('label__nicolas_chung_career_details2_3sub4')}
                                    </div>
                                </div>
                            </div>
                            <div className={`border rounded-xl mt-[10px]`}>
                                <div className={`bg-gray-100 p-[10px] rounded-t-xl`}>
                                    <div>
                                        <span
                                            className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_duration3')}</span>: <span
                                        className={`font-bold`}>{Translator.translateId('label__nicolas_chung_career_description3')}</span> - {Translator.translateId('label__nicolas_chung_career_company3')}
                                    </div>
                                    <div className={`italic`}>
                                        {Translator.translateId('label__nicolas_chung_career_stacks3')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details3_1')}
                                    </div>
                                </div>
                                <div className={`p-[10px]`}>
                                    <div>
                                        {Translator.translateId('label__nicolas_chung_career_details3_2')}
                                    </div>
                                    <div className={`mt-[10px]`}>
                                        {Translator.translateId('label__nicolas_chung_career_details3_3')}
                                    </div>
                                </div>
                            </div>
                            <div className={`text-xl font-bold underline mt-[20px]`}>
                                {Translator.translateId('label__academic_background')}
                            </div>
                            <div className={`mt-[10px]`}>
                                - <span
                                className={`font-bold`}>{Translator.translateId('label__nicolas_chung_academic_duration1')}</span>: {Translator.translateId('label__nicolas_chung_academic_description1')}
                            </div>
                            <div className={`italic`}>
                                {Translator.translateId('label__nicolas_chung_academic_details1')}
                            </div>
                            <div className={`mt-[10px]`}>
                                - <span
                                className={`font-bold`}>{Translator.translateId('label__nicolas_chung_academic_duration2')}</span>: {Translator.translateId('label__nicolas_chung_academic_description2')}
                            </div>
                            <div className={`italic`}>
                                {Translator.translateId('label__nicolas_chung_academic_details2')}
                            </div>
                            <div className={`mt-[10px]`}>
                                - <span
                                className={`font-bold`}>{Translator.translateId('label__nicolas_chung_academic_duration3')}</span>: {Translator.translateId('label__nicolas_chung_academic_description3')}
                            </div>
                            <div className={`italic`}>
                                {Translator.translateId('label__nicolas_chung_academic_details3')}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`min-w-[300px] mt-[10px] lg:mt-0`}>
                <div className={`border bg-white rounded-xl p-[10px]`}>
                    <div className={`font-bold`}>
                        {Translator.translateId('label__also_discover')}
                    </div>
                    <div className={`ml-[10px]`}>
                        <PrimaryLink to={'/about-us'} label={Translator.translateId('cta__about_us')}/>
                        <PrimaryLink to={'/roadmap'} label={Translator.translateId('cta__roadmap')}/>
                        <PrimaryLink to={'/team'} label={Translator.translateId('cta__team')}/>
                        {/*<PrimaryLink to={'/ama'} label={Translator.translateId('cta__ask_me_anything')}/>*/}
                        {/*<PrimaryLink to={'/features'} label={Translator.translateId('cta__features')}/>*/}
                        {/*<PrimaryLink to={'/conditions'} label={Translator.translateId('cta__conditions')}/>*/}
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>;
}
