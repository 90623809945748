import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {BACK_OFFICE_ALL_ADMINISTRATORS} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeAdministrators() {
    const [administrators, setAdministrators] = useState([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_ALL_ADMINISTRATORS,
            (response: any) => setAdministrators(response.data)
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_administrators'}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {administrators.map((administrator: any) => <Link to={`/back-office/administrators/${administrator.id}`}>
                    <div key={`administrator-${administrator.id}`}
                         className={`hover:bg-${theme.value}-primary-200 rounded p-[10px] border-[1px] border-${theme.value}-primary-950`}>
                        {administrator.firstname} {administrator.lastname} ({administrator.login})
                    </div>
                </Link>)}
            </div>
        </div>
    </PageTemplate>;
}
