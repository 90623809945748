import PageTemplate from "../Components/PageTemplate/PageTemplate";
import { Card as UserCard } from '../Components/User/Card';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isLoggedIn } from '../Signals/UserAccountSignal';
import Translator from '../Utils/Translator';
import { ExclamationTriangledIcon } from '../Icons/ExclamationTriangledIcon';
import { PrimaryButton } from '../Components/Common/Button/PrimaryButton';
import { useEffect, useState } from 'react';
import { CouponInterface } from '../Interfaces/CouponInterface';
import { NewApiManager } from '../Utils/NewApiManager';
import { USER_COUPONS, USER_POSTS } from '../Constants/ApiUrls';
import {Cards as CouponCards} from '../Components/Coupon/Cards';
import {Cards as PostCards} from '../Components/Posts/Cards';
import { SpinnerIcon } from '../Icons/SpinnerIcon';
import { PostBasicInterface } from '../Interfaces/PostBasicInterface';

export function UserProfile() {
    const navigate = useNavigate();
    const {userId} = useParams();

    const [posts, setPosts] = useState<PostBasicInterface[]|undefined>(undefined);
    const [coupons, setCoupons] = useState<CouponInterface[]|undefined>(undefined);

    useEffect(() => {
        loadPosts();
        loadCoupons();
    }, []);

    if (userId === undefined) {
        navigate('/');
        return <></>;
    }

    const loadPosts = () => {
        if (userId === undefined) {
            return;
        }

        NewApiManager.get(
            USER_POSTS(userId),
            (response: any) => {
                setPosts(response.data);
            }
        );
    }

    const loadCoupons = () => {
        if (userId === undefined) {
            return;
        }
        NewApiManager.get(
            USER_COUPONS(userId),
            (response: any) => setCoupons(response.data)
        );
    }
    //
    // const loadDevices = () => NewApiManager.get(
    //     USER_DEVICES,
    //     (response: any) => setDevices(response.data)
    // );

    return <PageTemplate sidebar={"profile"}>
        <div className={`md:flex gap-[10px]`}>
            <div>
                <UserCard userId={userId} className={'mt-[10px]'}/>
            </div>
            <div className={`w-full`}>
                {!isLoggedIn() && <div className={`mt-[10px] border rounded-xl bg-white p-[10px]`}>
                    <div className={`font-bold flex gap-[10px] items-center`}>
                        <ExclamationTriangledIcon className={`w-[20px]`}/>
                        {Translator.translateId('label__user_profile_not_logged_in_warning')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__user_profile_not_logged_in_description')}
                    </div>
                    <div className={`md:flex gap-[10px] justify-center`}>
                        <div className={`mt-[10px] flex gap-[10px] justify-center`}>
                            <PrimaryButton className={`text-center`}>{Translator.translateId('cta__be_referred')}</PrimaryButton>
                        </div>
                        <div className={`mt-[10px] flex justify-center`}>
                            <Link to={'/log-in'}><PrimaryButton className={`text-center`} withBackground={false}>{Translator.translateId('cta__have_account')}</PrimaryButton></Link>
                        </div>
                    </div>
                </div>}
                <div>
                    {coupons !== undefined ?
                        <CouponCards coupons={coupons} className={`mt-[10px]`} limit={4} showMore={true}/>
                        : <SpinnerIcon/>
                    }
                </div>
                <div>
                    {posts !== undefined ?
                        <PostCards posts={posts} className={`mt-[10px]`} limit={4} showMore={true} setPosts={setPosts} loadPosts={loadPosts} canWritePost={false}/>
                        : <div className={`mt-[10px]`}><SpinnerIcon/></div>
                    }
                </div>
            </div>
        </div>
    </PageTemplate>;
}
