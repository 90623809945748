import moment from "moment/moment";
import {BoardCardInterface} from "../../Interfaces/BoardCardInterface";
import {DotIcon} from "../../Icons/DotIcon";
import Translator from "../../Utils/Translator";
import {DateTimeLabel} from "../Common/Date/DateTimeLabel";
import {isSameDate} from "../../Utils/Date";
import {theme} from "../../Signals/ThemeSignal";
import { DotsIcon } from '../../Icons/DotsIcon';

interface DueDotsInterface {
    card: BoardCardInterface;
}

export function DueDots({card}: DueDotsInterface) {
    let daysLeft = undefined
    if (card.dueDate !== null) {
        daysLeft = moment(card.dueDate.date).diff(moment(), 'day');
    }

    let bubbleColor = undefined;
    let bubbleCount = 0;
    let displayDots = false;
    if (daysLeft !== undefined) {
        if (daysLeft > 0) {bubbleColor = 'black'; bubbleCount = daysLeft;}
        if (daysLeft > 5) {bubbleColor = 'green'; bubbleCount = daysLeft-5;}
        if (daysLeft > 10) {bubbleColor = 'blue'; bubbleCount = daysLeft-10}
        if (daysLeft < 0) {bubbleColor = 'grey'; bubbleCount = -daysLeft;}
        if (daysLeft < -5) {bubbleColor = 'orange'; bubbleCount = -daysLeft-5}
        if (daysLeft < -10) {bubbleColor = 'red'; bubbleCount = -daysLeft-10}
    }
    if (bubbleCount > 5) {
        bubbleCount = 5;
        displayDots = true;
    }

    let bubbles = [];
    for (let i=0; i<bubbleCount; i++) {
        bubbles.push(<DotIcon style={{fill: bubbleColor}} className={`w-[15px]`} key={`dot-${i}`}/>);
    }

    if (card.dueDate === null || daysLeft === undefined) {
        return <></>;
    }

    return <div title={`${Translator.translateId(daysLeft > 0 ? `label__days_left` : `label__days_passed`, undefined, {days: daysLeft > 0 ? daysLeft : (daysLeft*-1)})}`}>
        {card.completeDate === null && <div className={`flex gap-[5px] justify-end w-[90px] ${daysLeft <= 0 && 'border-red-600 border-[1px] rounded justify-center'}`}>
            {displayDots && <DotsIcon style={{fill: bubbleColor}} className={`w-[15px]`}/>}
            {bubbles}
            {daysLeft === 0 && <span className={`text-red-600 text-xs font-bold`}>{Translator.translateId('label___deadline')}</span>}
        </div>}
        {card.completeDate !== null && isSameDate(card.completeDate, card.dueDate) && <div className={`flex gap-[5px] justify-end w-[90px] ${daysLeft <= 0 && `border-${theme.value}-primary-950 border-[1px] rounded justify-center`}`}>
            <div className={`text-${theme.value}-primary-950 text-xs font-bold`}>
            {Translator.translateId('label__board_card_label_just_in_time')}
            </div>
        </div>}
        <div className={`text-sm text-right`}>
            <DateTimeLabel datetime={card.dueDate.date} showTime={false}/>
        </div>
    </div>;
}
