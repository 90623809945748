import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_COLUMN_CREATE_FORM_ERROR,
    LABEL_BOARD_COLUMN_NAME
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {FormHook} from "../../Hooks/FormHook";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {useEffect} from "react";
import {CheckIcon} from "../../Icons/CheckIcon";
import {CREATE_BOARD_COLUMN, CREATE_BOARD_COLUMN_FORM_VALIDATOR} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { Modal } from '../Modal/Modal';

interface ColumnCreationModalInterface {
    toggle: () => void;
    board: BoardInterface;
    loadBoard: () => void;
}

export function ColumnCreationModal({toggle, board, loadBoard}: ColumnCreationModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, setForm, submitLock, submitError, loadConstrains} = FormHook(['name', 'autoCompleteCard'], {autoCompleteCard: false});

    useEffect(() => {
        loadConstrains(CREATE_BOARD_COLUMN_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.post(
            CREATE_BOARD_COLUMN(board.id),
            {
                name: form.fields.name.value,
                autoCompleteCard: form.fields.autoCompleteCard.value ? 1 : 0
            },
            () => {
                setValues({name: ''});
                toggle();

                loadBoard();
            },
            () => submitError(LABEL_BOARD_COLUMN_CREATE_FORM_ERROR)
        );
    }

    const toggleAutoComplete = () => {
        setForm({
            ...form,
            fields: {
                ...form.fields,
                autoCompleteCard: {
                    ...form.fields.autoCompleteCard,
                    // @ts-ignore
                    value: !form.fields.autoCompleteCard.value,
                }
            }
        });
    }

    return <Modal label={Translator.translateId('label__new_column')} toggle={toggle}>
        <form onSubmit={onSubmit}>
            <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_BOARD_COLUMN_NAME}
                   className={`min-w-[150px]`}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                <div
                    className={`p-[5px] w-[40px] h-[40px] border-[1px] border-${theme.value}-primary-950 rounded flex items-center justify-center`}
                    onClick={toggleAutoComplete}>
                    {!form.fields.autoCompleteCard.value ? '' : <CheckIcon className={`w-[20px] h-[20px]`}/>}
                </div>
                <div className={`flex items-center cursor-pointer`} onClick={toggleAutoComplete}>
                    {Translator.translateId('label__board_column_auto_complete')}
                </div>
            </div>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={toggle}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
        </form>
    </Modal>;
}
