import {signal} from "@preact/signals-react";
import { NewApiManager } from '../Utils/NewApiManager';
import { FRONT_VERSION } from '../Constants/ApiUrls';

export const versionAlertShow = signal<boolean>(false);
export let frontVersion: string|undefined = undefined;
export let reminderCounter = 0;
export const maxReminderCounter = 3;

export function initFrontVersion() {
    if (undefined !== frontVersion) {
        return;
    }

    NewApiManager.get(
        FRONT_VERSION,
        (response: any) => frontVersion = response.data,
        (response: any) => frontVersion = ''
    );

    checkFrontVersion();
}

export function canCloseVersionAlertShow() {
    return reminderCounter <= maxReminderCounter;
}

export function checkFrontVersion() {
    if (!canCloseVersionAlertShow()) {
        return;
    }
    versionAlertShow.value = false;
    setTimeout(() => {
        NewApiManager.get(
            FRONT_VERSION,
            (response: any) => {
                if (frontVersion !== response.data) {
                    reminderCounter++;
                    versionAlertShow.value = true;
                } else {
                    checkFrontVersion();
                }
            },
        );
    }, 300000); // 5min
}

initFrontVersion();
