import { FormHook } from '../../Hooks/FormHook';
import Translator from '../../Utils/Translator';
import Input from '../Common/Input/Input';
import TextArea from '../Common/Input/Textarea';
import {
    LABEL_CONTACT_ADMIN_DESCRIPTION,
    LABEL_CONTACT_SUBJECT,
    LABEL_LEAVE_COMMENT,
    LABEL_LINK_URL,
    LABEL_PROBLEM_DESCRIPTION,
    LABEL_TITLE
} from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { StarIcon } from '../../Icons/StarIcon';
import { useEffect, useState } from 'react';
import {
    BUG_REPORT_FORM_VALIDATOR, CONTACT_ADMIN_FORM_VALIDATOR,
    POST_BUG_REPORT, POST_CONTACT_ADMIN,
    POST_RATE_SPACESHARE,
    RATING_FORM_VALIDATOR
} from '../../Constants/ApiUrls';
import { NewApiManager } from '../../Utils/NewApiManager';
import { Dropdown } from '../Common/Input/Dropdown';

export function ContactAdminForm() {
    const {form, setForm, handleChange, setValues, loadConstrains, submitLock, shouldSubmit} = FormHook(['subject', 'message', 'adminLogin'], {url: document.location.href});

    useEffect(() => {
        loadConstrains(CONTACT_ADMIN_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (shouldSubmit()) {
            submitLock();

            NewApiManager.post(
                POST_CONTACT_ADMIN,
                {
                    subject: form.fields.subject.value,
                    adminLogin: form.fields.adminLogin.value,
                    message: form.fields.message.value,
                },
                (response: any) => {
                    setForm({
                        ...form,
                        success: '',
                        error: undefined,
                        isLoading: false,
                    });
                },
                () => {
                    setForm({
                        ...form,
                        success: undefined,
                        error: '',
                        isLoading: false,
                    });
                }
            )
        }
    };

    if (form.success !== undefined) {
        return <div>
            {Translator.translateId('label__thank_for_writing_to_admin')}
        </div>;
    }

    return <form onSubmit={onSubmit}>
        <Dropdown form={form} name={'adminLogin'} values={[
            {
                id: '',
                label: Translator.translateId('label__choose_admin'),
            },
            {
                id: 'Safronix',
                label: 'Safronix',
            },
        ]} setValues={setValues}/>
        <Input className={`mt-[10px]`} form={form} name={'subject'} handleChange={handleChange} placeholder={LABEL_CONTACT_SUBJECT}/>
        <TextArea form={form} name={'message'} handleChange={handleChange} className={`mt-[10px] border border-black`} placeholder={LABEL_CONTACT_ADMIN_DESCRIPTION}/>
        <PrimaryButton className={`mt-[10px]`} onClick={onSubmit}>
            {Translator.translateId('cta__submit')}
        </PrimaryButton>
    </form>;
}
