import PageTemplate from "../Components/PageTemplate/PageTemplate";
import { isFeatureAllowed } from '../Signals/UserAccountSignal';
import Translator from '../Utils/Translator';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';

export default function BackOfficeHomepage() {
    return <PageTemplate sidebar={`back-office`} childrenClassName={`flex gap-[10px] p-[10px] mt-[10px] flex-wrap`}>
        {isFeatureAllowed('back_office_users', 'rightRead') &&
            <PrimaryLink to={`/back-office/users`} label={Translator.translateId('cta__back_office_users')}/>}
        {isFeatureAllowed('back_office_administrators', 'rightRead') &&
            <PrimaryLink to={`/back-office/administrators`} label={Translator.translateId('cta__back_office_administrators')}/>}
        {isFeatureAllowed('back_office_companies', 'rightRead') &&
            <PrimaryLink to={`/back-office/companies`} label={Translator.translateId('cta__back_office_companies')}/>}
        {isFeatureAllowed('back_office_coupon_auto_apply', 'rightRead') &&
            <PrimaryLink to={`/back-office/coupon-auto-applies`} label={Translator.translateId('cta__back_office_coupon_auto_applies')}/>}
        {isFeatureAllowed('back_office_coupons', 'rightRead') &&
            <PrimaryLink to={`/back-office/coupons`} label={Translator.translateId('cta__back_office_coupons')}/>}
        {isFeatureAllowed('back_office_youtube', 'rightUpdate') &&
            <PrimaryLink to={`/back-office/youtube/update`} label={Translator.translateId('cta__back_office_youtube')}/>}
        {isFeatureAllowed('back_office_news', 'rightRead') && <PrimaryLink to={`/back-office/news`} label={Translator.translateId('cta__back_office_news')}/>}
        {isFeatureAllowed('back_office_weather', 'rightRead') &&
        <PrimaryLink to={`/back-office/weathers/api-keys/update`} label={Translator.translateId('cta__back_office_weathers')}/>}
        <PrimaryLink to={`/back-office/automation-logs`} label={Translator.translateId('cta__back_office_automation_logs')}/>
        <PrimaryLink to={`/back-office/spaceshare-rates`} label={Translator.translateId('cta__back_office_spaceshare_rates')}/>
        <PrimaryLink to={`/back-office/bug-reports`} label={Translator.translateId('cta__back_office_bug_reports')}/>
        <PrimaryLink to={`/back-office/contact-admins`} label={Translator.translateId('cta__back_office_contact_admins')}/>
    </PageTemplate>;
}
