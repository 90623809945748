import PageTemplate from '../Components/PageTemplate/PageTemplate';
import Translator from '../Utils/Translator';
import { PrimaryLink } from '../Components/Common/Link/PrimaryLink';
import { PictureIcon } from '../Icons/PictureIcon';
import { FriendIcon } from '../Icons/FriendIcon';
import { NewsIcon } from '../Icons/NewsIcon';
import { DollarIcon } from '../Icons/DollarIcon';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { HomeIcon } from '../Icons/HomeIcon';
import logo from '../Images/logo.png';

export function AboutUs() {
    return <PageTemplate>
        <div className={`p-[10px] lg:flex gap-[10px]`}>
            <div className={`w-full`}>
                <div className={`bg-white p-[10px] rounded-xl border`}>
                    <div className={`text-3xl font-bold`}>
                        Spaceshare.fr
                    </div>
                    <div className={`mt-[10px]`}>
                        <div>
                            {Translator.translateId('label__spaceshare_project_description')}
                        </div>
                        <div>
                            {Translator.translateId('label__spaceshare_project_description2')}
                        </div>
                        <div className={`mt-[10px]`}>
                            {Translator.translateId('label__spaceshare_project_description3')}
                        </div>
                    </div>
                </div>
                <div className={`bg-white p-[10px] rounded-xl border mt-[10px]`}>
                    <div className={`text-2xl font-bold`}>
                        {Translator.translateId('label__spaceshare_project_goals_title')}
                    </div>
                    <div className={`flex gap-[10px] flex-wrap w-full mt-[10px] justify-center`}>
                        <Goal icon={<PictureIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal1')}/>
                        <Goal icon={<FriendIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal2')}/>
                        <Goal icon={<NewsIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal3')}/>
                        <Goal icon={<DollarIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal4')}/>
                        <Goal icon={<CalendarIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal5')}/>
                        <Goal icon={<HomeIcon className={`w-[100px]`}/>}
                              label={Translator.translateId('label__spaceshare_project_goal6')}/>
                    </div>
                </div>
                <div className={`bg-white p-[10px] rounded-xl border mt-[10px]`}>
                    <div className={`text-2xl font-bold`}>
                        {Translator.translateId('label__spaceshare_project_conclusion_title')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_project_conclusion1')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_project_conclusion2')}
                    </div>
                </div>
                <div className={`bg-white p-[10px] rounded-xl border mt-[10px]`}>
                    <div className={`text-2xl font-bold`}>
                        {Translator.translateId('label__spaceshare_project_stack_title')}
                    </div>
                    <div className={`text-xl font-bold underline mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_project_stack_sub_title')}
                    </div>
                    <div className={`mt-[10px] flex gap-[10px] flex-wrap items-center`}>
                        <img src={logo} className={`w-[80px]`}/>
                        <img src={'https://fr.wizcase.com/wp-content/uploads/2022/03/GiTlab-lOGO.png'}
                             className={`w-[80px]`}/>
                        <img
                            src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/PhpStorm_Icon.svg/1200px-PhpStorm_Icon.svg.png'}
                            className={`w-[80px]`}/>
                    </div>
                    <div className={`border-b my-[10px]`}></div>
                    <div className={`text-xl font-bold underline`}>
                        {Translator.translateId('label__spaceshare_project_stack_sub_title1')}
                    </div>
                    <div className={`mt-[10px] flex gap-[10px] flex-wrap items-center`}>
                        <img className={`w-[70px] h-[70px]`}
                             src={'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png'}/>
                        <img className={`w-[130px] h-[50px]`}
                             src={'https://eportfolio.u-pec.fr/artefact/file/download.php?file=25742&view=10076&embedded=1&text=40119'}/>
                        <img className={`w-[100px] h-[50px]`}
                             src={'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Npm-logo.svg/1200px-Npm-logo.svg.png'}/>
                        <img className={`w-[130px] h-[70px]`}
                             src={'https://www.spi-informatique.fr/wp-content/uploads/2018/05/React.png'}/>
                        <img className={`w-[130px] h-[60px]`}
                             src={'https://fathomtech.io/blog/using-aws-s3-and-cloudfront-for-fast-static-web-sites/amazon-s3.png'}/>
                        <img className={`w-[130px] h-[70px]`}
                             src={'https://uploads-ssl.webflow.com/61af9eab93c48b14f5026d5c/63b47fd520e108d936ca02b4_Amazon-Route-53-Basics.png'}/>
                        <img className={`w-[130px] h-[70px]`}
                             src={'https://www.nicepng.com/png/detail/21-217813_amazon-cloudfront-logo-png-amazon-cloudfront.png'}/>
                        <img className={`w-[130px] h-[80px]`}
                             src={'https://acloudxpert.com/wp-content/uploads/2019/03/AWS-CM.png'}/>
                        <img className={`w-[80px] h-[80px]`}
                             src={'https://i.ytimg.com/vi/Fix0niR6Hm8/maxresdefault.jpg'}/>
                        <img className={`w-[220px] h-[80px]`}
                             src={'https://miro.medium.com/v2/resize:fit:700/1*csQT-n_POQQTfDLywDEdQQ.png'}/>
                        <img className={`w-[80px] h-[80px]`}
                             src={'https://www.npmjs.com/npm-avatar/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdmF0YXJVUkwiOiJodHRwczovL3MuZ3JhdmF0YXIuY29tL2F2YXRhci84ODc0N2NjZTE1ODAxZTllOTZiY2I3Njg5NWZjZDdmOT9zaXplPTQ5NiZkZWZhdWx0PXJldHJvIn0.stWeAK4THGegvRuZuUxRvaP2fmWRxWP3AvFCtQaiCEI'}/>
                    </div>
                    <div className={`border-b my-[10px]`}></div>
                    <div className={`text-xl font-bold underline`}>
                        {Translator.translateId('label__spaceshare_project_stack_sub_title2')}
                    </div>
                    <div className={`mt-[10px] flex gap-[10px] flex-wrap items-center`}>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://static.formation.tech/formations/logos/d5e995bdc918bc780b002362c4753f48.svg'}/>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://d31ezp3r8jwmks.cloudfront.net/P4LMkQbCoYdT6NiE8ZjETEbw'}/>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://upload.wikimedia.org/wikipedia/fr/thumb/3/3b/Raspberry_Pi_logo.svg/1200px-Raspberry_Pi_logo.svg.png'}/>
                        <img className={`w-[90px] h-[90px]`} src={'https://fr-wiki.ikoula.com/images/4/4a/Nginx.png'}/>
                        <img className={`w-[130px] h-[90px]`}
                             src={'https://les-tilleuls.coop/wp-content/uploads/2023/11/vignette-symfony-1-1024x614.png'}/>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://seeklogo.com/images/L/let-s-encrypt-logo-DF90D21CBA-seeklogo.com.png'}/>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://d2qr50rz2oof04.cloudfront.net/assets/img/logo/logo-bug.png'}/>
                        <img className={`w-[90px] h-[90px]`} src={'https://cdn2.boryl.fr/2020/12/fd8a25a0-mysql.svg'}/>
                        <img className={`w-[120px] h-[90px]`}
                             src={'https://repository-images.githubusercontent.com/1864363/49ec0c0f-21fe-40d8-bfc3-2002b0a5c1c8'}/>
                        <img className={`w-[90px] h-[90px]`}
                             src={'https://blog.ippon.fr/content/images/2017/10/jwt.svg'}/>
                        <img className={`w-[120px] h-[70px]`}
                             src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f7/PHPUnit_Logo.svg/langfr-120px-PHPUnit_Logo.svg.png'}/>
                        <img className={`w-[90px] h-[70px]`}
                             src={'https://www.skyminds.net/wp-content/uploads/2018/11/cron.jpg'}/>
                        <img className={`w-[220px] h-[80px]`}
                             src={'https://miro.medium.com/v2/resize:fit:700/1*csQT-n_POQQTfDLywDEdQQ.png'}/>
                    </div>
                </div>
                <div className={`bg-white p-[10px] rounded-xl border mt-[10px]`}>
                    <div className={`text-3xl font-bold`}>
                        {Translator.translateId('label__spaceshare_ecosystem')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_ecosystem_description1')}
                    </div>
                    <div className={`mt-[10px]`}>
                        {Translator.translateId('label__spaceshare_ecosystem_description2')}
                    </div>
                    <div className={`mt-[20px] flex justify-center`}>
                        <div>
                            <div className={`flex justify-center`}>
                                <img className={`w-[100px]`} src={'https://cdn-icons-png.flaticon.com/512/1493/1493169.png'}/>
                            </div>
                            <div>
                                <div className={`flex gap-[20px] items-center`}>
                                    <div>
                                        <div>
                                            <img className={`h-[100px]`}
                                                 src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPIsTI0e2bIHYI8GbB5qgV1BWGWy0a5a7tTg&s'}/>
                                        </div>
                                        <div className={`text-center`}>
                                            {Translator.translateId('label__planned')}
                                        </div>
                                    </div>
                                    <div>
                                        <img className={`w-[100px]`}
                                             src={'https://cdn-icons-png.flaticon.com/512/2004/2004699.png'}/>
                                        <div className={`text-center`}>
                                            {Translator.translateId('label__in_progress')}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <img className={`w-[100px]`}
                                                 src={'https://cdn.prod.website-files.com/61f9082050036c6c4b4899f8/640f389ddcbff27ccb49790e_10%20Browser%20Extensions%20for%20(Non)%20Designers%20-%20main.svg'}/>
                                        </div>
                                        <div className={`text-center`}>
                                            {Translator.translateId('label__soon')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`min-w-[300px] mt-[10px] lg:mt-0`}>
                <div className={`border bg-white rounded-xl p-[10px]`}>
                    <div className={`font-bold`}>
                        {Translator.translateId('label__also_discover')}
                    </div>
                    <div className={`ml-[10px]`}>
                        <PrimaryLink to={'/about-us'} label={Translator.translateId('cta__about_us')}/>
                        <PrimaryLink to={'/roadmap'} label={Translator.translateId('cta__roadmap')}/>
                        <PrimaryLink to={'/team'} label={Translator.translateId('cta__team')}/>
                        {/*<PrimaryLink to={'/ama'} label={Translator.translateId('cta__ask_me_anything')}/>*/}
                        {/*<PrimaryLink to={'/features'} label={Translator.translateId('cta__features')}/>*/}
                        {/*<PrimaryLink to={'/conditions'} label={Translator.translateId('cta__conditions')}/>*/}
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>;
}

function Goal({label, icon}: { label: string, icon: any }) {
    return <div
        className={`border rounded-2xl bg-white p-[10px] lg:w-[calc(calc(100%/4)-7px)] md:w-[calc(calc(100%/3)-7px)] w-full max-w-[250px]`}>
        <div className={`flex justify-center`}>
            {icon}
        </div>
        <div className={`font-bold text-center mt-[10px]`}>
            {label}
        </div>
    </div>;
}
