import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useRef, useState} from "react";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_CREATE_NOTE_FORM_ERROR,
    LABEL_NOTE_NAME,
} from "../Constants/Label";
import {Editor} from "@tinymce/tinymce-react";
import {theme} from "../Signals/ThemeSignal";
import {useNavigate} from "react-router-dom";
import {CREATE_NOTE, CREATE_NOTE_FORM_VALIDATOR, TINYMCE_API_KEY} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function CreateNote() {
    const navigate = useNavigate();
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitError, submitLock} = FormHook(['name', 'content']);
    const [tinyMCEApiKey, setTinyMCEApiKey] = useState<undefined|string>(undefined);
    const editorRef = useRef(null);

    useEffect(() => {
        resetGlobalSearch();
        loadConstrains(CREATE_NOTE_FORM_VALIDATOR);
        NewApiManager.get(
            TINYMCE_API_KEY,
            (response) => setTinyMCEApiKey(response.data)
        );
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (editorRef.current) {
            // @ts-ignore
            setValues({content: editorRef.current.getContent()});
        }
        if (!shouldSubmit) {
            return;
        }
        submitLock();

        NewApiManager.post(
            CREATE_NOTE,
            {
                name: form.fields.name.value,
                content: form.fields.content.value
            },
            (response: any) => navigate(`/notes/${response.data}`),
            () => submitError(LABEL_CREATE_NOTE_FORM_ERROR)
        );
    }

    return <PageTemplate sidebar={`normal`}>
        <div className={`p-[10px]`}>
            {tinyMCEApiKey !== undefined && <form onSubmit={onSubmit}>
                <div className={`flex gap-[10px]`}>
                    <Input className={`w-full`} form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_NOTE_NAME}/>
                    <button type={'submit'} className={`border-${theme.value}-primary-950 border-[1px] p-[10px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>Save</button>
                </div>

                <div className={`mt-[10px]`}>
                {/* @ts-ignore */}
                    <Editor onInit={(_evt, editor) => editorRef.current = editor}
                            apiKey={tinyMCEApiKey}
                            initialValue=""
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                    />
                </div>
            </form>}
        </div>
    </PageTemplate>;
}
