import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_ROW_CREATE_FORM_ERROR,
    LABEL_BOARD_ROW_NAME
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {FormHook} from "../../Hooks/FormHook";
import {useEffect} from "react";
import {CREATE_BOARD_ROW, CREATE_BOARD_ROW_FORM_VALIDATOR} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { Modal } from '../Modal/Modal';

interface RowCreationModalInterface {
    toggle: () => void;
    boardId: string;
    loadBoard: () => void;
}

export function RowCreationModal({toggle, boardId, loadBoard}: RowCreationModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, loadConstrains, submitError, submitLock} = FormHook(['name']);

    useEffect(() => {
        loadConstrains(CREATE_BOARD_ROW_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.post(
            CREATE_BOARD_ROW(boardId),
            {
                name: form.fields.name.value
            },
            () => {
                setValues({name: ''});
                toggle();
                loadBoard();
            },
            () => submitError(LABEL_BOARD_ROW_CREATE_FORM_ERROR)
        );
    }

    return <Modal toggle={toggle} label={Translator.translateId('label__new_row')}>
        <form onSubmit={onSubmit}>
            <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_BOARD_ROW_NAME}
                   className={`min-w-[150px]`}/>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={toggle}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
        </form>
    </Modal>;
}
