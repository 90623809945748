import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {theme} from "../Signals/ThemeSignal";
import Translator from "../Utils/Translator";

export function ExtensionInstalled() {
    return <PageTemplate>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div
                className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div
                        className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__extension_installed')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    {Translator.translateId('label__extension_installed_description')}
                </div>
            </div>
        </div>
    </PageTemplate>;
}