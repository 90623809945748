import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import { BACK_OFFICE_ALL_AUTOMATION_LOGS } from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { AutomationLogInterface } from '../Interfaces/AutomationLogInterface';
import { DateTimeLabel } from '../Components/Common/Date/DateTimeLabel';

export function BackOfficeAutomationLogs() {
    const [logs, setLogs] = useState<AutomationLogInterface[]>([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_ALL_AUTOMATION_LOGS,
            (response: any) => setLogs(response.data)
        );
    }, []);

    const getLogColor = (logStatus: 'success'|'error'|'warning') => {
        switch (logStatus) {
            case 'success': return 'text-green-500';
            case 'error': return 'text-ref-500';
            case 'warning': return 'text-yellow-500';
        }
    }

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_automation_logs'}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                <table className={`bg-white w-full`}>
                    {logs.map((log: AutomationLogInterface) => <tr>
                        <td className={`border p-[10px]`}><DateTimeLabel datetime={log.creationDate.date}/></td>
                        <td className={`border p-[10px]`}>{log.name}</td>
                        <td className={`border p-[10px]`}>{log.description}</td>
                        <td className={`border p-[10px]`}>{log.output}</td>
                        <td className={`border p-[10px] ${getLogColor(log.status)}`}>{log.status.toUpperCase()}</td>
                    </tr>)}
                </table>
            </div>
        </div>
    </PageTemplate>;
}
