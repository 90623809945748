import { DetailsInterface, WeatherInterface } from '../Pages/WeatherInterface';
import moment from 'moment';
import { serverDateTimeToCustom } from './Date';

export function getCurrentWeather(weather: WeatherInterface) {
    return weather.forecast.forecastday[0].hour.filter((hour: DetailsInterface) => Number(moment(hour.time).format('HH')) === Number(moment(serverDateTimeToCustom(weather.location.tz_id)).format('HH')))[0];
}

export function getDayWeathersFromTime(hoursWeather: any, fromTime: string) {
    return hoursWeather.filter((hour: DetailsInterface) => Number(moment(hour.time).format('HH')) >= Number(moment(fromTime).format('HH')));
}