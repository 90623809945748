import { PostInterface } from '../Interfaces/PostInterface';
import { addRef, closeRef } from '../Signals/FirebaseDatabaseSignal';
import { NewApiManager } from '../Utils/NewApiManager';
import { POST } from '../Constants/ApiUrls';

export function postCommentsRefs(post: PostInterface, onCommented: (userId: string) => void) {
    addRef(`post-comments-${post.id}`, (action: any) => {
        const splittedAction = action.performedAction.split(':');
        if (splittedAction.length !== 2) {
            return;
        }
        const actionName = splittedAction[0];
        const commentedUserId = splittedAction[1];
        switch(actionName) {
            case 'commented':
                onCommented(commentedUserId);
                break;
        }
    });
}
export function closePostCommentsRefs(post: PostInterface) {
    closeRef(`post-comments-${post.id}`);
}
