import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_COLUMN_NAME,
    LABEL_BOARD_COLUMN_UPDATE_FORM_ERROR,
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {TrashIcon} from "../../Icons/TrashIcon";
import {BoardColumnInterface} from "../../Interfaces/BoardColumnInterface";
import {useEffect, useState} from "react";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {FormHook} from "../../Hooks/FormHook";
import {CheckIcon} from "../../Icons/CheckIcon";
import {DELETE_BOARD_COLUMN, UPDATE_BOARD_COLUMN, UPDATE_BOARD_COLUMN_FORM_VALIDATOR} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { Modal } from '../Modal/Modal';

interface ColumnUpdateModalInterface {
    toggle: (column?: BoardColumnInterface) => void;
    column: BoardColumnInterface;
    board: BoardInterface;
    loadBoard: () => void;
}

export function ColumnUpdateModal({column, toggle, board, loadBoard}: ColumnUpdateModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, setForm, loadConstrains, submitError, submitLock} = FormHook(['name', 'autoCompleteCard'], {autoCompleteCard: 0});

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string|undefined>(undefined);

    useEffect(() => {
        setValues({
            name: column.name,
            autoCompleteCard: column.autoCompleteCard === 1
        });

        loadConstrains(UPDATE_BOARD_COLUMN_FORM_VALIDATOR);
    }, []);

    const submitUpdateColumn = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            UPDATE_BOARD_COLUMN(board.id, column.id),
            {
                name: form.fields.name.value,
                autoCompleteCard: form.fields.autoCompleteCard.value ? 1 : 0
            },
            () => {
                toggle();
                loadBoard();
            },
            () => submitError(LABEL_BOARD_COLUMN_UPDATE_FORM_ERROR)
        );
    }
    const onDelete = () => {
        NewApiManager.delete(
            DELETE_BOARD_COLUMN(board.id, column.id),
            () => {
                toggle();
                loadBoard();
            },
            (response: any) => {
                if (['column_not_empty'].includes(response.data)) {
                    setDeleteErrorMessage(`label__${response.data}`);
                }
            }
        );
    }

    const toggleAutoComplete = () => {
        setForm({
            ...form,
            fields: {
                ...form.fields,
                autoCompleteCard: {
                    ...form.fields.autoCompleteCard,
                    // @ts-ignore
                    value: !form.fields.autoCompleteCard.value,
                }
            }
        });
    }

    return <Modal toggle={toggle} label={Translator.translateId('label__update_column')}>
        <form onSubmit={submitUpdateColumn}>
            <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_BOARD_COLUMN_NAME}
                   className={`min-w-[150px]`}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                <div
                    className={`p-[5px] w-[40px] h-[40px] border-[1px] border-${theme.value}-primary-950 rounded flex items-center justify-center`}
                    onClick={toggleAutoComplete}>
                    {!form.fields.autoCompleteCard.value ? '' :
                        <CheckIcon className={`w-[20px] h-[20px]`}/>}
                </div>
                <div className={`flex items-center cursor-pointer`} onClick={toggleAutoComplete}>
                    {Translator.translateId('label__board_column_auto_complete')}
                </div>
            </div>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={() => toggle()}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
            {!isDeleting && <div
                className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}
                onClick={() => setIsDeleting(true)}>
                <TrashIcon
                    className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__delete')}
            </div>}
            {isDeleting && <div
                className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`}
                onClick={onDelete}>
                <TrashIcon
                    className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__confirm')}
            </div>}
            {deleteErrorMessage !== undefined &&
                <div className={`text-red-600`}>{Translator.translateId(deleteErrorMessage)}</div>}
        </form>
    </Modal>
}
