import {theme} from "../../Signals/ThemeSignal";
import Input from "../Common/Input/Input";
import {
    LABEL_BOARD_ROW_NAME, LABEL_BOARD_ROW_UPDATE_FORM_ERROR, LABEL_BOARD_ROW_UPDATE_FORM_SUCCESS
} from "../../Constants/Label";
import Translator from "../../Utils/Translator";
import {TrashIcon} from "../../Icons/TrashIcon";
import {BoardRowInterface} from "../../Interfaces/BoardRowInterface";
import {useEffect, useState} from "react";
import {BoardInterface} from "../../Interfaces/BoardInterface";
import {FormHook} from "../../Hooks/FormHook";
import {DELETE_BOARD_ROW, UPDATE_BOARD_ROW, UPDATE_BOARD_ROW_FORM_VALIDATOR} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { Modal } from '../Modal/Modal';

interface RowUpdateModalInterface {
    toggle: (row?: BoardRowInterface) => void;
    row: BoardRowInterface;
    board: BoardInterface;
    loadBoard: () => void;
}

export function RowUpdateModal({row, toggle, board, loadBoard}: RowUpdateModalInterface) {
    const {form, shouldSubmit, handleChange, setValues, setForm, loadConstrains, submitSuccess, submitError, submitLock} = FormHook(['name']);

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string|undefined>(undefined);

    useEffect(() => {
        setValues({
            name: row.name,
        });
        loadConstrains(UPDATE_BOARD_ROW_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();
        NewApiManager.put(
            UPDATE_BOARD_ROW(board.id, row.id),
            {
                name: form.fields.name.value
            },
            () => {
                setForm(() => ({
                    ...form,
                    success: LABEL_BOARD_ROW_UPDATE_FORM_SUCCESS,
                    error: undefined,
                    isLoading: false,
                }));
                toggle();
                loadBoard();
            },
            () => submitError(LABEL_BOARD_ROW_UPDATE_FORM_ERROR)
        );
    }

    const onDelete = () => {
        NewApiManager.delete(
            DELETE_BOARD_ROW(board.id, row.id),
            () => {
                toggle();
                loadBoard();
            },
            (response: any) => {
                if (['row_not_empty'].includes(response.data)) {
                    setDeleteErrorMessage(`label__${response.data}`);
                }
            }
        );
    }

    return <Modal toggle={toggle} label={Translator.translateId('label__update_row')}>
        <form onSubmit={onSubmit}>
            <Input form={form} name={'name'} handleChange={handleChange} placeholder={LABEL_BOARD_ROW_NAME}
                   className={`min-w-[150px]`}/>
            <div className={`flex justify-between mt-[10px] gap-[10px] flex-row-reverse`}>
                <button type={'submit'}
                        className={`w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                <button onClick={() => toggle()}
                        className={`w-full border-[1px] p-[10px] border-red-600 text-${theme.value}-primary-50 rounded bg-red-300`}>{Translator.translateId('cta__cancel')}</button>
            </div>
            {!isDeleting && <div className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`} onClick={() => setIsDeleting(true)}>
                <TrashIcon className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__delete')}
            </div>}
            {isDeleting && <div className={`p-[10px] flex justify-center items-center mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded gap-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50 cursor-pointer`} onClick={onDelete}>
                <TrashIcon className={`fill-${theme.value}-primary-50 w-[20px]`}/> {Translator.translateId('cta__confirm')}
            </div>}
            {deleteErrorMessage !== undefined && <div className={`text-red-600`}>{Translator.translateId(deleteErrorMessage)}</div>}
        </form>
    </Modal>;
}
