import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {theme} from "../Signals/ThemeSignal";
import {FormHook} from "../Hooks/FormHook";
import { useEffect, useState } from "react";
import Input from "../Components/Common/Input/Input";
import {
    CTA_SUBMIT,
    LABEL_CONFIRM_PASSWORD,
    LABEL_EMAIL,
    LABEL_FIRSTNAME,
    LABEL_FORM_RULE,
    LABEL_LASTNAME,
    LABEL_LOGIN,
    LABEL_PASSWORD,
    LABEL_REFERRER_LOGIN,
    LABEL_REGISTER_FORM_ERROR,
    LABEL_REGISTER_FORM_SUCCESS,
    LABEL_REGISTER_UNKNOWN_REFERRER_FORM_ERROR
} from "../Constants/Label";
import Translator from "../Utils/Translator";
import {Link} from "react-router-dom";
import {NewApiManager} from "../Utils/NewApiManager";
import {USER_REGISTER, USER_REGISTER_FORM_VALIDATOR} from "../Constants/ApiUrls";
import { TriangleDownIcon } from '../Icons/TrianglDownIcon';
import { TriangleRightIcon } from '../Icons/TriangleRightIcon';

export function Register() {
    const [referralInputIsVisible, setReferralInputIsVisible] = useState(false);
    const {form, handleChange, setForm, shouldSubmit, submitLock, submitSuccess, submitError, loadConstrains} = FormHook(['firstname', 'lastname', 'email', 'login', 'password', 'confirm-password', 'referrerLogin']);
    const isSamePassword = (cpassword: string) => {
        const passwordConstrainLabelId = `same_password`;
        const errors: string [] = [];
        const success: string[] = [];
        // @ts-ignore
        if (form.fields.password.value !== cpassword) {
            // @ts-ignore
            errors.push(LABEL_FORM_RULE(passwordConstrainLabelId));
        } else {
            // @ts-ignore
            success.push(LABEL_FORM_RULE(passwordConstrainLabelId));
        }

        setForm(() => ({
            ...form,
            fields: {
                ...form.fields,
                'confirm-password': {
                    // @ts-ignore
                    ...form.fields['confirm-password'],
                    errors,
                    success
                }
            }
        }));

        return success.length > 0;
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit() || !isSamePassword(form.fields['confirm-password'].value)) {
            return;
        }
        submitLock();

        let payload = {
            firstname: form.fields.firstname.value,
            lastname: form.fields.lastname.value,
            email: form.fields.email.value,
            login: form.fields.login.value,
            password: form.fields.password.value
        };
        if (referralInputIsVisible) {
            // @ts-ignore
            payload['referrerLogin'] = form.fields['referrerLogin'].value;
        }

        NewApiManager.post(
            USER_REGISTER,
            payload,
            () => submitSuccess(LABEL_REGISTER_FORM_SUCCESS),
            (response: any) => {
                if (response.data === 'referrer_not_found') {
                    submitError(LABEL_REGISTER_UNKNOWN_REFERRER_FORM_ERROR);
                    return;
                }
                submitError(LABEL_REGISTER_FORM_ERROR);
            }
        );
    };

    useEffect(() => {
        loadConstrains(USER_REGISTER_FORM_VALIDATOR);
    }, []);

    return <PageTemplate childrenClassName={`min-h-[800px]`} sidebar={"normal"}>
        <div className={`w-full h-full flex items-center p-[10px]`}>
            <div className={`bg-${theme.value}-primary-50 m-auto w-full max-w-[360px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                <div className={`ml-[10px] mt-[-25px]`}>
                    <div className={`z-normal p-[5px] absolute border-${theme.value}-primary-950 border-[1px] bg-${theme.value}-primary-50 rounded text-3xl`}>{Translator.translateId('label__register')}</div>
                </div>
                <div className={`pt-[50px] p-[10px]`}>
                    <form onSubmit={onSubmit}>
                        <Input className={`mt-[10px]`} form={form} name={'firstname'} handleChange={handleChange} placeholder={LABEL_FIRSTNAME}/>
                        <Input className={`mt-[10px]`} form={form} name={'lastname'} handleChange={handleChange} placeholder={LABEL_LASTNAME}/>
                        <Input className={`mt-[10px]`} form={form} name={'email'} handleChange={handleChange} placeholder={LABEL_EMAIL}/>
                        <Input className={`mt-[10px]`} form={form} name={'login'} handleChange={handleChange} placeholder={LABEL_LOGIN}/>
                        <Input className={`mt-[10px]`} type={"password"} form={form} name={'password'} handleChange={(e: any) => {handleChange(e); isSamePassword(e.target.value)}} placeholder={LABEL_PASSWORD}/>
                        <Input className={`mt-[10px]`} type={"password"} form={form} name={'confirm-password'} handleChange={(e: any) => {handleChange(e); isSamePassword(e.target.value)}} placeholder={LABEL_CONFIRM_PASSWORD}/>
                        <div className={`mt-[10px]`}>
                            <div className={`items-center flex gap-[5px] cursor-pointer`} onClick={() => setReferralInputIsVisible(() => !referralInputIsVisible)}>
                                {referralInputIsVisible ? <TriangleDownIcon className={`w-[20px]`}/> : <TriangleRightIcon className={`w-[20px]`}/>}
                                {Translator.translateId('label__register_with_referrer')}
                            </div>
                            {referralInputIsVisible && <div>
                                <Input form={form} name={'referrerLogin'} handleChange={handleChange} placeholder={LABEL_REFERRER_LOGIN}/>
                            </div>}
                        </div>
                        <Link to={'/log-in'}><div className={`text-right pt-[10px] text-sm underline`}>{Translator.translateId('cta__register_to_log_in')}</div></Link>
                        {form.error !== undefined && <div className={`mt-[10px] bg-red-200 border-[1px] border-red-300 text-red-700 rounded p-[10px]`}>{Translator.translate(form.error)}</div>}
                        {form.success !== undefined && <div className={`mt-[10px] bg-green-200 border-[1px] border-green-300 text-green-700 rounded p-[10px]`}>{Translator.translate(form.success)}</div>}
                        <div className={"flex justify-center mt-[20px]"}>
                            <button type={"submit"} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded`}>
                                {Translator.translate(CTA_SUBMIT)}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </PageTemplate>;
}
