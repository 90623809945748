import { signal } from '@preact/signals-react';
import { NewApiManager } from '../Utils/NewApiManager';
import { TIME } from '../Constants/ApiUrls';
import moment from 'moment-timezone';

export const serverDateTime = signal<undefined|any>(undefined);
let isFetched = false;

initServerDateTime();

function initServerDateTime() {
    if (undefined === serverDateTime.value && !isFetched) {
        isFetched = true;
        NewApiManager.get(
            TIME,
            (response: any) => {
                serverDateTime.value = response.data;
                updateDateTime();
            },
        );
    }
}

function updateDateTime() {
    setTimeout(() => {
        if (serverDateTime.value !== undefined) {
            serverDateTime.value = moment(serverDateTime.value).add(1, 'second').format('Y-MM-DD HH:mm:ss');
        }

        updateDateTime()
    }, 1000);
}