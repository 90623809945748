import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {useEffect, useState} from "react";
import {NewsConfigInterface} from "../Interfaces/NewsConfigInterface";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {FlagIcon} from "../Icons/FlagIcon";
import {CrossRoundedIcon} from "../Icons/CrossRoundedIcon";
import {CheckRoundedIcon} from "../Icons/CheckRoundedIcon";
import Translator from "../Utils/Translator";
import {convertDateTime} from "../Utils/Formatter";
import {BACK_OFFICE_ALL_NEWS_CONFIGS} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeNewsConfigs() {
    const [configs, setConfigs] = useState<NewsConfigInterface[]|undefined>();

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_ALL_NEWS_CONFIGS,
            (response: any) => setConfigs(response.data)
        );
    }, []);

    const getPositionTile = (position: 'position_1'|'position_2'|'position_3') => {
        switch (position) {
            case 'position_1':
                return <div className={`rounded-xl bg-yellow-500 text-black p-[5px] font-bold`}>{Translator.translateId('label__news_position_1')}</div>;
            case "position_2":
                return <div className={`rounded-xl bg-blue-500 text-white p-[5px] font-bold`}>{Translator.translateId('label__news_position_2')}</div>;
            case "position_3":
                return <div className={`rounded-xl bg-gray-500 text-white p-[5px] font-bold`}>{Translator.translateId('label__news_position_3')}</div>;
        }
    }

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/back-office'} label={'label__back_office_news_configs'}/>
                <Link to={`/back-office/news-configs/create`} className={`flex border-[1px] border-${theme.value}-primary-950 justify-center items-center p-[10px] bg-${theme}-primary-300 text-xl rounded`}>
                    {Translator.translateId('cta__new')}
                </Link>
            </div>
            <div className={`mt-[10px]`}>
                {configs !== undefined && configs.map((config: NewsConfigInterface) => <Link to={`/back-office/news-configs/${config.id}/update`} key={`news-${config.id}`} className={`block border-[1px] border-${theme.value}-primary-950 mb-[10px] p-[10px] rounded`}>
                    <div className={`flex gap-[10px] items-center`}>
                        <div>
                            {config.lastExecutionDateTime === null && <FlagIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                            {config.lastExecutionDateTime !== null && config.isBroken && <CrossRoundedIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                            {config.lastExecutionDateTime !== null && !config.isBroken && <CheckRoundedIcon className={`w-[20px] stroke-${theme.value}-primary-950`}/>}
                        </div>
                        <div>{getPositionTile(config.position)}</div>
                        <div className={`font-bold`}>
                            {config.name}
                        </div>
                        <div>
                            {config.description}
                        </div>
                    </div>
                    <div className={`flex justify-end text-${theme.value}-primary-500 italic`}>
                        {convertDateTime(config.lastSuccessDateTime)}
                        {config.lastSuccessDateTime === null && Translator.translateId('label__never_worked')}
                    </div>
                </Link>)}
            </div>
        </div>
    </PageTemplate>;
}
