import {theme} from "../../../Signals/ThemeSignal";
import TogglerHook from "../../../Hooks/Toggler/TogglerHook";
import moment from 'moment';
import {useEffect, useState} from "react";
import {TriangleUpIcon} from "../../../Icons/TriangleUpIcon";
import {TriangleDownIcon} from "../../../Icons/TrianglDownIcon";
import Translator from "../../../Utils/Translator";
import {DateTimeLabel} from "../Date/DateTimeLabel";

interface InputDateTimeInterface {
    form: any;
    label: string;
    name: string;
    setValues: (data: any, validateInputs: boolean) => void;
    className?: string;
}

export function InputDateTime({form, label, name, setValues, className = ''}: InputDateTimeInterface) {
    const {isVisible, toggleVisibility, ref} = TogglerHook();
    const now = moment().add(1, "day");

    const loadDateTime = () => {
        let dateTime = form.fields[name].value;
        if (dateTime === '' || !moment(dateTime).isValid()) {
            dateTime = now.format('YYYY-MM-DD HH:mm');
        }

        setValues({
            [name]: dateTime,
        }, true);
    }

    const removeDate = () => {
        const values: any = {};
        values[name] = '';

        setValues(values, true);
    }

    return <div className={`border-[1px] border-${theme.value}-primary-950 rounded ${className}`} onClick={() => toggleVisibility(undefined, true)} ref={ref}>
        {!isVisible ? <>
            {form.fields[name].value === '' ? <div className={`p-[10px]`} onClick={loadDateTime}>
                {label}
            </div> : <div className={`p-[10px] flex justify-center`}>
                <DateTimeLabel datetime={form.fields[name].value}/>
            </div>}
        </> : <>
            {form.fields[name].value !== '' ? <div className={`text-center`}>
                <DateSelector form={form} name={name} setValues={setValues}/>
                <div className={`border-b-[1px] border-${theme.value}-primary-950`}></div>
                <TimeSelector form={form} name={name} setValues={setValues}/>
                <div className={`border-b-[1px] border-${theme.value}-primary-950`}></div>
                <div onClick={(e: any) => {
                    removeDate();
                    toggleVisibility(e, false);
                }}
                     className={`cursor-pointer border-[1px] border-${theme.value}-primary-950 m-[10px] rounded p-[10px] bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>
                    {Translator.translateId('cta__cancel')}
                </div>
            </div> : <div className={`p-[10px]`} onClick={loadDateTime}>
                {label}
            </div>}
        </>}
    </div>;
}

interface DateSelectorInterface {
    form: any;
    name: string;
    setValues: (data: any, validateInputs: boolean) => void;
}
function DateSelector({form, name, setValues}: DateSelectorInterface) {
    const [day, setDay] = useState(form.fields[name].value.split(' ')[0].split('-')[2]);
    const [month, setMonth] = useState(form.fields[name].value.split(' ')[0].split('-')[1]);
    const [year, setYear] = useState(form.fields[name].value.split(' ')[0].split('-')[0]);

    useEffect(() => {
        const values: any = {};
        values[name] = `${year}-${month}-${day} ${form.fields[name].value.split(' ')[1]}`;

        setValues(values, true);
    }, [day, month, year]);

    const dayUp = () => {
        let nextDay = Number(day)+1;
        if (
            nextDay > 31
            || !moment(`${year}-${month}-${nextDay} ${form.fields[name].value.split(' ')[1]}`).isValid()
        ) {
            nextDay = 1
            monthUp();
        }
        setDay(nextDay.toString().padStart(2, '0'));
    };

    const dayDown = () => {
        let nextDay = Number(day)-1;
        if (nextDay < 1) {
            nextDay = 31;
            const nextMonth = Number(month)>1 ? Number(month)-1 : 12;
            while (!moment(`${year}-${(nextMonth).toString().padStart(2, '0')}-${nextDay} ${form.fields[name].value.split(' ')[1]}`).isValid()) {
                nextDay-=1;
            }
            monthDown();
        }
        setDay(nextDay.toString().padStart(2, '0'));
    };

    const monthUp = () => {
        let nextMonth = Number(month)+1;
        let currentDay = day;

        if (nextMonth > 12) {
            nextMonth = 1;
            yearUp();
        }

        while (!moment(`${year}-${nextMonth.toString().padStart(2, '0')}-${currentDay.toString().padStart(2, '0')} ${form.fields[name].value.split(' ')[1]}`).isValid()) {
            currentDay-=1;
        }
        if (currentDay !== day)  {
            setDay(currentDay);
        }
        setMonth(nextMonth.toString().padStart(2, '0'));
    };

    const monthDown = () => {
        let nextMonth = Number(month)-1;
        if (nextMonth < 1) {
            nextMonth = 12;
            yearDown();
        }
        setMonth(nextMonth.toString().padStart(2, '0'));
    };

    const yearUp = () => {
        setYear((Number(year)+1).toString());
    };

    const yearDown = () => {
        let nextYear = Number(year)-1;
        if (nextYear < 1) {
            nextYear = moment().year();
        }
        setYear(nextYear.toString());
    };

    return <div>
        <div className={`font-bold`}>
            {Translator.translateId('label__date')}
        </div>
        <div className={`flex justify-center p-[10px]`}>
            <div>
                <div className={`flex justify-center`}><TriangleUpIcon onClick={dayUp}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
                <div className={`p-[5px] border-[1px] border-${theme.value}-primary-950 rounded`}>{day}</div>
                <div className={`flex justify-center`}><TriangleDownIcon onClick={dayDown}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
            </div>
            <div className={`flex items-center p-[10px]`}>
                /
            </div>
            <div>
                <div className={`flex justify-center`}><TriangleUpIcon onClick={monthUp}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
                <div className={`p-[5px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                    {Translator.translateId(`label__month_${month}_short`)}
                </div>
                <div className={`flex justify-center`}><TriangleDownIcon onClick={monthDown}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
            </div>
            <div className={`flex items-center p-[10px]`}>
                /
            </div>
            <div>
                <div className={`flex justify-center`}><TriangleUpIcon onClick={yearUp}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
                <div className={`p-[5px] border-[1px] border-${theme.value}-primary-950 rounded`}>{year}</div>
                <div className={`flex justify-center`}><TriangleDownIcon onClick={yearDown}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
            </div>
        </div>
    </div>;
}

interface TimeSelectorInterface {
    form: any;
    name: string;
    setValues: (data: any, validateInputs: boolean) => void;
}

function TimeSelector({form, name, setValues}: TimeSelectorInterface) {
    const [hours, setHours] = useState(form.fields[name].value.split(' ')[1].split(':')[0]);
    const [minutes, setMinutes] = useState(form.fields[name].value.split(' ')[1].split(':')[1]);


    useEffect(() => {
        const values: any = {};
        values[name] = `${form.fields[name].value.split(' ')[0]} ${hours}:${minutes}:00`;

        setValues(values, true);
    }, [hours, minutes]);

    const hoursUp = () => {
        let nextHours = Number(hours)+1;
        if (nextHours > 23) {
            nextHours = 0
        }
        setHours(nextHours.toString().padStart(2, '0'));
    };

    const hoursDown = () => {
        let nextHours = Number(hours)-1;
        if (nextHours < 0) {
            nextHours = 23;
        }
        setHours(nextHours.toString().padStart(2, '0'));
    };

    const minutesUp = () => {
        let nextMonth = Number(minutes)+1;
        if (nextMonth > 59) {
            nextMonth = 0;
            hoursUp();
        }
        setMinutes(nextMonth.toString().padStart(2, '0'));
    };

    const minutesDown = () => {
        let nextMinutes = Number(minutes)-1;
        if (nextMinutes < 0) {
            nextMinutes = 59;
            hoursDown();
        }
        setMinutes(nextMinutes.toString().padStart(2, '0'));
    };
    return <div>
        <div className={`font-bold`}>
            {Translator.translateId('label__hours')}
        </div>
        <div className={`flex justify-center py-[5px]`}>
            <div>
                <div className={`flex justify-center`}><TriangleUpIcon onClick={hoursUp}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
                <div className={`p-[10px] border-[1px] border-${theme.value}-primary-950 rounded`}>{hours}</div>
                <div className={`flex justify-center`}><TriangleDownIcon onClick={hoursDown}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
            </div>
            <div className={`flex items-center p-[10px]`}>
                :
            </div>
            <div>
                <div className={`flex justify-center`}><TriangleUpIcon onClick={minutesUp}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
                <div className={`p-[10px] border-[1px] border-${theme.value}-primary-950 rounded`}>{minutes}</div>
                <div className={`flex justify-center`}><TriangleDownIcon onClick={minutesDown}
                    className={`w-[20px] stroke-${theme.value}-primary-950`}/></div>
            </div>
        </div>
    </div>;
}
