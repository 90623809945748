import { PostBasicInterface } from '../../Interfaces/PostBasicInterface';
import Translator from '../../Utils/Translator';
import { useState } from 'react';
import { CreatePostBasicModal } from './CreatePostBasicModal';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { Card } from './Card';
import { PostCouponInterface } from '../../Interfaces/PostCouponInterface';

interface CardsInterface {
    posts: PostBasicInterface[]|PostCouponInterface[]
    setPosts: (posts: PostBasicInterface[]|PostCouponInterface[]) => void;
    className?: string;
    limit?: number;
    showMore?: boolean;
    loadPosts?: () => void;
    canWritePost?: boolean;
}

export function Cards({posts, className = '', limit = 0, showMore = false, loadPosts = () => {}, setPosts, canWritePost = true}: CardsInterface) {
    const [postBasicModalVisible, setPostBasicModalVisible] = useState(false);

    const togglePostModal = () => {
        setPostBasicModalVisible(() => !postBasicModalVisible);
    }

    return <>
        {postBasicModalVisible && canWritePost && <CreatePostBasicModal toggleModal={togglePostModal} submitSuccessCallback={(response: any) => {togglePostModal(); loadPosts();}}/>}
        <div className={`${className} bg-white border rounded-xl w-full`}>
            <div>
                <div className={`p-[20px]`}>
                    <div className={`text-[25px] font-bold`}>
                        {Translator.translateId('label__activities')}
                    </div>
                    {canWritePost && <div className={`flex my-[10px]`}>
                        <PrimaryButton onClick={togglePostModal}>{Translator.translateId('label__create_post')}</PrimaryButton>
                    </div>}
                    <div className={` mt-[20px]`}>
                        {posts.length === 0 ? <div className={`italic`}>
                            {Translator.translateId('label__no_posts')}
                        </div> : posts.slice(0, limit === 0 ? posts.length : limit).map((post: PostBasicInterface, index: number) =>
                            <Card posts={posts} setPosts={setPosts} key={`post-${index}`} post={post} className={`${index > 0 ? 'border-t pt-[10px]' : ''}`}/>)}
                    </div>
            </div>
            {showMore && limit > 0 && posts?.length > limit &&
                <div className={`font-bold underline p-[20px] border-t text-center`}>
                    {Translator.translateId('label__show_more')}
                </div>
            }
            </div>
        </div>
    </>;
}
