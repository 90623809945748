interface StoryPointTileInterface {
    backgroundColor: string;
    textColor: string;
    name: string;
    points: string|number;
    className?: string;
}

export function StoryPointTile({name, textColor, backgroundColor, points, className = ''}: StoryPointTileInterface) {
    return <div className={`w-[75px] truncate text-ellipsis  p-[5px] rounded text-center ${backgroundColor === 'white' && 'border border-black'} ${className}`} style={{color: textColor, backgroundColor: backgroundColor}} title={`${name} - ${points}`}>
        {name}
    </div>;
}