import { theme } from '../../Signals/ThemeSignal';
import { FormHook } from '../../Hooks/FormHook';
import { NewApiManager } from '../../Utils/NewApiManager';
import {
    CREATE_POST_BASIC, CREATE_POST_BASIC_FORM_VALIDATOR,
} from '../../Constants/ApiUrls';
import { useEffect } from 'react';
import { Dropdown } from '../Common/Input/Dropdown';
import Translator from '../../Utils/Translator';
import TextArea from '../Common/Input/Textarea';
import { LABEL_CREATE_POST_BASIC_PLACEHOLDER } from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { userAccount } from '../../Signals/UserAccountSignal';

interface CreatePostModalInterface {
    toggleModal?: () => void;
    submitSuccessCallback?: (response: any) => void;
}

export function CreatePostBasicModal({toggleModal = () => {}, submitSuccessCallback = () => {}}: CreatePostModalInterface) {
    const {loadConstrains, shouldSubmit, form, handleChange, setValues} = FormHook(['content', 'entityVisibility']);

    useEffect(() => {
        loadConstrains(CREATE_POST_BASIC_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.post(
            CREATE_POST_BASIC,
            {
                entityVisibility: form.fields.entityVisibility.value,
                content: form.fields.content.value
            },
            (response: any) => {
                submitSuccessCallback(response);
                setValues({
                    content: '',
                    entityVisibility: '',
                })
            }
        );
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:max-w-[500px] w-[calc(100%-20px)]`}>
            <div className={`bg-${theme.value}-primary-50 rounded-xl`}>
                <form>
                    <div className={`p-[20px]`}>
                        <div className={`flex mb-[20px] gap-[10px]`}>
                            <div className={`w-[80px] h-[80px] flex justify-center items-center rounded-full bg-gray-300`}>
                                <ProfileIcon className={`w-[40px]`}/>
                            </div>
                            <div>
                                <div className={`font-bold text-[20px]`}>{userAccount.value?.login}</div>
                                <Dropdown form={form} name={'entityVisibility'} values={[
                                    {
                                        id: '',
                                        label: Translator.translateId('label__choose_visibility')
                                    },
                                    {
                                        id: 'public',
                                        label: Translator.translateId('label__visibility_public')
                                    },
                                    {
                                        id: 'followers',
                                        label: Translator.translateId('label__visibility_followers')
                                    },
                                    {
                                        id: 'me',
                                        label: Translator.translateId('label__visibility_me')
                                    },
                                ]} setValues={setValues}/>
                            </div>
                        </div>
                        <TextArea form={form} name={'content'} handleChange={handleChange} className={`mt-[10px]`} textareaClassName={`min-h-[300px]`} placeholder={LABEL_CREATE_POST_BASIC_PLACEHOLDER}/>
                    </div>
                    <div className={`border-t p-[20px] flex justify-end`}>
                        <PrimaryButton onClick={onSubmit}>{Translator.translateId('cta__share')}</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    </>;
}
