import { Modal } from '../Modal/Modal';
import Translator from '../../Utils/Translator';
import { FormHook } from '../../Hooks/FormHook';
import TextArea from '../Common/Input/Textarea';
import { LABEL_COMMENT } from '../../Constants/Label';
import { Card } from './Card';
import { PostBasicInterface } from '../../Interfaces/PostBasicInterface';
import { PostCouponInterface } from '../../Interfaces/PostCouponInterface';
import { isLoggedIn, userAccount } from '../../Signals/UserAccountSignal';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { useEffect, useState } from 'react';
import { CREATE_POST_COMMENT, CREATE_POST_COMMENT_FORM_VALIDATOR, POST_COMMENTS } from '../../Constants/ApiUrls';
import { NewApiManager } from '../../Utils/NewApiManager';
import { postCommentsRefs } from '../../FirebaseRefs/PostComments';
import { PostCommentInterface } from '../../Interfaces/PostCommentInterface';
import { DateTimeLabel } from '../Common/Date/DateTimeLabel';
import { utcDateTimeToLocal } from '../../Utils/Date';
import { SecondaryButton } from '../Common/Button/SecondaryButton';

interface CommentModalInterface {
    post: PostBasicInterface|PostCouponInterface;
    toggle: () => void;
    posts: PostBasicInterface[]|PostCouponInterface[];
    setPosts: (posts: PostBasicInterface[]|PostCouponInterface[]) => void;
}
let latestCommentDate: string|undefined = undefined;
let olderCommentDate: string|undefined = undefined;
let oldComments: PostCommentInterface[] = [];

export function CommentModal({post, toggle, posts, setPosts}: CommentModalInterface) {
    const {form, loadConstrains, shouldSubmit, handleChange, setValues} = FormHook(['comment']);
    const [comments, setComments] = useState<PostCommentInterface[]>([]);
    const [hasNewComment, setHasNewComment] = useState<boolean>(false);
    const [hasOlderComments, setHasOlderComments] = useState<boolean>(false);

    useEffect(() => {
        loadConstrains(CREATE_POST_COMMENT_FORM_VALIDATOR);
        postCommentsRefs(post, onCommentedPost);
        loadComments(5);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (shouldSubmit()) {
            postCommentsRefs(post, onCommentedPost);

            NewApiManager.post(
                CREATE_POST_COMMENT(post.id),
                {comment: form.fields.comment.value},
                (response: any) => {
                    setValues({comment: ''});
                }
            )
        }
    }

    const loadComments = (limit: number = 0, enableRef: boolean = false, loadOldComments: boolean = false) => {
        NewApiManager.get(
            POST_COMMENTS(post.id, limit, !loadOldComments ?  latestCommentDate : undefined, loadOldComments ? olderCommentDate : undefined),
            (response: any) => {
                if (!loadOldComments) {
                    setComments([
                        ...oldComments,
                        ...response.data.comments,
                    ]);
                } else {
                    setComments([
                        ...response.data.comments,
                        ...oldComments,
                    ]);
                }
                setHasNewComment(false);
                setHasOlderComments(response.data.hasOlderComments);

                if (enableRef) {
                    postCommentsRefs(post, onCommentedPost);
                }
            }
        )
    }

    const onCommentedPost = (userId: string) => {
        loadComments(0);

        postCommentsRefs(post, onCommentedPost);
    }

    oldComments = comments;
    latestCommentDate = comments[comments.length-1]?.creationDate.date;
    olderCommentDate = comments[0]?.creationDate.date;

    return <Modal toggle={toggle} label={Translator.translateId('label__comments')}>
        <Card post={post} showFakeCommentBlock={false} className={`mt-0`} posts={posts} setPosts={setPosts}/>
        <div className={`mt-[10px] border-t pt-[10px]`}/>
        {hasOlderComments && <div className={`flex justify-center py-[10px]`}>
            <SecondaryButton onClick={() => loadComments(5, false, true)}>
                {Translator.translateId('cta__load_older_comments_in_post')}
            </SecondaryButton>
        </div>}
        {comments.length > 0 ? comments.map((comment: PostCommentInterface, index: number) => <div
            key={`post-comment-${index}`} className={`${index > 0 ? 'mt-[10px]' : ''}`}>
            <div className={`flex gap-[10px] items-start`}>
                <div className={`rounded-full w-[50px] h-[50px] bg-gray-200 flex justify-center items-center`}>
                    {userAccount.value !== undefined && userAccount.value.profileImage !== null ?
                        <img src={userAccount.value.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                        <ProfileIcon className={`w-[20px] h-[20px] fill-black`}/>}
                </div>
                <div className={`w-full`}>
                    <div className={`font-bold`}>{comment.userAccount.login}</div>
                    <div className={`text-gray-400`}><DateTimeLabel
                        datetime={utcDateTimeToLocal(comment.creationDate.date)}/></div>
                    <div>
                        {comment.comment}
                    </div>
                </div>
            </div>
        </div>) : <div className={`text-center italic`}>
            {Translator.translateId(isLoggedIn() ? 'label__be_first_to_comment' : 'label__log_in_and_be_first_to_comment')}
        </div>}
        {hasNewComment && <div className={`flex justify-center py-[10px]`}>
            <SecondaryButton onClick={() => loadComments(0, true)}>
                {Translator.translateId('cta__new_comments_in_post')}
            </SecondaryButton>
        </div>}
        {isLoggedIn() && <div className={`mt-[10px] pb-[10px]`}>
            <div className={`flex gap-[10px] items-start`}>
                <div className={`rounded-full w-[50px] h-[50px] bg-gray-200 flex justify-center items-center`}>
                    {userAccount.value !== undefined && userAccount.value.profileImage !== null ?
                        <img src={userAccount.value.profileImage.url} alt={Translator.translateId('label__profile_image')} className={`w-full h-full bg-white rounded-full`}/>:
                        <ProfileIcon className={`w-[20px] h-[20px] fill-black`}/>}
                </div>
                <div className={`w-full`}>
                    <div className={`font-bold`}>{post.userAccount.login}</div>
                    <div className={`border rounded`}>
                        <form>
                            <TextArea autoFocus={true} form={form} name={'comment'} handleChange={handleChange}
                                      textareaClassName={`h-[50px]`} placeholder={LABEL_COMMENT}/>
                            <div className={`p-[10px] flex justify-end`}>
                                <PrimaryButton onClick={onSubmit}>
                                    {Translator.translateId('cta_comment')}
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>}
    </Modal>;
}
