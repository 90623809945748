import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {
    BACK_OFFICE_ALL_ADMINISTRATORS,
    BACK_OFFICE_ALL_USERS, BACK_OFFICE_BUG_REPORTS, BACK_OFFICE_CONTACT_ADMINS,
    BACK_OFFICE_SPACESHARE_RATES
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { UserInterface } from '../Interfaces/UserInterface';
import { RateInterface } from '../Interfaces/RateInterface';
import { BugReportInterface } from '../Interfaces/BugReportInterface';
import { ContactAdminInterface } from '../Interfaces/ContactAdminInterface';

export function BackOfficeContactAdmins() {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_CONTACT_ADMINS,
            (response: any) => setContacts(response.data)
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {contacts.map((contact: ContactAdminInterface, index) => <div key={`contact-${index}`}>
                    {contact.userAccount.login} to {contact.admin.login} {contact.subject} {contact.message}
                </div>)}
            </div>
        </div>
    </PageTemplate>;
}
