import { signal } from '@preact/signals-react';
import moment from 'moment';
import { GeolocationInterface } from '../Interfaces/GeolocationInterface';
import { LocalStorage } from '../Utils/LocalStorage';

const LSItem = 'geolocation';
export const geolocation = signal<undefined|GeolocationInterface>(LocalStorage.get(LSItem) ?? undefined);
export let lastPositionsUpdate: any|undefined = undefined;

export function getCurrentPosition(forceUpdate: boolean = false) {
    navigator.geolocation.getCurrentPosition((position) => {
        let shouldUpdatePositions = true;

        if (lastPositionsUpdate !== undefined) {
            const minutesPassed = lastPositionsUpdate.diff(moment(), 'minute');
            shouldUpdatePositions = minutesPassed > 120;
        }

        if (shouldUpdatePositions || forceUpdate) {
            geolocation.value = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            };
            lastPositionsUpdate = moment();
        }
    });
}

export function initGeolocation() {
    navigator.permissions.query({name:'geolocation'}).then(function(result) {
        // if (result.state !== 'granted') {
        //     return;
        // }

        getCurrentPosition();
    });
}