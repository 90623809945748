import {playingMediaList} from "../Signals/PlayingMediaListSignal";
import {CREATE_YOUTUBE_VIDEO_STATISTIC} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

let previousVideoId: string|null = null;
let videoPlayedTimeReachedTimeout: any = null;

export function resetYoutubeVideoStatisticState() {
    previousVideoId = null;
}

export function savePlayStatistic(videoId: string) {
    NewApiManager.post(
        CREATE_YOUTUBE_VIDEO_STATISTIC(videoId, 'play'),
        {
            previousVideoId
        },
        () => previousVideoId = videoId
    );
    savePlayedTimeReached(videoId);
}

export function saveCompletedStatistic(videoId: string) {
    NewApiManager.post(
        CREATE_YOUTUBE_VIDEO_STATISTIC(videoId, 'completed'),
        {
            previousVideoId
        }
    );
}

export function savePlayedTimeReached(videoId: string) {
    clearTimeout(videoPlayedTimeReachedTimeout);
    videoPlayedTimeReachedTimeout = setTimeout(() => {
        if (playingMediaList.value.status === 'play') {
            NewApiManager.post(
                CREATE_YOUTUBE_VIDEO_STATISTIC(videoId, 'playedTimeReached'),
                {
                    previousVideoId
                }
            );
        } else {
            savePlayedTimeReached(videoId);
        }
    }, 30000);
}