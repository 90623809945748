import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";

export function YoutubeSettings() {
    return <PageTemplate sidebar={"normal"}>
        <div className={'p-[10px]'}>
            <BackButton link={'/youtube'} label={'cta__youtube'}/>
            <div className={`mt-[10px] text-2xl underline`}>
                {Translator.translateId('label__my_youtube_api_keys')}
            </div>
        </div>
    </PageTemplate>;
}
