import { theme } from '../../Signals/ThemeSignal';
import Translator from '../../Utils/Translator';
import { FormHook } from '../../Hooks/FormHook';
import { Dropdown } from '../Common/Input/Dropdown';
import { ContactRateForm } from './ContactRateForm';
import { isLoggedIn } from '../../Signals/UserAccountSignal';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { ContactBugReportForm } from './ContactBugReportForm';
import { ContactAdminForm } from './ContactAdminForm';

interface ContactUsModalInterface {
    toggleModal: () => void;
}

export function ContactUsModal({toggleModal}: ContactUsModalInterface) {
    const {form, setValues} = FormHook(['contactType'], {contactType: 'rating'});

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`} onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px] max-w-[350px]`}>
                <div className={`text-2xl`}>
                    <Dropdown form={form} name={'contactType'} values={[
                        {
                            id: 'rating',
                            label: Translator.translateId('label__contact_type_rating')
                        },
                        {
                            id: 'bug',
                            label: Translator.translateId('label__contact_type_bug')
                        },
                        {
                            id: 'admin',
                            label: Translator.translateId('label__contact_type_admin')
                        },
                    ]} setValues={setValues}/>
                </div>
                <div className={`my-[10px] border-t`}></div>
                {isLoggedIn() ? <div>
                    {form.fields.contactType.value === 'rating' && <div>
                        <ContactRateForm/>
                    </div>}
                    {form.fields.contactType.value === 'bug' && <div>
                        <ContactBugReportForm/>
                    </div>}
                    {form.fields.contactType.value === 'admin' && <div>
                        <ContactAdminForm/>
                    </div>}
                </div> : <div>
                    <div>
                        {Translator.translateId('label__log_in_to_contact_us')}
                    </div>
                    <div className={`flex gap-[10px] justify-center mt-[10px] items-center`}>
                        <PrimaryButton onClick={() => document.location.href = '/register'}>
                            {Translator.translateId('cta__register')}
                        </PrimaryButton>
                        <PrimaryButton onClick={() => document.location.href = '/log-in'}>
                            {Translator.translateId('cta__log_in')}
                        </PrimaryButton>
                    </div>
                </div>}
            </div>
        </div>
    </>;
}
