import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {
    BACK_OFFICE_ALL_ADMINISTRATORS,
    BACK_OFFICE_ALL_USERS, BACK_OFFICE_BUG_REPORTS,
    BACK_OFFICE_SPACESHARE_RATES
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { UserInterface } from '../Interfaces/UserInterface';
import { RateInterface } from '../Interfaces/RateInterface';
import { BugReportInterface } from '../Interfaces/BugReportInterface';

export function BackOfficeBugReports() {
    const [bugs, setBugs] = useState([]);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_BUG_REPORTS,
            (response: any) => setBugs(response.data)
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {bugs.map((bug: BugReportInterface, index) => <div key={`bug-${index}`}>
                    {bug.userAccount.login} {bug.title} {bug.message}
                </div>)}
            </div>
        </div>
    </PageTemplate>;
}
