import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {VideoDataInterface} from "../Interfaces/VideoDataInterface";
import {YoutubeVideoCard} from "../Components/YoutubeVideo/YoutubeVideoCard";
import {resetGlobalSearch} from "../Signals/GlobalSearchSignal";
import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {PlaylistInterface} from "../Interfaces/PlaylistInterface";
import Translator from "../Utils/Translator";
import {PlayIcon} from "../Icons/PlayIcon";
import {
    addToListProcess,
    getPlayingVideo,
    removeAllProcess, updatePlayerInfo
} from "../Signals/PlayingMediaListSignal";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {PLAYLIST} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import { theme } from '../Signals/ThemeSignal';

export default function Playlist() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [playlist, setPlaylist] = useState<undefined|PlaylistInterface>(undefined);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    useEffect(() => {
        resetGlobalSearch();
        loadPlaylist();
    }, []);

    const loadPlaylist = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.get(
            PLAYLIST(id),
            (response: any) => setPlaylist(response.data)
        );
    }

    const playWholeList = () => {
        if (playlist?.items === undefined) {
            return;
        }
        removeAllProcess();
        addVideosToPlayer([...playlist.items], true);
    }

    const addVideosToPlayer = (videoList: VideoDataInterface[], play: boolean = false) => {
        addToListProcess(videoList[0], play, false);
        videoList.splice(0, 1);
        if (videoList.length > 0) {
            setTimeout(() => {
                addVideosToPlayer(videoList);
            }, 20);
        } else {
            updatePlayerInfo();
        }
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const deleteCurrentPlaylist = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            PLAYLIST(id),
            () => navigate(`/youtube`)
        );
    }

    const playingVideo = getPlayingVideo();

    return <PageTemplate sidebar={"normal"}>
        <DeleteConfirmationModal message={Translator.translateId('label__playlist_delete_confirmation_message')} deleteAction={deleteCurrentPlaylist} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        {playlist === undefined ? <></> : <div className={'p-[10px]'}>
            <div className={`text-2xl mt-[10px] flex justify-between`}>
                <div className={`flex font-bold underline gap-[10px] items-center`}>
                    {playlist.videosCounter !== undefined && playlist.videosCounter > 0 &&
                        <PlayIcon className={`w-[25px] cursor-pointer`} onClick={playWholeList}/>}
                    {playlist.name}
                </div>
                <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`}
                        onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
            </div>
            <div>
                {Translator.translateId('label__videos_counter', undefined, {counter: playlist.videosCounter})}
            </div>
            <div className={`mt-[10px] flex gap-[10px] flex-wrap`}>
                {playlist?.items?.filter((result: VideoDataInterface) => result?.videoId !== undefined).map((result: VideoDataInterface, index: number) =>
                    <YoutubeVideoCard playlistId={playlist.id} loadPlaylist={loadPlaylist} key={`video-${index}`} videoData={result}
                                      className={`xl:w-[calc(100%/4-8px)] lg:w-[calc(100%/3-8px)] md:w-[calc(100%/3-8px)] sm:w-[calc(100%/2-8px)] w-full cursor-pointer ${result.videoId === playingVideo?.videoId && `bg-${theme.value}-primary-300 p-[10px] rounded`}`}/>)}
            </div>
        </div>}
    </PageTemplate>;
}