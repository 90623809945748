import { theme } from '../../Signals/ThemeSignal';
import { FormHook } from '../../Hooks/FormHook';
import { NewApiManager } from '../../Utils/NewApiManager';
import { DELETE_COUPON, UPDATE_COUPON, UPDATE_COUPON_FORM_VALIDATOR, } from '../../Constants/ApiUrls';
import { useEffect } from 'react';
import Translator from '../../Utils/Translator';
import {
    LABEL_COUPON_DESCRIPTION,
    LABEL_COUPON_VALUE,
} from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { CouponInterface } from '../../Interfaces/CouponInterface';
import { Card } from './Card';
import { localDateTimeToUtc, utcDateTimeToLocal } from '../../Utils/Date';
import Input from '../Common/Input/Input';
import { InputDateTime } from '../Common/Input/InputDateTime';
import { ImportantButton } from '../Common/Button/ImportantButton';
import { SecondaryButton } from '../Common/Button/SecondaryButton';

interface EditModalInterface {
    coupon: CouponInterface;
    toggleModal?: () => void;
    deleteSuccessCallback?: (response: any) => void;
}

export function DeleteModal({coupon, toggleModal = () => {}, deleteSuccessCallback = () => {}}: EditModalInterface) {
    const deleteCoupon = () => {
        NewApiManager.delete(
            DELETE_COUPON(coupon.id),
            (response: any) => deleteSuccessCallback(response),
        )
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:max-w-[500px] w-[calc(100%-20px)]`}>
            <div className={`bg-${theme.value}-primary-50 rounded-xl p-[10px]`}>
                <div>
                    <Card coupon={coupon}/>
                </div>
                <div className={`mt-[10px] font-bold text-center`}>
                    {Translator.translateId('label__coupon_delete_confirmation')}
                </div>
                <div className={`flex justify-center mt-[10px] gap-[10px]`}>
                    <ImportantButton onClick={deleteCoupon}>{Translator.translateId('cta__confirm')}</ImportantButton>
                    <SecondaryButton onClick={toggleModal}>{Translator.translateId('cta__cancel')}</SecondaryButton>
                </div>
            </div>
        </div>
    </>;
}
