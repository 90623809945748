import {theme} from "../../Signals/ThemeSignal";
import Translator from "../../Utils/Translator";

interface DeleteConfirmationModalInterface {
    message: string;
    deleteAction: () => void;
    isVisible: boolean;
    toggleModal: () => void;
}

export function DeleteConfirmationModal({message = '', deleteAction = () => {}, isVisible = false, toggleModal = () => {}}: DeleteConfirmationModalInterface) {
    if (!isVisible) {
        return <></>;
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`} onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px] max-w-[300px] text-center`}>
                <div>
                    {message}
                </div>
                <div className={'flex justify-center mt-[10px]'}>
                    <button onClick={deleteAction} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>
                        {Translator.translateId('cta__confirm')}
                    </button>
                </div>
            </div>
        </div>
    </>;
}
