import {signal} from "@preact/signals-react";
import translationsJson from '../Constants/translations.json';
import {LocalStorage} from "../Utils/LocalStorage";

const LSItem = 'translations';
const storageValue = LocalStorage.get(LSItem) ?? '{}';
let translationsLoaded = false;
export const translations = signal(storageValue);
export function loadTranslations() {
  try {
    if (!translationsLoaded) {
      (async function() {
        // const response = await ApiManager.get(TRANSLATION_URL);
        translations.value = translationsJson;
        LocalStorage.set(LSItem, translationsJson);
      })();
    }
    translationsLoaded = true;
  } catch(e) {
    console.error('Error on loading translations', e);
  }
}

loadTranslations();