import { theme } from '../../Signals/ThemeSignal';
import { FormHook } from '../../Hooks/FormHook';
import { NewApiManager } from '../../Utils/NewApiManager';
import {
    UPDATE_USER, UPDATE_USER_FORM_VALIDATOR,
} from '../../Constants/ApiUrls';
import { useEffect } from 'react';
import Translator from '../../Utils/Translator';
import {
    LABEL_EMAIL,
    LABEL_FIRSTNAME,
    LABEL_LASTNAME,
    LABEL_LOG_IN, LABEL_USER_DESCRIPTION, LABEL_USER_IS_PRIVATE, LABEL_USER_MOOD
} from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { UserInterface } from '../../Interfaces/UserInterface';
import Input from '../Common/Input/Input';
import Textarea from '../Common/Input/Textarea';
import { Checkbox } from '../Common/Input/Checkbox';

interface UpdateUserModalInterface {
    user: UserInterface;
    toggleModal?: () => void;
    submitSuccessCallback?: (response: any) => void;
}

export function UpdateUserModal({user, toggleModal = () => {}, submitSuccessCallback = () => {}}: UpdateUserModalInterface) {
    const {loadConstrains, shouldSubmit, form, handleChange, setValues, handleFocus, handleBlur} = FormHook(['firstname', 'lastname', 'email', 'login', 'mood', 'description', 'isPrivate'], {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        login: user.login,
        mood: user.mood,
        description: user.description,
        isPrivate: user.isPrivate+'',
    });

    useEffect(() => {
        loadConstrains(UPDATE_USER_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.put(
            UPDATE_USER,
            {
                firstname: form.fields.firstname.value,
                lastname: form.fields.lastname.value,
                login: form.fields.login.value,
                email: form.fields.email.value,
                isPrivate: form.fields.isPrivate.value,
                mood: form.fields.mood.value,
                description: form.fields.description.value,
            },
            (response: any) => {
                submitSuccessCallback(response);
            }
        );
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:max-w-[500px] w-[calc(100%-20px)]`}>
            <div className={`bg-${theme.value}-primary-50 rounded-xl`}>
                <form>
                    <div className={`p-[20px]`}>
                        <Input form={form} name={'login'} handleChange={handleChange} placeholder={LABEL_LOG_IN}/>
                        <Input form={form} name={'firstname'} handleChange={handleChange} placeholder={LABEL_FIRSTNAME} className={`mt-[10px]`}/>
                        <Input form={form} name={'lastname'} handleChange={handleChange} placeholder={LABEL_LASTNAME} className={`mt-[10px]`}/>
                        <Input form={form} name={'email'} handleChange={handleChange} placeholder={LABEL_EMAIL} className={`mt-[10px]`}/>
                        <Input form={form} name={'mood'} handleChange={handleChange} placeholder={LABEL_USER_MOOD} className={`mt-[10px]`}/>
                        <Textarea form={form} name={'description'} handleChange={handleChange} textareaClassName={`lg:h-[200px]`} className={`border border-black mt-[10px]`} placeholder={LABEL_USER_DESCRIPTION} handleFocus={handleFocus} handleBlur={handleBlur}/>
                        <Checkbox form={form} name={'isPrivate'} checkedValue={'1'} uncheckedValue={'0'} setValues={setValues} label={LABEL_USER_IS_PRIVATE} className={`mt-[10px]`} description={Translator.translateId('label__user_is_private_description')}/>
                    </div>
                    <div className={`border-t p-[20px] flex justify-end`}>
                        <PrimaryButton onClick={onSubmit}>{Translator.translateId('cta__update')}</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    </>;
}
