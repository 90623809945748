import {useState} from "react";
import {validateInput} from "../Utils/FormUtils";
import {TranslationDataInterface} from "../Utils/Translator";
import {LABEL_FORM_ERROR} from "../Constants/Label";
import {NewApiManager} from "../Utils/NewApiManager";

interface FormInterface {
    isLoading: boolean;
    error: TranslationDataInterface|undefined;
    success: TranslationDataInterface|undefined;
    fields: {
        [name: string]: {
            focus: boolean;
            value: string|boolean|number|null;
            constrains: FormFieldConstrains;
            errors: TranslationDataInterface[];
            success: TranslationDataInterface[];
        };
    };
    displayBorders: boolean;
}

interface FormValuesInterface {
    [name: string]: string|number|boolean|null;
}

interface FormFieldConstrains {
    [fieldname: string]: {
        [constrainName: string]: string;
    };
}

interface FormInputConstrainsList {
    [inputName: string]: FormFieldConstrains;
}

interface FormHookInterface {
    form: FormInterface;
    setForm: (newState: any) => void;
    handleChange: (e: any) => void;
    handleChangeCheckbox: (e: any) => void;
    handleFocus: (e: any) => void;
    handleBlur: (e: any) => void;
    shouldSubmit: () => boolean;
    setConstrains: (constrains: FormInputConstrainsList, validateInputs?: boolean) => void;
    setValues: (values: FormValuesInterface) => void;
    resetSuccessError: () => void;
    submitLock: () => void;
    submitSuccess: (label?: TranslationDataInterface) => void;
    submitError: (label?: TranslationDataInterface) => void;
    loadConstrains: (url: string) => void;
}

export const FormHook = (fields: string[], values: FormValuesInterface = {}): FormHookInterface => {
    const defaultState: FormInterface = {
        isLoading: false,
        error: undefined,
        success: undefined,
        fields: {},
        displayBorders: false
    };

    fields.forEach((fieldName: string) => {
        defaultState.fields[fieldName] = {
            value: values[fieldName] ?? '',
            constrains: {},
            errors: [],
            success: [],
            focus: false
        }
    });

    const [form, setForm] = useState<FormInterface>(defaultState);

    const handleChange = (e: any) => {
        const {errors, success} = validateInput(e.target.value, form.fields[e.target.name].constrains);

        let newFormState = {...form};
        newFormState.fields[e.target.name].value = e.target.value;
        newFormState.fields[e.target.name].errors = errors;
        newFormState.fields[e.target.name].success = success;
        newFormState.success = undefined;
        newFormState.error = undefined;

        setForm(newFormState);
    };

    const handleChangeCheckbox = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].value = !newFormState.fields[e.target.name].value;
        newFormState.fields[e.target.name].errors = [];
        newFormState.fields[e.target.name].success = [];
        newFormState.success = undefined;
        newFormState.error = undefined;

        setForm(newFormState);
    };

    const handleFocus = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].focus = true;

        setForm(newFormState);
    }

    const handleBlur = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].focus = false;

        setForm(newFormState);
    }

    const shouldSubmit = () => {
        if (form.isLoading) {
            setForm(() => {
                return {
                    ...form,
                    success: undefined,
                    error: undefined
                };
            });
            return false;
        }

        let hasFormError = false;
        let newFormState = {...form};

        Object.keys(form.fields).map((fieldName: string) => {
            const {errors, success} = validateInput(form.fields[fieldName].value, form.fields[fieldName].constrains);
            newFormState.fields[fieldName].errors = errors;
            newFormState.fields[fieldName].success = success;
            if (errors.length > 0) {
                hasFormError = true;
            }
        });

        if (hasFormError) {
            setForm(() => {
                return {
                    ...newFormState,
                    success: undefined,
                    error: LABEL_FORM_ERROR,
                    isLoading: false,
                };
            });
            return false;
        }

        setForm(() => {
            return {
                ...newFormState,
                isLoading: true,
                error: undefined,
                success: undefined
            }
        });

        return true;
    };

    const setConstrains = (constrains: FormInputConstrainsList, validateInputs: boolean|null = false) => {
        const newState: FormInterface = {...form};

        Object.keys(constrains).map((fieldName: string) => {
            if (form.fields[fieldName] !== undefined) {
                const fieldConstrains: FormFieldConstrains = constrains[fieldName];

                if (validateInputs) {
                    const {errors, success} = validateInput(form.fields[fieldName].value, fieldConstrains);
                    newState.fields[fieldName].errors = errors;
                    newState.fields[fieldName].success = success;
                }

                newState.fields[fieldName].constrains = fieldConstrains;
            }
        });

        setForm(() => newState);
    };

    const setValues = (values: FormValuesInterface, validateInputs: boolean = false) => {
        const newState: FormInterface = {...form};

        Object.keys(values).map((fieldName: string) => {
            if (form.fields[fieldName] !== undefined) {
                const fieldValue = values[fieldName];

                if (validateInputs) {
                    const {errors, success} = validateInput(form.fields[fieldName].value, form.fields[fieldName].constrains);
                    newState.fields[fieldName].errors = errors;
                    newState.fields[fieldName].success = success;
                }

                if (values) {
                    newState.fields[fieldName].value = fieldValue;
                    newState.fields[fieldName].errors = [];
                    newState.fields[fieldName].success = [];
                }
            }
        });

        setForm(() => newState);
    };

    const resetSuccessError = () => {
        setForm(() => ({
            ...form,
            success: undefined,
            error: undefined,
        }));
    }

    const submitLock = () => {
        setForm(() => ({
            ...form,
            isLoading: true,
        }));
    }

    const submitSuccess = (label: TranslationDataInterface|undefined = undefined) => {
        setForm(() => ({
            ...form,
            success: label,
            error: undefined,
            isLoading: false,
        }));
    }

    const submitError = (label: TranslationDataInterface|undefined = undefined) => {
        setForm(() => ({
            ...form,
            success: undefined,
            error: label,
            isLoading: false,
        }));
    }

    const loadConstrains = (url: string) => {
        NewApiManager.get(url, (response: any) => {setConstrains(response.data);});
    }

    return {resetSuccessError, form, setForm, handleChange, handleFocus, handleBlur, shouldSubmit, setConstrains, setValues, handleChangeCheckbox, submitLock, submitSuccess, submitError, loadConstrains};
};
