export const TOKEN_REFRESH_URL = `${process.env.REACT_APP_API_URL}/tokens/refresh`;

export const USER_REGISTER_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/user-register`;
export const FRONT_VERSION = `${process.env.REACT_APP_API_URL}/front-version`;
export const USER_LOG_IN_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/user-log-in`;
export const USER_CONFIGURE_PASSWORD_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/user-configure-password`;
export const CREATE_PLAYLIST_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-playlist`;
export const CREATE_NOTE_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-note`;
export const UPDATE_NOTE_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-note`;
export const RATING_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/rate`;
export const BUG_REPORT_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/bug-report`;
export const CONTACT_ADMIN_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/contact-admin`;
export const REGISTER_YOUTUBE_REMOTE_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/register-youtube-remote`;
export const USER_FORGOT_PASSWORD_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/forgot-password`;
export const USER_REGISTER = `${process.env.REACT_APP_API_URL}/users/register`;
export const USER_FORGOT_PASSWORD = `${process.env.REACT_APP_API_URL}/forgot-password`;
export const TIME = `${process.env.REACT_APP_API_URL}/time`;
export const USER_LOG_IN = `${process.env.REACT_APP_API_URL}/users/log-in`;
export const CREATE_YOUTUBE_VIDEO_STATISTIC = (videoId: string, statType: string) => `${process.env.REACT_APP_API_URL}/youtube/${videoId}/stats/${statType}`;
export const MOST_POPULAR_YOUTUBE_VIDEOS = (limit: number = 20) => `${process.env.REACT_APP_API_URL}/youtube/stats/most-popular?limit=${limit}`;
export const USER_CONFIGURE_PASSWORD = `${process.env.REACT_APP_API_URL}/users/configure-password`;
export const USERS_ME = `${process.env.REACT_APP_API_URL}/users/me`;
export const USER = (userId: string) => `${process.env.REACT_APP_API_URL}/users/${userId}`;
export const FOLLOW_USER = (userId: string) => `${process.env.REACT_APP_API_URL}/users/${userId}/followers`;
export const UNFOLLOW_USER = (userId: string) => `${process.env.REACT_APP_API_URL}/users/${userId}/followers`;
export const USER_SELF = `${process.env.REACT_APP_API_URL}/users/self`;
export const POSTS = (type: 'beforeDate'|'afterDate'|undefined = undefined, value: string|undefined = undefined, limit: number = 0) => `${process.env.REACT_APP_API_URL}/posts?${type !== undefined && value !== undefined ? `${type}=${value}` : ''}${limit > 0 ? `&limit=${limit}` : ''}`;
export const USER_GENERATE_AUTO_LOG_IN = `${process.env.REACT_APP_API_URL}/tokens/generate-auto-log-in`;
export const USER_APPLY_AUTO_LOG_IN = `${process.env.REACT_APP_API_URL}/tokens/apply-auto-log-in`;
export const BACK_OFFICE_ALL_COUPON_AUTO_APPLIES = `${process.env.REACT_APP_API_URL}/back-office/coupon-auto-applies`;
export const BACK_OFFICE_ALL_COMPANIES = `${process.env.REACT_APP_API_URL}/back-office/companies`;
export const BACK_OFFICE_ALL_NEWS_CONFIGS = `${process.env.REACT_APP_API_URL}/back-office/news-configs`;
export const BACK_OFFICE_ALL_NEWS = `${process.env.REACT_APP_API_URL}/back-office/news`;
export const ALL_NEWS = `${process.env.REACT_APP_API_URL}/news`;
export const BACK_OFFICE_ALL_ADMINISTRATORS = `${process.env.REACT_APP_API_URL}/back-office/administrators`;
export const BACK_OFFICE_ALL_AUTOMATION_LOGS = `${process.env.REACT_APP_API_URL}/back-office/automation-logs`;
export const BACK_OFFICE_ALL_USERS = `${process.env.REACT_APP_API_URL}/back-office/users`;
export const BACK_OFFICE_SPACESHARE_RATES = `${process.env.REACT_APP_API_URL}/back-office/spaceshare-rates`;
export const BACK_OFFICE_BUG_REPORTS = `${process.env.REACT_APP_API_URL}/back-office/bug-reports`;
export const BACK_OFFICE_CONTACT_ADMINS = `${process.env.REACT_APP_API_URL}/back-office/contact-admins`;
export const PUBLIC_COUPONS = (limit: number = 20) => `${process.env.REACT_APP_API_URL}/coupons/public${limit > 0 ? `?limit=${limit}` : ''}`;
export const USER_SELF_COUPONS = (limit: number = 0, offset: number = 1) => `${process.env.REACT_APP_API_URL}/users/self/coupons?${offset > 1 ? `offset=${offset}` : ''}${limit > 0 ? `&limit=${limit}` : ''}`;
export const USER_COUPONS = (userId: string, limit: number = 0, offset: number = 1) => `${process.env.REACT_APP_API_URL}/users/${userId}/coupons?${offset > 1 ? `offset=${offset}` : ''}${limit > 0 ? `&limit=${limit}` : ''}`;
export const USER_SELF_POSTS = `${process.env.REACT_APP_API_URL}/users/self/posts`;
export const USER_POSTS = (userId: string) => `${process.env.REACT_APP_API_URL}/users/${userId}/posts`;
export const POST = (id: string) => `${process.env.REACT_APP_API_URL}/posts/${id}`;
export const CREATE_POST_BASIC = `${process.env.REACT_APP_API_URL}/post-basics`;
export const CREATE_POST_COUPON = `${process.env.REACT_APP_API_URL}/post-coupons`;
export const UPDATE_USER = `${process.env.REACT_APP_API_URL}/users/update`;
export const BACK_OFFICE_COUPONS = `${process.env.REACT_APP_API_URL}/back-office/coupons`;
export const BACK_OFFICE_YOUTUBE_API_KEYS = `${process.env.REACT_APP_API_URL}/back-office/youtube/api-keys`;
export const BACK_OFFICE_WEATHER_API_KEYS = `${process.env.REACT_APP_API_URL}/back-office/weathers/api-keys`;
export const BACK_OFFICE_DELETE_YOUTUBE_API_KEY = (apiKey: string) => `${process.env.REACT_APP_API_URL}/back-office/youtube/api-keys/${apiKey}`;
export const BACK_OFFICE_DELETE_WEATHER_API_KEY = (apiKey: string) => `${process.env.REACT_APP_API_URL}/back-office/weathers/api-keys/${apiKey}`;
export const BACK_OFFICE_COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupons/${id}`;
export const BACK_OFFICE_COMPANY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/companies/${id}`;
export const BACK_OFFICE_NEWS_CONFIG = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/news-configs/${id}`;
export const BACK_OFFICE_COUPON_AUTO_APPLY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupon-auto-applies/${id}`;
export const COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/coupons/${id}`;
export const USER_CREATE_COUPON = `${process.env.REACT_APP_API_URL}/coupons`;
export const BACK_OFFICE_CREATE_COUPON = `${process.env.REACT_APP_API_URL}/back-office/coupons/create`;
export const BACK_OFFICE_CREATE_COMPANY = `${process.env.REACT_APP_API_URL}/back-office/companies/create`;
export const BACK_OFFICE_CREATE_COUPON_AUTO_APPLY = `${process.env.REACT_APP_API_URL}/back-office/coupon-auto-applies/create`;
export const BACK_OFFICE_UPDATE_COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupons/${id}/update`;
export const BACK_OFFICE_UPDATE_COMPANY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/companies/${id}/update`;
export const BACK_OFFICE_UPDATE_NEWS_CONFIG = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/news-configs/${id}/update`;
export const BACK_OFFICE_CREATE_NEWS_CONFIG = `${process.env.REACT_APP_API_URL}/back-office/news-configs/create`;
export const BACK_OFFICE_FETCH_NEWS_DATA = `${process.env.REACT_APP_API_URL}/back-office/news-configs/fetch-data`;
export const BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupon-auto-applies/${id}/update`;
export const UPDATE_COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/coupons/${id}`;
export const UPDATE_BOARD = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/update`;
export const SHORTCUT_LINKS = `${process.env.REACT_APP_API_URL}/shortcut-links`;
export const REFERRAL_LINKS = `${process.env.REACT_APP_API_URL}/referral-links`;
export const UPDATE_SHORTCUT_LINKS = `${process.env.REACT_APP_API_URL}/shortcut-links`;
export const CREATE_BOARD_COLUMN = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/columns/create`;
export const UPDATE_BOARD_COLUMN = (id: string, columnId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/columns/${columnId}`;
export const UPDATE_BOARD_ROW = (id: string, rowId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/rows/${rowId}`;
export const CREATE_BOARD_STORY_POINT = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/story-points/create`;
export const UPDATE_BOARD_STORY_POINT = (id: string, storyPointId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/story-points/${storyPointId}`;
export const CREATE_BOARD_ROW = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/rows/create`;
export const CREATE_BOARD_CARD = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/create`;
export const CREATE_SHORTCUT_LINK = `${process.env.REACT_APP_API_URL}/shortcut-links`;
export const CREATE_REFERRAL_LINK = `${process.env.REACT_APP_API_URL}/referral-links`;
export const UPDATE_SHORTCUT_LINK = (id: string) => `${process.env.REACT_APP_API_URL}/shortcut-links/${id}`;
export const UPDATE_REFERRAL_LINK = (id: string) => `${process.env.REACT_APP_API_URL}/referral-links/${id}`;
export const UPDATE_BOARD_CARD = (id: string, cardId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/${cardId}`;
export const CREATE_BOARD_CARD_COMMENT = (id: string, cardId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/${cardId}/comments/create`;
export const DELETE_BOARD_CARD = (id: string, cardId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/${cardId}`;
export const DELETE_BOARD_CARD_COMMENT = (id: string, cardId: string, commentId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/${cardId}/comments/${commentId}`;
export const DELETE_BOARD = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}`;
export const DELETE_BOARD_COLUMN = (id: string, columnId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/columns/${columnId}`;
export const DELETE_BOARD_ROW = (id: string, rowId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/rows/${rowId}`;
export const DELETE_BOARD_STORY_POINT = (id: string, storyPointId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/story-points/${storyPointId}`;
export const UPDATE_BOARD_COLUMNS_ORDER = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/columns-order`;
export const UPDATE_BOARD_ROWS_ORDER = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/rows-order`;
export const UPDATE_BOARD_CARDS_ORDER = (id: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards-order`;
export const UPDATE_BOARD_ROW_VISIBILITY = (id: string, rowId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/rows/${rowId}/visibility`;
export const UPDATE_BOARD_COLUMN_VISIBILITY = (id: string, columnId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/columns/${columnId}/visibility`;
export const USER_CREATE_COUPON_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/user-create-coupon`;
export const BACK_OFFICE_CREATE_COUPON_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-coupon`;
export const BACK_OFFICE_CREATE_YOUTUBE_API_KEY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-youtube-api-key`;
export const CREATE_POST_BASIC_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-post-basic`;
export const CREATE_POST_COMMENT_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-post-comment`;
export const INFO = `${process.env.REACT_APP_API_URL}/info`;
export const CREATE_POST_COUPON_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-post-coupon`;
export const UPDATE_USER_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-user`;
export const BACK_OFFICE_CREATE_WEATHER_API_KEY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-weather-api-key`;
export const UPDATE_BOARD_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-board`;
export const UPLOAD_USER_PROFILE_IMAGE = `${process.env.REACT_APP_API_URL}/profile-image`;
export const CREATE_BOARD_COLUMN_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-board-column`;
export const CREATE_BOARD_ROW_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-board-row`;
export const CREATE_BOARD_CARD_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-board-card`;
export const CREATE_SHORTCUT_LINK_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-shortcut-link`;
export const CREATE_REFERRAL_LINK_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-referral-link`;
export const UPDATE_SHORTCUT_LINK_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-shortcut-link`;
export const UPDATE_REFERRAL_LINK_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-referral-link`;
export const CREATE_BOARD_STORY_POINT_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-board-story-point`;
export const UPDATE_BOARD_STORY_POINT_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-board-story-point`;
export const CREATE_BOARD_CARD_COMMENT_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/create-board-card-comment`;
export const UPDATE_BOARD_CARD_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-board-card`;
export const UPDATE_BOARD_COLUMN_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-board-column`;
export const UPDATE_BOARD_ROW_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-board-row`;
export const YOUTUBE_SEARCH_BY_KEYWORD = (keyword: string, pageToken: string|undefined = undefined) => `${process.env.REACT_APP_API_URL}/youtube/search?q=${keyword}${pageToken !== undefined ? `&pageToken=${pageToken}` : ''}`;
export const SELF_PLAYLISTS = `${process.env.REACT_APP_API_URL}/users/self/playlists`;
export const CREATE_PLAYLIST = `${process.env.REACT_APP_API_URL}/playlists`;
export const PLAYLIST_VIDEO_TOGGLE = (playlistId: string) => `${process.env.REACT_APP_API_URL}/playlists/${playlistId}/videos`;
export const PLAYLIST = (playlistId: string) => `${process.env.REACT_APP_API_URL}/playlists/${playlistId}`;
export const DELETE_PLAYLIST = (playlistId: string) => `${process.env.REACT_APP_API_URL}/playlists/${playlistId}`;
export const PUBLIC_PLAYLISTS = `${process.env.REACT_APP_API_URL}/users/public/playlists`;
export const BACK_OFFICE_CREATE_COMPANY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-company`;
export const BACK_OFFICE_UPDATE_COUPON_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-update-coupon`;
export const BACK_OFFICE_UPDATE_COMPANY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-update-company`;
export const BACK_OFFICE_UPDATE_NEWS_CONFIG_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-update-news-config`;
export const BACK_OFFICE_CREATE_NEWS_CONFIG_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-news-config`;
export const BACK_OFFICE_CREATE_COUPON_AUTO_APPLY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-create-coupon-auto-apply`;
export const BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/back-office-update-coupon-auto-apply`;
export const UPDATE_COUPON_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/update-coupon`;
export const BACK_OFFICE_DELETE_COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupons/${id}/delete`;
export const BACK_OFFICE_DELETE_NOTE = (id: string) => `${process.env.REACT_APP_API_URL}/notes/${id}/delete`;
export const BACK_OFFICE_DELETE_LINK = (id: string) => `${process.env.REACT_APP_API_URL}/links/${id}/delete`;
export const BACK_OFFICE_DELETE_COMPANY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/companies/${id}/delete`;
export const BACK_OFFICE_DELETE_NEWS_CONFIG = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/news-configs/${id}/delete`;
export const BACK_OFFICE_ENABLE_NEWS_CONFIG = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/news-configs/${id}/enable`;
export const BACK_OFFICE_DELETE_COUPON_AUTO_APPLY = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/coupon-auto-applies/${id}/delete`;
export const BACK_OFFICE_CREATE_YOUTUBE_API_KEY = `${process.env.REACT_APP_API_URL}/back-office/youtube/api-keys/create`;
export const BACK_OFFICE_CREATE_WEATHER_API_KEY = `${process.env.REACT_APP_API_URL}/back-office/weathers/api-keys/create`;
export const WEATHER_CURRENT = (location: string) => `${process.env.REACT_APP_API_URL}/weathers/current?location=${location}`;
export const INTERRUPT_YOUTUBE_PLAYER = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}/interrupt`;
export const ACTIVATE_YOUTUBE_PLAYER = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}/activate`;
export const YOUTUBE_PLAYER_INFO = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}`;
export const REGISTER_YOUTUBE_REMOTE = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}/remotes`;
export const SAVE_YOUTUBE_REMOTE_ACTION = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}/actions`;
export const YOUTUBE_REMOTE_ACTIONS = (deviceId: string) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}/actions`;
export const UPDATE_YOUTUBE_DEVICE = (deviceId: string, resetActions: boolean = false) => `${process.env.REACT_APP_API_URL}/youtube-player/${deviceId}${resetActions ? '?resetActions=1' : ''}`;
export const TINYMCE_API_KEY = `${process.env.REACT_APP_API_URL}/notes/api-key`;
export const CREATE_NOTE = `${process.env.REACT_APP_API_URL}/notes/create`;
export const UPDATE_NOTE = (id: string) => `${process.env.REACT_APP_API_URL}/notes/${id}`;
export const NOTE = (id: string) => `${process.env.REACT_APP_API_URL}/notes/${id}`;
export const ALL_NOTES = `${process.env.REACT_APP_API_URL}/notes`;
export const CREATE_BOARD = `${process.env.REACT_APP_API_URL}/back-office/boards/create`;
export const BOARD = (id: string) => `${process.env.REACT_APP_API_URL}/back-office/boards/${id}`;
export const QRCODE_AUTO_LOG_IN_FORM_VALIDATOR = `${process.env.REACT_APP_API_URL}/form-validators/qrcode-auto-log-in`;
export const QRCODE_AUTO_LOG_IN = (deviceId: string) => `${process.env.REACT_APP_API_URL}/tokens/qrcode-auto-log-in/${deviceId}`;
export const QRCODE_AUTO_LOG_IN_APPLY = `${process.env.REACT_APP_API_URL}/tokens/apply-qr-code-auto-log-in`;
export const BOARDS = `${process.env.REACT_APP_API_URL}/back-office/boards`;
export const BOARD_CARD_COMMENTS = (id: string, cardId: string) => `${process.env.REACT_APP_API_URL}/boards/${id}/cards/${cardId}/comments`;
export const DELETE_COUPON = (id: string) => `${process.env.REACT_APP_API_URL}/coupons/${id}/delete`;
export const DEVICE_REGISTER = `${process.env.REACT_APP_API_URL}/devices/register`;
export const USER_DEVICES = `${process.env.REACT_APP_API_URL}/devices`;
export const POST_LIKE = (id: string) => `${process.env.REACT_APP_API_URL}/posts/${id}/like`;
export const DELETE_POST_LIKE = (id: string) => `${process.env.REACT_APP_API_URL}/posts/${id}/like`;
export const CREATE_POST_COMMENT = (id: string) => `${process.env.REACT_APP_API_URL}/posts/${id}/comments`;
export const POST_COMMENTS = (id: string, limit: number = 0, latestCommentDate: string|undefined, olderCommentDate: string|undefined) => `${process.env.REACT_APP_API_URL}/posts/${id}/comments?${limit > 0 ? `limit=${limit}` : ''}${latestCommentDate !== undefined ? `&latestCommentDate=${latestCommentDate}` : ''}${olderCommentDate !== undefined ? `&olderCommentDate=${olderCommentDate}` : ''}`;
export const POST_RATE_SPACESHARE = `${process.env.REACT_APP_API_URL}/rates/spaceshare`;
export const POST_BUG_REPORT = `${process.env.REACT_APP_API_URL}/bug-reports`;
export const POST_CONTACT_ADMIN = `${process.env.REACT_APP_API_URL}/contact-admins`;
