import { theme } from '../../Signals/ThemeSignal';
import { FormHook } from '../../Hooks/FormHook';
import { NewApiManager } from '../../Utils/NewApiManager';
import {UPDATE_COUPON, UPDATE_COUPON_FORM_VALIDATOR, } from '../../Constants/ApiUrls';
import { useEffect } from 'react';
import Translator from '../../Utils/Translator';
import {
    LABEL_COUPON_DESCRIPTION, LABEL_COUPON_URL,
    LABEL_COUPON_VALUE,
} from '../../Constants/Label';
import { PrimaryButton } from '../Common/Button/PrimaryButton';
import { CouponInterface } from '../../Interfaces/CouponInterface';
import { Card } from './Card';
import { localDateTimeToUtc, utcDateTimeToLocal } from '../../Utils/Date';
import Input from '../Common/Input/Input';
import { InputDateTime } from '../Common/Input/InputDateTime';
import { CheckIcon } from '../../Icons/CheckIcon';
import { Dropdown } from '../Common/Input/Dropdown';

interface EditModalInterface {
    coupon: CouponInterface;
    toggleModal?: () => void;
    submitSuccessCallback?: (response: any) => void;
}

export function EditModal({coupon, toggleModal = () => {}, submitSuccessCallback = () => {}}: EditModalInterface) {
    const {loadConstrains, shouldSubmit, form, handleChange, setValues, setForm} = FormHook(['description', 'couponValue', 'startDate', 'endDate', 'couponUrl', 'entityVisibility'],
        {
            description: coupon.description,
            couponValue: coupon.couponValue,
            couponUrl: coupon.couponUrl,
            startDate: coupon.startDate === null ? '' : utcDateTimeToLocal(coupon.startDate.date),
            endDate: coupon.endDate === null ? '' : utcDateTimeToLocal(coupon.endDate.date),
            entityVisibility: coupon.entityVisibility
        });

    useEffect(() => {
        loadConstrains(UPDATE_COUPON_FORM_VALIDATOR);
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (!shouldSubmit()) {
            return;
        }

        NewApiManager.put(
            UPDATE_COUPON(coupon.id),
            {
                description: form.fields.description.value,
                couponValue: form.fields.couponValue.value,
                couponUrl: form.fields.couponUrl.value,
                // @ts-ignore
                startDate: form.fields.startDate.value === '' ? '' : localDateTimeToUtc(form.fields.startDate.value),
                // @ts-ignore
                endDate: form.fields.endDate.value === '' ? '' : localDateTimeToUtc(form.fields.endDate.value),
                entityVisibility: form.fields.entityVisibility.value,
            },
            (response: any) => {
                submitSuccessCallback(response);
            }
        );
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] md:max-w-[500px] w-[calc(100%-20px)]`}>
            <div className={`bg-${theme.value}-primary-50 rounded-xl`}>
                <div className={`p-[10px]`}>
                    <Card coupon={{
                        id: coupon.id,
                        // @ts-ignore
                        couponValue: form.fields.couponValue.value,
                        // @ts-ignore
                        couponUrl: form.fields.couponUrl.value,
                        // @ts-ignore
                        description: form.fields.description.value,
                        // @ts-ignore
                        startDate: form.fields.startDate.value === '' ? null : {date: localDateTimeToUtc(form.fields.startDate.value)},
                        // @ts-ignore
                        endDate: form.fields.endDate.value === '' ? null : {date: localDateTimeToUtc(form.fields.endDate.value)},
                    }}/>
                </div>
                <form>
                    <div className={`p-[20px]`}>
                        <Input form={form} name={'couponValue'} handleChange={handleChange}
                               placeholder={LABEL_COUPON_VALUE}/>
                        <Input className={`mt-[10px]`} form={form} name={'couponUrl'} handleChange={handleChange}
                               placeholder={LABEL_COUPON_URL}/>
                        <Input className={`mt-[10px]`} form={form} name={'description'} handleChange={handleChange}
                               placeholder={LABEL_COUPON_DESCRIPTION}/>
                        <div className={`flex gap-[10px]`}>
                            <div>
                                <div className={`flex`}>
                                    <InputDateTime className={`mt-[10px]`} form={form}
                                                   label={Translator.translateId('label__coupon_update_start_date')}
                                                   name={'startDate'} setValues={setValues}/>
                                </div>
                            </div>
                            <div>
                                <div className={`flex`}>
                                    <InputDateTime className={`mt-[10px]`} form={form}
                                                   label={Translator.translateId('label__coupon_update_end_date')}
                                                   name={'endDate'} setValues={setValues}/>
                                </div>
                            </div>
                        </div>
                        <Dropdown form={form} name={'entityVisibility'} values={[
                            {
                                id: '',
                                label: Translator.translateId('label__choose_visibility')
                            },
                            {
                                id: 'public',
                                label: Translator.translateId('label__visibility_public')
                            },
                            {
                                id: 'followers',
                                label: Translator.translateId('label__visibility_followers')
                            },
                            {
                                id: 'me',
                                label: Translator.translateId('label__visibility_me')
                            },
                        ]} setValues={setValues}/>
                    </div>
                    <div className={`border-t p-[20px] flex justify-end`}>
                        <PrimaryButton onClick={onSubmit}>{Translator.translateId('cta__submit')}</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    </>;
}
