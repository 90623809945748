import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import Input from "../Components/Common/Input/Input";
import {
    LABEL_CAA_COUPON,
    LABEL_CAA_DOM_CHANGE,
    LABEL_CAA_REMOVE,
    LABEL_CAA_REMOVE_TIMEOUT,
    LABEL_CAA_SUBMIT,
    LABEL_CAA_SUBMIT_TIMEOUT, LABEL_CAA_TOTAL,
    LABEL_CAA_UPDATE_FORM_ERROR,
    LABEL_CAA_UPDATE_FORM_SUCCESS,
    LABEL_CAA_URL_REGEXP,
} from "../Constants/Label";
import {useNavigate, useParams} from "react-router-dom";
import {CompanyInterface} from "../Interfaces/CompanyInterface";
import ToggleHook from "../Hooks/Toggler/TogglerHook";
import {SearchHook} from "../Hooks/SearchHook";
import {DeleteConfirmationModal} from "../Components/DeleteConfirmationModal/DeleteConfirmationModal";
import {CheckIcon} from "../Icons/CheckIcon";
import {
    BACK_OFFICE_ALL_COMPANIES,
    BACK_OFFICE_COUPON_AUTO_APPLY, BACK_OFFICE_DELETE_COUPON_AUTO_APPLY,
    BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY,
    BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY_FORM_VALIDATOR
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeCouponAutoApplyUpdate() {
    const {id} = useParams();
    const navigate = useNavigate();

    const getBackOfficeAllCompanies = (keyword?: string, callback: (response: any) => void = () => {}) => {
        const getParameters = keyword === undefined ? '' : `?keyword=${keyword}`;
        NewApiManager.get(
            BACK_OFFICE_ALL_COMPANIES+getParameters,
            (response: any) => callback(response)
        );
    }
    const {keywordDebounce: companyKeywordDebounce, results: companiesKeywordResults, setKeywordDebounce: setCompaniesKeywordDebounce} = SearchHook(getBackOfficeAllCompanies);
    const {toggleVisibility: toggleCompanyResults, isVisible: isCompanyResultsVisible, ref: companyResultsRef} = ToggleHook();
    const [formCompanyName, setFormCompanyName] = useState<undefined|string>();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {shouldSubmit, form, handleChange, setValues, submitSuccess, submitError, submitLock, loadConstrains} = FormHook(['urlRegExp', 'couponSelector', 'submitSelector', 'totalSelector', 'removeSelector', 'domChangeSelector', 'customRemoveTimeout', 'customSubmitTimeout', 'companyId', 'injectFakeCoupon']);

    const onSubmit = (e: any) => {
        if (id === undefined) {
            return;
        }

        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.put(
            BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY(id),
            {
                urlRegExp: form.fields.urlRegExp.value,
                couponSelector: form.fields.couponSelector.value !== '' ? form.fields.couponSelector.value : '',
                submitSelector: form.fields.submitSelector.value !== '' ? form.fields.submitSelector.value : '',
                totalSelector: form.fields.totalSelector.value !== '' ? form.fields.totalSelector.value : '',
                removeSelector: form.fields.removeSelector.value !== '' ? form.fields.removeSelector.value : '',
                domChangeSelector: form.fields.domChangeSelector.value !== '' ? form.fields.domChangeSelector.value : '',
                customRemoveTimeout: Number(form.fields.customRemoveTimeout.value) && form.fields.customRemoveTimeout.value !== '' ? Number(form.fields.customRemoveTimeout.value) : '',
                customSubmitTimeout: Number(form.fields.customSubmitTimeout.value) && form.fields.customSubmitTimeout.value !== '' ? Number(form.fields.customSubmitTimeout.value) : '',
                companyId: form.fields.companyId.value !== '' ? form.fields.companyId.value : '',
                injectFakeCoupon: form.fields.injectFakeCoupon.value ? 1 : 0
            },
            () => submitSuccess(LABEL_CAA_UPDATE_FORM_SUCCESS),
            () => submitError(LABEL_CAA_UPDATE_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_UPDATE_COUPON_AUTO_APPLY_FORM_VALIDATOR);
    }, []);


    useEffect(() => {
        if (id === undefined) {
            return;
        }

        NewApiManager.get(
            BACK_OFFICE_COUPON_AUTO_APPLY(id),
            (response: any) => {
                setValues({
                    urlRegExp: response.data.urlRegExp,
                    couponSelector: response.data.couponSelector !== null ? response.data.couponSelector : '',
                    submitSelector: response.data.submitSelector !== null ? response.data.submitSelector : '',
                    totalSelector: response.data.totalSelector !== null ? response.data.totalSelector : '',
                    removeSelector: response.data.removeSelector !== null ? response.data.removeSelector : '',
                    domChangeSelector: response.data.domChangeSelector !== null ? response.data.domChangeSelector : '',
                    customRemoveTimeout: response.data.customRemoveTimeout !== null ? response.data.customRemoveTimeout.toString() : '',
                    customSubmitTimeout: response.data.customSubmitTimeout !== null ? response.data.customSubmitTimeout.toString() : '',
                    companyId: response.data.company !== null ? response.data.company.id : '',
                    injectFakeCoupon: response.data.injectFakeCoupon,
                });

                if (response.data.company !== null) {
                    setFormCompanyName(response.data.company.name);
                }
            }
        );
    }, []);

    const deleteCaa = () => {
        if (id === undefined) {
            return;
        }

        NewApiManager.delete(
            BACK_OFFICE_DELETE_COUPON_AUTO_APPLY(id),
            () => navigate('/back-office/coupon-auto-applies')
        );
    }

    const toggleDeletionModal = () => {
        setDeleteModalVisible(() => !deleteModalVisible);
    }

    const selectCompanyFromResults = (company: CompanyInterface) => {
        setValues({
            companyId: company.id,
        });
        setFormCompanyName(company.name);
    };

    const toggleInjectFakeCoupon = () => {
        setValues({
            injectFakeCoupon: !form.fields.injectFakeCoupon.value,
        });
    }

    return <PageTemplate sidebar={"back-office"}>
        <DeleteConfirmationModal message={Translator.translateId('label__caa_delete_confirmation_message')} deleteAction={deleteCaa} toggleModal={toggleDeletionModal} isVisible={deleteModalVisible}/>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/back-office/coupon-auto-applies'} label={'label__back_office_coupon_auto_applies'}/>
                <button className={`border-[1px] border-red-600 p-[10px] rounded bg-red-400 text-white font-bold`} onClick={toggleDeletionModal}>{Translator.translateId('cta__delete')}</button>
            </div>
            <div className={`mt-[10px]`}>
                <form onSubmit={onSubmit}>
                    <Input form={form} name={'urlRegExp'} handleChange={handleChange}
                           placeholder={LABEL_CAA_URL_REGEXP}/>
                    <Input className={`mt-[10px]`} form={form} name={'removeSelector'} handleChange={handleChange}
                           placeholder={LABEL_CAA_REMOVE}/>
                    <Input className={`mt-[10px]`} form={form} name={'couponSelector'} handleChange={handleChange}
                           placeholder={LABEL_CAA_COUPON}/>
                    <Input className={`mt-[10px]`} form={form} name={'totalSelector'} handleChange={handleChange}
                           placeholder={LABEL_CAA_TOTAL}/>
                    <Input className={`mt-[10px]`} form={form} name={'submitSelector'} handleChange={handleChange}
                           placeholder={LABEL_CAA_SUBMIT}/>
                    <Input className={`mt-[10px]`} form={form} name={'domChangeSelector'} handleChange={handleChange}
                           placeholder={LABEL_CAA_DOM_CHANGE}/>
                    <Input className={`mt-[10px]`} form={form} name={'customRemoveTimeout'} handleChange={handleChange}
                           placeholder={LABEL_CAA_REMOVE_TIMEOUT}/>
                    <Input className={`mt-[10px]`} form={form} name={'customSubmitTimeout'} handleChange={handleChange}
                           placeholder={LABEL_CAA_SUBMIT_TIMEOUT}/>
                    <div ref={companyResultsRef} onClick={() => toggleCompanyResults(null, true)}
                         className={`mt-[10px] border-[1px] border-${theme.value}-primary-950 rounded`}>
                        <div className={`p-[10px] cursor-pointer`}>
                            {formCompanyName ?? <span
                                className={'italic'}>{Translator.translateId('label__link_coupon_to_company')}</span>}
                        </div>
                        <div
                            className={`${!isCompanyResultsVisible ? 'hidden' : ''} border-t-[1px] border-${theme.value}-primary-950 px-[10px]`}>
                            <div className={`border-b border-${theme.value}-primary-950`}>
                                <input className={`p-[10px] w-full`} type={'text'} value={companyKeywordDebounce}
                                       onChange={(e) => setCompaniesKeywordDebounce(e.target.value)}
                                       placeholder={Translator.translateId('label__companies_search_input')}/>
                            </div>
                            {(companiesKeywordResults === undefined || (companiesKeywordResults?.results?.length === 0 && companiesKeywordResults.status === 'done')) &&
                                <div className={`p-[10px] italic`}>
                                    {Translator.translateId('label__companies_search_no_result')}
                                </div>}
                            {companiesKeywordResults?.status === 'in_progress' && <div className={`p-[10px] italic`}>
                                {Translator.translateId('label__companies_search_searching')}
                            </div>}
                            {companiesKeywordResults?.results?.length > 0 && companiesKeywordResults.results.map((company: CompanyInterface, index: number) =>
                                <div className={`p-[10px] hover:bg-${theme.value}-primary-200 cursor-pointer`}
                                     key={`company-${index}`} onClick={() => selectCompanyFromResults(company)}>
                                    {company.name}
                                </div>)}
                        </div>
                    </div>
                    <div className={`mt-[10px] flex gap-[10px]`}>
                        <div
                            className={`p-[5px] w-[40px] h-[40px] border-[1px] border-${theme.value}-primary-950 rounded flex items-center justify-center`}
                            onClick={toggleInjectFakeCoupon}>
                            {!form.fields.injectFakeCoupon.value ? '' : <CheckIcon className={`w-[20px] h-[20px]`}/>}
                        </div>
                        <div className={`flex items-center cursor-pointer`} onClick={toggleInjectFakeCoupon}>
                            {Translator.translateId('label__caa_inject_fake_coupon')}
                        </div>
                    </div>
                    {form.error !== undefined && <div className={`pt-[10px] text-red-600`}>
                        {Translator.translateId(form.error.id)}
                    </div>}
                    {form.success !== undefined && <div className={`pt-[10px] text-green-600`}>
                        {Translator.translateId(form.success.id)}
                    </div>}
                    <div className={`flex justify-end py-[10px]`}>
                        <button type={'submit'}
                                className={`block w-full border-[1px] border-${theme.value}-primary-950 p-[5px] rounded cursor-pointer text-center text-${theme.value}-primary-50 bg-${theme.value}-primary-950`}>{Translator.translateId('cta__save')}</button>
                    </div>
                </form>
            </div>
        </div>
    </PageTemplate>;
}
