import {Link} from "react-router-dom";
import {isFeatureAllowed, isLoggedIn, isUserGroup} from "../../Signals/UserAccountSignal";
import Translator from "../../Utils/Translator";
import {AdministratorIcon} from "../../Icons/AdministratorIcon";
import {CompanyIcon} from "../../Icons/CompanyIcon";
import {DealIcon} from "../../Icons/DealIcon";
import {VideoIcon} from "../../Icons/VideoIcon";
import {NewsIcon} from "../../Icons/NewsIcon";
import {CouponAutoApplyIcon} from "../../Icons/CouponAutoApplyIcon";
import {theme} from "../../Signals/ThemeSignal";
import {HomeIcon} from "../../Icons/HomeIcon";
import { CloudIcon } from '../../Icons/CloudIcon';

export function BackOfficeSidebar() {
    return <div className={`p-[10px] text-xl`}>
        <Link to={`/`} className={`flex gap-[10px] mt-[10px] items-center`}>
            <HomeIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__home')}
        </Link>
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_administrators', 'rightRead') && <Link to={`/back-office/administrators`} className={`flex gap-[10px] mt-[5px] items-center`}>
            <AdministratorIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_administrators')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_companies', 'rightRead') && <Link to={`/back-office/companies`} className={`flex gap-[10px] mb-[5px] items-center`}>
            <CompanyIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_companies')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_coupon_auto_apply', 'rightRead') && <Link to={`/back-office/coupon-auto-applies`} className={`flex gap-[10px] py-[10px] items-center`}>
            <CouponAutoApplyIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_coupon_auto_applies')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_coupons', 'rightRead') && <Link to={`/back-office/coupons`} className={`flex gap-[10px] py-[10px] items-center`}>
            <DealIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_coupons')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_youtube', 'rightUpdate') && <Link to={`/back-office/youtube/update`} className={`flex gap-[10px] py-[10px] items-center`}>
            <VideoIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_youtube')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_news', 'rightRead') && <Link to={`/back-office/news`} className={`flex gap-[10px] py-[10px] items-center`}>
            <NewsIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_news')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_weather', 'rightRead') && <Link to={`/back-office/weathers/api-keys/update`} className={`flex gap-[10px] py-[10px] items-center`}>
            <CloudIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office_weathers')}
        </Link>}
    </div>;
}

interface BackOfficeHeaderLinksInterface {
    toggleMobileMenu: () => void;
}
export function BackOfficeHeaderLinks({toggleMobileMenu}: BackOfficeHeaderLinksInterface) {
    return <>
        <Link to={"/"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__home')}
            </div>
        </Link>
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_administrators', 'rightRead') && <Link to={"/back-office/administrators"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_administrators')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_companies', 'rightRead') && <Link to={"/back-office/companies"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_companies')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_coupon_auto_apply', 'rightRead') && <Link to={"/back-office/coupon-auto-applies"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_coupon_auto_applies')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_coupons', 'rightRead') && <Link to={"/back-office/coupons"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_coupons')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_youtube', 'rightUpdate') && <Link to={"/back-office/youtube/update"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_youtube')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_news', 'rightRead') && <Link to={"/back-office/news"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_news')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && isFeatureAllowed('back_office_weather', 'rightRead') && <Link to={"/back-office/weathers/api-keys/update"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office_weathers')}
            </div>
        </Link>}
    </>;
}