import {signal} from "@preact/signals-react";
import firebase from "firebase/compat/app";
import 'firebase/database';
import {getDatabase, ref, onValue} from 'firebase/database';

const subscribedRefs: any = {};
const subscribedRefsTimeout: any = {};
const subscribedRefDefaultTimeout = 60; // seconds

interface FirebaseConfigInterface {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSender: string;
    appId: string;
}

interface FirebaseRealTimeDatabaseInterface {
    config: FirebaseConfigInterface|undefined;
    firebaseApp: undefined|any;
    firebaseDatabaseConnection: undefined|any;
}

export const firebaseRealTimeDatabaseSignal = signal<FirebaseRealTimeDatabaseInterface>({
    config: undefined,
    firebaseApp: undefined,
    firebaseDatabaseConnection: undefined,
});

export function setFirebaseConfig(config: FirebaseConfigInterface) {
    firebaseRealTimeDatabaseSignal.value = {
        ...firebaseRealTimeDatabaseSignal.value,
        config
    }
}

export function addRef(path: string, actions: (action: any) => void, timeoutSeconds: number = subscribedRefDefaultTimeout) {
    connectFirebaseRealTimeDatabase();
    if (
        firebaseRealTimeDatabaseSignal.value.firebaseApp === undefined
        || firebaseRealTimeDatabaseSignal.value.firebaseDatabaseConnection === undefined
    ) {
        return;
    }

    if (undefined !== subscribedRefs[path]) {
        subscribedRefs[path]();
    }

    const currentRef = ref(firebaseRealTimeDatabaseSignal.value.firebaseDatabaseConnection, path);
    let isFirst = true;
    subscribedRefs[path] = onValue(currentRef, (snapshot: any) => {
        if (isFirst) {
            isFirst = false;
            return;
        }

        if (null ===snapshot.val() || undefined === snapshot.val()) {
            return;
        }
        const keys = Object.keys(snapshot.val());
        if (keys.length === 0) {
            return;
        }
        const lastIndex = keys[keys.length-1];

        const firebaseAction = snapshot.val()[lastIndex];

        actions(firebaseAction);
    });

    if (undefined == subscribedRefsTimeout[path]) {
        subscribedRefsTimeout[path] = null;
    } else {
        clearTimeout(subscribedRefsTimeout[path]);
    }

    if (timeoutSeconds !== 0) {
        subscribedRefsTimeout[path] = setTimeout(() => {
            subscribedRefs[path]();
        }, timeoutSeconds*1000);
    }
}

export function closeRef(path: string) {
    if (subscribedRefs[path] === undefined) {
        return;
    }
    subscribedRefs[path]();
}

function connectFirebaseRealTimeDatabase() {
    if (firebaseRealTimeDatabaseSignal.value.firebaseDatabaseConnection !== undefined || firebaseRealTimeDatabaseSignal.value.config === undefined) {
        return;
    }

    const app = firebase.initializeApp(firebaseRealTimeDatabaseSignal.value.config);
    firebaseRealTimeDatabaseSignal.value = {
        ...firebaseRealTimeDatabaseSignal.value,
        firebaseApp: app,
        firebaseDatabaseConnection: getDatabase(app)
    };
}

export function disconnectFirebaseRealTimeDatabase() {
    if (firebaseRealTimeDatabaseSignal.value.firebaseApp === undefined) {
        return;
    }

    firebaseRealTimeDatabaseSignal.value.firebaseApp.delete();
    firebaseRealTimeDatabaseSignal.value = {
        ...firebaseRealTimeDatabaseSignal.value,
        firebaseApp: undefined,
        firebaseDatabaseConnection: undefined,
    }
}
