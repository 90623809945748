import {DateTimeInterface} from "./DateTimeInterface";
import Translator from '../Utils/Translator';

export const positions = [
    {id: 'position_1', label: Translator.translateId('label__news_position_1')},
    {id: 'position_2', label: Translator.translateId('label__news_position_2')},
    {id: 'position_3', label: Translator.translateId('label__news_position_3')},
];

export interface NewsConfigInterface {
    id: string;
    url: string;
    name: string;
    description: string|null;
    lang: string;
    imageSelector: null|string;
    titleSelector: null|string;
    descriptionSelector: null|string;
    sourceDateTimeSelector: null|string;
    lastSuccessDateTime: null|DateTimeInterface;
    lastExecutionDateTime: null|DateTimeInterface;
    isBroken: null|string;
    position: 'position_1'|'position_2'|'position_3';
}
