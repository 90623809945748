import {NewsInterface} from "../../Interfaces/NewsInterface";
import {theme} from "../../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import Translator from "../../Utils/Translator";
import { isValidUrl } from '../../Utils/String';

interface NewsSecondaryInterface {
    news: NewsInterface;
    className?: string;
}
export function NewsSecondary({news, className = ''}: NewsSecondaryInterface) {
    const block = (content: any) => {
        if (news.url) {
            return <Link to={news.url} className={className} target={`_blank`}>
                {content}
            </Link>;
        }

        return <>
            {content}
        </>;
    };

    return block(<div className={`bg-white w-full p-[10px] mt-[10px]`}>
        <div className={`text-xl font-bold`}>
            {news.title}
        </div>
        <div>
            <img src={news.image} alt={news.imageCredit ?? ''} className={`w-full`}/>
        </div>
        {null !== news.imageCredit && <div className={`text-gray-400 text-center break-words`}>{news.imageCredit}</div>}
        <div>
            {news.description}
        </div>
        <div className={`mt-[10px] italic text-gray-500 text-center`}>
            {news.sourceDateTime}
        </div>
        <div className={`mt-[10px] text-gray-500 text-center`}>
            <span className={`underline`}>{isValidUrl(news.url) && new URL(news.url).hostname}</span>
        </div>
    </div>);
}
