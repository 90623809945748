import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {useEffect, useState} from "react";
import {theme} from "../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import Translator from "../Utils/Translator";
import {FormHook} from "../Hooks/FormHook";
import {
    LABEL_CAA_CREATION_FORM_ERROR,
    LABEL_CAA_URL_REGEXP,
} from "../Constants/Label";
import Input from "../Components/Common/Input/Input";
import {CouponAutoApplyInterface} from "../Interfaces/CouponAutoApplyInterface";
import {ExclamationTriangledIcon} from "../Icons/ExclamationTriangledIcon";
import {CheckIcon} from "../Icons/CheckIcon";
import {
    BACK_OFFICE_ALL_COUPON_AUTO_APPLIES,
    BACK_OFFICE_CREATE_COUPON_AUTO_APPLY,
    BACK_OFFICE_CREATE_COUPON_AUTO_APPLY_FORM_VALIDATOR,
} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeCouponAutoApplies() {
    const [caas, setCaas] = useState([]);
    const [isCreating, setIsCreating] = useState(false);

    const {shouldSubmit, form, handleChange, setValues, submitError, submitLock, loadConstrains} = FormHook(['urlRegExp']);

    const onSubmit = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            BACK_OFFICE_CREATE_COUPON_AUTO_APPLY,
            {
                urlRegExp: form.fields.urlRegExp.value
            },
            () => {
                setValues({
                    name: '',
                    internalName: '',
                })
                loadCaas();
            },
            () => submitError(LABEL_CAA_CREATION_FORM_ERROR)
        );
    };

    useEffect(() => {
        loadConstrains(BACK_OFFICE_CREATE_COUPON_AUTO_APPLY_FORM_VALIDATOR);
    }, []);


    const loadCaas = () => {
        NewApiManager.get(
            BACK_OFFICE_ALL_COUPON_AUTO_APPLIES,
            (response: any) => setCaas(response.data)
        );
    };

    useEffect(() => {
        loadCaas();
    }, []);

    const toggleIsCreating = () => {
        setIsCreating(() => !isCreating);
    }

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <BackButton link={'/back-office'} label={'label__back_office_coupon_auto_applies'}/>
            <div className={`mt-[10px] flex gap-[10px]`}>
                {!isCreating && <div className={`border-dashed border-${theme.value}-primary-950 p-[10px] border-[1px] rounded cursor-pointer flex items-center`} onClick={toggleIsCreating}>
                    {Translator.translateId('cta__add_caa_configuration')}
                </div>}
                {isCreating && <div className={`border-dashed border-${theme.value}-primary-950 p-[10px] border-[1px] rounded cursor-pointer`}>
                    <form className={`flex gap-[10px]`} onSubmit={onSubmit}>
                        <Input className={'min-w-[200px]'} form={form} handleChange={handleChange} name={'urlRegExp'}
                               placeholder={LABEL_CAA_URL_REGEXP}/>
                        <button type={'submit'} className={`border-[1px] border-${theme.value}-primary-950 p-[10px] rounded bg-${theme.value}-primary-950 text-${theme.value}-primary-50`}>{Translator.translateId('cta__submit')}</button>
                        <button className={`border-[1px] border-red-700 p-[10px] rounded text-${theme.value}-primary-50 bg-red-600`} onClick={toggleIsCreating}>{Translator.translateId('cta__cancel')}</button>
                    </form>
                </div>}
                {caas.map((caa: CouponAutoApplyInterface) => <Link to={`/back-office/coupon-auto-applies/${caa.id}/update`} key={`caa-${caa.id}`}>
                    <div className={`hover:bg-${theme.value}-primary-200 rounded p-[10px] border-[1px] border-${theme.value}-primary-950 h-[70px] flex items-center gap-[5px]`}>
                        {caa.totalSelector !== null && caa.couponSelector !== null && caa.submitSelector !== null && caa.company !== null ? <CheckIcon className={`w-20px h-[20px]`}/> : <ExclamationTriangledIcon className={`w-20px h-[20px]`}/>}
                        {caa.urlRegExp}
                    </div>
                </Link>)}
            </div>

        </div>
    </PageTemplate>;
}
