interface SvgInterface {
    className?: string;
}
export function RecIcon({className = ''}: SvgInterface) {
    return <svg className={className} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <title/>
        <g data-name="Layer 2" id="Layer_2">
            <g id="Interface-Solid"><g id="interface-solid-multimedia-rec-button">
                <path d="M15,10a5,5,0,1,0,5,5A4.97764,4.97764,0,0,0,15,10Z" fill="none"/>
                <path d="M15,0A15,15,0,1,0,30,15,15.01641,15.01641,0,0,0,15,0Zm0,20a5,5,0,1,1,5-5A5,5,0,0,1,15,20Z"/>
            </g>
            </g>
        </g>
    </svg>;
}