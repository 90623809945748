import { theme } from '../../Signals/ThemeSignal';
import { UserInterface } from '../../Interfaces/UserInterface';
import QRCode from 'react-qr-code';
import { CopyButton } from '../Common/CopyButton';
import Translator from '../../Utils/Translator';

interface UserQrCodeModalInterface {
    user: UserInterface;
    toggleModal?: () => void;
}

export function UserQrCodeModal({user, toggleModal = () => {}}: UserQrCodeModalInterface) {
    const profileLink = `${process.env.REACT_APP_FRONT_URL}/users/${user.id}`;

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={toggleModal}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`}>
            <div className={`bg-${theme.value}-primary-50 rounded-xl p-[10px]`}>
                <div className={`text-2xl underline text-center`}>
                    {user.login}
                </div>
                <QRCode className={`my-[10px]`} value={profileLink}/>
                <CopyButton value={profileLink} label={Translator.translateId('label__copy_link')}/>
            </div>
        </div>
    </>;
}
