import { isLoggedIn, isUserGroup, userAccount } from "../../Signals/UserAccountSignal";
import {Link} from "react-router-dom";
import Translator from "../../Utils/Translator";
import {MaintenanceIcon} from "../../Icons/MaintenanceIcon";
import {theme} from "../../Signals/ThemeSignal";
import {ProfileIcon} from "../../Icons/ProfileIcon";
import {HomeIcon} from "../../Icons/HomeIcon";

export function ProfileSidebar() {
    return <div className={`p-[10px] text-xl`}>
        <Link to={`/`} className={`flex gap-[10px] mt-[10px] mb-[5px] items-center`}>
            <HomeIcon className={`w-[30px] stroke-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__home')}
        </Link>
        {isLoggedIn() && userAccount.value !== undefined && <Link to={`/users/${userAccount.value.id}`} className={`flex gap-[10px] py-[10px] items-center`}>
            <ProfileIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__profile')}
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && <Link to={`/back-office`} className={`flex gap-[10px] py-[10px] items-center`}>
            <MaintenanceIcon className={`w-[30px] fill-${theme.value}-primary-950`}/>
            {Translator.translateId('cta__back_office')}
        </Link>}
    </div>;
}
interface ProfileHeaderLinksInterface {
    toggleMobileMenu: () => void;
}
export function ProfileHeaderLinks({toggleMobileMenu}: ProfileHeaderLinksInterface) {
    return <>
        <Link to={"/"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__home')}
            </div>
        </Link>
        {isLoggedIn() && <Link to={"/users/me"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__profile')}
            </div>
        </Link>}
        {isLoggedIn() && isUserGroup('admin') && <Link to={"/back-office"} onClick={toggleMobileMenu}>
            <div className={`p-[10px] hover:bg-${theme.value}-primary-200 rounded`}>
                {Translator.translateId('cta__back_office')}
            </div>
        </Link>}
    </>;
}