import {signal} from "@preact/signals-react";
import {LocalStorage} from "../Utils/LocalStorage";
import {parseJwt} from "../Utils/Jwt";
import { registerDevice } from './DeviceIdSignal';
import { ImageInterface } from '../Interfaces/ImageInterface';

export interface UserAccountInterface {
    id: string;
    firstname: string;
    lastname: string;
    jwt: string;
    login: string;
    accountGroup: 'user'|'admin';
    features: {
        [name: string]: {
            [right: string]: 1|0
        }
    };
    profileImage: ImageInterface|null;
}

const storageValue = LocalStorage.getUserAccount();
export const userAccount = signal<undefined|UserAccountInterface>(storageValue);
export function setUserAccount(newUserAccount?: UserAccountInterface, fn: any = () => {}) {
    userAccount.value = newUserAccount;
    LocalStorage.setUserAccount(newUserAccount ?? {}, fn);
    registerDevice();
}

export function logOut(event: any = undefined, isAuto: boolean = false) {
    userAccount.value = undefined;
    LocalStorage.removeUserAccount();
    registerDevice();

    if (LocalStorage.storageContext === 'web') {
        document.location.href = `/logged-out${isAuto ? '?auto=1' : ''}`;
    }
}

export function isLoggedIn() {
    return userAccount.value !== undefined;
}

export function extractJwtData() {
    if (userAccount.value?.jwt === undefined) {
        return undefined;
    }

    return parseJwt(userAccount.value.jwt);
}

export function getUserFeatureRights(featureInternalName: string) {
    if (userAccount.value?.features === undefined || userAccount.value.features[featureInternalName] === undefined) {
        return undefined;
    }

    return userAccount.value.features[featureInternalName];
}

export function isFeatureAllowed(featureInternalName: string, rightName: 'rightCreate'|'rightUpdate'|'rightRead'|'rightDelete') {
    const featureRights = getUserFeatureRights(featureInternalName);
    if (featureRights === undefined) {
        return false;
    }

    return featureRights[rightName] === 1;
}

export function isUserGroup(group: 'user'|'admin') {
    if (userAccount.value?.accountGroup === undefined) {
        return false;
    }

    return userAccount.value.accountGroup === group;
}

export function updateUserProfileImage(image: ImageInterface|null) {
    let data = userAccount.value;
    // @ts-ignore
    data['profileImage'] = image;

    setUserAccount(data);
}
