import {NewsInterface} from "../../Interfaces/NewsInterface";
import {theme} from "../../Signals/ThemeSignal";
import {Link} from "react-router-dom";
import Translator from "../../Utils/Translator";
import { isValidUrl } from '../../Utils/String';

interface NewsPremiumInterface {
    news: NewsInterface;
}
export function NewsPremium({news}: NewsPremiumInterface) {
    const block = (content: any) => {
        if (news.url) {
            return <Link to={news.url} target={`_blank`}>
                {content}
            </Link>;
        }

        return <>
            {content}
        </>;
    };

    return block(<div className={`bg-white w-full p-[10px] mt-[10px]`}>
        <div className={`text-2xl font-bold`}>
            {news.title}
        </div>
        <div className={`md:flex`}>
            <div className={`md:w-[50%]`}>
                <div className={`flex justify-center mt-[10px]`}>
                    <img src={news.image} alt={news.imageCredit ?? ''} className={`w-full`}/>
                </div>
                {null !== news.imageCredit && <div className={`text-gray-400 text-center break-words`}>{news.imageCredit}</div>}
            </div>
            <div className={`md:w-[50%] px-[10px] mt-[5px] text-xl`}>
                <div>
                    {news.description}
                </div>
                <div className={`mt-[10px] italic text-gray-500 text-center`}>
                    {news.sourceDateTime}
                </div>
                <div className={`mt-[10px] text-gray-500 text-center max-w-full`}>
                    <span className={`underline`}>{isValidUrl(news.url) && new URL(news.url).hostname}</span>
                </div>
            </div>
        </div>
    </div>);
}
