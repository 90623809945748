import {
    activateRemote,
    changeReadTypeProcess, disableRemote, endVideoProcess, executeRemoteActions, getPlayingVideoIndex,
    hasVideos,
    initPlayerProcess, isNextPreviousEnabled,
    nextVideoProcess,
    pauseProcess, playByVideoIndexProcess, playingMediaList,
    playProcess, previousVideoProcess, removeAllProcess, removeProcess, reorderItems, shuffleProcess
} from "../../Signals/PlayingMediaListSignal";
import {theme} from "../../Signals/ThemeSignal";
import YouTube, {YouTubePlayer} from "react-youtube";
import {ListIcon} from "../../Icons/ListIcon";
import {RecIcon} from "../../Icons/RecIcon";
import {RepeatAllIcon} from "../../Icons/RepeatAllIcon";
import {RepeatOneIcon} from "../../Icons/RepeatOneIcon";
import {PreviousIcon} from "../../Icons/PreviousIcon";
import {PlayIcon} from "../../Icons/PlayIcon";
import {PauseIcon} from "../../Icons/PauseIcon";
import {NextIcon} from "../../Icons/NextIcon";
import {MonitorYoutubeIcon} from "../../Icons/MonitorYoutubeIcon";
import {DotsIcon} from "../../Icons/DotsIcon";
import {VideoDataInterface} from "../../Interfaces/VideoDataInterface";
import {ShuffleIcon} from "../../Icons/ShuffleIcon";
import {useEffect, useState} from "react";
import Translator from "../../Utils/Translator";
import {PlaylistInterface} from "../../Interfaces/PlaylistInterface";
import {BackIcon} from "../../Icons/BackIcon";
import {ReloadIcon} from "../../Icons/ReloadIcon";
import {CheckIcon} from "../../Icons/CheckIcon";
import {FormHook} from "../../Hooks/FormHook";
import Input from "../Common/Input/Input";
import {LABEL_CAA_CREATION_FORM_ERROR, LABEL_PLAYLIST_CARD_NAME} from "../../Constants/Label";
import {ExpandIcon} from "../../Icons/ExpandIcon";
import {MinimizeIcon} from "../../Icons/MinimizeIcon";
import {isLoggedIn} from "../../Signals/UserAccountSignal";
import './NewYoutubePlayer.css';
import {InternetMobileIcon} from "../../Icons/InternetMobileIcon";
import {DotIcon} from "../../Icons/DotIcon";
import QRCode from "react-qr-code";
import {deviceId} from "../../Signals/DeviceIdSignal";
import {
    CREATE_PLAYLIST,
    CREATE_PLAYLIST_FORM_VALIDATOR,
    PLAYLIST_VIDEO_TOGGLE,
    SELF_PLAYLISTS,
    YOUTUBE_REMOTE_ACTIONS
} from "../../Constants/ApiUrls";
import {NewApiManager} from "../../Utils/NewApiManager";
import { ExclamationTriangledIcon } from '../../Icons/ExclamationTriangledIcon';
import { Drag, DragDrop, Drop } from '../Common/DragAndDrop/SimpleDragAndDrop';
import { addRef } from '../../Signals/FirebaseDatabaseSignal';
import { CopyButton } from '../Common/CopyButton';

export default function NewYoutubePlayer() {
    const remoteRefPath = `remote-${deviceId.value}`;

    const [videoIndexForOptions, setVideoIndexForOptions] = useState<number|undefined>(undefined);
    const [optionsModalIsVisible, setOptionsModalIsVisible] = useState<boolean>(false);
    const [playlistModalIsVisible, setPlaylistModalIsVisible] = useState<boolean>(false);
    const [remoteModalIsVisible, setRemoteModalIsVisible] = useState<boolean>(false);
    const [savablePlaylistsModalIsVisible, setSavablePlaylistsModalIsVisible] = useState<boolean>(false);
    const [videoIndexForSave, setVideoIndexForSave] = useState<number|undefined>(undefined);

    useEffect(() => {
        if (window.location.href.indexOf("/youtube-remote/") > -1) {
            return;
        }
        disableRemote();
    }, []);

    if (window.location.href.indexOf("/youtube-remote/") > -1) {
        return <></>;
    }

    const closeModals = () => {
        setOptionsModalIsVisible(false);
        setPlaylistModalIsVisible(false);
        setSavablePlaylistsModalIsVisible(false);
        setRemoteModalIsVisible(false);
        setVideoIndexForOptions(undefined);
    }

    const openOptionsModal = (videoIndex: number|undefined) => {
        closeModals();
        setOptionsModalIsVisible(true);
        setVideoIndexForOptions(videoIndex);
    }

    const openSavablePlaylistsModal = (videoIndex: number|undefined) => {
        closeModals();
        setSavablePlaylistsModalIsVisible(true);
        setVideoIndexForSave(videoIndex);
    }

    const onReady = (e: YouTubePlayer) => {
        initPlayerProcess(e);
    }

    const opts = {
        height: "100%",
        width: "100%",
    };


    const togglePlayer = () => {
        const youtubePlayerScreenContainer = document.getElementById('youtube-player-container');
        const youtubeRemoteOnlyContainer = document.getElementById('youtube-remote-only-container');
        const youtubePlayerScreenElement = document.getElementById('youtube-player');
        const youtubePlayerPlaylistContainer = document.getElementById('youtube-player-playlist');

        if (youtubePlayerScreenContainer == null || youtubeRemoteOnlyContainer === null || youtubePlayerScreenElement == null || youtubePlayerPlaylistContainer == null) {
            return;
        }

        youtubePlayerScreenContainer.classList.toggle('hidden');
        youtubeRemoteOnlyContainer.classList.toggle('hidden');
        youtubePlayerScreenElement.classList.toggle('hidden');
        youtubePlayerScreenContainer.classList.toggle('top-[calc(100%-50px)]');
        youtubePlayerPlaylistContainer.classList.toggle('h-[calc(100%-50px)]');

    };

    const toggleExpand = () => {
        const youtubePlayerScreenElement = document.getElementById('youtube-player');
        const youtubePlayerScreenContainer = document.getElementById('youtube-player-container');
        const youtubePlayerPlaylistContainer = document.getElementById('youtube-player-playlist');
        const youtubePlayerExpandIcon = document.getElementById('youtube-player-expand-icon');
        const youtubePlayerMinimizeIcon = document.getElementById('youtube-player-minimize-icon');

        if (youtubePlayerScreenContainer === null || youtubePlayerScreenElement === null || youtubePlayerScreenElement.firstElementChild === null || youtubePlayerPlaylistContainer === null || youtubePlayerExpandIcon === null || youtubePlayerMinimizeIcon === null) {
            return;
        }
        youtubePlayerScreenElement.firstElementChild.classList.toggle('sm:!h-[500px]');
        youtubePlayerScreenElement.firstElementChild.classList.toggle('sm:w-[500px]');

        youtubePlayerScreenContainer.classList.toggle('sm:w-[500px]');
        youtubePlayerScreenContainer.classList.toggle('sm:left-[calc(100%-500px)]');
        youtubePlayerScreenContainer.classList.toggle('sm:top-[calc(100%-550px)]');

        youtubePlayerPlaylistContainer.classList.toggle('sm:w-[500px]');

        youtubePlayerExpandIcon.classList.toggle('hidden');
        youtubePlayerMinimizeIcon.classList.toggle('hidden');
    }

    const onDrop = (result: any) => {
        reorderItems(result.source.index, result.destination.index);
    }

    return <>
        {optionsModalIsVisible && <OptionsModal closeModals={closeModals} videoIndex={videoIndexForOptions} openSavablePlaylistsModal={openSavablePlaylistsModal}/>}
        {playlistModalIsVisible && <PlaylistModal onDrop={onDrop} closeModals={closeModals} openOptionsModal={openOptionsModal} videoIndex={videoIndexForSave}/>}
        {savablePlaylistsModalIsVisible && <SavablePlaylistsModal closeModals={closeModals} openOptionsModal={openOptionsModal} videoIndex={videoIndexForSave}/>}
        {remoteModalIsVisible && <RemoteModal remoteRefPath={remoteRefPath} closeModals={closeModals}/>}
        <div id={`youtube-player-container`} className={`
            ${!hasVideos() && 'hidden'}
            fixed z-transition bg-${theme.value}-primary-50 border-${theme.value}-primary-950
            min-[1720px]:h-[100vh] min-[1720px]:top-0 min-[1720px]:border-l-[1px] 
            sm:right-0 sm:w-[300px]
            top-[calc(100%-350px)] h-[350px] w-full
            `}>
            <div id={`youtube-player`}>
                <YouTube
                    onReady={onReady}
                    onPlay={playProcess}
                    onPause={pauseProcess}
                    onEnd={endVideoProcess}
                    opts={opts}
                />
            </div>
            <Remote openRemoteModal={() => setRemoteModalIsVisible(true)} togglePlayer={togglePlayer} setPlaylistModalIsVisible={setPlaylistModalIsVisible} toggleExpand={toggleExpand} openOptionsModal={openOptionsModal}/>
            <div className={`h-[calc(100%-350px)] overflow-y-auto min-[1720px]:border-${theme.value}-primary-950 min-[1720px]:border-t-[1px]`} id={`youtube-player-playlist`}>
                <DragDrop onDragEnd={onDrop}>
                    <Drop id={'playlist'}>
                        {playingMediaList.value.items.map((videoData: VideoDataInterface, index: number) => <VideoCard idPrefix={'playlist'} video={videoData} key={`player-video-${index}`} index={index} openOptionsModal={openOptionsModal}/>)}
                    </Drop>
                </DragDrop>
            </div>
        </div>
        <Remote openRemoteModal={() => setRemoteModalIsVisible(true)} id={`youtube-remote-only-container`} className={`w-full top-[calc(100%-50px)] fixed sm:w-[300px] right-0 hidden z-transition`} togglePlayer={togglePlayer} setPlaylistModalIsVisible={setPlaylistModalIsVisible} toggleExpand={toggleExpand} openOptionsModal={openOptionsModal} showExpandMinimize={false}/>
    </>;
}

interface RemoteInterface {
    setPlaylistModalIsVisible: (value: boolean) => void;
    toggleExpand: () => void;
    togglePlayer: () => void;
    openOptionsModal: (videoIndex: number|undefined) => void;
    openRemoteModal: () => void;
    className?: string;
    id?: string;
    showExpandMinimize?: boolean;
}
function Remote({setPlaylistModalIsVisible, toggleExpand, togglePlayer, openRemoteModal, openOptionsModal, className = '', id = '', showExpandMinimize = true}: RemoteInterface) {
    return <div id={id} className={`${className} flex h-[50px] justify-between items-center p-[10px] bg-${theme.value}-primary-200`}>
        <div className={`flex gap-[10px] justify-center items-center`}>
            <div className={`min-[1720px]:hidden`} onClick={() => setPlaylistModalIsVisible(true)}>
                <ListIcon className={`cursor-pointer w-[20px] fill-${theme.value}-primary-950`}/>
            </div>
            {showExpandMinimize && <div onClick={toggleExpand} className={`sm:block hidden cursor-pointer`}>
                <ExpandIcon id={'youtube-player-expand-icon'} className={`w-[20px]`}/>
                <MinimizeIcon id={'youtube-player-minimize-icon'} className={`w-[30px] hidden`}/>
            </div>}
            <div onClick={changeReadTypeProcess}
                 className={`cursor-pointer flex height-[30px] items-center justify-center`}>
                {playingMediaList.value.readType === 'no-repeat' &&
                    <RecIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                {playingMediaList.value.readType === 'repeat-all' &&
                    <RepeatAllIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
                {playingMediaList.value.readType === 'repeat-one' &&
                    <RepeatOneIcon className={`w-[20px] fill-${theme.value}-primary-950`}/>}
            </div>
        </div>
        <div className={`flex justify-center items-center`}>
            <div className={`w-[30px]`}>
                {isNextPreviousEnabled('previous') && <PreviousIcon onClick={previousVideoProcess}
                                                                    className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}/>}
            </div>
            {playingMediaList.value.status === 'pause' && <div>
                <PlayIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}
                          onClick={() => playProcess()}/>
            </div>}
            {playingMediaList.value.status === 'play' && <div onClick={pauseProcess}>
                <PauseIcon className={`cursor-pointer w-[40px] fill-${theme.value}-primary-950`}/>
            </div>}
            <div className={`w-[30px]`}>
                {isNextPreviousEnabled('next') &&
                    <NextIcon onClick={nextVideoProcess}
                              className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}/>}
            </div>
        </div>
        <div className={`flex items-center`}>
            <div onClick={shuffleProcess}>
                <ShuffleIcon className={`cursor-pointer w-[20px] fill-${theme.value}-primary-950`}/>
            </div>
            <InternetMobileIcon
                className={`w-[30px] cursor-pointer ${!playingMediaList.value.isRemoteActivated ? 'fill-red-600' : 'fill-green-600'}`}
                onClick={openRemoteModal}/>
            <MonitorYoutubeIcon className={`cursor-pointer w-[30px] fill-${theme.value}-primary-950`}
                                onClick={togglePlayer}/>
            <div className={`w-[15px]`}>
                <div className={`cursor-pointer hover:bg-${theme.value}-primary-300 h-[30px] flex items-center`}
                     onClick={() => openOptionsModal(getPlayingVideoIndex())}>
                    <DotsIcon
                        className={`w-[15px] stroke-${theme.value}-primary-950 fill-${theme.value}-primary-950`}/>
                </div>
            </div>
        </div>
    </div>
}

interface OptionsModalInterface {
    closeModals: () => void;
    videoIndex: number | undefined;
    openSavablePlaylistsModal: (videoIndex?: number | undefined) => void;
}

function OptionsModal({closeModals, videoIndex, openSavablePlaylistsModal}: OptionsModalInterface) {
    const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={closeModals}/>
        <div
            className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px]`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px]`}>
                {videoIndex !== undefined &&
                    <div className={`mb-[5px] border-${theme.value}-primary-950 border-b-[1px]`}>
                        <VideoCard video={playingMediaList.value.items[videoIndex]} index={videoIndex}
                                   isDraggable={false}
                                   idPrefix={'options-modal'}
                                   showOptions={false}
                                   playable={false}/>
                        <div className={`my-[5px]`}>
                            {isLoggedIn() &&
                                <div className={`hover:bg-${theme.value}-primary-200 p-[5px] rounded cursor-pointer`}
                                     onClick={() => openSavablePlaylistsModal(videoIndex)}>
                                    {Translator.translateId('cta__save')}
                                </div>}
                            <div className={`hover:bg-${theme.value}-primary-200 p-[5px] rounded cursor-pointer`}
                                 onClick={() => confirmDelete ? (() => {
                                     removeProcess(videoIndex);
                                     closeModals();
                                 })() : setConfirmDelete(true)}>
                                {Translator.translateId(confirmDelete ? 'cta__confirm' : 'cta__delete_video')}
                            </div>
                        </div>
                    </div>}
                <div className={`font-bold p-[5px]`}>
                    {Translator.translateId(`label__videos_counter`, undefined, {counter: playingMediaList.value.items.length})}
                </div>
                {isLoggedIn() && <div className={`hover:bg-${theme.value}-primary-200 p-[5px] rounded cursor-pointer`}
                                      onClick={() => openSavablePlaylistsModal()}>
                    {Translator.translateId('cta__save_all_videos')}
                </div>}
                <div className={`hover:bg-${theme.value}-primary-200 p-[5px] rounded cursor-pointer`}
                     onClick={() => confirmDeleteAll ? (() => {
                         removeAllProcess();
                         closeModals();
                     })() : setConfirmDeleteAll(true)}>
                    {Translator.translateId(confirmDeleteAll ? 'cta__confirm' : 'cta__delete_all_video')}
                </div>
            </div>
        </div>
    </>;
}

interface PlaylistModalInterface {
    closeModals: () => void;
    openOptionsModal: (videoIndex: number) => void;
    videoIndex: number | undefined;
    onDrop: (result: any) => void;
}

function PlaylistModal({closeModals, openOptionsModal, onDrop}: PlaylistModalInterface) {
    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={closeModals}/>
        <div
            className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px] max-h-[calc(100vh-50px)] overflow-y-auto`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px]`}>
                <DragDrop onDragEnd={onDrop}>
                    <Drop id={'playlist-modal'}>
                        {playingMediaList.value.items.map((videoData: VideoDataInterface, index: number) => <VideoCard
                            idPrefix={'playlist-modal'}
                            className='z-top'
                            video={videoData} key={`player-video-${index}`} index={index} openOptionsModal={() => {
                            openOptionsModal(index)
                        }}/>)}
                    </Drop>
                </DragDrop>
            </div>
        </div>
    </>;
}

interface RemoteModalInterface {
    remoteRefPath: string;
    closeModals: () => void;
}

function RemoteModal({remoteRefPath, closeModals}: RemoteModalInterface) {
    const handleActivateRemote = () => {
        activateRemote(true);
        addRef(remoteRefPath, (action: any) => {
            switch(action.performedAction) {
                case 'added':
                    NewApiManager.get(
                        YOUTUBE_REMOTE_ACTIONS(deviceId.value),
                        (response: any) => {
                            response.data.forEach((data: any) => executeRemoteActions(data))
                        }
                    );
                    break;
            }
        }, 0);
    }

    const handleDisableRemote = () => {
        disableRemote(true);
    }

    const remoteUrl = `${process.env.REACT_APP_FRONT_URL}/youtube-remote/${deviceId.value}`;

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={closeModals}/>
        <div
            className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px] max-h-[calc(100vh-50px)] overflow-y-auto`}>
            <div className={`bg-${theme.value}-primary-50 rounded`}>
                <div className={`w-full sm:border-b-0 border-${theme.value}-primary-950 border-b-[1px] p-[10px]`}>
                    <div
                        className={`flex justify-center items-center border-[1px] border-${theme.value}-primary-950 rounded`}
                    >
                        {playingMediaList.value.isRemoteActivated ? <div>
                            <DotIcon className={`w-[20px] fill-green-500 absolute`}/>
                            <DotIcon className={`w-[20px] fill-green-500 animate-ping`}/>
                        </div> : <DotIcon className={`w-[20px] fill-red-500`}/>}
                        {Translator.translateId(!playingMediaList.value.isRemoteActivated ? 'cta__disabled_youtube_player' : 'cta__enabled_youtube_player')}
                    </div>
                    <div className={`justify-center flex p-[10px] cursor-pointer`} onClick={() => !playingMediaList.value.isRemoteActivated ? handleActivateRemote() : handleDisableRemote()}>
                        <QRCode value={remoteUrl} className={`w-[290px] h-[290px] ${!playingMediaList.value.isRemoteActivated && 'opacity-10'}`}/>
                        {!playingMediaList.value.isRemoteActivated && <div className={`w-[300px] h-[300px] flex gap-[10px] font-bold justify-center items-center absolute p-[10px]`}>
                            <ExclamationTriangledIcon className={`w-[50px] fill-red-500`}/> {Translator.translateId(`cta__disabled_youtube_player_message`)}
                        </div>}
                    </div>
                    <CopyButton label={Translator.translateId(`cta__copy_remote_page_link`)} value={remoteUrl}/>
                </div>
            </div>
        </div>
    </>;
}

interface SavablePlaylistsModalInterface {
    closeModals: () => void;
    openOptionsModal?: (videoIndex: number | undefined) => void;
    videoIndex: number | undefined;
}

function SavablePlaylistsModal({
                                   closeModals, openOptionsModal = () => {
    }, videoIndex
                               }: SavablePlaylistsModalInterface) {
    const [allSavedPlaylistId, setAllSavedPlaylistId] = useState<undefined | string>(undefined);
    const [playlists, setPlaylists] = useState<PlaylistInterface[] | undefined>(undefined);
    const {form, shouldSubmit, handleChange, setValues, submitError, submitSuccess, submitLock, loadConstrains} = FormHook(['name']);

    useEffect(() => {
        loadConstrains(CREATE_PLAYLIST_FORM_VALIDATOR);
        loadSavablePlaylists();
    }, []);

    useEffect(() => {
        if (allSavedPlaylistId !== undefined) {
            setTimeout(() => {
                setAllSavedPlaylistId(undefined);
            }, 2000);
        }
    }, [allSavedPlaylistId]);


    const loadSavablePlaylists = () => {
        let videoId = undefined;
        if (videoIndex !== undefined) {
            const currentVideo = playingMediaList.value.items[videoIndex];
            if (currentVideo !== undefined) {
                videoId = currentVideo.videoId;
            }
        }

        NewApiManager.get(
            `${SELF_PLAYLISTS}${videoIndex !== undefined ? `?doesExists=${videoId}` : ''}`,
            (response: any) => setPlaylists(response.data)
        );
    }

    const toggleSaveVideoInPlaylist = (playlistId: string) => {
        let videoIdsToSave: string[] = [];
        let toggleVideos = false;
        if (videoIndex !== undefined) {
            const currentVideo = playingMediaList.value.items[videoIndex];
            if (currentVideo?.videoId === undefined) {
                return;
            }
            videoIdsToSave.push(currentVideo.videoId);
            toggleVideos = true;
        } else {
            // @ts-ignore
            videoIdsToSave = playingMediaList.value.items.filter((video:VideoDataInterface) => video.videoId !== undefined).map((video: VideoDataInterface) => video.videoId);
        }

        NewApiManager.post(
            PLAYLIST_VIDEO_TOGGLE(playlistId),
            {
                videoIds: videoIdsToSave,
                toggleVideos
            },
            () => {
                if (videoIndex !== undefined) {
                    loadSavablePlaylists();
                } else {
                    setAllSavedPlaylistId(playlistId);
                }
            }
        );
    }
    const submitPlaylist = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (!shouldSubmit()) {
            return;
        }
        submitLock();

        NewApiManager.post(
            CREATE_PLAYLIST,
            {
                name: form.fields.name.value
            },
            () => {
                submitSuccess();
                setValues({
                    name: '',
                })
                loadSavablePlaylists();
            },
            () => submitError(LABEL_CAA_CREATION_FORM_ERROR)
        );
    }

    return <>
        <div className={`fixed top-0 left-0 w-full h-[100vh] opacity-50 bg-${theme.value}-primary-600 z-modal`}
             onClick={closeModals}/>
        <div className={`fixed z-modal-content top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[300px] max-h-[calc(100vh-50px)] overflow-y-auto`}>
            <div className={`bg-${theme.value}-primary-50 rounded p-[10px]`}>
                <div className={`flex justify-between items-center`}>
                    <div className={`p-[10px]`}>
                        <BackIcon className={`w-[20px] cursor-pointer fill-${theme.value}-primary-950`}
                                  onClick={() => openOptionsModal(videoIndex)}/>
                    </div>
                    <div className={`p-[10px]`} onClick={loadSavablePlaylists}>
                        <ReloadIcon className={`w-[30px] cursor-pointer fill-${theme.value}-primary-950`}/>
                    </div>
                </div>
                {playlists?.map((playlist: PlaylistInterface, index: number) => <div
                    onClick={() => toggleSaveVideoInPlaylist(playlist.id)}
                    className={`hover:bg-${theme.value}-primary-400 cursor-pointer p-[10px] flex items-center gap-[10px]`}
                    key={`playlist-${index}`}>
                    {videoIndex !== undefined && <div
                        className={`border-[1px] border-${theme.value}-primary-950 p-[5px] rounded w-[20px] h-[20px]`}>
                        {playlist.alreadyAdded && <CheckIcon className={`w-[10px]`}/>}
                    </div>}
                    {allSavedPlaylistId === playlist.id && <div
                        className={`border-[1px] border-${theme.value}-primary-950 p-[5px] rounded w-[20px] h-[20px]`}>
                        <CheckIcon className={`w-[10px]`}/>
                    </div>}
                    {playlist.name}
                </div>)}
                <div className={`p-[5px] border-b-[1px] border-${theme.value}-primary-950 mb-[10px] font-bold`}>
                    {playlists?.length !== undefined && playlists.length === 0 && Translator.translateId(`label__no_playlist`)}
                </div>
                <form onSubmit={submitPlaylist}>
                    <div className={`pb-[10px] font-bold`}>
                        {Translator.translateId('label__create_playlist')}
                    </div>
                    <Input form={form} name={'name'} handleChange={handleChange}
                           placeholder={LABEL_PLAYLIST_CARD_NAME}/>
                    <button type={'submit'}
                            className={`mt-[10px] w-full border-[1px] p-[10px] border-${theme.value}-primary-950 text-${theme.value}-primary-50 rounded bg-${theme.value}-primary-950`}>{Translator.translateId('cta__submit')}</button>
                </form>
            </div>
        </div>
    </>;
}

interface VideoCardInterface {
    video: VideoDataInterface;
    index: number;
    openOptionsModal?: (videoIndex: number) => void;
    showOptions?: boolean;
    playable?: boolean;
    idPrefix: string;
    className?: string;
    isDraggable?: boolean;
}

function VideoCard({
                       idPrefix, video, index, showOptions = true, playable = true, isDraggable = true, className = '', openOptionsModal = () => {
    }
                   }: VideoCardInterface) {

    const playingVideoIndex = getPlayingVideoIndex();

    const dragWrap = (children: any) => {
        if (!isDraggable) {
            return children;
        }

        return <Drag id={`${idPrefix}-video-${index}`} index={index}>
            {children}
        </Drag>
    }

    return dragWrap(
        <div
            className={`rounded flex gap-[10px] p-[5px] cursor-pointer ${className} ${playingVideoIndex === index ? `bg-${theme.value}-primary-200` : `bg-${theme.value}-primary-50`}`}>
            <div
                className={`flex justify-center items-center max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px] overflow-hidden`}>
                <img onClick={() => playable && playByVideoIndexProcess(index)} src={video.image.url}
                     className={`${playingVideoIndex === index ? `rounded-full w-[80px] h-[80px] animate-[spin_5s_linear_infinite]` : 'rounded'}`}/>
            </div>
            <div onClick={() => playable && playByVideoIndexProcess(index)} className={`w-full`}>
                <div className={`line-clamp-2 font-bold`}>
                    {video.title}
                </div>
                <div className={`line-clamp-2`}>
                    {video.channelTitle}
                </div>
            </div>
            {showOptions && <div className={`flex justify-center items-center hover:bg-${theme.value}-primary-300 cursor-pointer`} onClick={() => openOptionsModal(index)}>
                <DotsIcon className={`w-[15px]`}/>
            </div>}
        </div>
    );
}
