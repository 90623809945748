import {useState} from "react";
import Translator, {TranslationDataInterface} from "../../../Utils/Translator";
import {theme} from "../../../Signals/ThemeSignal";
import TogglerHook from "../../../Hooks/Toggler/TogglerHook";
import {validateInput} from "../../../Utils/FormUtils";
import {CheckRoundedIcon} from "../../../Icons/CheckRoundedIcon";
import {CrossRoundedIcon} from "../../../Icons/CrossRoundedIcon";
import {ExclamationTriangledIcon} from "../../../Icons/ExclamationTriangledIcon";

interface InputInterface {
    form: any;
    name: string;
    placeholder?: TranslationDataInterface;
    handleChange: (e: any) => void;
    className?: string;
    textareaClassName?: string;
    handleFocus?: (e: any) => void;
    handleBlur?: (e: any) => void;
    autoFocus?: boolean;
}
function TextArea({form, name, placeholder, handleChange, className = '', textareaClassName = '', handleFocus = () => {}, handleBlur = () => {}, autoFocus = false}: InputInterface) {
    const {isVisible: isCriteriaVisible, toggleVisibility: toggleCriteriaVisibility, ref} = TogglerHook();

    const [hasTyped, setHasTyped] = useState(false);

    const onChange = (e: any) => {
        handleChange(e);
        setHasTyped(() => true);
    }

    const hasError = form.fields[name].errors.length > 0;
    const isSuccess = form.fields[name].errors.length === 0 && form.fields[name].success.length > 0;

    const {errors: errorsBeforeTyping, success: successBeforeTyping} = validateInput('', form.fields[name].constrains);

    return <div className={`${className} text-[20px] flex items-center ${hasTyped && hasError && 'border-red-500'} ${hasTyped && isSuccess && 'border-green-500'} rounded bg-${theme.value}-primary-50`}>
        <div className={`w-full rounded bg-white`}>
            <textarea autoFocus={autoFocus}
                className={`focus:outline-0 p-[10px] w-full rounded bg-white ${textareaClassName}`}
                name={name} value={form.fields[name].value}
                placeholder={placeholder !== undefined ? Translator.translate(placeholder) : ''} onChange={onChange}
                onFocus={handleFocus} onBlur={handleBlur}/>

        </div>
        {(errorsBeforeTyping.length > 0 || successBeforeTyping.length > 0 || hasError) &&
            <div ref={ref} onClick={() => toggleCriteriaVisibility(undefined, true)}
                 className={`w-[40px] h-[40px] flex items-center justify-center border-l-[1px] border-black ${hasTyped && hasError ? 'border-red-500' : ''} ${isSuccess ? 'border-green-500' : ''}`}>
            {hasTyped && isSuccess && <CheckRoundedIcon className={"w-[20px] stroke-green-500"}/>}
            {hasTyped && hasError && <CrossRoundedIcon className={"w-[20px] stroke-red-500"}/>}
            {(!hasTyped || (!hasError && !isSuccess)) && <ExclamationTriangledIcon className={"w-[20px] fill-yellow-500"}/>}

            {isCriteriaVisible && <div className={`w-[300px] absolute ml-[-350px] bg-${theme.value}-primary-50 border-${theme.value}-primary-950 border-[1px] ${hasTyped && hasError && 'border-red-500'} ${hasTyped && isSuccess && 'border-green-500'} rounded`}>
                {hasTyped && form.fields[name].errors.length > 0 && form.fields[name].errors.map((error: TranslationDataInterface, index: number) => <div className={"p-[5px] bg-red-500"} key={`error-${index}`}>
                    {Translator.translate(error)}
                </div>)}
                {hasTyped && form.fields[name].success.length > 0 && form.fields[name].success.map((success: TranslationDataInterface, index: number) => <div className={"p-[5px] bg-green-500"} key={`success-${index}`}>
                    {Translator.translate(success)}
                </div>)}

                {(!hasTyped || (!hasError && !isSuccess)) && errorsBeforeTyping.map((label: TranslationDataInterface, index: number) => <div className={"p-[5px]"} key={`normal-error-${index}`}>
                    {Translator.translate(label)}
                </div>)}
                {(!hasTyped || (!hasError && !isSuccess)) && successBeforeTyping.map((label: TranslationDataInterface, index: number) => <div className={"p-[5px]"} key={`normal-success-${index}`}>
                    {Translator.translate(label)}
                </div>)}
            </div>}
        </div>}
    </div>;
}

export default TextArea;
