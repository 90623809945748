import PageTemplate from "../Components/PageTemplate/PageTemplate";
import {BackButton} from "../Components/Common/BackButton/BackButton";
import {Link} from "react-router-dom";
import Translator from "../Utils/Translator";
import {theme} from "../Signals/ThemeSignal";
import {GearIcon} from "../Icons/GearIcon";
import {useEffect, useState} from "react";
import {NewsInterface} from "../Interfaces/NewsInterface";
import {NewsCard} from "../Components/News/NewsCard";
import {BACK_OFFICE_ALL_NEWS} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";

export function BackOfficeNews() {
    const [news, setNews] = useState<NewsInterface[]|undefined>(undefined);

    useEffect(() => {
        NewApiManager.get(
            BACK_OFFICE_ALL_NEWS,
            (response: any) => setNews(response.data),
            () => setNews([])
        );
    }, []);

    return <PageTemplate sidebar={"back-office"}>
        <div className={`m-[10px]`}>
            <div className={`flex justify-between`}>
                <BackButton link={'/back-office'} label={'label__back_office_news'}/>
                <Link to={'/back-office/news-configs'} className={`bg-${theme.value}-primary-300 p-[10px] flex gap-[10px] items-center border-${theme.value}-primary-950 border-[1px] rounded text-xl`}>
                    <GearIcon className={`stroke-${theme.value}-primary-950 w-[30px]`}/>
                    {Translator.translateId('label__back_office_news_configs')}
                </Link>
            </div>
            <div className={`mt-[10px] flex gap-[10px] flex-wrap`}>
                {news !== undefined && news.map((news: NewsInterface) => <NewsCard key={`news-${news.id}`} news={news}/>)}
            </div>
        </div>
    </PageTemplate>;
}
