import logo from '../Images/logo.png';
import { KaraokePlayer } from '../Components/YoutubePlayer/KaraokePlayer';

export function Karaoke() {
    return <div>
        <div className={`p-[10px] border-b`}>
            <a href={'/'} className={`p-[10px] bg-blue-500 rounded-xl w-[50px] block`}>
                <img src={logo} className={`w-[50px]`}/>
            </a>
        </div>
        <KaraokePlayer/>
    </div>
}
