import {signal} from "@preact/signals-react";
import {LocalStorage} from "../Utils/LocalStorage";
import { VideoDataForPlayingMediaListInterface, VideoDataInterface } from "../Interfaces/VideoDataInterface";
import {YouTubePlayer} from "react-youtube";
import {deviceId} from "./DeviceIdSignal";
import {YoutubePlayerActionInterface} from "../Interfaces/YoutubePlayerActionInterface";
import {
    resetYoutubeVideoStatisticState,
    saveCompletedStatistic,
    savePlayStatistic
} from "../Statistics/YoutubeVideoStatistics";
import {UPDATE_YOUTUBE_DEVICE} from "../Constants/ApiUrls";
import {NewApiManager} from "../Utils/NewApiManager";
import moment from 'moment';
import { localDateTimeToUtc } from '../Utils/Date';

export let youtubePlayer: YouTubePlayer|undefined = undefined;
var transitionState: PlayingMediaListInterface|undefined = undefined;

export interface PlayingMediaListInterface {
    status: 'play'|'pause';
    readType: 'no-repeat'|'repeat-all'|'repeat-one';
    items: VideoDataForPlayingMediaListInterface[];
    isRemoteActivated: boolean;
}

const version = '0';
const LSItem = `playingMediaList-${version}`;
const defaultState: PlayingMediaListInterface = {
    readType: 'no-repeat',
    status: 'pause',
    items: [],
    isRemoteActivated: false,
};
export const playingMediaList = signal<PlayingMediaListInterface>(LocalStorage.get(LSItem) ?? defaultState);

function deleteOldLSIndexes() {
    localStorage.removeItem('playingMediaList');
}

export function activateRemote(resetActions: boolean = false) {
    startTransitionState();
    if (transitionState === undefined) return;
    transitionState.isRemoteActivated = true;
    completeTransitionState();

    updatePlayerInfo(resetActions);
}

export function updatePlayerInfo(resetActions: boolean = false) {
    NewApiManager.put(
        UPDATE_YOUTUBE_DEVICE(deviceId.value, resetActions),
        {
            lastState: JSON.stringify(playingMediaList.value)
        }
    );
}

export function disableRemote(resetActions: boolean = false) {
    startTransitionState();
    if (transitionState === undefined) return;
    transitionState.isRemoteActivated = false;
    completeTransitionState();

    NewApiManager.put(
        UPDATE_YOUTUBE_DEVICE(deviceId.value, resetActions),
        {
            lastState: JSON.stringify(playingMediaList.value)
        }
    );
}

export function initPlayerProcess(player: YouTubePlayer) {
    deleteOldLSIndexes();
    if (player !== undefined) {
        youtubePlayer = player;

        startTransitionState();
        if (transitionState === undefined) return;
        transitionState.status = 'pause';
        completeTransitionState();
        playByVideoIndex(getPlayingVideoIndex());
        setTimeout(() => {
            pauseProcess();
        }, 200);
    }
}

export function playByVideoIndexProcess(index: number) {
    playByVideoIndex(index);
}

export function playByVideoIndex(videoIndex: number) {
    if (playingMediaList.value.items[videoIndex] === undefined) {
        return;
    }

    // @ts-ignore
    const videoId = playingMediaList.value.items[videoIndex].videoId;
    youtubePlayer.target.loadVideoById(videoId);

    const videoData = getPlayingVideo();
    if (videoData?.videoId !== undefined) {
        savePlayStatistic(videoData.videoId);
    }

    startTransitionState();
    if (transitionState === undefined) return;
    transitionState.items = transitionState.items.map((videoData: VideoDataForPlayingMediaListInterface, currentIndex: number) => ({
        ...videoData,
        isPlaying: currentIndex === videoIndex,
    }));
    completeTransitionState();
}

export function getPlayingVideo() {
    const playingVideos = playingMediaList.value.items.filter((videoData: VideoDataForPlayingMediaListInterface) => videoData.isPlaying);
    if (playingVideos.length > 0) {
        return playingVideos[0];
    }

    return undefined;
}

export function addToListProcess(videoData: VideoDataInterface, play: boolean = false, shouldUpdatePlayerInfo: boolean = true) {
    startTransitionState();
    addItem(videoData);
    let playIndex = 0;
    if (play && transitionState !== undefined) {
        playIndex = transitionState.items.length-1;
    }
    if (!play && transitionState !== undefined && transitionState.items.length === 1) {
        play = true;
    }
    completeTransitionState();

    if (play && videoData.videoId !== undefined) {
        playByVideoIndex(playIndex);
    } else {
        if (shouldUpdatePlayerInfo) {
            updatePlayerInfo();
        }
    }
}

export function getPlayingVideoIndex() {
    const playingVideoIndex = playingMediaList.value.items.map((videoData: VideoDataForPlayingMediaListInterface) => videoData.isPlaying).indexOf(true);

    return playingVideoIndex === -1 ? 0 : playingVideoIndex;
}

export function nextVideoProcess() {
    startTransitionState();
    if (transitionState === undefined) return;
    const playingVideoIndex = getPlayingVideoIndex();
    const nextVideoIndex = getNextVideoIndex();
    completeTransitionState();

    if (playingMediaList.value.readType === 'repeat-one') {
        playByVideoIndex(playingVideoIndex);
        return;
    }

    if (playingVideoIndex !== nextVideoIndex && nextVideoIndex !== undefined) {
        playByVideoIndex(nextVideoIndex);
    }
}

export function previousVideoProcess() {
    startTransitionState();
    if (transitionState === undefined) return;
    const playingVideoIndex = getPlayingVideoIndex();
    const previousVideoIndex = getPreviousVideoIndex();
    completeTransitionState();

    if (playingMediaList.value.readType === 'repeat-one') {
        playByVideoIndex(playingVideoIndex);
        return;
    }

    if (playingVideoIndex !== previousVideoIndex && previousVideoIndex !== undefined) {
        playByVideoIndex(previousVideoIndex);
    }
}

export function playProcess() {
    if (youtubePlayer !== undefined && youtubePlayer.target.getPlayerState() !== undefined) {
        startTransitionState();
        if (youtubePlayer.target.getPlayerState() !== 1) {
            youtubePlayer.target.playVideo();
        }
        setPlay();
        completeTransitionState();
        updatePlayerInfo();
    }
}

export function pauseProcess() {
    if (youtubePlayer !== undefined && youtubePlayer.target.getPlayerState() !== undefined) {
        startTransitionState();
        setPause();
        completeTransitionState();
        if (youtubePlayer.target.getPlayerState() !== 2) {
            youtubePlayer.target.pauseVideo();
        }
        updatePlayerInfo();
    }
}

export function removeProcess(index: number) {
    startTransitionState();
    if (transitionState === undefined) return;
    const playingVideoIndex = getPlayingVideoIndex();
    const nextVideoIndex = getNextVideoIndex();
    const previousVideoIndex = getPreviousVideoIndex();

    let videoIndexToPlay: number|undefined = undefined;
    if (index === playingVideoIndex) {
        videoIndexToPlay = undefined === nextVideoIndex ? previousVideoIndex : nextVideoIndex;
    }

    if (videoIndexToPlay !== undefined) {
        playByVideoIndex(videoIndexToPlay);
    }

    removeItem(index);

    completeTransitionState();
    updatePlayerInfo();

    if (playingMediaList.value.items.length === 0) {
        pauseProcess();
        disableRemote();
    }
}

export function removeAllProcess() {
    transitionState = defaultState;
    completeTransitionState();
    resetYoutubeVideoStatisticState();
    pauseProcess();
    disableRemote();
    // resetPlayerAndRemoteKey(deviceId.value);
}

export function changeReadTypeProcess() {
    startTransitionState();
    if (transitionState === undefined) return;
    const types: any = ['no-repeat', 'repeat-all', 'repeat-one'];
    let typeIndex = types.indexOf(transitionState.readType);

    typeIndex++;
    if (typeIndex >= types.length) {
        typeIndex = 0;
    }
    setReadType(types[typeIndex]);
    completeTransitionState();
}

export function shuffleProcess() {
    startTransitionState();
    if (transitionState === undefined) return;
    shuffle();
    completeTransitionState();

    playByVideoIndex(0);
}

export function hasVideos() {
    return playingMediaList.value.items.length > 0;
}

function startTransitionState() {
    transitionState = {...playingMediaList.value};
}

function completeTransitionState() {
    if (transitionState === undefined) return;
    playingMediaList.value = transitionState;
    LocalStorage.set(LSItem, playingMediaList.value);
}

function setPause() {
    if (transitionState === undefined) return;
    transitionState = {
        ...transitionState,
        status: 'pause',
    };
}

function setPlay() {
    if (transitionState === undefined) return;
    transitionState = {
        ...transitionState,
        status: 'play',
    };
}

function setReadType(readType: 'no-repeat'|'repeat-all'|'repeat-one') {
    if (transitionState === undefined) return;
    transitionState.readType = readType;
}

function addItem(videoData: VideoDataInterface) {
    if (transitionState === undefined) return;


    transitionState = {
        ...transitionState,
        items: [
            ...transitionState.items,
            {
                ...videoData,
                isPlaying: false
            },
        ]
    };
}

function removeItem(index: number) {
    if (transitionState === undefined) return;
    transitionState.items.splice(index, 1);
}

function getNextVideoIndex() {
    if (transitionState === undefined) return;
    let videoIndex = getPlayingVideoIndex();
    if (videoIndex === undefined) {
        videoIndex = 0;
    }

    if (transitionState.readType !== 'repeat-one') {
        videoIndex++;
        if (videoIndex >= transitionState.items.length) {
            if (transitionState.readType === 'repeat-all') {
                return 0;
            }
            return undefined;
        }
    }

    return videoIndex;
}

function getPreviousVideoIndex() {
    if (transitionState === undefined) return;
    let videoIndex = getPlayingVideoIndex();
    if (videoIndex === undefined) {
        videoIndex = 0;
    }

    if (transitionState.readType !== 'repeat-one') {
        videoIndex--;
        if (videoIndex < 0) {
            if (transitionState.readType === 'repeat-all') {
                return playingMediaList.value.items.length-1;
            }
            return undefined;
        }
    }

    return videoIndex;
}
function shuffle() {
    if (transitionState === undefined) return;
    let newItemsOrder = [...transitionState.items];
    let currentIndex = newItemsOrder.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [newItemsOrder[currentIndex], newItemsOrder[randomIndex]] = [
            newItemsOrder[randomIndex], newItemsOrder[currentIndex]];
    }

    transitionState = {
        ...transitionState,
        items: newItemsOrder,
    }
}

export function isNextPreviousEnabled(changeType: 'next'|'previous') {
    const playingVideoIndex = getPlayingVideoIndex();

    return playingMediaList.value.readType !== 'repeat-one' && (
        playingMediaList.value.readType === 'repeat-all'
        || (changeType === 'previous' && playingVideoIndex !== undefined && playingVideoIndex > 0)
        || (changeType === 'next' && playingVideoIndex !== undefined && playingMediaList.value.items.length-1 > playingVideoIndex)
    );
}
 export function endVideoProcess() {
     // @ts-ignore
     const videoData = getPlayingVideo();
     if (videoData?.videoId !== undefined) {
         saveCompletedStatistic(videoData.videoId);
     }
     nextVideoProcess();
 }

 export function reorderItems(sourceIndex: number, destinationIndex: number) {
    startTransitionState();
    if (transitionState === undefined) {
        return;
    }

     const [draggedItem] = transitionState.items.splice(sourceIndex, 1);
     transitionState.items.splice(destinationIndex, 0, draggedItem);
    completeTransitionState();

     updatePlayerInfo();
 }

export function executeRemoteActions(actionToExecute: any) {
    const splittedActionContent = actionToExecute.actionContent.split(':');
    const action = splittedActionContent.shift();
    actionToExecute.actionContent = splittedActionContent.join(':');
    switch (action) {
        case 'playIndex':
            playByVideoIndexProcess(Number(actionToExecute.actionContent));
        break;
        case 'next':
            isNextPreviousEnabled('next') && nextVideoProcess();
        break;
        case 'previous':
            isNextPreviousEnabled('previous') && previousVideoProcess();
        break;
        case 'play':
            playProcess();
        break;
        case 'pause':
            pauseProcess();
        break;
        case 'remove':
            removeProcess(Number(actionToExecute.actionContent));
        break;
        case 'addVideo':
            // @ts-ignore
            addToListProcess(JSON.parse(actionToExecute.actionContent));
        break;
        case 'reorder':
            const splittedData = actionToExecute.actionContent.split('--');
            if (splittedData.length < 2) {
                return;
            }

            reorderItems(splittedData[0], splittedData[1]);
        break;
    }
}